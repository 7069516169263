import { gql } from "@apollo/client";

export const GET_TREATMENTS = gql`
  query getTreatmentsList(
    $where: treatments_bool_exp!
    $limit: Int!
    $offset: Int!
  ) {
    treatments(
      where: $where
      limit: $limit
      offset: $offset
      order_by: { id: asc }
    ) {
      data
      description
      descriptor
      form_id
      id
      image
      title
      form {
        template
        version
        id
        descriptor
      }
      prescriptions(order_by: { id: asc }) {
        body
        description
        id
        title
        treatment_id
      }
      category_id
      category {
        id
        title
        descriptor
      }
    }

    treatments_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_TREATMENT_BY_ID = gql`
  query getTreatmentById($id: Int!) {
    treatments_by_pk(id: $id) {
      created_at
      data
      description
      descriptor
      form_id
      id
      image
      title
      updated_at
      category_id
      prescriptions(order_by: { id: asc }) {
        id
        title
        description
        body
      }
      category {
        id
        title
        descriptor
      }
    }
  }
`;

export const GET_TREATMENT_CATEGORIES_RELATION = gql`
  query GetTreatmentCategoriesRelation($center_id: Int!) {
    treatment_categories(where: { center_id: { _eq: $center_id } }) {
      id
      title
      descriptor
    }
  }
`;

export const GET_FORMS = gql`
  query getFormsList($center_id: Int!) {
    form_schemas(where: { center_id: { _eq: $center_id } }) {
      descriptor
      id
      version
      title
    }
  }
`;

export const ADD_TREATMENT = gql`
  mutation addTreatment($object: treatments_insert_input!) {
    insert_treatments_one(object: $object) {
      id
    }
  }
`;

export const UPDATE_TREATMENT = gql`
  mutation updateTreatment($id: Int!, $object: treatments_set_input!) {
    update_treatments_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
    }
  }
`;

export const GET_TREATMENT_PRESCRIPTION_BY_ID = gql`
  query getTreatmentPrescriptionById($id: Int!) {
    treatment_prescriptions_by_pk(id: $id) {
      body
      description
      id
      title
      treatment_id
    }
  }
`;

export const ADD_TREATMENT_PRESCRIPTION = gql`
  mutation addTreatmentPrescription(
    $object: treatment_prescriptions_insert_input!
  ) {
    insert_treatment_prescriptions_one(object: $object) {
      id
    }
  }
`;

export const UPDATE_TREATMENT_PRESCRIPTION = gql`
  mutation updateTreatmentPrescription(
    $id: Int!
    $object: treatment_prescriptions_set_input!
  ) {
    update_treatment_prescriptions_by_pk(
      pk_columns: { id: $id }
      _set: $object
    ) {
      id
    }
  }
`;
