import { FormItemProps } from "../../../interfaces/Interfaces";
import { Text, FormControl, FormLabel, Box } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";

import QuillEditor from "react-quill";
import styles from "./styles.module.css";
import "react-quill/dist/quill.snow.css";
import { useDebounce } from "use-debounce";

export const RichTextBox = (props: FormItemProps) => {
  let item = props.item;
  let inputItemHandler = props.inputItemHandler;
  let state = props.state;
  let validator = props.validator;
  let IsInvalidMessage =
    validator && item.validation_rule
      ? validator.message(
          item.title,
          state[item.descriptor],
          item.validation_rule
        )
      : null;

  const [value, setValue] = useState((state && state[item.descriptor]) || "");

  useEffect(() => {
    if(inputItemHandler){
        inputItemHandler(value, item);
    }
  }, [value])

  return (
    <FormControl position="relative">
      {item.title.length > 0 && (
        <FormLabel flexDirection="column">
          {item.required && (
            <Text as="span" color="red.500" fontSize="14px" me="5px">
              *
            </Text>
          )}
          <Text as="span">{item.title}</Text>
        </FormLabel>
      )}
      <Box w="full" position="relative" className={styles.wrapper}>
        <QuillEditor
          className={styles.editor}
          theme="snow"
          value={value}
          onChange={(value) => setValue(value)}
        />
      </Box>
      {!item?.hide_validation_error && IsInvalidMessage}
    </FormControl>
  );
};
