import {
    Button,
    FormControl,
    FormHelperText,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    VStack,
    Select, Checkbox, Textarea, HStack, Box
} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import AddNewOptionModal from "./AddNewOptionModal";
import FieldValuesModal from "./FieldValuesModal";

const FieldModal = (props:any) => {

    const {
        isOpen,
        onClose,
        onSubmit,
        data,
        fields,
        indexes,
        indexH,
        availableFieldTypes
    } = props;

    const components = [
        {
           title: 'Address Box',
           value: 'AddressBox'
        },
        {
            title: 'Mobile Authorizer',
            value: 'MobileAuthorizer'
        }
    ];

    const types = [
        {
            title: 'Text Input Box',
            value: 'input'
        },
        {
            title: 'TextArea',
            value: 'textarea'
        },
        {
            title: 'Select Box',
            value: 'select'
        },
        {
            title: 'Group Box',
            value: 'group'
        },
        {
            title: 'CheckBox',
            value: 'checkbox'
        },
        {
            title: 'Radio Card',
            value: 'RadioCard'
        },
        {
            title: 'Radio Card Group',
            value: 'radioCardGroup'
        },
        {
            title: 'Option Box',
            value: 'option_box'
        },
        {
            title: 'Action Checkbox',
            value: 'action_checkbox'
        },
        {
            title: 'Action Option Box',
            value: 'action_option_box'
        },
        {
            title: 'Yes No Buttons',
            value: 'yes_no_button'
        },
        {
            title: 'Component',
            value: 'component'
        },
        {
            title: 'Alert',
            value: 'alert'
        },
        {
            title: 'Text',
            value: 'text'
        }
    ];

    const defaultFieldData = {
        title: '',
        descriptor: '',
        type: 'input',
        placeholder: '',
        text: '',
        place: 'end',
        field: '',
        default_value: '',
        component: '',
        required: false,
        validation_rule: '',
        values: {},
        sub_items: null,
        options: "{}",
        hide_validation_error: false

    };

    const [fieldData, setFieldData] = useState<any>(null);
    const [fieldValuesModalIsOpen, setFieldValuesModalIsOpen] = useState(false)

    const [actionCheckedValue, setActionCheckedValue] = useState<any>(null);
    const [actionUnCheckedValue, setActionUnCheckedValue] = useState<any>(null);
    const [yesButtonObject, setYesButtonObject] = useState<any>({
        title: '',
        value: '',
        action: '',
        show_type: '',
        description: '',
        descriptor: '',
    });
    const [noButtonObject, setNoButtonObject] = useState<any>({
        title: '',
        value: '',
        action: '',
        show_type: '',
        description: '',
        descriptor: '',
    });

    const needTextInput = [
        'checkbox',
        'actionCheckbox',
        'alert',
        'text',
        'input',
        'textarea',
        'group',
        'component',
        'radioCardGroup'
    ];

    const [actionOptionBoxValues, setActionOptionBoxValues] = useState<any>(null);

    const [addNewOptionModalOpen, setAddNewOptionModalOpen] = useState<boolean>(false);
    useEffect(() => {
        if (data) {
            setFieldData({
                title: data.title,
                descriptor: data.descriptor,
                type: data.type,
                text: data.text,
                place: '',
                field: '',
                placeholder: data.placeholder,
                default_value: data.default_value,
                component: data.component,
                required: data.required,
                validation_rule: data.validation_rule,
                values: data.values,
                sub_items: data.sub_items,
                options: data.options ? JSON.stringify(data.options) : {},
                hide_validation_error: data.hide_validation_error,
            })

            if (data.type === 'action_checkbox') {
                let checkedValue = (!!data.values.checked) && (data.values.checked.constructor === Object) ? 'advanced' : data.values.checked.toString();
                let uncheckedValue = (!!data.values.unchecked) && (data.values.unchecked.constructor === Object) ? 'advanced' : data.values.unchecked.toString();
                setActionCheckedValue(checkedValue);
                setActionUnCheckedValue(uncheckedValue);
            }

            if (data.type === 'yes_no_button') {
                setYesButtonObject(
                    {
                        title: data.values.yes?.title,
                        value: data.values.yes?.value,
                        action: data.values.yes?.action,
                        show_type: data.values.yes?.show_type ? data.values.yes.show_type : 'no',
                        description: data.values.yes?.description,
                        descriptor: data.values.yes?.descriptor,
                    }
                );

                setNoButtonObject(
                    {
                        title: data.values.no?.title,
                        value: data.values.no?.value,
                        action: data.values.no?.action,
                        show_type: data.values.no?.show_type ?  data.values.no.show_type : 'no',
                        description: data.values.no?.description,
                        descriptor: data.values.no?.descriptor,
                    }
                );
            }

            if (data.type === 'action_option_box') {
                setActionOptionBoxValues(data.values);
            }
        } else {
            setFieldData(defaultFieldData)
        }
    }, []);

    const getExpansionType = (value:string) => {
        if (typeof fieldData?.values[value]?.items !== typeof undefined) {
            return 'items';
        }

        if (typeof fieldData?.values[value]?.forms !== typeof undefined) {
            return 'form';
        }

        return fieldData?.values[value]?.toString();
    }

    const filterBeforeStore = () => {
        if (onSubmit) {
            let newFieldData = JSON.parse(JSON.stringify(fieldData));

            if (fieldData.type === 'yes_no_button') {
                let yesValues = JSON.parse(JSON.stringify(yesButtonObject));
                let noValues = JSON.parse(JSON.stringify(noButtonObject));

                if (yesValues.show_type === 'next_form') {
                    yesValues.forms = fieldData?.values?.yes?.forms ? fieldData?.values?.yes?.forms : [];
                    yesValues.has_added = false;
                } else {
                    delete yesValues.forms;
                    delete yesValues.has_added;
                    delete yesValues.descriptor;
                    yesValues.show_type = null;
                }

                if (noValues.show_type === 'next_form') {
                    noValues.forms = fieldData?.values?.no?.forms ? fieldData?.values?.no?.forms : [];
                    noValues.has_added = false;
                } else {
                    delete noValues.forms;
                    delete noValues.has_added;
                    delete noValues.descriptor;
                    noValues.show_type = null;
                }
                newFieldData.values = {
                    yes: {},
                    no: {},
                };
                newFieldData.values.yes = yesValues
                newFieldData.values.no = noValues
            } else if (fieldData.type === 'group' || fieldData.type === 'radioCardGroup') {
                newFieldData.sub_items = newFieldData.sub_items ? newFieldData.sub_items : [];
            } else if (fieldData.type === 'action_option_box') {
                newFieldData.values = actionOptionBoxValues ? actionOptionBoxValues : {};
            }

            if (newFieldData.options) {
                newFieldData.options = JSON.parse(newFieldData.options)
            } else {
                newFieldData.options = {};
            }

            onSubmit('field', data ? "update" : "create", newFieldData, indexH)
        }
    }

    return (
        <>
            { fieldData &&
                <Modal isOpen={isOpen} onClose={onClose} size='xl'>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>
                            {data?.title &&
                                <>
                                    Edit Field :
                                    <Box fontSize="16px" dangerouslySetInnerHTML={{__html: data.title}}></Box>
                                </>
                            }
                            {!data?.title &&  'Create New Field'}
                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <VStack w='full' gap='15px'>
                                <FormControl>
                                    <FormLabel>Type</FormLabel>
                                    <Select
                                        value={fieldData.type}
                                        onChange={(e) => {
                                            if (e.target.value === 'action_checkbox' || e.target.value === 'checkbox') {
                                                if (typeof fieldData.values.checked === typeof undefined) {
                                                    setFieldData({
                                                        ...fieldData, values: {
                                                            "checked": false,
                                                            "unchecked": false
                                                        },
                                                        type: e.target.value
                                                    })
                                                }
                                            } else {
                                                setFieldData({...fieldData, type: e.target.value, values: []})
                                            }

                                        }}
                                    >
                                        {availableFieldTypes && availableFieldTypes.length > 0 && availableFieldTypes.map((type:any) => {
                                            return (
                                                <option key={type.value} value={type.value}>{type.title}</option>
                                            )
                                        })}
                                        {(!availableFieldTypes || availableFieldTypes.length === 0) && types.map((type) => {
                                            return (
                                                <option key={type.value} value={type.value}>{type.title}</option>
                                            )
                                        })}
                                    </Select>
                                </FormControl>

                                { fieldData.type === 'component' &&
                                    <FormControl>
                                        <FormLabel>Component</FormLabel>
                                        <Select
                                            value={fieldData.component}
                                            onChange={(e) => {setFieldData({...fieldData, component: e.target.value})}}
                                        >
                                            {components.map((comp) => {
                                                return (
                                                    <option key={comp.value} value={comp.value}>{comp.title}</option>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                }

                                <FormControl>
                                    <FormLabel>Title</FormLabel>
                                    <Input type='text' value={fieldData.title} onChange={(e) => {setFieldData({...fieldData, title: e.target.value})}} />
                                </FormControl>

                                <FormControl>
                                    <FormLabel>Descriptor</FormLabel>
                                    <Input type='text' value={fieldData.descriptor} onChange={(e) => {setFieldData({...fieldData, descriptor: e.target.value})}} />
                                </FormControl>
                                {needTextInput.indexOf(fieldData.type) !== -1 &&
                                    <FormControl>
                                        <FormLabel>Text</FormLabel>
                                        <Textarea value={fieldData.text} onChange={(e) => {setFieldData({...fieldData, text: e.target.value})}} />
                                    </FormControl>
                                }
                                { (fieldData.type === 'select' || fieldData.type === 'option_box') &&
                                    <Button
                                        onClick={() => {
                                            setFieldValuesModalIsOpen(true)
                                        }}
                                    >
                                        Manage Options
                                    </Button>
                                }

                                <FormControl>
                                    <FormLabel>Placeholder</FormLabel>
                                    <Input type='text' value={fieldData.placeholder} onChange={(e) => {setFieldData({...fieldData, placeholder: e.target.value})}} />
                                </FormControl>
                                {fieldData.type === 'select' &&
                                    <FormControl>
                                        <FormLabel>Default Value</FormLabel>
                                        <Input type='text' value={fieldData.default_value} onChange={(e) => {setFieldData({...fieldData, default_value: e.target.value})}} />
                                    </FormControl>
                                }
                                { fieldData.type === 'yes_no_button' &&
                                    <HStack w='full'>
                                        <VStack w='full'>
                                            <FormControl>
                                                <FormLabel>Yes Title:</FormLabel>
                                                <Input
                                                    type='text'
                                                    value={yesButtonObject.title}
                                                    onChange={
                                                        (e) => {
                                                            setYesButtonObject({...yesButtonObject, title: e.target.value})
                                                        }
                                                    }
                                                ></Input>
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel>Yes Value:</FormLabel>
                                                <Input
                                                    type='text'
                                                    value={yesButtonObject.value}
                                                    onChange={
                                                        (e) => {
                                                            setYesButtonObject({...yesButtonObject, value: e.target.value})
                                                        }
                                                    }
                                                ></Input>
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel>Action:</FormLabel>
                                                <Select
                                                    value={yesButtonObject.action}
                                                    onChange={
                                                        (e) => {
                                                            setYesButtonObject({...yesButtonObject, action: e.target.value})
                                                        }
                                                    }
                                                >
                                                    <option>Select ...</option>
                                                    <option value='nextStep'>Go To Next Step</option>
                                                </Select>
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel>Is Extendable:</FormLabel>
                                                <Select
                                                    value={yesButtonObject.show_type}
                                                    onChange={
                                                        (e) => {
                                                            if (e.target.value === 'next_form') {
                                                                setYesButtonObject({...yesButtonObject, show_type: e.target.value})
                                                            } else {
                                                                setYesButtonObject({...yesButtonObject, show_type: 'no'})
                                                            }
                                                        }
                                                    }
                                                >
                                                    <option>Select ...</option>
                                                    <option value='no'>No</option>
                                                    <option value='next_form'>Yes</option>
                                                </Select>
                                            </FormControl>
                                            { yesButtonObject.show_type === 'next_form' &&
                                                <>
                                                    <FormControl>
                                                        <FormLabel>Descriptor:</FormLabel>
                                                        <Input
                                                            w='full'
                                                            value={yesButtonObject.descriptor}
                                                            onChange={
                                                                (e) => {
                                                                    setYesButtonObject({...yesButtonObject, descriptor: e.target.value})
                                                                }
                                                            }
                                                        ></Input>
                                                    </FormControl>
                                                    <FormControl>
                                                        <FormLabel>Description:</FormLabel>
                                                        <Textarea
                                                            w='full'
                                                            value={yesButtonObject.description}
                                                            onChange={
                                                                (e) => {
                                                                    setYesButtonObject({...yesButtonObject, description: e.target.value})
                                                                }
                                                            }
                                                        ></Textarea>
                                                    </FormControl>
                                                </>
                                            }
                                        </VStack>


                                        <VStack w='full'>
                                            <FormControl>
                                                <FormLabel>No Title:</FormLabel>
                                                <Input
                                                    type='text'
                                                    value={noButtonObject.title}
                                                    onChange={
                                                        (e) => {
                                                            setNoButtonObject({...noButtonObject, title: e.target.value})
                                                        }
                                                    }
                                                ></Input>
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel>No Value:</FormLabel>
                                                <Input
                                                    type='text'
                                                    value={noButtonObject.value}
                                                    onChange={
                                                        (e) => {
                                                            setNoButtonObject({...noButtonObject, value: e.target.value})
                                                        }
                                                    }
                                                ></Input>
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel>Action:</FormLabel>
                                                <Select
                                                    value={noButtonObject.action}
                                                    onChange={
                                                        (e) => {
                                                            setNoButtonObject({...noButtonObject, action: e.target.value})
                                                        }
                                                    }
                                                >
                                                    <option>Select ...</option>
                                                    <option value='nextStep'>Go To Next Step</option>
                                                </Select>
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel>Is Extendable:</FormLabel>
                                                <Select
                                                    value={noButtonObject.show_type}
                                                    onChange={
                                                        (e) => {
                                                            if (e.target.value === 'next_form') {
                                                                setNoButtonObject({...noButtonObject, show_type: e.target.value})
                                                            } else {
                                                                setNoButtonObject({...noButtonObject, show_type: 'no'})
                                                            }
                                                        }
                                                    }
                                                >
                                                    <option>Select ...</option>
                                                    <option value='no'>No</option>
                                                    <option value='next_form'>Yes</option>
                                                </Select>
                                            </FormControl>
                                            { noButtonObject.show_type === 'next_form' &&
                                                <>
                                                    <FormControl>
                                                        <FormLabel>Descriptor:</FormLabel>
                                                        <Input
                                                            w='full'
                                                            value={noButtonObject.descriptor}
                                                            onChange={
                                                                (e) => {
                                                                    setNoButtonObject({...noButtonObject, descriptor: e.target.value})
                                                                }
                                                            }
                                                        ></Input>
                                                    </FormControl>
                                                    <FormControl>
                                                        <FormLabel>Description:</FormLabel>
                                                        <Textarea
                                                            w='full'
                                                            value={noButtonObject.description}
                                                            onChange={
                                                                (e) => {
                                                                    setNoButtonObject({...noButtonObject, description: e.target.value})
                                                                }
                                                            }
                                                        ></Textarea>
                                                    </FormControl>
                                                </>
                                            }
                                        </VStack>
                                    </HStack>
                                }

                                { fieldData.type === 'checkbox' &&
                                    <HStack w='full'>
                                        <FormControl>
                                            <FormLabel>Checked Value</FormLabel>
                                            <Input type='text' value={fieldData?.values?.checked } onChange={(e) => {setFieldData({...fieldData, values: {...fieldData.values, checked: e.target.value}})}} />
                                        </FormControl>

                                        <FormControl>
                                            <FormLabel>UnChecked Value</FormLabel>
                                            <Input type='text' value={fieldData?.values?.unchecked } onChange={(e) => {setFieldData({...fieldData, values: {...fieldData.values, unchecked: e.target.value}})}} />

                                        </FormControl>
                                    </HStack>
                                }

                                { fieldData.type === 'action_option_box' &&
                                    <>
                                        <VStack w='full'>
                                            {
                                                actionOptionBoxValues && Object.keys(actionOptionBoxValues).map((opt:any, optKey:number) => {
                                                    return (
                                                        <VStack w='full' borderTop='1px solid #ccc' p='15px'>
                                                            <FormControl>
                                                                <HStack w='full' justifyContent='space-between'>
                                                                    <FormLabel>Title:</FormLabel>
                                                                    <Button
                                                                        mb='10px'
                                                                        onClick={
                                                                            (e) => {
                                                                                let temp:any = JSON.parse(JSON.stringify(actionOptionBoxValues));
                                                                                delete temp[opt];

                                                                                setActionOptionBoxValues(temp);
                                                                            }
                                                                        }
                                                                    >
                                                                        Remove This Option
                                                                    </Button>
                                                                </HStack>
                                                                <Input
                                                                    type='text'
                                                                    value={actionOptionBoxValues[opt].name}
                                                                    onChange={
                                                                        (e) => {
                                                                            let temp:any = JSON.parse(JSON.stringify(actionOptionBoxValues));
                                                                            temp[opt].name = e.target.value;

                                                                            setActionOptionBoxValues(temp);
                                                                        }
                                                                    }
                                                                ></Input>
                                                            </FormControl>
                                                            <FormControl>
                                                                <FormLabel>Value:</FormLabel>
                                                                <Input
                                                                    type='text'
                                                                    value={actionOptionBoxValues[opt].value}
                                                                    onChange={
                                                                        (e) => {
                                                                            let temp:any = JSON.parse(JSON.stringify(actionOptionBoxValues));
                                                                            temp[opt].value = e.target.value;

                                                                            setActionOptionBoxValues(temp);
                                                                        }
                                                                    }
                                                                ></Input>
                                                            </FormControl>
                                                            <FormControl>
                                                                <FormLabel>Descriptor:</FormLabel>
                                                                <Input
                                                                    type='text'
                                                                    value={actionOptionBoxValues[opt].descriptor}
                                                                    onChange={
                                                                        (e) => {
                                                                            let temp:any = JSON.parse(JSON.stringify(actionOptionBoxValues));
                                                                            temp[opt].descriptor = e.target.value;

                                                                            setActionOptionBoxValues(temp);
                                                                        }
                                                                    }
                                                                ></Input>
                                                            </FormControl>
                                                            <FormControl>
                                                                <FormLabel>Description:</FormLabel>
                                                                <Textarea
                                                                    value={actionOptionBoxValues[opt].description}
                                                                    onChange={
                                                                        (e) => {
                                                                            let temp:any = JSON.parse(JSON.stringify(actionOptionBoxValues));
                                                                            temp[opt].description = e.target.value;

                                                                            setActionOptionBoxValues(temp);
                                                                        }
                                                                    }
                                                                ></Textarea>
                                                            </FormControl>
                                                            <FormControl>
                                                                <FormLabel>Is Extendable:</FormLabel>
                                                                <Select
                                                                    value={actionOptionBoxValues[opt].show_type}
                                                                    onChange={
                                                                        (e) => {
                                                                            let temp:any = JSON.parse(JSON.stringify(actionOptionBoxValues));
                                                                            if (e.target.value === 'attached') {
                                                                                temp[opt].items = [];
                                                                                temp[opt].show_type = e.target.value;
                                                                            } else {
                                                                                delete temp[opt].items;
                                                                                delete temp[opt].show_type;
                                                                            }
                                                                            setActionOptionBoxValues(temp);
                                                                        }
                                                                    }
                                                                >
                                                                    <option>Select ...</option>
                                                                    <option value='no'>No</option>
                                                                    <option value='attached'>Yes</option>
                                                                </Select>
                                                            </FormControl>
                                                        </VStack>
                                                    );
                                                })
                                            }

                                            <Button
                                                onClick={() => setAddNewOptionModalOpen(true)}
                                            >
                                                Add New Option
                                            </Button>
                                        </VStack>
                                        {addNewOptionModalOpen &&
                                            <AddNewOptionModal
                                                onClose={() => setAddNewOptionModalOpen(false)}
                                                keys={actionOptionBoxValues ? Object.keys(actionOptionBoxValues) : []}
                                                onSubmit={(value:string) => {
                                                    let temp:any = JSON.parse(JSON.stringify(actionOptionBoxValues));
                                                    if (!temp) {
                                                        temp = {};
                                                    }
                                                    temp[value] = {
                                                        "descriptor": "",
                                                        "name": "",
                                                        "value": ""
                                                    };

                                                    setActionOptionBoxValues(temp);
                                                    setAddNewOptionModalOpen(false);
                                                }}
                                            ></AddNewOptionModal>
                                        }
                                    </>
                                }

                                { fieldData.type === 'action_checkbox' &&
                                    <HStack w='full'>
                                        <FormControl>
                                            <FormLabel>Checked Value:</FormLabel>

                                            <Select
                                                value={actionCheckedValue}
                                                onChange={(e) => {
                                                    setActionCheckedValue(e.target.value)
                                                }}
                                            >
                                                <option>Select ...</option>
                                                <option value='true'>True</option>
                                                <option value='false'>False</option>
                                                <option value='advanced'>Advanced Value</option>
                                            </Select>
                                            {actionCheckedValue === 'advanced' &&
                                                <VStack w='full'>
                                                    <FormControl>
                                                        <FormLabel>Descriptor</FormLabel>
                                                        <Input type='text' value={fieldData.values.checked.descriptor} onChange={(e) => {
                                                            setFieldData({...fieldData, values: {...fieldData.values, checked: {...fieldData.values.checked, descriptor: e.target.value}}})
                                                        }} />
                                                    </FormControl>

                                                    <FormControl>
                                                        <FormLabel>Text</FormLabel>
                                                        <Textarea value={fieldData.values.checked.description} onChange={(e) => {
                                                            setFieldData({...fieldData, values: {...fieldData.values, checked: {...fieldData.values.checked, description: e.target.value}}})
                                                        }} />
                                                    </FormControl>
                                                    <FormControl>
                                                        <FormLabel>Show Type:</FormLabel>
                                                        <Select
                                                            value={fieldData?.values?.checked?.show_type}
                                                            placeholder="select expantion place"
                                                            onChange={(e) => {
                                                                setFieldData({...fieldData, values: {...fieldData.values, checked: {...fieldData.values.checked, show_type: e.target.value}}})
                                                            }}
                                                        >
                                                            <option value='attached'>Attached to current field</option>
                                                            <option value='next_form'>Add as next form</option>
                                                            <option value='end_of_current_section'>End of current section</option>
                                                        </Select>
                                                    </FormControl>
                                                    <FormControl>
                                                        <FormLabel>Expansion Type:</FormLabel>
                                                        <Select
                                                            value={getExpansionType('checked')}
                                                            placeholder="select expansion type"
                                                            onChange={(e) => {
                                                                let temp:any = JSON.parse(JSON.stringify(fieldData));
                                                                if (e.target.value === 'forms') {
                                                                    delete temp.values['checked']?.items;
                                                                    temp.values['checked'].forms = [];
                                                                } else if (e.target.value === 'items') {
                                                                    delete temp.values['checked']?.forms;
                                                                    temp.values['checked'].items = [];
                                                                } else {
                                                                    delete temp.values['checked']?.forms;
                                                                    delete temp.values['checked']?.items;
                                                                }

                                                                setFieldData(temp);
                                                            }}
                                                        >
                                                            <option value='forms'>New Form</option>
                                                            <option value='items'>Add new fields</option>
                                                        </Select>
                                                    </FormControl>
                                                </VStack>
                                            }

                                            {/*<Input type='text' value={fieldData?.values?.checked == true ? 'true' : 'false'} onChange={(e) => {setFieldData({...fieldData, values: {...fieldData.values, checked: e.target.value}})}} />*/}
                                        </FormControl>

                                        <FormControl>
                                            <FormLabel>UnChecked Value</FormLabel>
                                            <Select
                                                value={actionUnCheckedValue}
                                                onChange={(e) => {
                                                    setActionUnCheckedValue(e.target.value)
                                                }}
                                            >
                                                <option>Select ...</option>
                                                <option value='true'>True</option>
                                                <option value='false'>False</option>
                                                <option value='advanced'>Advanced Value</option>
                                            </Select>
                                            {actionUnCheckedValue === 'advanced' &&
                                                <VStack w='full'>
                                                    <FormControl>
                                                        <FormLabel>Descriptor</FormLabel>
                                                        <Input type='text' value={fieldData.values.unchecked.descriptor} onChange={(e) => {
                                                            setFieldData({...fieldData, values: {...fieldData.values, unchecked: {...fieldData.values.unchecked, descriptor: e.target.value}}})
                                                        }} />
                                                    </FormControl>
                                                    <FormControl>
                                                        <FormLabel>Text</FormLabel>
                                                        <Textarea value={fieldData.values.unchecked.description} onChange={(e) => {
                                                            setFieldData({...fieldData, values: {...fieldData.values, unchecked: {...fieldData.values.unchecked, description: e.target.value}}})
                                                        }} />
                                                    </FormControl>
                                                    <FormControl>
                                                        <FormLabel>Show Type:</FormLabel>
                                                        <Select
                                                            value={fieldData?.values?.unchecked?.show_type}
                                                            placeholder="select expantion place"
                                                            onChange={(e) => {
                                                                setFieldData({...fieldData, values: {...fieldData.values, unchecked: {...fieldData.values.unchecked, show_type: e.target.value}}})
                                                            }}
                                                        >
                                                            <option value='attached'>Attached to current field</option>
                                                            <option value='next_form'>Add as next form</option>
                                                            <option value='end_of_current_section'>End of current section</option>
                                                        </Select>
                                                    </FormControl>
                                                    <FormControl>
                                                        <FormLabel>Expansion Type:</FormLabel>
                                                        <Select
                                                            value={getExpansionType('unchecked')}
                                                            placeholder="select expansion type"
                                                            onChange={(e) => {
                                                                let temp:any = JSON.parse(JSON.stringify(fieldData));

                                                                if (e.target.value === 'forms') {
                                                                    delete temp.values['unchecked']?.items;
                                                                    temp.values['unchecked'].forms = [];
                                                                } else if (e.target.value === 'items') {
                                                                    delete temp.values['unchecked']?.forms;
                                                                    temp.values['unchecked'].items = [];
                                                                } else {
                                                                    delete temp.values['unchecked']?.forms;
                                                                    delete temp.values['unchecked']?.items;
                                                                }

                                                                setFieldData(temp);
                                                            }}
                                                        >
                                                            <option value='forms'>New Form</option>
                                                            <option value='items'>Add new fields</option>
                                                        </Select>
                                                    </FormControl>
                                                </VStack>
                                            }
                                        </FormControl>
                                    </HStack>
                                }

                                <FormControl>
                                    <FormLabel>Options:(Like class, props, max_character, ...)</FormLabel>
                                    <Textarea value={fieldData.options} onChange={(e) => {setFieldData({...fieldData, options: e.target.value})}} ></Textarea>
                                </FormControl>

                                <FormControl>
                                    <FormLabel>Validation Rules</FormLabel>
                                    <Input type='text' value={fieldData.validation_rule} onChange={(e) => {setFieldData({...fieldData, validation_rule: e.target.value})}} />
                                </FormControl>

                                <FormControl>
                                    <Checkbox
                                        isChecked={fieldData.hide_validation_error}
                                        onChange={(e) => {setFieldData({...fieldData, hide_validation_error: e.target.checked})}}
                                    >
                                        Hide Validation Error
                                    </Checkbox>
                                </FormControl>

                                <FormControl>
                                    <Checkbox
                                        isChecked={fieldData.required}
                                        onChange={(e) => {setFieldData({...fieldData, required: e.target.checked})}}
                                    >
                                        Field Is Required
                                    </Checkbox>
                                </FormControl>
                            </VStack>

                            { fieldValuesModalIsOpen &&
                                <FieldValuesModal
                                    onClose={() => {setFieldValuesModalIsOpen(false)}}
                                    data={fieldData.values}
                                    onSubmit={(items:any) => {
                                        setFieldData({...fieldData, values: items});
                                        setFieldValuesModalIsOpen(false);
                                    }}
                                ></FieldValuesModal>
                            }

                        </ModalBody>

                        <ModalFooter>
                            <Button
                                variant='ghost'
                                mr={3}
                                onClick={onClose}
                            >
                                Close
                            </Button>
                            <Button
                                colorScheme='blue'
                                onClick={() => {filterBeforeStore()}}
                            >
                                Save
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            }
        </>
    );
}

export default FieldModal;