import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Card,
  CardBody,
  Container,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spinner,
  Square,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Link } from "@chakra-ui/react";
import { Link as ReactRouterLink, useParams } from "react-router-dom";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DeleteIcon,
  EditIcon,
  LinkIcon,
} from "@chakra-ui/icons";
import * as React from "react";
import { getPatientImageBasePath } from "../../../../helpers/S3Options";
import { useLazyQuery } from "@apollo/client";
import { GET_META_DATA, GET_PATIENT_DATA } from "../queries";
import { useEffect, useState } from "react";
import axiosInstance from "../../../../helpers/axiosInstance";
import AddDocumentModal from "./modals/AddDocumentModal";
import { IoIosResize } from "react-icons/io";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { getHRFormat } from "../../../../helpers/DateUtil";
import Confirmation from "../../../../components/Confirmation";
import useCustomToast from "../../../../hooks/useCustomToast";
import FileIcon from "../../../../components/FileIcon";
import { FiDownload } from "react-icons/fi";
import { cleanFolderName } from "../../../../helpers/StringUtil";
import {
  GetMetaDataQuery,
  GetMetaDataQueryVariables,
} from "../../../../gql/graphql";

const Documents = () => {
  const params = useParams();
  const toast = useCustomToast();

  const [basePath, setBasePath] = useState("");
  const [documents, setDocuments] = useState<Array<any> | null>(null);
  const [documentIsLoading, setDocumentIsLoading] = useState(true);
  const [addDocumentModalIsOpen, setAddDocumentModalIsOpen] = useState(false);
  const [deleteDocumentIsLoading, setDeleteDocumentIsLoading] = useState(false);
  const [keyToDelete, setKeyToDelete] = useState<string | null>(null);
  const [currentDocument, setCurrentDocument] = useState<any>({
    name: "",
    tag: "",
    type: "",
    url: "",
    index: 0,
  });
  const [documentsIsEmpty, setDocumentsIsEmpty] = useState(true);
  const [tags, setTags] = useState<Array<string>>([]);

  const [getMetaData, documentFoldersMetaData] = useLazyQuery<
    GetMetaDataQuery,
    GetMetaDataQueryVariables
  >(GET_META_DATA, {
    variables: {
      descriptor: "PATIENT_DOCUMENT_FOLDERS",
      centerId: Number(process.env.REACT_APP_CENTER_ID),
    },
  });

  const [getPatientData, { error, loading, data, called }] = useLazyQuery(
    GET_PATIENT_DATA,
    {
      fetchPolicy: "no-cache",
      variables: {
        id: params.pid,
      },
    }
  );

  const addSubFolder = (parent: any, child: any) => {};

  useEffect(() => {
    if (documents && documentFoldersMetaData?.data?.meta_data[0]?.data) {
      let toBeAddedFolder: any = [];
      let currentFolders = Object.keys(documents);
      let tempTags = currentFolders;

      for (let folder of documentFoldersMetaData?.data?.meta_data[0]?.data) {
        if (currentFolders.indexOf(folder.path) === -1) {
          toBeAddedFolder.push(basePath + folder.path);
        }
        tempTags.push(folder.path);
      }

      if (toBeAddedFolder.length > 0) {
        axiosInstance().post(
          "/storage/create_folder",
          {
            keys: toBeAddedFolder,
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem("access_token"),
            },
          }
        );
      }

      setTags(tempTags);
    }
  }, [documentFoldersMetaData, documents]);

  const getDocuments = async () => {
    setDocumentIsLoading(true);

    let list = await axiosInstance().post("/storage/list", {
      key: basePath,
    }, {
        headers: {
            "Authorization": 'Bearer ' + localStorage.getItem('access_token')
        }
    });

    if (list?.data?.data?.files) {
      let tempDocuments = [];
      let tempFolders: any = {
        "/": [],
      };
      let i = 0;
      for (let file of list?.data?.data?.files) {
        let tempKey = file.key.replace(basePath, "");
        file.index = i;
        if (file.type === "folder") {
          if (!tempFolders[tempKey]) {
            tempFolders[tempKey] = [];
          }
        } else {
          let tempFolderIndex = tempKey.replace(file.name, "");
          if (tempFolderIndex.length > 0) {
            tempFolders[tempFolderIndex].push(file);
          } else {
            tempFolders["/"].push(file);
          }
          setDocumentsIsEmpty(false);
        }
        tempDocuments.push(file);
        i++;
      }

      setDocuments(tempFolders);
    }

    setDocumentIsLoading(false);
  };

  useEffect(() => {
    getMetaData();
    if (params.pid) {
      getPatientData();
    }
  }, [params]);

  useEffect(() => {
    if (data?.patients_by_pk) {
      setBasePath(getPatientImageBasePath(data?.patients_by_pk) + "documents/");
    }
  }, [data]);

  useEffect(() => {
    if (basePath) {
      getDocuments();
    }
  }, [basePath]);

  return (
    <Container w="full" maxW="container.xl" mt="35px">
      <HStack flexWrap="wrap">
        <Button
          as={ReactRouterLink}
          to={`/dashboard/patient/${params.pid}`}
          display="flex"
          bg="transparent"
          borderRadius="100px"
          h="48px"
          w="120px"
          border="1px solid"
          borderColor="gray.300"
        >
          <ChevronLeftIcon fontSize="xl"></ChevronLeftIcon>
          <Text fontSize="md" mx="10px">
            Back
          </Text>
        </Button>

        <Heading fontSize="30px" mx="15px">
          Archived Documents
        </Heading>

        {basePath && (
          <HStack flex="1" justifyContent="end">
            <Button
              variant="btnNormal"
              mx="10px"
              onClick={() => {
                setAddDocumentModalIsOpen(true);
              }}
            >
              upload
            </Button>
          </HStack>
        )}
      </HStack>

      {documentIsLoading && (
        <Flex justifyContent="center" alignItems="center" h="100px" w="full">
          <Spinner></Spinner>
        </Flex>
      )}

      {!documentIsLoading && (
        <Card
          my="30px"
          w="full"
          boxShadow="null"
          borderRadius="16px"
          border="1px solid"
          borderColor="gray.300"
          position="relative"
        >
          <CardBody p="30px">
            {documentsIsEmpty && (
              <Alert status="info">
                <AlertIcon />
                There is no documents to show. you can
                <Button
                  variant="btnNormal"
                  mx="10px"
                  onClick={() => {
                    setAddDocumentModalIsOpen(true);
                  }}
                >
                  upload your documents
                </Button>
              </Alert>
            )}

            {!documentsIsEmpty && (
              <VStack w="full" spacing="15px" alignItems="start">
                {documents &&
                  Object.keys(documents).map((folder: any, folderIndex) => {
                    if (documents[folder].length === 0) {
                      return <></>;
                    } else {
                      return (
                        <VStack
                          key={folderIndex}
                          w="full"
                          spacing="30px"
                          alignItems="start"
                        >
                          <Heading fontSize="18px">
                            {folder === "/"
                              ? "General"
                              : cleanFolderName(folder)}
                          </Heading>
                          <SimpleGrid
                            columns={{ base: 1, md: 4, lg: 5, xl: 7 }}
                            gap="15px"
                            mt="25px"
                            w="full"
                          >
                            {documents[folder].map(
                              (file: any, index: number) => (
                                <Card
                                  boxShadow="none"
                                  key={index}
                                  border="1px solid"
                                  borderColor="gray.100"
                                  w="full"
                                  p="0"
                                  overflow="hidden"
                                  borderRadius="16px"
                                >
                                  <CardBody
                                    justifyContent="center"
                                    alignItems="center"
                                    p="0"
                                    position="relative"
                                  >
                                    <VStack
                                      justifyContent="space-between"
                                      h="full"
                                    >
                                      {/*<Image src={file.url} w="full" maxH="207px" objectFit="cover"></Image>*/}
                                      <FileIcon
                                        ext={file.ext}
                                        props={{
                                          mt: "15px",
                                          w: "85px",
                                        }}
                                      ></FileIcon>
                                      <Text
                                        fontSize="14px"
                                        fontWeight="400"
                                        color="gray"
                                      >
                                        {file.name}
                                      </Text>
                                      <HStack
                                        px="15px"
                                        w="full"
                                        mb="10px"
                                        justifyContent="space-between"
                                        borderTop="1px dashed"
                                        borderTopColor="gray.200"
                                        pt="10px"
                                      >
                                        <Link href={file.url} target="_blank">
                                          <Icon
                                            fontSize="16px"
                                            color="gray.500"
                                            as={FiDownload}
                                            cursor="pointer"
                                          ></Icon>
                                        </Link>

                                        <DeleteIcon
                                          fontSize="16px"
                                          color="gray.500"
                                          cursor="pointer"
                                          onClick={() => {
                                            setKeyToDelete(file.key);
                                          }}
                                        />
                                      </HStack>
                                    </VStack>
                                  </CardBody>
                                </Card>
                              )
                            )}
                          </SimpleGrid>
                          <Divider></Divider>
                        </VStack>
                      );
                    }
                  })}
              </VStack>
            )}
          </CardBody>
        </Card>
      )}
      {addDocumentModalIsOpen && (
        <AddDocumentModal
          isOpen={addDocumentModalIsOpen}
          basePath={basePath}
          tags={tags}
          closeAction={() => {
            setAddDocumentModalIsOpen(false);
          }}
          addAction={async () => {
            getDocuments();
            setAddDocumentModalIsOpen(false);
          }}
        ></AddDocumentModal>
      )}

      {keyToDelete && (
        <Confirmation
          isOpen={!!keyToDelete && keyToDelete?.length > 0}
          isLoading={deleteDocumentIsLoading}
          closeAction={() => {
            setKeyToDelete(null);
          }}
          acceptAction={async () => {
            setDeleteDocumentIsLoading(true);
            try {
              let result = await axiosInstance().post(
                "/storage/delete",
                {
                  key: keyToDelete,
                },
                {
                  headers: {
                    Authorization: 'Bearer ' + localStorage.getItem("access_token"),
                  },
                }
              );

              if (result.data.status === 0) {
                toast.success("File removed successfully.");

                setCurrentDocument(null);

                getDocuments();

                setKeyToDelete(null);
              } else {
                toast.error("There is a problem on deleting this File.");
              }
            } catch (err) {
              toast.error("There is a problem on deleting this File.");
            }
            setDeleteDocumentIsLoading(false);
          }}
        >
          Are you sure to Delete this document?
        </Confirmation>
      )}
    </Container>
  );
};

export default Documents;
