import { gql } from "@apollo/client";

export const GET_USER_BY_ID = gql`
  query getUserById($id: Int!) {
    users_by_pk(id: $id) {
      email
      enable
      id
      mobile
      name
      title
      role_id
      role {
        id
        name
      }
    }
  }
`;

export const GET_USERS = gql`
  query getUsersList($where: users_bool_exp!, $limit: Int!, $offset: Int!) {
    users(
      limit: $limit
      offset: $offset
      order_by: { id: asc }
      where: $where
    ) {
      email
      enable
      id
      mobile
      name
      title
      role_id
      role {
        id
        name
      }
    }

    users_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_ROLES = gql`
  query getRolesList {
    roles {
      descriptor
      id
      name
    }
  }
`;

export const ADD_USER = gql`
  mutation addUser($object: users_insert_input!) {
    insert_users_one(object: $object) {
      id
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($id: Int!, $object: users_set_input!) {
    update_users_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
    }
  }
`;
