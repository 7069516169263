import {Alert, AlertIcon} from "@chakra-ui/react";
import React from "react";

const AlertField = (props:any) => {

    const {
        item
    } = props;
    return (
        <Alert status={item.options?.type} borderRadius="10px">
            <AlertIcon />
            {item.text}
        </Alert>
    );
}

export default AlertField;