import Item from "./Item";
import {Box, FormControl, FormLabel, HStack, Text} from "@chakra-ui/react";

const GroupField = (props:any) => {
    const {
        item,
        preEdit,
        preDelete,
        currentSectionIndex,
        indexH,
        updateData,
        sections,
        swapItems
    } = props;

    return (
        <FormControl w='full'>
            <FormLabel>
                {item.required && (
                    <Text as="span" color="red.500" fontSize="14px" me="5px">
                        *
                    </Text>
                )}
                <Box fontSize="16px" dangerouslySetInnerHTML={{__html: item.title}}></Box>
            </FormLabel>
            <HStack gap='15px'>
            {item.sub_items.map((subItem:any, key: number) => {
                return (
                    <Item swapItems={swapItems} isSubItem={true} updateData={updateData} indexH={indexH + 'sub_items|' + key + '|'} sections={sections} currentSectionIndex={currentSectionIndex} preDelete={preDelete} preEdit={preEdit} item={subItem}></Item>
                );
            }) }
            </HStack>
        </FormControl>
    );
}

export default GroupField;