import {Box, Button, FormControl, FormLabel, HStack, Input, Text} from "@chakra-ui/react";

const PlainTextField = (props:any) => {

    const {
        item
    } = props;
    return (
        <FormControl w='full'>
            {item.title &&
                <FormLabel>
                    {item.required && (
                        <Text as="span" color="red.500" fontSize="14px" me="5px">
                            *
                        </Text>
                    )}
                    <Box fontSize="16px" dangerouslySetInnerHTML={{__html: item.title}}></Box>
                </FormLabel>
            }

            {item.text && item.text.length > 0 &&
                <Box
                    fontSize="16px"
                    borderRadius="5px"
                    bg="darkcyan"
                    color="white"
                    p="10px"
                    dangerouslySetInnerHTML={{__html: item.text}}
                ></Box>
            }

        </FormControl>
    );
}

export default PlainTextField;