import {
    Button,
    FormControl,
    FormHelperText,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    VStack,
    Select, Checkbox, Textarea, HStack, Box
} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import AddNewOptionModal from "./AddNewOptionModal";
import item from "../components/Fields/Item";

const FieldValuesModal = (props:any) => {

    const {
        onClose,
        onSubmit,
        data,
    } = props;

    const defaultItem = [
        {
           title: '',
           value: ''
        }
    ];

    const [items, setItems] = useState<any>({});
    const [addNewOptionModalOpen, setAddNewOptionModalOpen] = useState<boolean>(false);

    useEffect(() => {
        if (data) {
            setItems({...data});
        }
    }, []);

    return (
        <>
            { items &&
                <Modal isOpen={true} onClose={onClose} size='xl'>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>
                            Manage options
                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <VStack w='full' gap='15px'>

                                { Object.keys(items).map(item =>
                                    <VStack
                                        p="10px"
                                        borderRadius="10px"
                                        w="full"
                                        border="1px solid #ccc"
                                    >
                                        <FormControl>
                                            <HStack w="full" justifyContent="space-between">
                                                <FormLabel>Key</FormLabel>
                                                <Button mb="5px" size="sm" onClick={() => {
                                                    let temp = JSON.parse(JSON.stringify(items));
                                                    delete temp[item];
                                                    setItems(temp);
                                                }}>
                                                    Remove
                                                </Button>
                                            </HStack>

                                            <Input
                                                isDisabled={true}
                                                value={item}
                                            ></Input>
                                        </FormControl>
                                        <HStack w="full">
                                            <FormControl>
                                                <FormLabel>Title</FormLabel>
                                                <Input
                                                    value={items[item].title}
                                                    onChange={(e) => {
                                                        let temp = JSON.parse(JSON.stringify(items));
                                                        temp[item].title = e.target.value;
                                                        setItems(temp);
                                                    }}
                                                ></Input>
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel>Value</FormLabel>
                                                <Input
                                                    value={items[item].value}
                                                    onChange={(e) => {
                                                        let temp = JSON.parse(JSON.stringify(items));
                                                        temp[item].value = e.target.value;
                                                        setItems(temp);
                                                    }}
                                                ></Input>
                                            </FormControl>
                                        </HStack>
                                    </VStack>
                                )}

                                {addNewOptionModalOpen &&
                                    <AddNewOptionModal
                                        onClose={() => setAddNewOptionModalOpen(false)}
                                        keys={Object.keys(items)}
                                        onSubmit={(value:string) => {
                                            let temp:any = JSON.parse(JSON.stringify(items));
                                            temp[value] = {
                                                title: "",
                                                value: ""
                                            };

                                            setItems(temp);

                                            setAddNewOptionModalOpen(false);
                                        }}
                                    ></AddNewOptionModal>
                                }

                                <Button
                                    onClick={() => setAddNewOptionModalOpen(true)}
                                >
                                    Add New Option
                                </Button>

                            </VStack>
                        </ModalBody>

                        <ModalFooter>
                            <Button
                                variant='ghost'
                                mr={3}
                                onClick={onClose}
                            >
                                Close
                            </Button>
                            <Button
                                colorScheme='blue'
                                onClick={() => {if (onSubmit) {onSubmit(items)}}}
                            >
                                Save
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            }
        </>
    );
}

export default FieldValuesModal;