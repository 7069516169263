import {
  Box,
  Button,
  HStack,
  Heading,
  Image,
  ModalBody,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Tr,
  VStack,
} from "@chakra-ui/react";
import emptyImage from "../../../../../assets/images/appointment_empty.svg";
import ListTable from "../../../../../components/FromsElements/ListTable";
import { ViewIcon } from "@chakra-ui/icons";
import { fields } from "./fields";
import { useDisclosureWithData } from "../../../../../hooks/useDisclosureWithData";
import ModalBox from "../../../../../components/ModalBox";
import { FaRegUser } from "react-icons/fa";
import nl2br from "react-nl2br";

type AppointmentType = {
  id: string;
  given_name: string;
  family_name: string;
  email: string;
  phone_number: string;
  status: string;
  start_at: string;
  customer_note: string;
};

type PropTypes = {
  appointments: Array<AppointmentType>;
  isLoading: boolean;
};

export const AppointmentsFormWidget = (props: PropTypes) => {
  const { appointments, isLoading } = props;

  const { isOpen, onClose, onOpen, data } =
    useDisclosureWithData<AppointmentType>();

  return (
    <>
      {Number(appointments.length) > 0 && (
        <VStack spacing={3} alignItems={"flex-start"} w={"100%"}>
          <ListTable
            data={appointments}
            fields={fields}
            page={1}
            total={appointments.length}
            loading={isLoading}
            hasPagination={false}
            perPage={1000}
            actionField={(id: any) => {
              return (
                <Button
                  onClick={() => {
                    const appointment = appointments.find((x) => x.id === id);
                    if (appointment) {
                      onOpen(appointment);
                    }
                  }}
                >
                  <ViewIcon></ViewIcon>
                </Button>
              );
            }}
          ></ListTable>
        </VStack>
      )}

      {isLoading && appointments.length === 0 && (
        <HStack w="full" h="100px" justifyContent="center" alignItems="center">
          <Spinner fontSize="24px"></Spinner>
        </HStack>
      )}

      {!isLoading && appointments.length === 0 && (
        <VStack w="full" my="30px">
          <Image src={emptyImage}></Image>
          <Text fontSize="14px" fontWeight="400" color="gray">
            No Appointments.
          </Text>
        </VStack>
      )}

      <ModalBox
        isOpen={isOpen}
        onClose={onClose}
        size="2xl"
        icon={FaRegUser}
        iconsize="24px"
      >
        <ModalBody pb={6}>
          <Box>
            <Heading size="md" textAlign="center">
              Patient Information
            </Heading>
            <Table mt={5}>
              <Tbody>
                <Tr>
                  <Th>Patient Name</Th>
                  <Td>
                    {data?.given_name} {data?.family_name}
                  </Td>
                </Tr>
                <Tr>
                  <Th>Email</Th>
                  <Td>
                    {data?.email}
                  </Td>
                </Tr>
                <Tr>
                  <Th>Contact Number</Th>
                  <Td>
                    {data?.phone_number}
                  </Td>
                </Tr>
                <Tr>
                  <Th verticalAlign={"top"} pt={4}>Customer Notes</Th>
                  <Td>
                    {nl2br(data?.customer_note || '')}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
        </ModalBody>
      </ModalBox>
    </>
  );
};
