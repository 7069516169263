import React, {useEffect, useState} from 'react';
import {FormItem, FormItemProps} from "../../../interfaces/Interfaces";
import {
    Box,
    Text,
    Card,
    CardBody,
    CardHeader,
    VStack,
    Heading,
    Square,
    HStack,
    Checkbox,
    Flex,
    Spinner
} from "@chakra-ui/react";
import {useDispatch, useSelector} from "react-redux";
import {nextStep} from "../../../store/slice";
import {RootState} from "../../../store/Store";
import CheckBox from "../CheckBox";
import Form from "./layouts/Form"
const Overview = (props: FormItemProps) => {
    const dispatch = useDispatch()
    let item = props.item;
    let inputItemHandler = props.inputItemHandler;
    let state = props.state;
    const consultationState = useSelector((state: RootState) => state.consultation)

    const [overviewData, setOverviewData] = useState<Array<any>>([]);


    useEffect(() => {

        let tempCards:Array<any> = [];
        let tempItems: Array<any> = [];
        let cardsIndex = 0;
        let currentDescriptor: string | null = null;

        if (consultationState.sections.length > 0) {
            consultationState.sections.map((section) => {
                if (section.descriptor !== 'overview') {
                    if (section.forms.length > 0) {
                        section.forms.map((form) => {
                            if (form.parent && form.parent !== null) {

                            } else if (currentDescriptor === null) {
                                tempItems.push(form);
                                currentDescriptor = form.descriptor;
                            } else if (currentDescriptor === form.descriptor) {
                                tempItems.push(form);
                            } else {
                                currentDescriptor = form.descriptor;
                                if (tempItems.length > 0) {
                                    tempCards[cardsIndex] = {
                                        "title" : tempItems[0].title,
                                        "items" : tempItems
                                    };
                                    cardsIndex++;
                                    tempItems = [];
                                }

                                tempItems.push(form);
                            }
                        });
                    }
                    tempCards[cardsIndex] = {
                        "title" : tempItems[0].title,
                        "items" : tempItems
                    };
                    cardsIndex++;
                    tempItems = [];
                }
            });
        }

        setOverviewData(tempCards);
    }, [consultationState, state])

    return (
        <>
            { overviewData && overviewData.length > 0 &&
                <VStack spacing="10" w="full">
                    <Heading w="full" textAlign="center" fontSize="xl">Your Entered Information</Heading>
                    {
                        overviewData.length > 0 && overviewData.map((card, key) => {
                            return (
                                <Card w="full"  minW={{base: 'full', md: '550px'}}>
                                    <CardHeader>
                                        <HStack borderBottom="3px dashed" borderColor="gray.200" pb="15px">
                                            <Square borderRadius="5px" size='40px' bg='blue.100' color='blue' fontSize="xl" fontWeight="bold">
                                                {key + 1}
                                            </Square>
                                            <Heading size='md'>{card.title}</Heading>
                                        </HStack>
                                    </CardHeader>
                                    <CardBody>
                                        {card.items.map((itemGroup:any) => {
                                            return (
                                                <>
                                                    { itemGroup?.overview_description &&
                                                        <Text my="15px" fontWeight="700" color="blue.500" dangerouslySetInnerHTML={{__html: itemGroup.overview_description ?? ''}}></Text>
                                                    }
                                                    {
                                                        itemGroup.items.map((item:FormItem) => {
                                                            return (
                                                                <>
                                                                    { item.type !== 'text' &&
                                                                        <Form state={state} item={item}></Form>
                                                                    }
                                                                </>
                                                            )

                                                        })
                                                    }
                                                </>
                                            )
                                        })}
                                    </CardBody>
                                </Card>
                            )
                        })
                    }
                </VStack>
            }

            { !overviewData || overviewData.length === 0 &&
                <HStack h="200px" w="full" justifyContent="center">
                    <Spinner size="lg"></Spinner>
                </HStack>
            }
        </>


    );
}

export default Overview;
