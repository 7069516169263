import {
  Box,
  Button,
  Card,
  CardBody,
  Container,
  Divider,
  Heading,
  HStack,
  Spinner,
  Square,
  Text,
  VStack,
} from "@chakra-ui/react";
import {
  Link as ReactRouterLink,
  useNavigate,
  useParams,
} from "react-router-dom";
import { ChevronLeftIcon, InfoIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GET_FORM_TEMPLATE,
  GET_PATIENT_FORM_DATA,
  GET_META_DATA,
} from "../queries";
import {
  ADD_FORM_DATA,
  UPDATE_FORM_DATA_BY_PK,
  UPDATE_PATIENT_DATA,
} from "../mutations";

import FormElementsContainer from "../../../../components/FromsElements/FormElementsContainer";
import { FormItem } from "../../../../interfaces/Interfaces";
import useCustomToast from "../../../../hooks/useCustomToast";
import SkinType from "./components/SkinType";
import useAuth from "../../../../hooks/useAuth";
import {
  AddFormVisitDataMutation,
  AddFormVisitDataMutationVariables,
  GetFormTemplateQuery,
  GetFormTemplateQueryVariables,
  GetMetaDataQuery,
  GetMetaDataQueryVariables,
  UpdateFormVisitDataByPkMutation,
  UpdateFormVisitDataByPkMutationVariables,
} from "../../../../gql/graphql";

interface FitzSkinProps {}
const FitzSkinForm = (props: FitzSkinProps) => {
  const params = useParams();
  const user = useAuth();
  const [showTopMessage, setShowTopMessage] = useState(true);
  const [formState, setFormState] = useState<any>({});
  const [total, setTotal] = useState(0);
  const toast = useCustomToast();
  const [InsertFormData] = useMutation<
    AddFormVisitDataMutation,
    AddFormVisitDataMutationVariables
  >(ADD_FORM_DATA);
  const [updateFormDataByPk] = useMutation<
    UpdateFormVisitDataByPkMutation,
    UpdateFormVisitDataByPkMutationVariables
  >(UPDATE_FORM_DATA_BY_PK);
  const [fdid, setFdid] = useState<any>(null);
  const navigate = useNavigate();
  const [UpdatePatient] = useMutation(UPDATE_PATIENT_DATA);
  const [getMetaData, skinTypeMetaData] = useLazyQuery<
    GetMetaDataQuery,
    GetMetaDataQueryVariables
  >(GET_META_DATA, {
    variables: {
      descriptor: "SKIN_TYPES",
      centerId: Number(process.env.REACT_APP_CENTER_ID),
    },
  });
  const [getFormTemplate, formTemplateData] = useLazyQuery<
    GetFormTemplateQuery,
    GetFormTemplateQueryVariables
  >(GET_FORM_TEMPLATE, {
    variables: {
      descriptor: "FITZ_SKIN_TYPE_FORM",
      centerId: Number(process.env.REACT_APP_CENTER_ID),
    },
  });

  const checkAllIsSelected = () => {
    let allIsSelected = true;

    for (let item of Object.keys(formState)) {
      if (formState[item] < 0) {
        allIsSelected = false;
      }
    }

    return allIsSelected;
  };

  const [getPatientFormData, formData] = useLazyQuery(GET_PATIENT_FORM_DATA, {
    variables: {
      id: fdid,
    },
  });
  const initData = async () => {
    await getFormTemplate();
    await getMetaData();

    if (params.fdid) {
      setFdid(params.fdid);
    }
  };

  useEffect(() => {
    if (fdid) {
      getPatientFormData();
    }
  }, [fdid]);

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    if (
      formTemplateData?.data?.form_schemas[0]?.template?.sections[0].forms[0]
        .items.length > 0
    ) {
      let data: any = {};

      for (let item of formTemplateData?.data?.form_schemas[0]?.template
        ?.sections[0].forms[0].items) {
        data[item.descriptor] = "-1";
      }

      setFormState(data);
    }
  }, [formTemplateData]);

  useEffect(() => {
    if (formData?.data?.form_data_by_pk?.data) {
      setFormState(formData.data.form_data_by_pk.data);
    }
  }, [formData]);

  useEffect(() => {
    if (Object.keys(formState).length) {
      let temp = 0;
      for (let item in formState) {
        if (parseInt(formState[item]) > -1) {
          temp += parseInt(formState[item]);
        }
      }
      setTotal(temp);
    }
  }, [formState]);

  const updateResult = (value: any, item: FormItem) => {
    let temp = JSON.parse(JSON.stringify(formState));
    temp[value.id] = value.value;
    setFormState(temp);
  };

  const insertData = async () => {
    if (formTemplateData?.data?.form_schemas[0].id) {
      let result = await InsertFormData({
        variables: {
          patient_id: Number(params.pid),
          data: formState,
          form_id: formTemplateData.data.form_schemas[0].id,
          user_id: user.user_id,
          center_id: Number(process.env.REACT_APP_CENTER_ID),
        },
      });

      if (result.data?.insert_form_data_one?.id) {
        setFdid(result.data?.insert_form_data_one?.id);
        await getPatientFormData();
        let updatePatientResult = await UpdatePatient({
          variables: {
            id: params.pid,
            object: {
              skin_type: total,
            },
          },
        });
        toast.success("Data saved successfully");
        navigate(`${result.data?.insert_form_data_one?.id}`);
      } else {
        toast.error("There is a problem on saving data!");
      }
    }
  };

  const updateData = async () => {
    let result = await updateFormDataByPk({
      variables: {
        id: fdid,
        object: {
          data: formState,
        },
      },
    });

    if (result.data?.update_form_data_by_pk?.id) {
      let updatePatientResult = await UpdatePatient({
        variables: {
          id: params.pid,
          object: {
            skin_type: total,
          },
        },
      });
      toast.success("Data saved successfully");
    } else {
      toast.error("There is a problem on saving data!");
    }
  };

  return (
    <Container w="full" maxW="container.xl" mt="35px">
      <HStack flexWrap="wrap">
        <Button
          as={ReactRouterLink}
          to={`/dashboard/patient/${params.pid}`}
          display="flex"
          bg="transparent"
          borderRadius="100px"
          h="48px"
          w="120px"
          border="1px solid"
          borderColor="gray.300"
        >
          <ChevronLeftIcon fontSize="xl"></ChevronLeftIcon>
          <Text fontSize="md" mx="10px">
            Back
          </Text>
        </Button>

        <Heading fontSize="30px" mx="15px">
          Fitz Skin Type
        </Heading>
      </HStack>

      {formTemplateData.called && formTemplateData.loading && (
        <VStack w="full" h="200px" justifyContent="center" alignItems="center">
          <Spinner></Spinner>
        </VStack>
      )}

      {formTemplateData?.data?.form_schemas[0]?.template && (
        <Box w="full" my="30px">
          {showTopMessage && (
            <HStack
              w="full"
              border="1px solid"
              borderColor="#99C1FF"
              borderRadius="12px"
              bg="#F0F6FF"
              px="15px"
              py="15px"
            >
              <InfoIcon fontSize="20px" color="#0056D9"></InfoIcon>
              <Text flex="1" fontWeight="400" fontSize="14px">
                The options of each question have a score between 0 and 4, and
                you can see the final result by answering each question.
              </Text>
              <Text
                fontWeight="500"
                fontSize="14px"
                color="#0065FF"
                cursor="pointer"
                onClick={() => {
                  setShowTopMessage(false);
                }}
              >
                Hide Message
              </Text>
            </HStack>
          )}

          <Card boxShadow="none" border="0" borderRadius="12px" my="30px">
            <CardBody p="45px">
              <Heading fontSize="24px" mb="30px">
                Questions
              </Heading>

              {formTemplateData?.data?.form_schemas[0]?.template?.sections[0]
                .forms[0].items.length > 0 &&
                Object.keys(formState).length > 0 && (
                  <VStack spacing={4} align="stretch" w="full">
                    <FormElementsContainer
                      items={
                        formTemplateData?.data?.form_schemas[0]?.template
                          ?.sections[0].forms[0].items
                      }
                      inputItemHandler={updateResult}
                      state={formState}
                      divider={<Divider mt="25px" mb="15px" />}
                    ></FormElementsContainer>
                  </VStack>
                )}
            </CardBody>
          </Card>

          <Card boxShadow="none" border="0" borderRadius="12px" my="30px">
            <CardBody px="45px" py="25px">
              <HStack flexWrap="wrap">
                <Heading fontSize="24px" flex="1">
                  Result
                </Heading>
                <HStack gap="15px">
                  <Text>Total Score</Text>
                  <Square
                    size="48px"
                    bg="#FAFAFA"
                    border="1px solid"
                    borderColor="#C9C9C9"
                    borderRadius="8px"
                    fontSize="24px"
                    fontWeight="700"
                  >
                    {total}
                  </Square>
                  <Button
                    variant="btnMain"
                    onClick={() => {
                      if (checkAllIsSelected()) {
                        if (fdid) {
                          updateData();
                        } else {
                          insertData();
                        }
                      } else {
                        toast.error("All questions must be answered.");
                      }
                    }}
                  >
                    Apply Changes
                  </Button>
                </HStack>
              </HStack>

              {skinTypeMetaData?.data?.meta_data[0]?.data?.length > 0 && (
                <HStack spacing="20px" my="50px" flexWrap="wrap">
                  {skinTypeMetaData?.data?.meta_data[0]?.data.map(
                    (metaData: any) => {
                      return (
                        <SkinType
                          key={metaData.descriptor}
                          meta_data={metaData}
                          score={total}
                        ></SkinType>
                      );
                    }
                  )}
                </HStack>
              )}
            </CardBody>
          </Card>
        </Box>
      )}
    </Container>
  );
};

export default FitzSkinForm;
