import { gql } from "@apollo/client";

export const GET_PATIENT_RESOURCES = gql`
  query GetPatientResources($center_id: Int!) {
    patient_resources(where: { center_id: { _eq: $center_id } }) {
      id
      image
      thumbnail
      descriptor
      description
      briefly
      data
      title
    }
  }
`;

export const GET_PATIENT_RESOURCE_CATEGORIES = gql`
  query GetPatientResourcesCategories($center_id: Int!) {
    patient_resources_categories(where: { center_id: { _eq: $center_id } }) {
      descriptor
      id
      title
      description
      patient_resources {
        id
        briefly
        category_id
        center_id
        data
        description
        descriptor
        image
        thumbnail
        title
      }
    }
  }
`;
