import { gql } from "@apollo/client";

export const DELETE_VISIT = gql`
    mutation deleteVisit($id: uuid!) {
        delete_visits_by_pk(id: $id) {
            id
        }
    }
`;

export const DELETE_VISIT_PRESCRIPTION = gql`
    mutation deleteVisitPrescription($id: uuid!) {
        delete_visit_prescriptions_by_pk(id: $id) {
            id
        }
    }
`;

export const DELETE_VISIT_NOTE = gql`
    mutation deleteVisitNote($id: uuid!) {
        delete_visit_notes_by_pk(id: $id) {
            id
        }
    }
`;

export const ADD_VISIT_NOTES = gql`
    mutation addVisitNotes($objects: [visit_notes_insert_input!]!) {
        insert_visit_notes(objects: $objects) {
            affected_rows
        }
    }
`;

export const UPDATE_VISIT_NOTE = gql`
    mutation updateVisitNoteByPk($id: uuid! $object: visit_notes_set_input!) {
        update_visit_notes_by_pk(pk_columns: {id: $id}, _set: $object) {
            id
        }
    }
`;

export const ADD_VISIT_PRESCRIPTIONS = gql`
    mutation addVisitPrescriptions($objects: [visit_prescriptions_insert_input!]!) {
        insert_visit_prescriptions(objects: $objects) {
            affected_rows
        }
    }
`;

export const UPDATE_VISIT_PRESCRIPTION = gql`
    mutation updateVisitPrescriptionByPk($id: uuid! $object: visit_prescriptions_set_input!) {
        update_visit_prescriptions_by_pk(pk_columns: {id: $id}, _set: $object) {
            id
        }
    }
`;