import {
  Box,
  Button,
  HStack,
  Heading,
  ModalBody,
  ModalFooter,
  Spinner,
  VStack,
} from "@chakra-ui/react";
import ModalBox from "../../../../../../components/ModalBox";
import {
  AddFormVisitDataMutation,
  AddFormVisitDataMutationVariables,
  GetTreatmentCategoriesWithTreatmentsQuery,
  GetTreatmentCategoriesWithTreatmentsQueryVariables,
  GetTreatmentQuery,
  GetTreatmentQueryVariables,
  GetVisitDataQuery,
} from "../../../../../../gql/graphql";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useEffect } from "react";
import Select from "../../../../../../components/FormsElementsWithHook/Select";
import { GET_TREATMENT_CATEGORIES_WITH_TREATMENTS } from "./queries";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { ADD_FORM_DATA } from "../../../mutations";
import useAuth from "../../../../../../hooks/useAuth";
import { GET_TREATMENT } from "../../queries";
import useCustomToast from "../../../../../../hooks/useCustomToast";

type AddRecordingModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSuccessStore: (id: number) => void;
  visit: GetVisitDataQuery["visits_by_pk"];
};

type FormFieldsType = {
  treatmentCategory: number;
  treatment: number;
};

export const AddRecordingModal = (props: AddRecordingModalProps) => {
  const { user_id } = useAuth();
  const toast = useCustomToast();

  const { isOpen, onClose, visit, onSuccessStore } = props;

  const form = useForm<FormFieldsType>();
  const { watch, handleSubmit, reset, setValue } = form;

  const [insertFormData, { loading: isLoading }] = useMutation<
    AddFormVisitDataMutation,
    AddFormVisitDataMutationVariables
  >(ADD_FORM_DATA);

  const [getTreatment] = useLazyQuery<
    GetTreatmentQuery,
    GetTreatmentQueryVariables
  >(GET_TREATMENT);

  const onSubmit: SubmitHandler<FormFieldsType> = async (data) => {
    
    // load treatment
    const { data: treatmentObj } = await getTreatment({
      variables: {
        id: Number(data.treatment),
      },
    });

    if (treatmentObj?.treatments_by_pk?.form_id) {
      const result = await insertFormData({
        variables: {
          center_id: Number(process.env.REACT_APP_CENTER_ID),
          patient_id: Number(visit?.patient_id),
          data: {},
          form_id: treatmentObj?.treatments_by_pk.form_id,
          user_id: user_id,
          visit_id: visit?.id,
          treatment_id: data.treatment
        },
      });

      if (result?.data?.insert_form_data_one?.id) {
        toast.success("Changes saved.");
        onClose();
        onSuccessStore(result?.data.insert_form_data_one.id);
      } else {
        toast.error("An error ocurred during save. Please try again.");
      }
    } else {
      toast.error("An error ocurred during save. Please try again.");
    }
  };

  useEffect(() => {
    reset();
  }, [isOpen]);

  const { data: treatmentCategories } = useQuery<
    GetTreatmentCategoriesWithTreatmentsQuery,
    GetTreatmentCategoriesWithTreatmentsQueryVariables
  >(GET_TREATMENT_CATEGORIES_WITH_TREATMENTS, {
    variables: {
      centerId: Number(process.env.REACT_APP_CENTER_ID),
    },
    skip: !isOpen,
  });

  const categoriesSelectOptions: any = {};
  const treatmentsSelectOptions: any = {};

  treatmentCategories?.treatment_categories?.forEach((category, idx) => {
    categoriesSelectOptions[idx] = {
      title: category.title,
      value: category.id,
    };
  });

  const selectedCategory = watch("treatmentCategory");
  treatmentCategories?.treatment_categories
    ?.find((x) => x.id === Number(selectedCategory))
    ?.treatments.forEach((item, idx) => {
      treatmentsSelectOptions[idx] = {
        title: item.title,
        value: item.id,
      };
    });

  useEffect(() => {
    setValue(
      "treatment",
      Number(
        treatmentCategories?.treatment_categories?.find(
          (x) => x.id === Number(selectedCategory)
        )?.treatments[0]?.id
      )
    );
  }, [selectedCategory]);

  useEffect(() => {
    reset({
      treatmentCategory: treatmentCategories?.treatment_categories[0]?.id,
    });
  }, [treatmentCategories?.treatment_categories]);

  return (
    <FormProvider {...form}>
      <ModalBox isOpen={isOpen} onClose={onClose} size="xl" iconsize="26px">
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody pb={6}>
            <Box>
              <Heading size="md" textAlign="center">
                Add Recordings
              </Heading>

              <VStack w="full" spacing={5}>
                <Select
                  w="100%"
                  item={{
                    descriptor: "treatmentCategory",
                    title: "1. Select Category",
                    values: categoriesSelectOptions,
                  }}
                  validator={{ required: true, min: 1 }}
                ></Select>

                <Select
                  w="100%"
                  item={{
                    descriptor: "treatment",
                    title: "2. Select Treatment",
                    values: treatmentsSelectOptions,
                  }}
                  validator={{ required: true, min: 1 }}
                ></Select>
              </VStack>
            </Box>
          </ModalBody>
          <ModalFooter>
            <HStack
              w="full"
              align="center"
              spacing={10}
              mb={5}
              justifyContent={"space-between"}
            >
              <Button
                variant="btnNoraml"
                border="1px solid"
                borderColor="gray.400"
                w="full"
                borderRadius="100px"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                variant="btnMain"
                border="1px solid"
                borderColor="gray.400"
                w="full"
                isDisabled={isLoading}
                borderRadius="100px"
                type="submit"
              >
                Save & Continue
                {isLoading && (
                  <>
                    <Spinner />
                  </>
                )}
              </Button>
            </HStack>
          </ModalFooter>
        </form>
      </ModalBox>
    </FormProvider>
  );
};
