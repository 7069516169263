import {
  Link as ReactRouterLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useApolloClient, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import axiosInstance from "../../../helpers/axiosInstance";
import skinTypeImage from "../../../assets/images/skin-type.png";
import {
  Button,
  Card,
  CardBody,
  Container,
  Heading,
  HStack,
  Icon,
  Square,
  Text,
  Box,
  VStack,
  Circle,
  SimpleGrid,
  Image,
  Link,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";

import { ChevronLeftIcon, ArrowForwardIcon, EditIcon } from "@chakra-ui/icons";
import ellipse6 from "../../../assets/images/ellipse-6.svg";
import { FaRegEnvelope } from "react-icons/fa";
import { FiPhone } from "react-icons/fi";
import { FiArrowUpRight, FiArrowRight } from "react-icons/fi";
import { useLazyQuery } from "@apollo/client";
import { GET_PATIENT_DATA, GET_META_DATA, GET_FORM_TEMPLATE } from "./queries";
import { getDayName, getHRFormat } from "../../../helpers/DateUtil";
import ModalBox from "../../../components/ModalBox";
import { FaRegUser } from "react-icons/fa";
import { AddVisitModal } from "./Visit/modals/AddVisitModal";
import Confirmation from "../../../components/Confirmation";
import useAuth from "../../../hooks/useAuth";
import { BsDownload } from "react-icons/bs";
import { HiOutlineDocumentDuplicate } from "react-icons/hi";
import { AiOutlineEdit, AiOutlinePicture } from "react-icons/ai";
import { AiOutlineMail } from "react-icons/ai";
import useCustomToast from "../../../hooks/useCustomToast";
import {
  AddFormVisitDataMutation,
  AddFormVisitDataMutationVariables,
  GetFormTemplateQuery,
  GetFormTemplateQueryVariables,
  GetMetaDataQuery,
  GetMetaDataQueryVariables,
  GetPatientDataQuery,
  GetPatientDataQueryVariables,
} from "../../../gql/graphql";
import dayjs from "dayjs";
import { ADD_FORM_DATA } from "./mutations";

interface PatientProps {}
const Patient = (props: PatientProps) => {
  const borderBg =
    "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23C9C9C9FF' stroke-width='2' stroke-dasharray='6%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e\")";
  const client = useApolloClient();
  let params = useParams();
  const { user_id } = useAuth();
  const navigate = useNavigate();
  let { state } = useLocation();
  const [patientInfoModalIsOpen, setPatientInfoModalIsOpen] = useState(false);
  const [visitModalIsOpen, setVisitModalIsOpen] = useState(false);
  const [getPatientData, { error, loading, data, called }] = useLazyQuery<
    GetPatientDataQuery,
    GetPatientDataQueryVariables
  >(GET_PATIENT_DATA, {
    fetchPolicy: "no-cache",
    variables: {
      id: Number(params.id),
    },
  });

  const [
    sendFitzSkinTypeEmailModalIsOpen,
    setSendFitzSkinTypeEmailModalIsOpen,
  ] = useState(false);
  const [sendConsentEmailObject, setSendConsentEmailObject] =
    useState<any>(null);
  const [
    sendSecondConsultationEmailModalIsOpen,
    setSendSecondConsultationEmailModalIsOpen,
  ] = useState(false);

  const [sendFitzSkinTypeEmailBtnLoading, setSendFitzSkinTypeEmailBtnLoading] =
    useState(false);
  const [sendConsentEmailBtnLoading, setSendConsentEmailBtnLoading] =
    useState(false);
  const [
    sendSecondConsultationEmailBtnLoading,
    setSendSecondConsultationEmailBtnLoading,
  ] = useState(false);

  const [consultationFormData, setConsultationFormData] = useState<any>(null);
  const [secondConsultationFormData, setSecondConsultationFormData] =
    useState<any>(null);
  const [clientConsent, setClientConsent] = useState<Array<any>>([]);
  const [fitzSkinTypeFormData, setFitzSkinTypeFormData] = useState<any>(null);
  const [currentSkinType, setCurrentSkinType] = useState<any>(null);
  const [getMetaData, skinTypeMetaData] = useLazyQuery<
    GetMetaDataQuery,
    GetMetaDataQueryVariables
  >(GET_META_DATA, {
    variables: {
      descriptor: "SKIN_TYPES",
      centerId: Number(process.env.REACT_APP_CENTER_ID),
    },
  });

  const toast = useCustomToast();

  useEffect(() => {
    if (params.id) {
      getPatientData();
    }
  }, [params]);

  useEffect(() => {
    if (data?.patients_by_pk?.treatments) {
      let tempConsents: Array<any> = [];
      let addedConsents: Array<any> = [];

      for (let user_treatment of data.patients_by_pk.treatments) {
        if (
          user_treatment.treatment?.data.consent_form_url?.length &&
          addedConsents.indexOf(user_treatment.treatment.id) === -1
        ) {
          tempConsents.push({
            id: user_treatment.treatment.id,
            title: user_treatment.treatment.title + " - " + user_treatment.id,
            url: user_treatment.treatment.data.consent_form_url,
          });

          addedConsents.push(user_treatment.treatment.id);
        }
      }

      setClientConsent(tempConsents);
    }

    if (
      data?.patients_by_pk?.patient_forms_data &&
      data?.patients_by_pk?.patient_forms_data.length > 0
    ) {
      data?.patients_by_pk?.patient_forms_data.map((formData: any) => {
        if (formData.form.descriptor === "CONSULTATION_FORM") {
          setConsultationFormData(formData);
        } else if (formData.form.descriptor === "FITZ_SKIN_TYPE_FORM") {
          setFitzSkinTypeFormData(formData);
        } else if (formData.form.descriptor === "SECOND_CONSULTATION_FORM") {
          setSecondConsultationFormData(formData);
        }
      });
    }
  }, [data]);

  useEffect(() => {
    getMetaData();
  }, []);

  useEffect(() => {
    if (
      data?.patients_by_pk?.skin_type !== null &&
      data?.patients_by_pk?.skin_type !== undefined &&
      skinTypeMetaData?.data?.meta_data[0]?.data?.length
    ) {
      for (let skinType of skinTypeMetaData?.data?.meta_data[0]?.data) {
        if (
          (skinType.start === null ||
            data.patients_by_pk.skin_type >= skinType.start) &&
          (skinType.end === null ||
            data.patients_by_pk.skin_type <= skinType.end)
        ) {
          setCurrentSkinType(skinType);
        }
      }
    }
  }, [skinTypeMetaData, data]);

  const [InsertFormData, { loading: insertIsLoading }] = useMutation<
    AddFormVisitDataMutation,
    AddFormVisitDataMutationVariables
  >(ADD_FORM_DATA);

  const [getFormTemplate] = useLazyQuery<
    GetFormTemplateQuery,
    GetFormTemplateQueryVariables
  >(GET_FORM_TEMPLATE, {
    fetchPolicy: "no-cache",
    variables: {
      descriptor: "CONSULTATION_FORM",
      centerId: Number(process.env.REACT_APP_CENTER_ID),
    },
  });
  const createFirstConsultationForm = async () => {
    const formTemplateData = await getFormTemplate();

    if (formTemplateData?.data) {
      const res = await InsertFormData({
        variables: {
          patient_id: Number(params.id),
          center_id: Number(process.env.REACT_APP_CENTER_ID),
          data: {},
          form_id: formTemplateData?.data?.form_schemas[0]?.id,
          user_id,
        },
      });

      if (res.data?.insert_form_data_one?.id) {
        navigate(
          `/dashboard/patient/${params.id}/form/${res.data?.insert_form_data_one?.id}`
        );
      }
    }
  };

  const patientInfoModalCancelButton = () => {
    return (
      <Button
        variant="link"
        border="1px solid"
        borderColor="gray.400"
        w="full"
        mx="45px"
        borderRadius="100px"
        h="48px"
        mb="30px"
        onClick={() => {
          setPatientInfoModalIsOpen(false);
        }}
      >
        Close
      </Button>
    );
  };

  return (
    <Container w="full" maxW="container.xl" mt="35px">
      <HStack flexWrap="wrap">
        <Button
          as={ReactRouterLink}
          to={state && state.back_url ? state.back_url : "/dashboard/"}
          display="flex"
          bg="transparent"
          borderRadius="100px"
          h="48px"
          w="120px"
          border="1px solid"
          borderColor="gray.300"
        >
          <ChevronLeftIcon fontSize="xl"></ChevronLeftIcon>
          <Text fontSize="md" mx="10px">
            Back
          </Text>
        </Button>

        <Heading fontSize="30px" mx="15px">
          Patient Status
        </Heading>

        <Button
          variant="btnNormal"
          onClick={() => {
            navigate("/dashboard/patients/edit/" + params.id);
          }}
        >
          <AiOutlineEdit />
        </Button>
      </HStack>

      {data?.patients_by_pk && (
        <>
          <HStack w="full" alignItems="stretch" spacing="10px" flexWrap="wrap">
            <Card
              boxShadow="null"
              borderRadius="16px"
              border="1px solid"
              borderColor="gray.100"
              position="relative"
              backgroundImage={ellipse6}
              backgroundRepeat="no-repeat"
              backgroundPosition="top right"
              mt="20px"
              flex="1"
              minW="60%"
            >
              <CardBody p="35px">
                <HStack alignItems="center">
                  <Heading fontWeight="800" fontSize="32px">
                    {data.patients_by_pk.first_name +
                      " " +
                      data.patients_by_pk.last_name}
                    <Text as="span" fontWeight="500" fontSize="20px">
                      {" "}
                      - {data.patients_by_pk.customer_code}
                    </Text>
                  </Heading>
                </HStack>
                <HStack gap="10px" mt="20px" flexWrap="wrap">
                  <Square
                    borderRadius="8px"
                    size="36px"
                    bg="#FFF2F7"
                    color="#FF0066"
                  >
                    <Icon fontSize="20px" as={FaRegEnvelope}></Icon>
                  </Square>
                  <Text fontWeight="400" fontSize="15px">
                    {data.patients_by_pk.email}
                  </Text>
                </HStack>
                <HStack
                  gap="10px"
                  mt="20px"
                  flexWrap="wrap"
                  justifyContent="space-between"
                >
                  <HStack>
                    <Square
                      borderRadius="8px"
                      size="36px"
                      bg="#F0F6FF"
                      color="#0065FF"
                    >
                      <Icon fontSize="20px" as={FiPhone}></Icon>
                    </Square>
                    <Text fontWeight="400" fontSize="15px">
                      {data.patients_by_pk.mobile}
                    </Text>
                  </HStack>
                  <Button
                    display="flex"
                    bg="transparent"
                    borderRadius="100px"
                    h="37px"
                    w="133px"
                    border="1px solid"
                    borderColor="gray.300"
                    onClick={() => {
                      setPatientInfoModalIsOpen(true);
                    }}
                  >
                    <Text fontSize="15px" fontWeight="400" mx="10px">
                      View More
                    </Text>
                    <Icon as={FiArrowUpRight}></Icon>
                  </Button>
                </HStack>
              </CardBody>
            </Card>

            <HStack
              flex="1"
              alignItems="stretch"
              flexWrap="wrap"
              minW={{
                base: "100%",
                sm: "340px",
              }}
            >
              <Card
                boxShadow="null"
                flex="1"
                borderRadius="16px"
                border="1px solid"
                borderColor="gray.100"
                position="relative"
                mt="20px"
                minW={{
                  base: "100%",
                  sm: "160px",
                }}
                as={ReactRouterLink}
                to={`/dashboard/patient/${params.id}/documents/`}
              >
                <CardBody h="full" justifyContent="center" alignItems="center">
                  <VStack
                    h="full"
                    w="full"
                    justifyContent="center"
                    alignItems="center"
                    spacing="20px"
                  >
                    <Square size="48px" borderRadius="6px" bg="#F5F5F5">
                      <Icon
                        fontSize="24px"
                        as={HiOutlineDocumentDuplicate}
                      ></Icon>
                    </Square>

                    <Text fontWeight="500">Archived documents</Text>
                  </VStack>
                </CardBody>
              </Card>

              <Card
                boxShadow="null"
                flex="1"
                borderRadius="16px"
                border="1px solid"
                borderColor="gray.100"
                position="relative"
                mt="20px"
                minW={{
                  base: "100%",
                  sm: "160px",
                }}
                as={ReactRouterLink}
                to={`/dashboard/patient/${params.id}/pictures/`}
              >
                <CardBody>
                  <VStack
                    h="full"
                    w="full"
                    justifyContent="center"
                    alignItems="center"
                    spacing="20px"
                  >
                    <Square size="48px" borderRadius="6px" bg="#F5F5F5">
                      <Icon fontSize="24px" as={AiOutlinePicture}></Icon>
                    </Square>

                    <Text fontWeight="500">General Photos</Text>
                  </VStack>
                </CardBody>
              </Card>
            </HStack>
          </HStack>

          <Card
            boxShadow="null"
            borderRadius="16px"
            border="1px solid"
            borderColor="gray.100"
            position="relative"
            mt="20px"
          >
            <CardBody p={{ base: "5px", sm: "35px" }}>
              <VStack
                h="full"
                borderStart="1px solid"
                borderColor="gray.300"
                px={{ base: "5px", sm: "30px" }}
                w="full"
              >
                <HStack w="full" position="relative">
                  <Circle bg="blue.200" size="20px" marginStart="-40px">
                    <Circle
                      bg="white"
                      border="4px solid"
                      size="12px"
                      borderColor="blue.500"
                    ></Circle>
                  </Circle>
                  <Heading fontSize="24px" ps="20px">
                    Visits
                  </Heading>
                </HStack>

                <Box w="full" pb="50px">
                  {data?.patients_by_pk?.visits?.length === 0 && (
                    <Box mb="35px" w="full" textAlign="center">
                      No visit recorded.
                    </Box>
                  )}

                  <SimpleGrid
                    columns={{ base: 1, md: 2, lg: 3, xl: 4 }}
                    gap="15px"
                    mt="15px"
                    mb={5}
                    w="full"
                  >
                    {data?.patients_by_pk?.visits?.map(
                      (visit, index: number) => {
                        return (
                          <Card
                            h="122"
                            w="full"
                            border="1px solid"
                            borderColor="#E6E6E6"
                            key={visit.id}
                            boxShadow="none"
                            cursor="pointer"
                            as={ReactRouterLink}
                            to={`/dashboard/patient/${params.id}/visit/${visit.id}`}
                          >
                            <CardBody
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <HStack
                                justifyContent="space-between"
                                w="full"
                                h="full"
                              >
                                <VStack
                                  bg={"#61AE78"}
                                  color={"white"}
                                  h="full"
                                  w="48px"
                                  borderRadius="100px"
                                  justifyContent="center"
                                >
                                  <Text fontSize="14px" fontWeight="500">
                                    {visit.date && (
                                      <>
                                        {getDayName(
                                          new Date(visit.date).getDay()
                                        )}
                                      </>
                                    )}

                                    {!visit.date && <>-</>}
                                  </Text>
                                  <Text fontSize="14px" fontWeight="500">
                                    {visit.date &&
                                      dayjs(visit.date).format("D")}
                                  </Text>
                                </VStack>
                                <VStack
                                  h="full"
                                  minW="48px"
                                  flex="1"
                                  justifyContent="center"
                                  alignItems="start"
                                  ms="10px"
                                >
                                  <Heading fontSize="16px" fontWeight="700">
                                    Visit #{visit.visit_number}
                                  </Heading>

                                  <Text fontSize="14px" fontWeight="500">
                                    {visit.start_time && (
                                      <>{visit.start_time}</>
                                    )}
                                    {(visit.start_time || visit.end_time) && (
                                      <Text as="span"> - </Text>
                                    )}

                                    {visit.end_time && <>{visit.end_time}</>}
                                    {!visit.start_time && !visit.end_time && (
                                      <>-</>
                                    )}
                                  </Text>

                                  <Text fontSize="14px" fontWeight="500">
                                    {dayjs(visit.date).format("MMM YYYY")}
                                  </Text>
                                </VStack>
                                <Box w="35px">
                                  <Circle
                                    size="32px"
                                    border="1px solid"
                                    borderColor="#B7B9C4"
                                  >
                                    <ArrowForwardIcon></ArrowForwardIcon>
                                  </Circle>
                                </Box>
                              </HStack>
                            </CardBody>
                          </Card>
                        );
                      }
                    )}
                  </SimpleGrid>

                  <Button
                    w="full"
                    h="48px"
                    variant="btnMain"
                    onClick={() => {
                      setVisitModalIsOpen(true);
                    }}
                  >
                    Record new visit +
                  </Button>
                </Box>

                <HStack w="full" position="relative">
                  <Circle bg="#c1efb9" size="20px" marginStart="-40px">
                    <Circle
                      bg="white"
                      border="4px solid"
                      size="12px"
                      borderColor="#429E5E"
                    ></Circle>
                  </Circle>
                  <Heading fontSize="24px" ps="20px">
                    Fitz Skin Type
                  </Heading>
                </HStack>

                <Box w="full" pb="50px">
                  <Card
                    boxShadow="null"
                    borderRadius="16px"
                    border="1px solid"
                    borderColor="gray.300"
                    position="relative"
                    mt="20px"
                    w="full"
                  >
                    <CardBody>
                      <>
                        {!fitzSkinTypeFormData && (
                          <HStack w="full" flexWrap="wrap">
                            <Text flexGrow="1">Uncompleted</Text>
                            <Button
                              display="flex"
                              bg="transparent"
                              borderRadius="100px"
                              h="37px"
                              border="1px solid"
                              borderColor="gray.300"
                              as={ReactRouterLink}
                              to={`/dashboard/patient/${params.id}/skin/`}
                            >
                              <Text fontSize="15px" fontWeight="400" mx="10px">
                                Complete Form
                              </Text>
                              <Icon as={FiArrowRight}></Icon>
                            </Button>
                          </HStack>
                        )}

                        {fitzSkinTypeFormData && (
                          <HStack w="full" flexWrap="wrap">
                            <Text flex="1">
                              Completed in{" "}
                              {getHRFormat(
                                new Date(fitzSkinTypeFormData.created_at)
                              )}
                            </Text>
                            <Box position="relative">
                              <Image
                                bg={
                                  currentSkinType
                                    ? currentSkinType.color
                                    : "#ffffff"
                                }
                                src={skinTypeImage}
                              ></Image>
                              <HStack
                                justifyContent="center"
                                w="full"
                                h="full"
                                fontSize="14px"
                                position="absolute"
                                right="0"
                                top="0"
                                left="0"
                                bottom="0"
                              >
                                <Text color="white" textShadow="0 0 7px black">
                                  {currentSkinType?.full_title}
                                </Text>
                              </HStack>
                            </Box>
                            <Button
                              display="flex"
                              bg="transparent"
                              borderRadius="100px"
                              h="37px"
                              border="1px solid"
                              borderColor="gray.300"
                              w="230px"
                              as={ReactRouterLink}
                              to={`/dashboard/patient/${params.id}/skin/${fitzSkinTypeFormData.id}`}
                            >
                              <Text fontSize="15px" fontWeight="400" mx="10px">
                                Edit Form
                              </Text>
                              <Icon as={EditIcon}></Icon>
                            </Button>
                            <Button
                              display="flex"
                              bg="transparent"
                              borderRadius="100px"
                              h="37px"
                              onClick={() => {
                                setSendFitzSkinTypeEmailModalIsOpen(true);
                              }}
                              border="1px solid"
                              borderColor="gray.300"
                            >
                              <Icon as={AiOutlineMail}></Icon>
                            </Button>
                          </HStack>
                        )}
                      </>
                    </CardBody>
                  </Card>
                </Box>

                {clientConsent.length > 0 && (
                  <>
                    <HStack w="full" position="relative">
                      <Circle bg="#FFEACF" size="20px" marginStart="-40px">
                        <Circle
                          bg="white"
                          border="4px solid"
                          size="12px"
                          borderColor="#FFAA40"
                        ></Circle>
                      </Circle>
                      <Heading fontSize="24px" ps="20px">
                        Client Consent
                      </Heading>
                    </HStack>
                    <Box w="full" pb="50px">
                      <VStack w="full" spacing="30px">
                        {clientConsent.map((cc: any) => (
                          <Card
                            boxShadow="null"
                            key={cc.id}
                            borderRadius="16px"
                            border="1px solid"
                            borderColor="gray.300"
                            position="relative"
                            mt="20px"
                            w="full"
                          >
                            <CardBody>
                              <HStack w="full" justifyContent="space-between">
                                <Text flexGrow="1">{cc.title}</Text>

                                <Link href={cc.url} isExternal>
                                  <Icon fontSize="20px" as={BsDownload}></Icon>
                                </Link>
                                <Button
                                  display="flex"
                                  bg="transparent"
                                  borderRadius="100px"
                                  h="37px"
                                  border="0"
                                  onClick={() => {
                                    setSendConsentEmailObject(cc);
                                  }}
                                >
                                  <Icon
                                    fontSize="18px"
                                    as={AiOutlineMail}
                                  ></Icon>
                                </Button>
                              </HStack>
                            </CardBody>
                          </Card>
                        ))}
                      </VStack>
                    </Box>
                  </>
                )}

                <HStack w="full" position="relative">
                  <Circle bg="red.200" size="20px" marginStart="-40px">
                    <Circle
                      bg="white"
                      border="4px solid"
                      size="12px"
                      borderColor="red.500"
                    ></Circle>
                  </Circle>
                  <Heading fontSize="24px" ps="20px">
                    Consultation
                  </Heading>
                </HStack>

                <VStack w="full" spacing="15px">
                  {secondConsultationFormData && (
                    <Card
                      boxShadow="null"
                      borderRadius="16px"
                      border="1px solid"
                      borderColor="gray.300"
                      position="relative"
                      mt="20px"
                      w="full"
                    >
                      <CardBody>
                        <HStack w="full" flexWrap="wrap">
                          <Text flexGrow="1">
                            Second Consultation - Sent in{" "}
                            {getHRFormat(
                              new Date(secondConsultationFormData.created_at)
                            )}
                          </Text>
                          <Button
                            display="flex"
                            bg="transparent"
                            borderRadius="100px"
                            h="37px"
                            w="133px"
                            border="1px solid"
                            borderColor="gray.300"
                            as={ReactRouterLink}
                            to={`/dashboard/patient/${params.id}/second_consultation_form/${secondConsultationFormData.id}`}
                          >
                            <Text fontSize="15px" fontWeight="400" mx="10px">
                              Edit Form
                            </Text>
                            <Icon as={FiArrowUpRight}></Icon>
                          </Button>
                          <Button
                            display="flex"
                            bg="transparent"
                            borderRadius="100px"
                            h="37px"
                            onClick={() => {
                              setSendSecondConsultationEmailModalIsOpen(true);
                            }}
                            border="1px solid"
                            borderColor="gray.300"
                          >
                            <Icon as={AiOutlineMail}></Icon>
                          </Button>
                        </HStack>
                      </CardBody>
                    </Card>
                  )}

                  {!secondConsultationFormData && (
                    <Card
                      boxShadow="null"
                      borderRadius="16px"
                      border="1px solid"
                      borderColor="gray.300"
                      position="relative"
                      mt="20px"
                      w="full"
                    >
                      <CardBody>
                        <HStack w="full" flexWrap="wrap">
                          <Text flexGrow="1">Second Consultation</Text>
                          <Button
                            display="flex"
                            bg="transparent"
                            borderRadius="100px"
                            h="37px"
                            w="160px"
                            border="1px solid"
                            borderColor="gray.300"
                            as={ReactRouterLink}
                            to={`/dashboard/patient/${params.id}/second_consultation_form/`}
                          >
                            <Text fontSize="15px" fontWeight="400" mx="10px">
                              Complete Form
                            </Text>
                            <Icon as={FiArrowUpRight}></Icon>
                          </Button>
                        </HStack>
                      </CardBody>
                    </Card>
                  )}

                  {consultationFormData && (
                    <Card
                      boxShadow="null"
                      borderRadius="16px"
                      border="1px solid"
                      borderColor="gray.300"
                      position="relative"
                      mt="20px"
                      w="full"
                    >
                      <CardBody>
                        <HStack w="full" flexWrap="wrap">
                          <Text flexGrow="1">
                            Consultation - Sent in{" "}
                            {getHRFormat(
                              new Date(consultationFormData.created_at)
                            )}
                          </Text>
                          <Button
                            display="flex"
                            bg="transparent"
                            borderRadius="100px"
                            h="37px"
                            w="133px"
                            border="1px solid"
                            borderColor="gray.300"
                            as={ReactRouterLink}
                            to={`/dashboard/patient/${params.id}/form/${consultationFormData.id}`}
                          >
                            <Text fontSize="15px" fontWeight="400" mx="10px">
                              View More
                            </Text>
                            <Icon as={FiArrowUpRight}></Icon>
                          </Button>
                        </HStack>
                      </CardBody>
                    </Card>
                  )}

                  {!consultationFormData && (
                    <Card
                      boxShadow="null"
                      borderRadius="16px"
                      border="1px solid"
                      borderColor="gray.300"
                      position="relative"
                      mt="20px"
                      w="full"
                    >
                      <CardBody>
                        <HStack w="full" flexWrap="wrap">
                          <Text flexGrow="1">First Consultation Form</Text>
                          <Button
                            display="flex"
                            bg="transparent"
                            borderRadius="100px"
                            h="37px"
                            w="160px"
                            border="1px solid"
                            borderColor="gray.300"
                            disabled={insertIsLoading}
                            onClick={createFirstConsultationForm}
                          >
                            <Text fontSize="15px" fontWeight="400" mx="10px">
                              Complete Form
                            </Text>
                            <Icon as={FiArrowUpRight}></Icon>
                          </Button>
                        </HStack>
                      </CardBody>
                    </Card>
                  )}
                </VStack>
              </VStack>
            </CardBody>
          </Card>

          <ModalBox
            isOpen={patientInfoModalIsOpen}
            onClose={() => {
              setPatientInfoModalIsOpen(false);
            }}
            size="2xl"
            icon={FaRegUser}
            iconsize="24px"
          >
            <ModalBody pb={6}>
              <Box px="45px">
                <Heading size="md" textAlign="center">
                  Patient Information
                </Heading>

                <SimpleGrid
                  my="30px"
                  w="full"
                  gap="15px"
                  alignItems="baseline"
                  columns={{
                    lg: 2,
                    md: 2,
                    base: 1,
                  }}
                  borderBottom="1px dashed"
                  borderColor="gray.300"
                >
                  <VStack flexGrow="1" alignItems="start" pb={"30px"}>
                    <Text fontSize="13px" color="gray.500">
                      Occupation
                    </Text>
                    <Text fontSize="14px">
                      {data.patients_by_pk.occupation || (
                        <Text color="red.300">Not Provided</Text>
                      )}
                    </Text>
                  </VStack>
                  <VStack flexGrow="1" alignItems="start" pb={"30px"}>
                    <Text fontSize="13px" color="gray.500">
                      Birthday
                    </Text>
                    <Text fontSize="14px">
                      {data.patients_by_pk.birthday ? (
                        <>
                          {dayjs(data.patients_by_pk.birthday).format(
                            "DD MMM YYYY"
                          )}
                          {" - "}
                          {dayjs().diff(
                            dayjs(data.patients_by_pk.birthday),
                            "year"
                          )}{" "}
                          year(s)
                        </>
                      ) : (
                        <Text color="red.300">Not Provided</Text>
                      )}
                    </Text>
                  </VStack>
                </SimpleGrid>

                <SimpleGrid
                  my="30px"
                  w="full"
                  gap="15px"
                  alignItems="baseline"
                  columns={{
                    lg: 2,
                    md: 2,
                    base: 1,
                  }}
                  borderBottom="1px dashed"
                  borderColor="gray.300"
                >
                  <VStack flexGrow="1" alignItems="start" pb="30px">
                    <Text fontSize="13px" color="gray.500">
                      Address
                    </Text>
                    <Text fontSize="14px">
                      {data.patients_by_pk.address?.full}
                    </Text>
                  </VStack>
                  <VStack flexGrow="1" alignItems="start" pb="30px">
                    <Text fontSize="13px" color="gray.500">
                      Contact Method
                    </Text>
                    <Text fontSize="14px">
                      {data.patients_by_pk.contact_method}
                    </Text>
                  </VStack>
                </SimpleGrid>

                <SimpleGrid
                  my="30px"
                  w="full"
                  gap="15px"
                  alignItems="baseline"
                  columns={{
                    lg: 2,
                    md: 2,
                    base: 1,
                  }}
                  borderBottom="1px dashed"
                  borderColor="gray.300"
                >
                  <VStack flexGrow="1" alignItems="start" pb="30px">
                    <Text fontSize="13px" color="gray.500">
                      Emergency Contact Full Name
                    </Text>
                    <Text fontSize="14px">
                      {data.patients_by_pk.emergency_contact
                        ?.emergency_contact_full_name || (
                        <Text color="red.300">Not Provided</Text>
                      )}
                    </Text>
                  </VStack>
                  <VStack flexGrow="1" alignItems="start" pb="30px">
                    <Text fontSize="13px" color="gray.500">
                      Emergency Contact Phone
                    </Text>
                    <Text fontSize="14px">
                      {data.patients_by_pk.emergency_contact
                        ?.emergency_contact_phone || (
                        <Text color="red.300">Not Provided</Text>
                      )}
                    </Text>
                  </VStack>
                </SimpleGrid>

                <SimpleGrid
                  my="30px"
                  w="full"
                  gap="15px"
                  alignItems="baseline"
                  columns={{
                    lg: 2,
                    md: 2,
                    base: 1,
                  }}
                >
                  <VStack flexGrow="1" alignItems="start">
                    <Text fontSize="13px" color="gray.500">
                      Emergency Contact email
                    </Text>
                    <Text fontSize="14px">
                      {data.patients_by_pk.emergency_contact
                        ?.emergency_contact_email || (
                        <Text color="red.300">Not Provided</Text>
                      )}
                    </Text>
                  </VStack>
                </SimpleGrid>
              </Box>
            </ModalBody>
            <ModalFooter>
              <HStack
                w="full"
                align="center"
                spacing={10}
                marginStart={10}
                marginEnd={10}
                justifyContent={"space-between"}
              >
                {patientInfoModalCancelButton()}
              </HStack>
            </ModalFooter>
          </ModalBox>

          <AddVisitModal
            isOpen={visitModalIsOpen}
            patient={data?.patients_by_pk}
            closeAction={async () => {
              setVisitModalIsOpen(false);
              await client.refetchQueries({
                include: [GET_PATIENT_DATA],
              });
            }}
          ></AddVisitModal>

          {/* Email Confirmations Sart*/}
          <Confirmation
            isOpen={sendFitzSkinTypeEmailModalIsOpen}
            isLoading={sendFitzSkinTypeEmailBtnLoading}
            closeAction={() => {
              setSendFitzSkinTypeEmailModalIsOpen(false);
            }}
            acceptAction={async () => {
              setSendFitzSkinTypeEmailBtnLoading(true);
              try {
                let sendEmailResult = await axiosInstance().post(
                  "/mail/send_by_template",
                  {
                    form: fitzSkinTypeFormData?.id,
                    template: "FITZ_SKIN_TYPE",
                  },
                  {
                    headers: {
                      Authorization: 'Bearer ' + localStorage.getItem("access_token"),
                    },
                  }
                );

                if (sendEmailResult.data.status === 0) {
                  toast.success("Email Sent Successfully.");
                } else {
                  toast.error("There is an error on sending email.");
                }
              } catch (e) {
                toast.error("There is an error on sending email.");
              }
              setSendFitzSkinTypeEmailBtnLoading(false);
              setSendFitzSkinTypeEmailModalIsOpen(false);
            }}
          >
            Are you sure you want to send this email to the patient?
          </Confirmation>

          <Confirmation
            isOpen={sendConsentEmailObject !== null}
            isLoading={sendConsentEmailBtnLoading}
            closeAction={() => {
              setSendConsentEmailObject(null);
            }}
            acceptAction={async () => {
              setSendConsentEmailBtnLoading(true);
              try {
                let filename = "";
                let temp = sendConsentEmailObject.url.split(".");
                filename =
                  sendConsentEmailObject.title + "." + temp[temp.length - 1];

                let sendEmailResult = await axiosInstance().post(
                  "/mail/send_by_template",
                  {
                    template: "CONSENT",
                    patient: data?.patients_by_pk?.id,
                    attachments_urls: [
                      {
                        filename: filename,
                        path: sendConsentEmailObject?.url,
                      },
                    ],
                  },
                  {
                    headers: {
                      Authorization: 'Bearer ' + localStorage.getItem("access_token"),
                    },
                  }
                );

                if (sendEmailResult.data.status === 0) {
                  toast.success("Email Sent Successfully.");
                } else {
                  toast.error("There is an error on sending email.");
                }
              } catch (e) {
                toast.error("There is an error on sending email.");
              }
              setSendConsentEmailBtnLoading(false);
              setSendConsentEmailObject(null);
            }}
          >
            Are you sure you want to send this email to the patient?
          </Confirmation>

          <Confirmation
            isOpen={sendSecondConsultationEmailModalIsOpen}
            isLoading={sendSecondConsultationEmailBtnLoading}
            closeAction={() => {
              setSendSecondConsultationEmailModalIsOpen(false);
            }}
            acceptAction={async () => {
              setSendSecondConsultationEmailBtnLoading(true);
              try {
                let sendEmailResult = await axiosInstance().post(
                  "/mail/send_by_template",
                  {
                    form: secondConsultationFormData?.id,
                    template: "SECOND_CONSULTATION",
                  },
                  {
                    headers: {
                      Authorization: 'Bearer ' + localStorage.getItem("access_token"),
                    },
                  }
                );

                if (sendEmailResult.data.status === 0) {
                  toast.success("Email Sent Successfully.");
                } else {
                  toast.error("There is an error on sending email.");
                }
              } catch (e) {
                toast.error("There is an error on sending email.");
              }

              setSendSecondConsultationEmailBtnLoading(false);
              setSendSecondConsultationEmailModalIsOpen(false);
            }}
          >
            Are you sure you want to send this email to the patient?
          </Confirmation>

          {/* Email Confirmations End*/}
        </>
      )}
    </Container>
  );
};

export default Patient;
