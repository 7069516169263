import { useEffect, useState } from "react";
import { FormItemProps } from "../../../interfaces/Interfaces";
import {
  Text,
  FormControl,
  FormLabel, Select as ChakraSelect,
  InputProps,
  HStack
} from "@chakra-ui/react";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { useFormContext } from "react-hook-form";
import { get } from "lodash";

interface SelectBoxInterFace extends FormItemProps, InputProps {}
const Select = (props: SelectBoxInterFace) => {
  let item = props.item;
  let inputItemHandler = props.inputItemHandler;
  const validator = props.validator;

  const {
    formState: { errors },
    register
  } = useFormContext();

  const error = get(errors, item.descriptor);

  let recommendationData = props.recommendationData;
  const [recommendedValue, setrecommendedValue] = useState<any>(null);

  useEffect(() => {
    if (item && item?.getRecommendedValue) {
      let func = new Function("recommendations,data", item.getRecommendedValue);
      setrecommendedValue(func(item.recommendations, recommendationData));
    }
  }, [item]);

  return (
    <FormControl isInvalid={!!error} w={props.w ?? ""} flex={props.flex ?? ""}>
      {item.title.length > 0 && (
        <FormLabel flexDirection="column">
          {item.required && (
            <Text as="span" color="red.500" fontSize="14px" me="5px">
              *
            </Text>
          )}
          <Text as="span">{item.title}</Text>
        </FormLabel>
      )}

      <ChakraSelect
        key={item.descriptor}
        fontSize="14px"
        fontWeight="400"
        {...item?.options?.props}
        placeholder={item.placeholder?.length ? item.placeholder : ""}
        {...register(item.descriptor, validator)}
      >
        {item.values &&
          Object.keys(item.values).map((optionKey) => {
            return (
              item.values && (
                <option
                  key={item.values[optionKey].value}
                  value={item.values[optionKey].value}
                >
                  {item.values[optionKey]?.title
                    ? item.values[optionKey]?.title
                    : item.values[optionKey]?.value}
                </option>
              )
            );
          })}
      </ChakraSelect>
      {item.recommendations && recommendedValue !== null && (
        <HStack my="10px">
          <InfoOutlineIcon color="#757575"></InfoOutlineIcon>
          <Text flex="1" fontSize="14px" fontWeight="400" color="#757575">
            Recommended:{" "}
            <Text color="#2E2E2E" as="span">
              {recommendedValue}
            </Text>
          </Text>
          <Text
            fontSize="14px"
            fontWeight="400"
            color="#0065FF"
            cursor="pointer"
            onClick={() => {
              inputItemHandler && inputItemHandler(recommendedValue, item);
            }}
          >
            Apply Recommended
          </Text>
        </HStack>
      )}

    </FormControl>
  );
};

export default Select;
