import { gql } from "@apollo/client";

export const GET_PATIENT_DATA = gql`
  query getPatientData($id: Int!) {
    patients_by_pk(id: $id) {
      occupation
      mobile
      last_name
      first_name
      id
      emergency_contact
      email
      customer_code
      contact_method
      center_id
      birthday
      address
      skin_type
      patient_forms_data(order_by: { id: asc }) {
        id
        data
        created_at
        updated_at
        treatment_id
        user_id
        version
        form {
          version
          descriptor
          center_id
        }
        center_id
      }
      treatments(order_by: { id: asc }) {
        created_at
        data
        description
        id
        session_number
        title
        treatment {
          data
          description
          id
          image
          title
          descriptor
          form {
            version
            descriptor
            center_id
            template
          }
          prescriptions {
            id
            treatment_id
            description
            title
            body
          }
        }
        user_id
        status
      }
      visits(where: { deleted_at: { _is_null: true } }, order_by: { visit_number: asc }) {
        id
        date
        start_time
        end_time
        duration
        visit_number
        created_at
      }
    }
  }
`;

export const GET_PATIENT_FORM_DATA = gql`
  query getPatientFormData($id: Int!) {
    form_data_by_pk(id: $id) {
      id
      data
      form {
        version
        template
        id
        descriptor
      }
      patient {
        unique_id
        occupation
        mobile
        last_name
        first_name
        id
        emergency_contact
        email
        customer_code
        contact_method
        center_id
        birthday
        address
        skin_type
      }
      notes(order_by: { id: desc }) {
        id
        body
        section
        created_at
      }
      id
      patient_id
      treatment_id
      user_id
      version
      treatment {
        data
        description
        id
        image
        title
        descriptor
      }
    }
  }
`;

export const GET_FORM_TEMPLATE = gql`
  query getFormTemplate($descriptor: String!, $centerId: Int!) {
    form_schemas(
      where: { descriptor: { _eq: $descriptor }, center_id: { _eq: $centerId } }
    ) {
      id
      version
      template
    }
  }
`;

export const GET_META_DATA = gql`
  query getMetaData($descriptor: String!, $centerId: Int!) {
    meta_data(
      where: { center_id: { _eq: $centerId }, descriptor: { _eq: $descriptor } }
    ) {
      data
      descriptor
      id
    }
  }
`;