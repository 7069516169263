import {Box, Button, FormLabel, HStack, Icon, Image, Input, Spinner, Text, VStack} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import { ImUpload } from "react-icons/im";
import {DeleteIcon} from "@chakra-ui/icons";

interface UploaderProps {
    extraFields?: any,
    cancelAction?: any,
    uploadAction?: any,
    errorMessages?: Array<string>,
    isLoading?: boolean,
    accept?: string
}
const Uploader = (props: UploaderProps) => {
    const imageExtensions = [
        'png',
        'jpg',
        'jpeg',
        'webm',
        'bpm',
        'giff',
    ];
    const [boardText, setBoardText] = useState('Drag and drop your files, or click to select files');
    const borderBg = 'url("data:image/svg+xml,%3csvg width=\'100%25\' height=\'100%25\' xmlns=\'http://www.w3.org/2000/svg\'%3e%3crect width=\'100%25\' height=\'100%25\' fill=\'none\' rx=\'10\' ry=\'10\' stroke=\'%23C9C9C9FF\' stroke-width=\'2\' stroke-dasharray=\'6%2c 10\' stroke-dashoffset=\'0\' stroke-linecap=\'square\'/%3e%3c/svg%3e")';
    const [files, setFiles] = useState<Array<any>>([])
    const dragStart = (e:React.DragEvent) => {
        e.preventDefault();
        (document.querySelector('#files') as HTMLElement)?.focus();
    }
    const dragOverHandler = (e:React.DragEvent) => {
        e.preventDefault();
        setBoardText('Drop Files Here!')
    }

    const dragOutHandler = (e:React.DragEvent) => {
        e.preventDefault();
        setBoardText('Drag and drop your files, or click to select files');
    }

    const dropHandler = (e:React.DragEvent) => {
        e.preventDefault();
        let selectedFiles:Array<any> = [];

        if (e.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            [...e.dataTransfer.items].forEach((item, i) => {
                // If dropped items aren't files, reject them
                if (item.kind === "file") {
                    let file:any = item.getAsFile();
                    selectedFiles.push(file);
                }
            });
        } else {
            // Use DataTransfer interface to access the file(s)
            [...e.dataTransfer.files].forEach((file:any, i) => {
                selectedFiles.push(file);
            });
        }

        setFiles(selectedFiles);
    }

    const getFileSize = (size: number) => {
        if (size > 1024 * 1024 * 1024) {
            return Math.floor(size / 1024 / 1024 / 1024) + ' gb';
        } else if (size > 1024 * 1024) {
            return Math.floor(size / 1024 / 1024) + ' mb';
        } else if (size > 1024) {
            return Math.floor(size / 1024) + ' kb';
        } else {
            return Math.floor(size / 1024) + ' b';
        }

        return 0;
    }

    return (
        <Box position="relative">
            <Input position="absolute" top="-70px" type="file" id="files" style={{opacity: 0}} zIndex="-1" multiple
                accept={props.accept ? props.accept : ''}
                onChange={(e) => {
                    e.preventDefault();
                    let files = [];
                    if (e?.target?.files?.length) {
                        for (let i = 0; i < e.target.files.length; i++) {
                            files.push(e.target.files[i]);
                        }
                    }
                    setFiles(files);
                }}
            />

            <FormLabel w="full" h="250px" htmlFor="files" position="relative" zIndex="10000000000"
                bg={borderBg}
                onDragOverCapture={(e) => {
                    e.preventDefault();
                }}
                onDragOver={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    // dragOverHandler(e);
                }}

                onDrop={(e) => {
                    e.preventDefault();
                    dropHandler(e);
                }}

                onDropCapture={(e) => {
                    e.preventDefault();
                    dropHandler(e);
                }}

                onDragLeave={(e) => {
                    e.preventDefault();
                    dragOutHandler(e);
                }}
            >
                <Box right="0" left="0" top="0" h="250px" position="absolute" zIndex="1000000" overflowY="auto">
                    {files.length > 0 &&
                        <VStack spacing="15px" w="full" p="15px">
                            {files.map((file, index) =>
                                <HStack w="full" key={index}  border="1px solid" borderRadius="8px" borderColor="gray.200" p="5px">
                                    <VStack gap="0" spacing="5px" h="full" alignItems="start"
                                        w="full">
                                        <Text flex="1">{file.name}</Text>
                                        <Text fontSize="13px" fontWeight="400" color="gray.800">{getFileSize(file.size)}</Text>
                                    </VStack>
                                    <Button
                                        onClick={(e) => {
                                            setFiles(files.filter((tfile, tindex) => {
                                                return tindex !== index;
                                            }));
                                        }}
                                    >
                                        <DeleteIcon></DeleteIcon>
                                    </Button>
                                </HStack>
                            )}
                        </VStack>
                    }
                    {files.length === 0 &&
                        <VStack w="full" h="full" justifyContent="center" gap="10px">
                            <Text fontSize="14px" fontWeight="500">
                                {boardText}
                            </Text>
                            <Icon as={ImUpload} color="gray.500" fontSize="64px"></Icon>
                        </VStack>
                    }
                </Box>
            </FormLabel>
            {props.extraFields && props.extraFields()}
            {props.errorMessages && props.errorMessages?.length>0 && props.errorMessages.map((message) => <Text my="15px" color="red.500" fontSize="14px" fontWeight="500">{message}</Text>)}
            <HStack w="full" gap="15px" mt="30px" justifyContent="center">
                <Button variant="btnNormal"
                    minW={120}
                    onClick={() => {
                        props.cancelAction();
                    }}
                >Cancel</Button>
                <Button minW={120} variant="btnMain" isDisabled={props.isLoading}
                    onClick={() => {
                        if (props.isLoading) {
                            return false;
                        }
                        props.uploadAction(files);
                    }}
                >
                    {props.isLoading ? <Spinner mx="5px"></Spinner> : "Upload"}
                </Button>
            </HStack>
        </Box>

    );
}

export default Uploader;