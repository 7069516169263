import { gql } from "@apollo/client";

export const GET_TREATMENT_CATEGORY_BY_ID = gql`
  query getTreatmentCategoryById($id: Int!) {
    treatment_categories_by_pk(id: $id) {
      description
      descriptor
      id
      title
      treatments(order_by: { id: asc }) {
        id
        title
      }
    }
  }
`;

export const GET_TREATMENT_CATEGORIES = gql`
  query getTreatmentCategoriesList(
    $where: treatment_categories_bool_exp!
    $limit: Int!
    $offset: Int!
  ) {
    treatment_categories(
      limit: $limit
      offset: $offset
      order_by: { id: asc }
      where: $where
    ) {
      description
      descriptor
      id
      title
      treatments(order_by: { id: asc }) {
        id
        title
      }
    }

    treatment_categories_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;
