import { gql } from "@apollo/client";

export const GET_PATIENTS_LAST_CUSTOMER_CODE = gql`
  query getPatientsLastCustomerCode($center_id: Int!) {
    patients_aggregate(where: { _and: { center_id: { _eq: $center_id } } }) {
      aggregate {
        max {
          customer_code
        }
      }
    }
  }
`;

export const GET_PATIENT_BY_ID = gql`
  query getPatientById($id: Int!) {
    patients_by_pk(id: $id) {
      address
      birthday
      contact_method
      customer_code
      email
      emergency_contact
      first_name
      last_name
      id
      mobile
      occupation
      skin_type
    }
  }
`;

export const GET_PATIENTS = gql`
  query getPatientsList(
    $where: patients_bool_exp!
    $limit: Int!
    $offset: Int!
  ) {
    patients(
      limit: $limit
      offset: $offset
      order_by: { id: asc }
      where: $where
    ) {
      address
      birthday
      contact_method
      customer_code
      email
      emergency_contact
      first_name
      last_name
      id
      mobile
      occupation
      skin_type
    }

    patients_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const ADD_PATIENT = gql`
  mutation AdminAddPatient($object: patients_insert_input!) {
    insert_patients_one(object: $object) {
      id
    }
  }
`;

export const UPDATE_PATIENT = gql`
  mutation AdminUpdatePatient($id: Int!, $object: patients_set_input!) {
    update_patients_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
    }
  }
`;

export const DELETE_PATIENT = gql`
  mutation AdminDeletePatient($id: Int!) {
    delete_patients_by_pk(id: $id) {
      id
    }
  }
`;
