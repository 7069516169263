import {
    Button,
    FormControl,
    FormHelperText,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    VStack,
    Select, Alert
} from "@chakra-ui/react";
import {useEffect, useState} from "react";

const DeleteModal = (props:any) => {

    const {
        onClose,
        onSubmit,
        indexH
    } = props;

    return (
        <Modal isOpen={true} onClose={onClose} size='sm'>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    Delete Item
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <VStack w='full' gap='15px'>
                        <Alert colorScheme="red">Are you sure to delete this item?</Alert>
                    </VStack>
                </ModalBody>

                <ModalFooter>
                    <Button
                        variant='ghost'
                        mr={3}
                        onClick={onClose}
                    >
                        Close
                    </Button>
                    <Button
                        colorScheme="red"
                        onClick={() => {
                            if (onSubmit) {
                                onSubmit(indexH)
                            }
                        }}
                    >
                        I am sure, Delete IT!
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default DeleteModal;