import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Button,
  Link,
  useDisclosure,
  Heading,
  Stack,
  FormLabel,
  ModalBody,
  ModalFooter,
  HStack,
} from "@chakra-ui/react";
import ModalBox from "../ModalBox";
import { FormItem, FormItemProps } from "../../interfaces/Interfaces";
import { CheckCircleIcon, PhoneIcon, EditIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/Store";
import InputMobile from "./steps/InputMobile";
import { setStep, steps } from "./slice";
import InputOtp from "./steps/InputOtp";
import Success from "./steps/Success";
import SimpleReactValidator from "simple-react-validator";
import axiosInstance from "../../helpers/axiosInstance";
interface MobileAuthorizerState {
  mobile: string;
  verified: boolean;
  inProgress: boolean;
  counter: number;
}

interface MobileAuthorizerProps extends FormItemProps {
  data?: MobileAuthorizerState;
}

const MobileAuthorizer = (props: MobileAuthorizerProps) => {
  const mobileAuthenticatorState = useSelector(
    (state: RootState) => state.mobileAuthenticator
  );
  const dispatch = useDispatch();
  let item = props.item;
  let inputItemHandler = props.inputItemHandler;
  let state = props.state;
  let validator = props.validator;

  const [cvalidator, setCValidator] = useState<any>(
    new SimpleReactValidator({
      element: (message: string) => {
        return (
          <Text textAlign="start" pt="10px" fontSize="sm" color="red.500">
            {message}
          </Text>
        );
      },
    })
  );
  let IsInvalidMessage =
    validator && item.validation_rule
      ? validator.message(
          item.title,
          state[item.descriptor],
          item.validation_rule
        )
      : null;

  const [isModalOpen, setIsModalOpen] = useState(true);

  const defaultData: MobileAuthorizerState = {
    mobile: "",
    verified: false,
    inProgress: false,
    counter: 0,
  };
  const [data, setData] = useState<MobileAuthorizerState>(defaultData);

  const icons = [PhoneIcon, EditIcon];
  const [sendOtpError, setSendOtpError] = useState("");
  const [checkOtpError, setCheckOtpError] = useState("");

  const borderBg =
    "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23C9C9C9FF' stroke-width='2' stroke-dasharray='6%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e\")";

  useEffect(() => {
    if (state[item.descriptor]) {
      setData({
        ...data,
        mobile: state[item.descriptor],
        verified: true,
        inProgress: false,
      });
    }
  }, [state]);

  const sendOtp = () => {
    setSendOtpError("");
    setCheckOtpError("");
    axiosInstance()
      .post("/otp/send", {
        number: mobileAuthenticatorState.mobile,
      })
      .then((response) => {
        if (response.data.status === 0) {
          if (mobileAuthenticatorState.step !== null) {
            dispatch(setStep(1));
          }
        } else {
          setSendOtpError(response.data.errors.number);
        }
      })
      .catch((response) => {
        setSendOtpError(response.response.data.data.errors.number);
      });
  };

  const checkOtp = () => {
    setSendOtpError("");
    setCheckOtpError("");
    axiosInstance()
      .post("/otp/check", {
        number: mobileAuthenticatorState.mobile,
        code: mobileAuthenticatorState.otp_code,
      })
      .then((response) => {
        if (response.data.status === 0) {
          if (mobileAuthenticatorState.step !== null) {
            dispatch(setStep(2));
          }
        } else {
          setCheckOtpError(response.data.errors.number);
        }
      })
      .catch((response) => {
        setCheckOtpError(response.response.data.data.errors.code);
      });
  };

  const getModalActionBtn = () => {
    if (
      mobileAuthenticatorState.step !== null &&
      steps[mobileAuthenticatorState.step] === "final"
    ) {
      return (
        <Button
          w="full"
          flexGrow="1"
          onClick={() => {
            setIsModalOpen(false);
            // UPDATE MOBILE DATA
            if (inputItemHandler) {
              inputItemHandler(mobileAuthenticatorState.mobile, item);
            }
          }}
        >
          {" "}
          Got It{" "}
        </Button>
      );
    } else if (mobileAuthenticatorState.step !== null) {
      return (
        <Button
          w="full"
          flexGrow="1"
          onClick={() => {
            setData({ ...data, counter: data.counter + 1 });
            if (mobileAuthenticatorState.step === 0) {
              if (cvalidator.allValid()) {
                sendOtp();
              } else {
                cvalidator.showMessages();
              }
            } else if (mobileAuthenticatorState.step === 1) {
              checkOtp();
            }
          }}
        >
          {" "}
          Continue{" "}
        </Button>
      );
    }

    return null;
  };

  return (
    <>
      <Box w="full">
        {item.title.length > 0 && (
          <FormLabel flexDirection="column">
            {item.required && (
              <Text as="span" color="red.500" fontSize="14px" me="5px">
                *
              </Text>
            )}
            <Text as="span">{item.title}</Text>
          </FormLabel>
        )}
        <Flex
          justifyContent="center"
          w="100%"
          background={borderBg}
          p="10px"
          borderRadius="10px"
        >
          {!data.inProgress && data.mobile.length == 0 && (
            <Link
              onClick={() => {
                setData({ ...data, inProgress: true });
                setIsModalOpen(true);
                dispatch(setStep(0));
              }}
              fontSize="14px"
              fontWeight="500"
              color="#045CE2"
            >
              {item.text}
            </Link>
          )}

          {!data.inProgress && data.mobile.length > 0 && (
            <Flex w="full" justifyContent="space-between">
              <Text>{data.mobile}</Text>
              <Link
                onClick={() => {
                  setData({ ...data, inProgress: true });
                  setIsModalOpen(true);
                  dispatch(setStep(0));
                }}
              >
                Change
              </Link>
            </Flex>
          )}
        </Flex>

        {!data.inProgress && data.verified && (
          <Flex
            color="green.700"
            fontSize="14px"
            mt="10px"
            ms="10px"
            alignItems="center"
          >
            <CheckCircleIcon></CheckCircleIcon>
            <Text ms="10px">Your mobile number has been authorized</Text>
          </Flex>
        )}

        {data.inProgress && (
          <>
            <ModalBox
              isOpen={isModalOpen}
              closeOnOverlayClick={false}
              icon={icons[mobileAuthenticatorState.step ?? 0]}
              onClose={() => {
                setIsModalOpen(false);
                setData({ ...data, inProgress: false });
              }}
            >
              <ModalBody pb={6}>
                {mobileAuthenticatorState.step !== null &&
                  steps[mobileAuthenticatorState.step] === "input_mobile" && (
                    <InputMobile
                      state={data}
                      dataHandler={setData}
                      sendErrorMessage={sendOtpError}
                      validator={cvalidator}
                    ></InputMobile>
                  )}

                {mobileAuthenticatorState.step !== null &&
                  steps[mobileAuthenticatorState.step] === "input_otp" && (
                    <InputOtp
                      state={data}
                      dataHandler={setData}
                      checkErrorMessage={checkOtpError}
                    ></InputOtp>
                  )}

                {mobileAuthenticatorState.step !== null &&
                  steps[mobileAuthenticatorState.step] === "final" && (
                    <Success state={data} dataHandler={setData}></Success>
                  )}
              </ModalBody>
              <ModalFooter>
                <HStack
                  w="full"
                  align="center"
                  spacing={10}
                  marginStart={10}
                  marginEnd={10}
                  justifyContent={"space-between"}
                >
                  {getModalActionBtn()}
                </HStack>
              </ModalFooter>
            </ModalBox>
          </>
        )}
      </Box>

      {IsInvalidMessage}
    </>
  );
};

export default MobileAuthorizer;
