import React, {useEffect, useState, useRef} from 'react';
import {
    Box,
    Flex,
    Text,
    Circle,
    Stepper,
    Step,
    StepIndicator,
    StepStatus,
    Progress,
    StepTitle,
    Link,
    InputGroup,
    InputRightElement,
    Input,
    Icon, FormLabel, useTab, HStack, VStack, Card, CardBody
} from "@chakra-ui/react";
import Geocode, {setKey, setLanguage, fromAddress} from "react-geocode";
import {FormItemProps} from "../../../interfaces/Interfaces"
import {CheckCircleIcon, Search2Icon} from "@chakra-ui/icons";
import {usePlacesWidget} from "react-google-autocomplete";
import {useDispatch} from "react-redux";
import CustomAddress from "./CustomAddress";
import { useClickAway } from "react-use";

interface AddressBoxProps extends FormItemProps {

}
const AddressBox = (props: AddressBoxProps) => {
    const dispatch = useDispatch()
    let item = props.item;
    let inputItemHandler = props.inputItemHandler;
    let state = props.state;
    let validator = props.validator;
    const [addressResults, setAddressResults] = useState<any[]>([]);
    const [isManual, setIsManual] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const ref = useRef(null);
    useClickAway(ref, () => {
        setIsSelected(true);
    });
    useEffect(() => {
        if (process.env.REACT_APP_GOOGLE_MAPS_API_KEY) {
            setKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
        }
        // set response language. Defaults to English.
        setLanguage("en");
    }, []);
    const handleChangeLocation = (addressValue: string) => {
        setIsSelected(false);
        if(addressValue === ""){
            setAddressResults([])
        };

        // Get latitude & longitude from address.
        if (addressValue) {
            fromAddress(addressValue).then(
                (response) => {
                    setAddressResults(response?.results);
                },
                (error) => {
                    // Handle error here.
                }
            );
        }
    };

    return (
        <Box w="full">
            {item.title.length > 0 &&
                <FormLabel flexDirection="column">
                    {item.required &&
                        <Text as="span" color="red.500" fontSize="14px" me="5px">*</Text>
                    }
                    <Text as="span">{item.title}</Text>
                </FormLabel>
            }
            <InputGroup position="relative"  zIndex="1000">
                <InputRightElement pointerEvents='none'>
                    <Icon as={Search2Icon} ></Icon>
                </InputRightElement>
                <Input type='text' placeholder={item.placeholder}
                    onChange={(e) => {
                        handleChangeLocation(e.target.value)
                        if (inputItemHandler) {
                            inputItemHandler(e.target.value, item);
                        }
                    }}
                    key={item.descriptor}
                    value={state[item.descriptor] ? state[item.descriptor] : ''}
                />
                {!isSelected && addressResults && addressResults.length > 0 &&
                    <Card  position="absolute" left="0" right="0" top="50px" boxShadow="lg" border="1px solid"
                        borderColor="gray.100" ref={ref}
                    >
                        <CardBody py="20px" px="20px">
                            <VStack alignItems="start" gap="4">
                                {
                                    addressResults.map((gitem, index) => {
                                        return (
                                            <HStack key={index} cursor="pointer"
                                                onClick={() => {
                                                    if (inputItemHandler) {
                                                        inputItemHandler(gitem.formatted_address, item);
                                                    }
                                                    setIsSelected(true);
                                                }}
                                            >
                                                <Text fontSize="14px" textAlign="start">
                                                    {gitem.formatted_address}
                                                </Text>
                                            </HStack>
                                        )
                                    })
                                }
                            </VStack>
                        </CardBody>
                    </Card>
                }

            </InputGroup>

            <Box p="10px">
                <Text as="span" me="5px">Can’t find your address?</Text>
                <Link color="#045CE2" fontWeight="600" onClick={() => {
                    setIsManual(true)
                }}>Enter It Manually</Link>
            </Box>

            {isManual &&
                <CustomAddress validator={validator} item={item} inputItemHandler={inputItemHandler} state={state} backToFormAction={() => {
                    setIsManual(false)
                }}></CustomAddress>
            }
        </Box>

    );
}

export default AddressBox;