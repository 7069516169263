import { gql } from "@apollo/client";

export const UPDATE_TREATMENT_CATEGORY = gql`
    mutation updateTreatmentCategory($id: Int! $object: treatment_categories_set_input!) {
        update_treatment_categories_by_pk(pk_columns: {id: $id}, _set: $object) {
            id
        }
    }
`;

export const ADD_TREATMENT_CATEGORY = gql`
    mutation addTreatmentCategory($object: treatment_categories_insert_input!) {
        insert_treatment_categories_one(object: $object) {
            id
        }
    }
`;