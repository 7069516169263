import { useEffect, useState } from "react";

export function usePagination(perPage: number = 10) {
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [lastPage, setLastPage] = useState(1);
  const [pages, setPages] = useState<Array<number>>([]);

  const getPagination = () => {

    let step = 2;
    let startFrom = 1;
    let endTo = lastPage;
    let hasLeft = page - step > 1;
    let hasRight = page + step < lastPage;

    if (hasRight) {
      endTo = page + step;
    }

    if (hasLeft) {
      startFrom = page - step;
    }

    let temp = [];
    for (let i = startFrom; i <= endTo; i++) {
      temp.push(i);
    }

    setPages(temp);

  };

  useEffect(() => {
    getPagination();
  }, [lastPage, page]);

  useEffect(() => {
    if (totalRecords) {
      let temp = totalRecords / perPage;
      let floored = Math.floor(totalRecords / perPage);

      if (temp == floored) {
        setLastPage(temp);
      } else {
        setLastPage(floored + 1);
      }
    } else {
      setLastPage(1);
    }
  }, [totalRecords]);  

  return {
    page,
    setPage,
    totalRecords,
    setTotalRecords,
    lastPage,
    pages,
    perPage,
    offset: (page - 1) * perPage,
  };
}
