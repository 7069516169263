import {
  useNavigate,
  useParams,
  Link as ReactRouterLink,
} from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import useCustomToast from "../../../hooks/useCustomToast";
import { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import { usePagination } from "../../../hooks/usePagination";
import { GET_FORM_SCHEMAS } from "./queries";
import { FaWpforms } from "react-icons/fa";

import {
  AdminGetFormSchemasListQuery,
  AdminGetFormSchemasListQueryVariables,
} from "../../../gql/graphql";
import { useQuery } from "@apollo/client";
import {
  Box,
  Button,
  Card,
  CardBody,
  HStack,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
  VStack,
} from "@chakra-ui/react";
import {
  ArrowBackIcon,
  ArrowForwardIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CloseIcon,
  EditIcon,
  SmallAddIcon,
} from "@chakra-ui/icons";
import { FiSearch } from "react-icons/fi";
import ListTable from "../../../components/FromsElements/ListTable";
import { fields } from "./list";

export const AdminFormSchama = () => {
  const { user_id } = useAuth();
  const toast = useCustomToast();
  const navigate = useNavigate();
  const params = useParams();
  const [keyword, setKeyword] = useState("");
  const [debouncedSearchInputValue] = useDebounce(keyword, 500);

  const [listIsLoading, setListIsLoading] = useState(true);

  const {
    page,
    setPage,
    perPage,
    offset,
    setTotalRecords,
    totalRecords,
    lastPage,
    pages,
  } = usePagination();

  const sanitizedSearch = debouncedSearchInputValue?.trim()?.replace(/^#/, "");
  const { data, loading, refetch } = useQuery<
    AdminGetFormSchemasListQuery,
    AdminGetFormSchemasListQueryVariables
  >(GET_FORM_SCHEMAS, {
    fetchPolicy: "no-cache",
    variables: {
      limit: perPage,
      offset,
      where: {
        _and: [
          { center_id: { _eq: Number(process.env.REACT_APP_CENTER_ID) } },
          { deleted_at: { _is_null: true } },
          {
            _or: [
              { descriptor: { _ilike: "%" + sanitizedSearch + "%" } },
              { title: { _ilike: "%" + sanitizedSearch + "%" } },
            ],
          },
        ],
      },
    },
    skip: !user_id,
  });

  useEffect(() => {
    setTotalRecords(data?.form_schemas_aggregate.aggregate?.count || 0);
    setListIsLoading(false);
  }, [data]);

  useEffect(() => {
    if (params?.k && params.k.length >= 3) {
      setKeyword(params.k);
    }

    if (params?.p) {
      let p = Number(params.p);
      setPage(p);
    }
  }, [params]);

  return (
    <VStack w="full" p="25px">
      {listIsLoading && (
        <HStack
          w="full"
          h="full"
          minH="200px"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner></Spinner>
        </HStack>
      )}
      {!listIsLoading && (
        <>
          <HStack w="full" flexWrap="wrap">
            <Heading flex="1" fontSize="30px">
              Form Schema List
            </Heading>
            <Button
              as={ReactRouterLink}
              to="/admin/form_schemas/add"
              variant="btnMain"
            >
              New Schema
              <SmallAddIcon mx="10px" fontSize="20px"></SmallAddIcon>
            </Button>
          </HStack>

          <VStack w="full">
            <Card
              boxShadow="null"
              w="full"
              borderRadius="16px"
              border="1px solid"
              borderColor="gray.100"
              position="relative"
              mt="20px"
              flex="1"
            >
              <CardBody p="15px">
                <Box mb="30px">
                  <InputGroup h="48px">
                    <InputLeftElement h="full" w="38px" justifyContent="end">
                      <Icon
                        as={FiSearch}
                        fontSize="24px"
                        color="gray.500"
                      ></Icon>
                    </InputLeftElement>
                    <Input
                      key="form_schema_list_keyword"
                      paddingStart="50px"
                      paddingEnd="50px"
                      placeholder="Search ..."
                      borderRadius="12px"
                      bg="white"
                      h="full"
                      value={keyword}
                      onChange={(e) => {
                        setKeyword(e.target.value);
                      }}
                    />
                    <InputRightElement
                      h="full"
                      w="60px"
                      justifyContent="center"
                      cursor="pointer"
                      onClick={() => {
                        setKeyword("");
                      }}
                    >
                      {keyword.length > 0 && <CloseIcon></CloseIcon>}
                    </InputRightElement>
                  </InputGroup>
                </Box>
                <ListTable
                  data={data?.form_schemas || []}
                  fields={fields}
                  page={page}
                  total={totalRecords}
                  loading={loading}
                  hasPagination={true}
                  perPage={perPage}
                  actionField={(id: any) => {
                    return (
                      <HStack>
                        <Button
                          title="Edit"
                          onClick={() => {
                            navigate("/admin/form_schemas/edit/" + id);
                          }}
                        >
                          <EditIcon></EditIcon>
                        </Button>
                        <Button
                            colorScheme='blue'
                            title="Show In Generator"
                            onClick={() => {
                              navigate("/admin/form_generator/" + id);
                            }}
                        >
                          <Icon as={FaWpforms}></Icon>
                        </Button>
                      </HStack>
                    );
                  }}
                ></ListTable>
                {data?.form_schemas && lastPage > 1 && (
                  <HStack
                    mt="30px"
                    w="full"
                    justifyContent="center"
                    spacing="10px"
                  >
                    <Button
                      isDisabled={page === 1}
                      as={ReactRouterLink}
                      onClick={() => {
                        setPage(1);
                      }}
                    >
                      <ArrowBackIcon fontSize="24px"></ArrowBackIcon>
                    </Button>
                    <Button
                      isDisabled={page === 1}
                      as={ReactRouterLink}
                      onClick={() => {
                        setPage(page - 1);
                      }}
                    >
                      <ChevronLeftIcon fontSize="24px"></ChevronLeftIcon>
                    </Button>

                    {pages?.map((i) => (
                      <Button
                        variant={page == i ? "btnMain" : ""}
                        as={ReactRouterLink}
                        onClick={() => {
                          setPage(i);
                        }}
                      >
                        {i}
                      </Button>
                    ))}

                    <Button
                      isDisabled={page === lastPage}
                      as={ReactRouterLink}
                      onClick={() => {
                        setPage(page + 1);
                      }}
                    >
                      <ChevronRightIcon fontSize="24px"></ChevronRightIcon>
                    </Button>
                    <Button
                      isDisabled={page === lastPage}
                      as={ReactRouterLink}
                      onClick={() => {
                        setPage(lastPage);
                      }}
                    >
                      <ArrowForwardIcon fontSize="24px"></ArrowForwardIcon>
                    </Button>
                  </HStack>
                )}
              </CardBody>
            </Card>
          </VStack>
        </>
      )}
    </VStack>
  );
};
