import {Fragment, useEffect, useState} from 'react';
import {FormItemProps} from "../../../interfaces/Interfaces";
import {Box, FormControl, FormLabel, Input, Checkbox as ChakraCheckbox, Stack} from "@chakra-ui/react";
import {extendForms, nextStep, prevStep, setFormData} from "../../../store/slice";
import {FormContainer} from "../FormContainer";
import * as React from "react";
import {useDispatch} from "react-redux";
const ActionCheckBox = (props: FormItemProps) => {
    let item = props.item;
    let inputItemHandler = props.inputItemHandler;
    let state = props.state;
    let validator = props.validator;
    let IsInvalidMessage = (validator && item.validation_rule)? validator.message(item.title, state[item.descriptor], item.validation_rule) : null;

    const handleClick = (activeItem:any, key: string) => {
        if (validator) {
            validator.purgeFields();
        }

        if (inputItemHandler) {
            inputItemHandler({
                "key" : key,
                "id" : item.descriptor,
                "value" : activeItem.value ? activeItem.value : key,
                "item" : item
            }, item);
        }
    }

    return (
        <>
            <FormControl>
                {item.title.length > 0 &&
                    <FormLabel>{item.title}</FormLabel>
                }

                <Stack spacing={5} direction='row'>
                    <ChakraCheckbox colorScheme='blue' size="lg"
                        isInvalid={IsInvalidMessage ? IsInvalidMessage : false}
                        key={item.descriptor} isChecked={state[item.descriptor] && state[item.descriptor] !== 'unchecked' ? state[item.descriptor] : false}
                        onChange={(e) => {
                            if (inputItemHandler) {
                                let key = !state[item.descriptor] || state[item.descriptor] === 'unchecked' ? 'checked' : 'unchecked';
                                handleClick(item.values[key], key);
                            }
                        }}>
                        {item.text && item.text.length > 0 &&
                            <Box fontSize="16px" dangerouslySetInnerHTML={{ __html: item.text }}></Box>
                        }
                    </ChakraCheckbox>
                </Stack>
                {!item?.hide_validation_error && IsInvalidMessage}
            </FormControl>

            { typeof state[item.descriptor] !== typeof undefined &&
                <>
                    { state[item.descriptor] === 'checked' && item.values && item.values.checked &&  item.values.checked.show_type === 'attached' &&
                        <Box mt="20px">
                            <FormContainer form={item.values.checked} validator={validator} inputItemHandler={inputItemHandler} state={state} extendFormHandler={extendForms}></FormContainer>
                        </Box>
                    }

                    { (!state[item.descriptor] || state[item.descriptor] === 'unchecked') && item.values && item.values.unchecked &&  item.values.unchecked.show_type === 'attached' &&
                        <Box mt="20px">
                            <FormContainer form={item.values.unchecked}  validator={validator} inputItemHandler={inputItemHandler} extendFormHandler={extendForms} state={state}></FormContainer>
                        </Box>
                    }
                </>
            }
        </>
    );
}

export default ActionCheckBox;
