import {useEffect, useState} from "react";
import {Button, Card, CardBody, Heading, HStack, Spinner, VStack} from "@chakra-ui/react";
import * as React from "react";
import {Link as ReactRouterLink, useNavigate, useParams} from "react-router-dom";
import {EditIcon, SmallAddIcon} from "@chakra-ui/icons";
import {useLazyQuery} from "@apollo/client";
import {GET_TREATMENT_BY_ID} from "../queries";
import ListTable from "../../../../components/FromsElements/ListTable";
import {TableFieldInterface} from "../../../../interfaces/Interfaces";
import { GetTreatmentByIdQuery, GetTreatmentByIdQueryVariables } from "../../../../gql/graphql";

const Prescriptions = () => {
    const fields: Array<TableFieldInterface> = [
        {
            title: "#",
            descriptor: "",
            expression: "INDEX_NUMBER_FIELD"
        },
        {
            title: "Title",
            descriptor: "title"
        },
        {
            title: "Action",
            descriptor: "",
            expression: "ACTION_FIELD"
        }
    ];
    const navigate = useNavigate();
    const params = useParams();
    const [listIsLoading, setListIsLoading] = useState(true);
    const [prescriptions, setPrescriptions] = useState<Array<any>>([]);
    const [treatment, setTreatment] = useState<any>({});
    const [getTreatmentById, getTreatmentByIdResult] = useLazyQuery<GetTreatmentByIdQuery, GetTreatmentByIdQueryVariables>(GET_TREATMENT_BY_ID, {
        fetchPolicy: "no-cache",
        variables: {
            id: Number(params.tid),
        }
    });

    useEffect(() => {
        if (params.tid) {
            getTreatmentById({
                variables: {
                    id: Number(params.tid),
                }
            });
        }
    }, [params])


    useEffect(() => {
        if (getTreatmentByIdResult?.data?.treatments_by_pk) {
            setTreatment(getTreatmentByIdResult?.data?.treatments_by_pk);
            setPrescriptions(getTreatmentByIdResult?.data?.treatments_by_pk?.prescriptions);
        }

        setListIsLoading(false);
    }, [getTreatmentByIdResult]);

    return (
        <VStack w="full" p="25px">
            { listIsLoading &&
                <HStack w="full" h="full" minH="200px" alignItems="center" justifyContent="center">
                    <Spinner></Spinner>
                </HStack>
            }

            { !listIsLoading &&
                <>
                    <HStack w="full" flexWrap="wrap">
                        <Heading flex="1" fontSize="30px">{treatment?.title} : Prescriptions List</Heading>
                        <HStack>
                            <Button as={ReactRouterLink} to={`/admin/treatments/edit/${params.tid}`} variant="btnNormal">
                                Back To Treatment
                            </Button>
                            <Button as={ReactRouterLink} to={`/admin/treatments/${params.tid}/prescriptions/add`} variant="btnMain">
                                New Prescription
                                <SmallAddIcon mx="10px" fontSize="20px"></SmallAddIcon>
                            </Button>

                        </HStack>
                    </HStack>

                    <VStack w="full">
                        <Card boxShadow="null" w="full" borderRadius="16px" border="1px solid" borderColor="gray.100" position="relative"
                              mt="20px" flex="1"
                        >
                            <CardBody p="15px">
                                <ListTable data={prescriptions} fields={fields}
                                   loading={getTreatmentByIdResult.loading}
                                   hasPagination={false}
                                   actionField = {(id:any) => {
                                       return (
                                           <HStack>
                                               <Button title="Edit"
                                                   onClick={() => {
                                                       navigate("/admin/treatments/" + params.tid + '/prescriptions/edit/' + id);
                                                   }}
                                               >
                                                   <EditIcon></EditIcon>
                                               </Button>
                                           </HStack>
                                       );
                                   }}
                                ></ListTable>
                            </CardBody>
                        </Card>
                    </VStack>
                </>
            }
            
        </VStack>
    )
}

export default Prescriptions;