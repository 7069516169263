import {Box} from "@chakra-ui/react";

export const Home = () => {


    return (
        <Box p="30px">
            Welcome To Admin Panel
        </Box>
    );
}