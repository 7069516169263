import { Box, Flex, HStack, VStack, Circle, Icon, Button } from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/react";
import Sidebar from "../Sidebar";
import { HamburgerIcon } from "@chakra-ui/icons";
import { useDispatch } from "react-redux";
import { toggleSidebar } from "../Sidebar/store/slice";
import { FiLogOut } from "react-icons/fi";
import { Link as ReactRouterLink } from "react-router-dom";

export const MainTemplate = (props: any) => {
  const is_loading = props.is_loading ?? false;
  const dispatch = useDispatch();
  return (
    <Box h="100vh" w="full">
      {is_loading && (
        <Flex
          position="absolute"
          top="0"
          left="0"
          bottom="0"
          right="0"
          w="full"
          h="full"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner />
        </Flex>
      )}

      {!is_loading && (
        <HStack w="full" h="full" bg="#f8f8f8" spacing="0" gap="0">
          <Sidebar></Sidebar>

          <VStack h="full" flex="1" gap="0" spacing="0" position="relative">
            <HStack
              h="64px"
              w="full"
              bg="white"
              borderBottom="1px solid"
              borderColor="#E6E6E6"
              px="15px"
            >
              <Box flex="1">
                <Box
                  display={{
                    base: "flex",
                    lg: "none",
                  }}
                  onClick={() => {
                    dispatch(toggleSidebar());
                  }}
                  cursor="pointer"
                >
                  <HamburgerIcon fontSize="20px" m="10px"></HamburgerIcon>
                </Box>
              </Box>

              <HStack alignItems="center" spacing="20px">
                  <Button
                    variant={"ghost"}
                    borderRadius="100px"
                    fontWeight="500"
                    fontSize="15px"
                    py="10px"
                    px="20px"
                    as={ReactRouterLink}
                    to="/dashboard"
                  >
                    Back to Dashboard
                  </Button>
                {" "}
                <Circle
                  border="2px solid"
                  borderColor="gray.200"
                  size="44px"
                  bg="white"
                  cursor="pointer"
                  onClick={() => {
                    localStorage.clear();
                    window.location.href = "/dashboard/login";
                  }}
                >
                  <Icon fontSize="24px" color="gray.500" as={FiLogOut}></Icon>
                </Circle>
              </HStack>
            </HStack>
            <Box
              w="full"
              position="absolute"
              top="64px"
              left="0"
              right="0"
              bottom="0"
              overflowY="auto"
            >
              {props.children}
            </Box>
          </VStack>
        </HStack>
      )}
    </Box>
  );
};
