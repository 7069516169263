import axios from "axios";

const axiosInstance = () => {
    const instance = axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL + '/api/v1/',
        headers: {
            "Content-Type": "application/json"
        },
        timeout: 100000
    });

    return instance;
}

export default axiosInstance;
