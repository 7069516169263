import { Box, ModalBody, ModalFooter } from "@chakra-ui/react";
import ModalBox from "../../../../components/ModalBox";
import { useEffect } from "react";

import { useDispatch } from "react-redux";
import { init } from "./../../../../store/slice";
import { SectionObject } from "../../../../interfaces/Interfaces";
import { useQuery } from "@apollo/client";
import {
  GetConsultationFormStructureQuery,
  GetConsultationFormStructureQueryVariables,
} from "../../../../gql/graphql";
import { GET_CONSULTATION_FORM_STRUCTURE } from "../../../Consultation/queries";
import { FormWizard } from "../../../../components/FormWizard";
type PropTypes = {
  isOpen: boolean;
  onClose: () => void;
  section: string;
  data: any;
  onSave: () => Promise<void>;
};

export const EditSectionModal = (props: PropTypes) => {
  const { data, isOpen, section, onClose, onSave } = props;

  const { data: formStructureResult } = useQuery<
    GetConsultationFormStructureQuery,
    GetConsultationFormStructureQueryVariables
  >(GET_CONSULTATION_FORM_STRUCTURE, {
    fetchPolicy: "no-cache",
    context: {
      assumeRole: "public",
    },
    variables: {
      center_id: Number(process.env.REACT_APP_CENTER_ID),
    },
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      formStructureResult?.form_schemas &&
      formStructureResult?.form_schemas?.length > 0
    ) {
      let formTemplate = formStructureResult.form_schemas[0]?.template;
      let tempSection: SectionObject[] = [];

      formTemplate.sections
        .filter((x: SectionObject) => x.descriptor === section)
        .map((item: SectionObject) => {
          tempSection.push({
            title: item.title,
            schema: item.schema,
            descriptor: item.descriptor,
            selected: true,
            isCurrent: true,
            forms: item.forms,
          });
        });

      if (tempSection.length > 0) {
        dispatch(
          init({
            sections: tempSection,
            currentForm: tempSection[0].forms[0],
            currentStep: 1,
            currentSection: 1,
            forms: [],
            formsData: data,
            patient_data: {
              completed: false,
            },
            form_finished: false,
          })
        );
      }
    }
  }, [formStructureResult, section, data]);

  return (
    <ModalBox isOpen={isOpen} onClose={onClose} size="xl" iconsize="24px">
      <ModalBody pb={6}>
        <Box w="full">
          <FormWizard title="Edit Form" onSubmit={onSave} alwaysDisplaySubmitButton={true} />
        </Box>
      </ModalBody>
      <ModalFooter></ModalFooter>
    </ModalBox>
  );
};
