import {
  Button,
  Card,
  CardBody,
  HStack,
  Heading,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams, Link as ReactRouterLink } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import useCustomToast from "../../../hooks/useCustomToast";
import { useMutation, useQuery } from "@apollo/client";
import {
  AdminAddFormSchemaMutation,
  AdminAddFormSchemaMutationVariables,
  AdminGetFormSchemaByIdQuery,
  AdminGetFormSchemaByIdQueryVariables,
  AdminUpdateFormSchemaMutation,
  AdminUpdateFormSchemaMutationVariables,
  Form_Schemas_Set_Input,
} from "../../../gql/graphql";
import { GET_FORM_SCHEMA_BY_ID } from "./queries";
import { ADD_FORM_SCHEMA, UPDATE_FORM_SCHEMA } from "./mutations";
import TextBox from "../../../components/FromsElements/TextBox";
import { FormItem } from "../../../interfaces/Interfaces";
import TextAreaBox from "../../../components/FromsElements/TextAreaBox";

type FormFieldsType = {
  id: number | null;
  version: string;
  template: string;
  title: string;
  descriptor: string;
  center_id: number;
  tries: number;
};

export const AdminFormSchemaForm = () => {
  const params = useParams();

  const [validator, setValidator] = useState<any>(
    new SimpleReactValidator({
      element: (message: string) => {
        return (
          <Text fontSize="sm" color="red.500">
            {message}
          </Text>
        );
      },
    })
  );

  const [isSaving, setIsSaving] = useState(false);
  const toast = useCustomToast();

  const defaultState: FormFieldsType = {
    id: null,
    version: "",
    title: "",
    descriptor: "",
    template: "",
    center_id: Number(process.env.REACT_APP_CENTER_ID),
    tries: 0,
  };

  const [stateData, setStateData] = useState<FormFieldsType>(defaultState);

  const { data, loading, refetch } = useQuery<
    AdminGetFormSchemaByIdQuery,
    AdminGetFormSchemaByIdQueryVariables
  >(GET_FORM_SCHEMA_BY_ID, {
    fetchPolicy: "no-cache",
    variables: {
      id: Number(params.id),
    },
    skip: !params?.id,
  });

  const [addFormSchema] = useMutation<
    AdminAddFormSchemaMutation,
    AdminAddFormSchemaMutationVariables
  >(ADD_FORM_SCHEMA);
  const [updateFormSchema] = useMutation<
    AdminUpdateFormSchemaMutation,
    AdminUpdateFormSchemaMutationVariables
  >(UPDATE_FORM_SCHEMA);

  const saveRecord = async () => {
    setStateData({ ...stateData, tries: stateData.tries + 1 });

    let templateString = stateData.template;
    if (!templateString?.trim() || templateString?.trim() === "{}") {
      templateString = '{ "sections": [] }';
    } else {
      templateString = stateData.template;
    }

    let parsedTemplate;
    try {
      parsedTemplate = JSON.parse(templateString);
    } catch {}

    if (validator.allValid() && parsedTemplate) {
      let record: Form_Schemas_Set_Input = {
        center_id: stateData.center_id,
        version: stateData.version,
        descriptor: stateData.descriptor,
        title: stateData.title,
        template: parsedTemplate,
        updated_at: new Date(),
      };

      if (!stateData.template?.trim() || stateData.template?.trim() === "{}") {
        stateData.template = '{ "sections": [] }';
        record.template = JSON.parse(stateData.template);
      }

      if (stateData.id) {
        record.id = stateData.id;

        let result = await updateFormSchema({
          variables: {
            id: record.id,
            object: record,
          },
        });

        if (result?.data?.update_form_schemas_by_pk?.id) {
          toast.success("Record Saved Successfully.");
        } else {
          toast.error("There is an error on saving record data.");
        }
      } else {
        let date = new Date();

        let result = await addFormSchema({
          variables: {
            object: record,
          },
        });

        if (result?.data?.insert_form_schemas_one?.id) {
          setStateData({
            ...stateData,
            id: result.data.insert_form_schemas_one.id,
          });
          toast.success("Record Saved Successfully.");
        } else {
          toast.error("There is an error on saving record data.");
        }
      }
    } else {
      validator.showMessages();

      if(!parsedTemplate){
        toast.error("Invalid template Json format.")
      }
    }
  };

  useEffect(() => {
    if (data?.form_schemas_by_pk) {
      let record = data.form_schemas_by_pk;

      let temp: FormFieldsType = {
        id: record.id,
        center_id: record.center_id,
        descriptor: record.descriptor,
        template: JSON.stringify(record.template, null, 4),
        title: record.title || "",
        version: record.version,
        tries: 0,
      };

      setStateData(temp);
    }
  }, [data]);

  return (
    <VStack w="full" p="25px">
      <HStack w="full" flexWrap="wrap">
        <Heading flex="1" fontSize="30px">
          {stateData.id && <>Edit Form Schema</>}

          {!stateData.id && <>Add New Form Schema</>}
        </Heading>
        <HStack>
          <Button
            as={ReactRouterLink}
            to="/admin/form_schemas"
            variant="btnNormal"
          >
            Back To List
          </Button>
          {stateData.id && (
            <Button
              as={ReactRouterLink}
              to={"/admin/form_generator/" + stateData.id}
              variant="btnNormal"
              colorScheme="blue"
            >
              Show in generator
            </Button>
          )}
          <Button
            variant="btnMain"
            minW={120}
            isDisabled={isSaving}
            onClick={async () => {
              setIsSaving(true);
              await saveRecord();
              setIsSaving(false);
            }}
          >
            {isSaving ? <Spinner px="5px"></Spinner> : "Save"}
          </Button>
        </HStack>
      </HStack>

      <VStack w="full">
        {loading && (
          <HStack w="full" h="full" justifyContent="center" py="35px">
            <Spinner></Spinner>
          </HStack>
        )}

        {!loading && (
          <Card
            boxShadow="null"
            w="full"
            borderRadius="16px"
            border="1px solid"
            borderColor="gray.100"
            position="relative"
            mt="20px"
            flex="1"
          >
            <CardBody p="15px">
              <VStack w="full" spacing={5}>
                <TextBox
                  w="full"
                  state={stateData}
                  item={{
                    title: "Title",
                    descriptor: "title",
                    values: [],
                    validation_rule: "required",
                  }}
                  validator={validator}
                  inputItemHandler={(value: string, item: FormItem) => {
                    setStateData({ ...stateData, title: value });
                  }}
                ></TextBox>

                <TextBox
                  w="full"
                  state={stateData}
                  item={{
                    title: "Form ID",
                    descriptor: "descriptor",
                    values: [],
                    validation_rule: "required",
                  }}
                  validator={validator}
                  inputItemHandler={(value: string, item: FormItem) => {
                    setStateData({ ...stateData, descriptor: value });
                  }}
                ></TextBox>

                <TextBox
                  w="full"
                  state={stateData}
                  item={{
                    title: "Version",
                    descriptor: "version",
                    values: [],
                    validation_rule: "required",
                  }}
                  validator={validator}
                  inputItemHandler={(value: string, item: FormItem) => {
                    setStateData({ ...stateData, version: value });
                  }}
                ></TextBox>

                <TextAreaBox
                  state={stateData}
                  item={{
                    title: "Template",
                    descriptor: "template",
                    values: [],
                  }}
                  inputItemHandler={(value: string, item: FormItem) => {
                    setStateData({ ...stateData, template: value });
                  }}
                ></TextAreaBox>
              </VStack>
            </CardBody>
          </Card>
        )}
      </VStack>
    </VStack>
  );
};
