import React, {useEffect, useState} from 'react';
import {
    Box,
    Flex,
    Text,
    Circle,
    Stepper,
    Step,
    StepIndicator,
    StepStatus,
    Progress,
    StepTitle,
    Link,
    InputGroup,
    InputRightElement,
    Input,
    Icon, FormLabel, Grid, GridItem, Container, Button, VStack, Heading
} from "@chakra-ui/react";
import Autocomplete from "react-google-autocomplete";

import {FormItemProps} from "../../../interfaces/Interfaces"
import {Link as ReactRouterLink} from "react-router-dom";
import {ArrowBackIcon} from "@chakra-ui/icons";
import {FormContainer} from "../FormContainer";
import {nextStep, prevStep, setFormData} from "../../../store/slice";
import FormElementsContainer from "../FormElementsContainer";
import {useDispatch} from "react-redux";
import ButtonBox from "../ButtonBox";


interface CustomAddressProps extends FormItemProps {
    backToFormAction?: any
}
const CustomAddress = (props: CustomAddressProps) => {
    const dispatch = useDispatch()
    let item = props.item;
    let inputItemHandler = props.inputItemHandler;
    let state = props.state;
    let validator = props.validator;
    let backToFormAction = props.backToFormAction;

    const backToForm = () => {
        if (backToFormAction) {
            backToFormAction();
            document.querySelectorAll("body")[0].style.overflow = 'auto';
        }
    }
    const getFinalAddress = () => {
        let finalAddress:Array<string> = [];
        if (item.sub_items) {
            item.sub_items.map((input, index) => {
                if (state[input.descriptor].length > 0) {
                    finalAddress.push(state[input.descriptor]);
                }
            })
        }


        return finalAddress.join(', ');
    }

    useEffect(() => {
        document.querySelectorAll("body")[0].style.overflow = 'hidden';
    }, []);

    return (
        <Box position="fixed" left="0" right="0" bottom="0" top="0" bgColor="white" zIndex="10000" overflow="auto" w="full">
            <Grid
                templateAreas={`"header" "main"`}
                gridTemplateRows={'80px 0.99fr'}
                gridTemplateColumns={'1fr'}
                h='100vh'
                gap='1'
            >
                <GridItem area={'header'}>
                    <Container w='100vw' maxW="container.xl" h="100%">
                        <Flex h="100%">
                            <Button colorScheme={"blue"} variant={"link"} onClick={() => {
                                backToForm()
                            }} display="flex">
                                <ArrowBackIcon fontSize="xl"></ArrowBackIcon>
                                <Text fontSize="md" mx="20px">Back To Form</Text>
                            </Button>
                        </Flex>
                    </Container>
                </GridItem>
                <GridItem p='2' area={'main'}>
                    <Container w='full' maxW="600px" h="100%" justifyContent="center">
                        <VStack spacing={4} align='stretch' w="full">
                            <Heading fontSize="2xl" textAlign="center">Add New Address</Heading>
                            <Box h="30px"></Box>
                            <FormElementsContainer items={item.sub_items} validator={validator} inputItemHandler={inputItemHandler} state={state}></FormElementsContainer>
                            <Box>
                                <Link onClick={() => {
                                    backToForm()
                                }}>Search for address</Link>
                            </Box>
                            <Box>
                                <ButtonBox item={
                                    {
                                        "title" : "Save Address",
                                        "descriptor" : "go_back_to_form",
                                        "text" : "",
                                        "type" : "button",
                                        "action" : {
                                            "onClick" : () => {
                                                if (inputItemHandler) {
                                                    inputItemHandler(getFinalAddress(), item);
                                                }
                                                backToForm()
                                            }
                                        },
                                        "required" : false,
                                        "placeholder" : "",
                                        "default_value" : "",
                                        "component" : "",
                                        "options" : {
                                            "type" : "submit",
                                            "rightIcon" : "ArrowForwardIcon",
                                            "props": {
                                                "w": "full"
                                            }
                                        },
                                        "values" : {},
                                        "sub_items" : []
                                    }
                                }></ButtonBox>
                            </Box>
                        </VStack>
                    </Container>
                </GridItem>
            </Grid>
        </Box>
    );
}

export default CustomAddress;