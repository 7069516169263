import {
  Circle,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Stack,
  ModalProps,
  Icon,
} from "@chakra-ui/react";

interface ModalBoxState {
  mobile: string;
  verified: boolean;
  inProgress: boolean;
}

interface ModalBoxProps extends ModalProps {
  data?: ModalBoxState;
  onContinue?: any;
  icon?: any;
  iconsize?: any;
}

const ModalBox = (props: ModalBoxProps) => {
  const { isOpen, onClose, onContinue } = props;
  const icon = props.icon ?? null;
  const iconSize = props.iconsize ?? null;

  return (
    <Modal {...props} isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {icon && (
            <Stack
              w="full"
              h="60px"
              mt="25px"
              justifyContent="center"
              alignItems="center"
            >
              <Circle size="56px" bg="#F0F6FF" color="#0065FF">
                <Icon as={icon} fontSize={iconSize ? iconSize : "xl"}></Icon>
              </Circle>
            </Stack>
          )}
        </ModalHeader>
        <ModalCloseButton />
        {props.children}
      </ModalContent>
    </Modal>
  );
};

export default ModalBox;
