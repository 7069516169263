import React, {useEffect} from 'react';
import {FormItemProps} from "../../../interfaces/Interfaces";
import {Text, FormControl, FormLabel, Input, Flex, Button, VStack} from "@chakra-ui/react";
import {useDispatch} from "react-redux";
import {nextStep} from "../../../store/slice";
const YesNoButton = (props: FormItemProps) => {
    const dispatch = useDispatch()
    let item = props.item;
    let inputItemHandler = props.inputItemHandler;
    let state = props.state;
    let validator = props.validator;
    let IsInvalidMessage = (validator && item.validation_rule)? validator.message(item.title, state[item.descriptor], item.validation_rule) : null;

    const handleClick = (activeItem:any, key: string) => {
        if (inputItemHandler) {
            inputItemHandler({
                "key" : key,
                "id" : item.descriptor,
                "value" : activeItem.value,
                "item" : item
            }, item);
        }

        if (activeItem.action === 'nextStep') {
            dispatch(nextStep())
        }
    }

    return (
        <FormControl>
            {item.title.length > 0 &&
                <FormLabel flexDirection="column">
                    {item.required &&
                        <Text as="span" color="red.500" fontSize="14px" me="5px">*</Text>
                    }
                    <Text as="span">{item.title}</Text>
                </FormLabel>
            }

            <VStack spacing="15px" mt={5}>
                {item.values &&
                    Object.keys(item.values).map((key:string) => {
                        return (
                            <Button w="full" key={key} variant={item?.values[key]?.value === state[item.descriptor] ? 'btnMain' : 'btnNormal'}
                                onClick={() => {
                                    if (item.values) {
                                        handleClick(item.values[key], key);
                                    }
                                }}
                            >{item.values ? item.values[key].title : ''}</Button>
                        )
                    })
                }
            </VStack>
            {!item?.hide_validation_error && IsInvalidMessage}
        </FormControl>
    );
}

export default YesNoButton;
