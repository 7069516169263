import {Box, Button, Checkbox, FormControl, FormLabel, HStack, Input, Text, VStack} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import Item from "./Item";
import RenderForm from "../RenderForm";
import FormModal from "../../modals/FormModal";

const YesNoButtonField = (props:any) => {
    const {
        item,
        preDelete,
        preEdit,
        sections,
        currentSection,
        currentSectionIndex,
        indexH,
        updateData,
    } = props;

    const [noExpansion, setNoExpansion] = useState<any>(null);
    const [yesExpansion, setYesExpansion] = useState<any>(null);
    const [formModalIsOpen, setFormModalIsOpen] = useState<boolean>(false);
    const [cIndexH, setCIndexH] = useState<any>(null);
    const closeFormModal = () => {
        setFormModalIsOpen(false);
    }

    useEffect(() => {
        if (item?.values?.no?.forms?.length > 0) {
            setNoExpansion(item.values.no.forms);
        } else {
            setNoExpansion(null);
        }

        if (item?.values?.yes?.forms?.length > 0) {
            setYesExpansion(item.values.yes.forms);
        } else {
            setYesExpansion(null)
        }

    }, [item]);

    return (
        <FormControl w='full'>
            <FormLabel>
                {item.required && (
                <Text as="span" color="red.500" fontSize="14px" me="5px">
                    *
                </Text>
                )}
                <Box fontSize="16px" dangerouslySetInnerHTML={{__html: item.title}}></Box>
            </FormLabel>

            <HStack w='full'>
                <VStack w='full'>
                    <FormLabel>{item?.values?.yes?.title}:</FormLabel>
                    <Text>Action: {item?.values?.yes?.action}</Text>
                    {item?.values?.yes?.show_type &&
                        <Text>Show Type: {item?.values?.yes?.show_type}</Text>
                    }
                    <VStack w='full'>
                        {yesExpansion && yesExpansion.map((yesForm:any, yesKey:number) => {
                            return (
                                <RenderForm
                                    indexH={indexH + 'values|yes|forms|' + yesKey + '|'}
                                    formObject={yesForm}
                                    currentFormIndex={yesKey}
                                    showEdit={true}
                                    sections={sections}
                                    currentSection={currentSection}
                                    currentSectionIndex={currentSectionIndex}
                                    updateForm={(oldData:any, newData:any, indexH:string) => {
                                        updateData('form', oldData ? 'update' : 'create', newData, indexH);
                                    }}
                                    updateData={updateData}
                                    preDelete={preDelete}
                                ></RenderForm>
                            )
                        })}
                        {item?.values?.yes?.show_type === 'next_form' &&
                            <Box>
                                <Button
                                    onClick={() => {
                                        setCIndexH(indexH + 'values|yes|forms|')
                                        setFormModalIsOpen(true);
                                    }}
                                >
                                    Add New Form
                                </Button>
                            </Box>
                        }

                    </VStack>
                </VStack>
                <VStack w='full'>
                    <FormLabel>{item?.values?.no?.title}:</FormLabel>
                    <Text>Action: {item?.values?.no?.action}</Text>
                    {item?.values?.no?.show_type &&
                        <Text>Show Type: {item?.values?.no?.show_type}</Text>
                    }
                    <VStack w='full'>
                        {noExpansion && noExpansion.map((noForm:any, noKey:number) => {
                            return (
                                <RenderForm
                                    indexH={indexH + 'values|no|forms|' + noKey + '|'}
                                    formObject={noForm}
                                    currentFormIndex={noKey}
                                    showEdit={true}
                                    sections={sections}
                                    currentSection={currentSection}
                                    currentSectionIndex={currentSectionIndex}
                                    updateForm={(oldData:any, newData:any, indexH:string) => {
                                        updateData('form', oldData ? 'update' : 'create', newData, indexH);
                                    }}
                                    updateData={updateData}
                                    preDelete={preDelete}
                                ></RenderForm>
                            )
                        })}
                        <Box>
                            {item?.values?.no?.show_type === 'next_form' &&
                                <Box>
                                    <Button
                                        onClick={() => {
                                            setCIndexH(indexH + 'values|no|forms|')
                                            setFormModalIsOpen(true);
                                        }}
                                    >
                                        Add New Form
                                    </Button>
                                </Box>
                            }
                        </Box>
                    </VStack>
                </VStack>
            </HStack>
            { formModalIsOpen &&
                <FormModal
                    onClose={closeFormModal}
                    sections={sections}
                    currentSection={currentSection}
                    currentSectionIndex={currentSectionIndex}
                    data={ null }
                    indexH={cIndexH}
                    onSubmit={(oldData:any, newData:any, indexH:string) => {
                        if (updateData) {
                            updateData('form', oldData, newData, indexH);
                            closeFormModal();
                        }
                    }}
                ></FormModal>
            }
        </FormControl>
    );
}

export default YesNoButtonField;