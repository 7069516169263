import {
  Box,
  Button,
  Card,
  CardBody,
  Container,
  Heading,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import {
  ArrowBackIcon,
  ArrowForwardIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CloseIcon,
  SmallAddIcon,
  ViewIcon,
} from "@chakra-ui/icons";
import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import {
  Link as ReactRouterLink,
  useNavigate,
  useParams,
} from "react-router-dom";
import ListTable from "../../../components/FromsElements/ListTable";
import { FiSearch } from "react-icons/fi";
import useCustomToast from "../../../hooks/useCustomToast";
import { useDebounce } from "use-debounce";
import useAuth from "../../../hooks/useAuth";
import {
  GetPatientsListQuery,
  GetPatientsListQueryVariables,
} from "../../../gql/graphql";
import { GET_PATIENTS } from "../../Admin/Patients/queries";
import { fields } from "./fields";

const Patients = () => {
  const { user_id } = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const [keyword, setKeyword] = useState("");
  const [debouncedSearchInputValue] = useDebounce(keyword, 500);
  const [page, setPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [lastPage, setLastPage] = useState(1);
  const [pages, setPages] = useState<Array<number>>([]);
  const [records, setRecords] = useState<Array<any>>([]);
  const perPage = 10;
  const [listIsLoading, setListIsLoading] = useState(true);

  const sanitizedSearch = debouncedSearchInputValue?.trim().replace(/^#/, "");
  const { data, loading, refetch } = useQuery<
    GetPatientsListQuery,
    GetPatientsListQueryVariables
  >(GET_PATIENTS, {
    fetchPolicy: "no-cache",
    variables: {
      limit: perPage,
      offset: (page - 1) * perPage,
      where: {
        _and: [
          {
            center_id: {
              _eq: Number(process.env.REACT_APP_CENTER_ID),
            },
          },
          {
            _or: [
              { mobile: { _ilike: "%" + sanitizedSearch + "%" } },
              { email: { _ilike: "%" + sanitizedSearch + "%" } },
              { first_name: { _ilike: "%" + sanitizedSearch + "%" } },
              { last_name: { _ilike: "%" + sanitizedSearch + "%" } },
            ],
          },
        ],
      },
    },
    skip: !user_id,
  });

  const makePaginationUrl = (page: number) => {
    if (page < 1) {
      page = 1;
    } else if (page > lastPage) {
      page = lastPage;
    }
    let path = "/dashboard/patients/list/" + page;

    if (keyword) {
      path += "/" + keyword;
    }

    return path;
  };
  const getPagination = () => {
    let step = 2;
    let startFrom = 1;
    let endTo = lastPage;
    let hasLeft = page - step > 1;
    let hasRight = page + step < lastPage;

    if (hasRight) {
      endTo = page + step;
    }

    if (hasLeft) {
      startFrom = page - step;
    }

    let temp = [];
    for (let i = startFrom; i <= endTo; i++) {
      temp.push(i);
    }
    setPages(temp);
  };

  useEffect(() => {
    if (data?.patients_aggregate?.aggregate?.count) {
      setTotalRecord(data?.patients_aggregate?.aggregate?.count);
    } else {
      setTotalRecord(0);
    }
  }, [data]);

  useEffect(() => {
    if (totalRecord) {
      let temp = totalRecord / perPage;
      let floored = Math.floor(totalRecord / perPage);

      if (temp == floored) {
        setLastPage(temp);
      } else {
        setLastPage(floored + 1);
      }
    } else {
      setLastPage(1);
    }
  }, [totalRecord]);

  useEffect(() => {
    getPagination();
  }, [lastPage, page]);

  useEffect(() => {
    if (params?.k && params.k.length >= 3) {
      setKeyword(params.k);
    }

    if (params?.p) {
      let p = parseInt(params.p);

      if (p >= 1 && p <= 100) {
        setPage(p);
      }
    }
  }, [params]);

  useEffect(() => {
    if (data?.patients?.length) {
      setRecords(data.patients);
    } else {
      setRecords([]);
    }

    setListIsLoading(false);
  }, [data]);

  return (
    <>
      <Container w="full" maxW="container.xl" mt="35px">
        <HStack flexWrap="wrap">
          <HStack flexWrap="wrap" flex={1}>
            <Button
              as={ReactRouterLink}
              to={`/dashboard/`}
              display="flex"
              bg="transparent"
              borderRadius="100px"
              h="48px"
              w="120px"
              border="1px solid"
              borderColor="gray.300"
            >
              <ChevronLeftIcon fontSize="xl"></ChevronLeftIcon>
              <Text fontSize="md" mx="10px">
                Back
              </Text>
            </Button>
            <Heading fontSize="30px" mx="15px">
              Patients List
            </Heading>
          </HStack>
          <Button
            as={ReactRouterLink}
            to="/dashboard/patients/add"
            variant="btnMain"
          >
            Add Patient
            <SmallAddIcon mx="10px" fontSize="20px"></SmallAddIcon>
          </Button>
        </HStack>

        <VStack w="full">
          {listIsLoading && (
            <HStack
              w="full"
              h="full"
              minH="200px"
              alignItems="center"
              justifyContent="center"
            >
              <Spinner></Spinner>
            </HStack>
          )}
          {!listIsLoading && (
            <>
              <VStack w="full">
                <Card
                  boxShadow="null"
                  w="full"
                  borderRadius="16px"
                  border="1px solid"
                  borderColor="gray.100"
                  position="relative"
                  mt="20px"
                  flex="1"
                >
                  <CardBody p="15px">
                    <Box mb="30px">
                      <InputGroup h="48px">
                        <InputLeftElement
                          h="full"
                          w="38px"
                          justifyContent="end"
                        >
                          <Icon
                            as={FiSearch}
                            fontSize="24px"
                            color="gray.500"
                          ></Icon>
                        </InputLeftElement>
                        <Input
                          key="patient_list_keyword"
                          paddingStart="50px"
                          paddingEnd="50px"
                          placeholder="Search ..."
                          borderRadius="12px"
                          bg="white"
                          h="full"
                          value={keyword}
                          onChange={(e) => {
                            setKeyword(e.target.value);
                          }}
                        />
                        <InputRightElement
                          h="full"
                          w="60px"
                          justifyContent="center"
                          cursor="pointer"
                          onClick={() => {
                            setKeyword("");
                          }}
                        >
                          {keyword.length > 0 && <CloseIcon></CloseIcon>}
                        </InputRightElement>
                      </InputGroup>
                    </Box>
                    <ListTable
                      data={records}
                      fields={fields}
                      page={page}
                      total={totalRecord}
                      loading={loading}
                      hasPagination={true}
                      perPage={perPage}
                      actionField={(id: any) => {
                        return (
                          <HStack>
                            <Button
                              onClick={() => {
                                navigate("/dashboard/patient/" + id, {
                                  state: { back_url: makePaginationUrl(page) },
                                });
                              }}
                            >
                              <ViewIcon></ViewIcon>
                            </Button>
                          </HStack>
                        );
                      }}
                    ></ListTable>
                    {records && lastPage > 1 && (
                      <HStack
                        mt="30px"
                        w="full"
                        justifyContent="center"
                        spacing="10px"
                      >
                        <Button
                          isDisabled={page === 1}
                          as={ReactRouterLink}
                          to={makePaginationUrl(1)}
                        >
                          <ArrowBackIcon fontSize="24px"></ArrowBackIcon>
                        </Button>
                        <Button
                          isDisabled={page === 1}
                          as={ReactRouterLink}
                          to={makePaginationUrl(page - 1)}
                        >
                          <ChevronLeftIcon fontSize="24px"></ChevronLeftIcon>
                        </Button>

                        {pages.map((i) => (
                          <Button
                            variant={page == i ? "btnMain" : ""}
                            as={ReactRouterLink}
                            to={makePaginationUrl(i)}
                          >
                            {i}
                          </Button>
                        ))}

                        <Button
                          isDisabled={page === lastPage}
                          as={ReactRouterLink}
                          to={makePaginationUrl(page + 1)}
                        >
                          <ChevronRightIcon fontSize="24px"></ChevronRightIcon>
                        </Button>
                        <Button
                          isDisabled={page === lastPage}
                          as={ReactRouterLink}
                          to={makePaginationUrl(lastPage)}
                        >
                          <ArrowForwardIcon fontSize="24px"></ArrowForwardIcon>
                        </Button>
                      </HStack>
                    )}
                  </CardBody>
                </Card>
              </VStack>
            </>
          )}
        </VStack>
      </Container>
    </>
  );
};

export default Patients;
