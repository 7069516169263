import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface sidebarMenuState {
    currentMenu: string,
    sidebarIsOpen: boolean
}

export interface initAction {

}

export const steps = ['input_mobile', 'input_otp', 'final'];

const initialState: sidebarMenuState = {
    currentMenu: 'DASHBOARD',
    sidebarIsOpen: false
}

export const MobileAuthenticatorSlice = createSlice({
    name: 'sidebarMenu',
    initialState,
    reducers: {
        setSelectedMenu: (state, action:  PayloadAction<string>) => {
            state.currentMenu = action.payload;
        },
        toggleSidebar: (state) => {
            state.sidebarIsOpen = !state.sidebarIsOpen;
        }
    },
})

export const { setSelectedMenu, toggleSidebar } = MobileAuthenticatorSlice.actions

export default MobileAuthenticatorSlice.reducer