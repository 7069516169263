import {
  Box,
  Card,
  CardBody,
  Divider,
  Heading,
  ModalBody,
  VStack,
} from "@chakra-ui/react";
import ModalBox from "../../../../../components/ModalBox";
import ParamsForm from "../components/ParamsForm";
import {
  GetPatientFormDataQuery,
  GetPatientFormDataQueryVariables,
  GetVisitDataQuery,
} from "../../../../../gql/graphql";
import { GET_PATIENT_FORM_DATA } from "../../queries";
import { useQuery } from "@apollo/client";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";

type EditRecordingModalProps = {
  isOpen: boolean;
  onClose: () => void;
  data?: EditModalDataType;
  visit: GetVisitDataQuery["visits_by_pk"];
};

export type EditModalDataType = {
  id: number;
};

export const EditRecordingModal = (props: EditRecordingModalProps) => {
  const { data, isOpen, onClose, visit } = props;

  const recommendationData = { skin_type: visit?.patient?.skin_type };

  const { data: formData } = useQuery<
    GetPatientFormDataQuery,
    GetPatientFormDataQueryVariables
  >(GET_PATIENT_FORM_DATA, {
    fetchPolicy: "no-cache",
    variables: {
      id: Number(data?.id),
    },
  });

  return (
    <ModalBox isOpen={isOpen} onClose={onClose} size="xxl" iconsize="26px">
      <ModalBody pb={6}>
        <Box>
          <Card
            w="full"
            border="1px solid"
            borderColor="gray.300"
            boxShadow="null"
            borderRadius="16px"
          >
            <CardBody p="30px">
              <Heading fontSize="24px" fontWeight="700" mb="30px">
                Edit Recording / { formData?.form_data_by_pk?.treatment?.title }
              </Heading>

              <Tabs variant="solid-rounded">
                <TabList>
                  <Tab>Recording Data</Tab>
                  <Tab>Treatment Description</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <Divider mb={5} />
                    <ParamsForm
                      recommendationData={recommendationData}
                      patientId={visit?.patient_id || -1}
                      formId={Number(formData?.form_data_by_pk?.form?.id)}
                      visitId={visit?.id}
                      formData={formData?.form_data_by_pk}
                      onClose={onClose}
                    ></ParamsForm>
                  </TabPanel>
                  <TabPanel>
                    <Divider mb={5} />
                    <div
                      style={{ minHeight: "50vh", width: "100%" }}
                      dangerouslySetInnerHTML={{
                        __html:
                          formData?.form_data_by_pk?.treatment?.description ||
                          "",
                      }}
                    ></div>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </CardBody>
          </Card>
        </Box>
      </ModalBody>
    </ModalBox>
  );
};
