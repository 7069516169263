import { FormItemProps } from "../../../interfaces/Interfaces";
import {
  Text,
  FormControl,
  FormLabel,
  Input,
  InputProps,
} from "@chakra-ui/react";
import "./style.css";
import { useFormContext } from "react-hook-form";
import { get } from "lodash";

interface TimePickerBoxInterFace extends FormItemProps, InputProps {}

const TimePickerBox = (props: TimePickerBoxInterFace) => {
  let item = props.item;
  const validator = props.validator;

  const {
    formState: { errors },
    register,
  } = useFormContext();

  const error = get(errors, item.descriptor);

  return (
    <FormControl w={props.w ?? ""} isInvalid={!!error}>
      {item.title.length > 0 && (
        <FormLabel flexDirection="column">
          {item.required && (
            <Text as="span" color="red.500" fontSize="14px" me="5px">
              *
            </Text>
          )}
          <Text as="span">{item.title}</Text>
        </FormLabel>
      )}

      <Input
        {...item?.options?.props}
        key={item.descriptor}
        className={"full"}
        placeholder={item.placeholder || ""}
        min="00:00"
        max="23:59"
        type="time"
        {...register(item.descriptor, validator)}
      />
    </FormControl>
  );
};

export default TimePickerBox;
