import { gql } from "@apollo/client";

export const GET_FORM_SCHEMAS = gql`
  query AdminGetFormSchemasList(
    $where: form_schemas_bool_exp!
    $limit: Int!
    $offset: Int!
  ) {
    form_schemas(
      limit: $limit
      offset: $offset
      order_by: { id: asc }
      where: $where
    ) {
      id
      center_id
      version
      descriptor
      title
      created_at
      updated_at
    }

    form_schemas_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_FORM_SCHEMA_BY_ID = gql`
  query AdminGetFormSchemaById($id: Int!) {
    form_schemas_by_pk(id: $id) {
      id
      center_id
      version
      descriptor
      title
      template
    }
  }
`;
