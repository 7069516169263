import {
  Card,
  CardBody,
  Heading,
  Image,
  SimpleGrid,
  Text,
  VStack,
  Flex,
  Spinner,
  HStack,
  Box,
  Icon,
  Button,
  Square,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import noImage from "../../../../../../assets/images/no-image.svg";
import { useEffect, useState } from "react";
import axiosInstance from "../../../../../../helpers/axiosInstance";
import { IoIosResize } from "react-icons/io";
import { getHRFormat } from "../../../../../../helpers/DateUtil";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { ChevronLeftIcon, ChevronRightIcon, EditIcon } from "@chakra-ui/icons";
import AddPictureModal from "../../modals/AddPictureModal";
import Confirmation from "../../../../../../components/Confirmation";
import useCustomToast from "../../../../../../hooks/useCustomToast";
import {
  GetMetaDataQuery,
  GetMetaDataQueryVariables,
  Visits,
} from "../../../../../../gql/graphql";
import { useLazyQuery } from "@apollo/client";
import { GET_META_DATA } from "../../../queries";

type PropTypes = {
  visit: Pick<Visits, "id" | "date" | "created_at" | "visit_number">;
  basePath: string;
};

const PatientPictures = (props: PropTypes) => {
  const { visit, basePath } = props;

  const borderBg =
    "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23C9C9C9FF' stroke-width='2' stroke-dasharray='6%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e\")";

  const [pictures, setPictures] = useState<any>([]);

  const [tags, setTags] = useState<Array<any>>([]);

  const [pictureIsLoading, setPictureIsLoading] = useState(false);
  const [pictureIsLoading2, setPictureIsLoading2] = useState(true);

  const [slidePictureIsLoading, setSlidePictureIsLoading] = useState(false);

  const [deletePictureIsLoading, setDeletePictureIsLoading] = useState(false);

  const [addPictureModalIsOpen, setAddPictureModalIsOpen] = useState(false);

  const [uploadPath, setUploadPath] = useState("");

  const [keyToDelete, setKeyToDelete] = useState<string | null>(null);

  const [currentPicture, setCurrentPicture] = useState<any>({
    name: "",
    tag: "",
    type: "",
    url: "",
    index: 0,
  });

  const [getMetaData, picturesFoldersMetaData] = useLazyQuery<
    GetMetaDataQuery,
    GetMetaDataQueryVariables
  >(GET_META_DATA, {
    variables: {
      descriptor: "VISIT_FOLDERS",
      centerId: Number(process.env.REACT_APP_CENTER_ID),
    },
  });

  useEffect(() => {
    getMetaData();
  }, [visit]);

  useEffect(() => {
    if (pictures && picturesFoldersMetaData?.data?.meta_data[0]?.data) {
      let toBeAddedFolder: any = [];
      let currentFolders = Object.keys(pictures);
      let tempTags = currentFolders;

      for (let folder of picturesFoldersMetaData?.data?.meta_data[0]?.data) {
        if (currentFolders.indexOf(folder.path) === -1) {
          toBeAddedFolder.push(
            basePath + visit.visit_number + "/" + folder.path
          );
        }
        tempTags.push(folder.path);
      }

      if (toBeAddedFolder.length > 0) {
        axiosInstance().post("/storage/create_folder", {
          keys: toBeAddedFolder,
        }, {
          headers: {
            "Authorization": 'Bearer ' + localStorage.getItem('access_token')
        }
        });
      }

      setTags(tempTags);
    }
  }, [picturesFoldersMetaData]);

  const toast = useCustomToast();

  const getPictures = async () => {
    if (!pictureIsLoading) {
      setPictureIsLoading(true);
      setPictureIsLoading2(true);
      let path = basePath + visit.visit_number + "/";
      setUploadPath(path);
      let list = await axiosInstance().post(
        "/storage/list",
        {
          key: path,
        },
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem("access_token"),
          },
        }
      );

      if (list?.data?.data?.files) {
        let indexes: any = {};
        for (let file of list?.data?.data?.files) {
          if (file.type === "image") {
            for (const tag of tags) {
              if (file.key.indexOf("/" + tag) !== -1) {
                if (!indexes[tag]) {
                  indexes[tag] = [];
                }
                indexes[tag].push({ ...file, tag, index: indexes[tag].length });
              }
            }
          }
        }

        setPictures(indexes);
      }

      setPictureIsLoading(false);
      setPictureIsLoading2(false);
    }
  };

  const getTagElement = (tag: string, props?: any) => {
    let bg = "#a29d9ccc";
    let color = "#888888";

    if (tag === "after") {
      bg = "#d6f9e2d6";
      color = "#117A31";
    } else if (tag === "before") {
      bg = "#f2c3b7cc";
      color = "#D94929";
    }

    return (
      <Text
        fontSize="14px"
        fontWeight="500"
        {...props}
        bg={bg}
        color={color}
        px="15px"
        py="5px"
        borderRadius="8px"
      >
        {tag?.replace(/\w+/g, function (w) {
          return w[0].toUpperCase() + w.slice(1).toLowerCase();
        })}
      </Text>
    );
  };

  useEffect(() => {
    if (basePath && tags.length > 0) {
      getPictures();
    }
  }, [basePath, tags]);

  return (
    <>
      <Card
        w="full"
        boxShadow="null"
        borderRadius="16px"
        border="1px solid"
        borderColor="gray.300"
        position="relative"
      >
        <CardBody p="30px">
          <Heading fontSize="24px" fontWeight="700">
            Photography
          </Heading>

          {pictureIsLoading2 && (
            <Flex
              justifyContent="center"
              alignItems="center"
              h="100px"
              w="full"
            >
              <Spinner></Spinner>
            </Flex>
          )}
          {!pictureIsLoading2 && (
            <SimpleGrid
              columns={{ base: 1, md: 2, lg: 3, xl: 4 }}
              gap="15px"
              mt="25px"
              w="full"
            >
              {Object.keys(pictures).map((tag: string, index) => (
                <Card
                  boxShadow="none"
                  key={index}
                  border="1px solid"
                  borderColor="#cccccc"
                  w="full"
                  p="0"
                  overflow="hidden"
                  borderRadius="16px"
                >
                  <CardBody
                    justifyContent="center"
                    alignItems="center"
                    p="0"
                    position="relative"
                  >
                    {getTagElement(tag, {
                      position: "absolute",
                      top: "15px",
                      left: "15px",
                    })}
                    <Box position="absolute" top="15px" right="15px">
                      <Square
                        size="25px"
                        bg="#f8f8f8"
                        borderRadius="8px"
                        onClick={() => {
                          setCurrentPicture(pictures[tag][0]);
                          setSlidePictureIsLoading(true);
                        }}
                      >
                        <Icon
                          color="#5C5C5C"
                          fontSize="15px"
                          as={IoIosResize}
                        ></Icon>
                      </Square>
                    </Box>
                    <VStack h="full" justifyContent="space-between">
                      <Image
                        src={pictures[tag][0].url}
                        w="full"
                        maxH="207px"
                        objectFit="cover"
                      ></Image>
                      <HStack
                        px="15px"
                        w="full"
                        mb="10px"
                        justifyContent="space-between"
                      >
                        <Text color="#2E2E2E" fontWeight="400" fontSize="14px">
                          {getHRFormat(new Date(visit.created_at))}
                        </Text>
                        <Menu>
                          <MenuButton
                            w="32px"
                            h="32px"
                            minW="32px"
                            as={IconButton}
                            aria-label="Options"
                            icon={
                              <HiOutlineDotsHorizontal
                                size="20"
                                color="black"
                              />
                            }
                            variant="link"
                            border="0px"
                          />
                          <MenuList>
                            <MenuItem
                              fontSize="14px"
                              icon={<EditIcon fontSize="16px" />}
                              onClick={() => {
                                setKeyToDelete(pictures[tag][0].key);
                              }}
                            >
                              Delete Image
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </HStack>
                    </VStack>
                  </CardBody>
                </Card>
              ))}

              <Card
                background={borderBg}
                border="none"
                boxShadow="none"
                w="full"
                cursor="pointer"
                onClick={() => {
                  setAddPictureModalIsOpen(true);
                }}
              >
                <CardBody justifyContent="center" alignItems="center">
                  <VStack gap="10" mt="30px">
                    <Image src={noImage}></Image>
                    <Text color="#0065FF" fontWeight="500">
                      New Photo +
                    </Text>
                  </VStack>
                </CardBody>
              </Card>
            </SimpleGrid>
          )}
        </CardBody>
      </Card>

      {currentPicture?.url?.length > 0 && (
        <Modal
          size="xl"
          isOpen={currentPicture?.url?.length > 0}
          onClose={() => {
            setCurrentPicture(null);
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader fontSize="17px">Visit Photography</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <HStack w="full">
                {pictures[currentPicture.tag]?.length > 1 && (
                  <Button
                    w="40px"
                    h="40px"
                    borderRadius="100%"
                    onClick={() => {
                      let newIndex = currentPicture.index - 1;
                      if (newIndex < 0) {
                        newIndex = pictures[currentPicture.tag]?.length - 1;
                      }

                      setCurrentPicture(pictures[currentPicture.tag][newIndex]);
                      setSlidePictureIsLoading(true);
                    }}
                  >
                    <ChevronLeftIcon fontSize="15px"></ChevronLeftIcon>
                  </Button>
                )}

                <Box flex="1" position="relative">
                  <HStack
                    position="absolute"
                    top="0"
                    right="0"
                    left="0"
                    bottom="0"
                    bg="#ffffff"
                    alignItems="center"
                    justifyContent="center"
                    zIndex={slidePictureIsLoading ? 100000 : -1}
                  >
                    <Spinner size="lg"></Spinner>
                  </HStack>
                  {currentPicture?.url && (
                    <Image
                      w="full"
                      src={currentPicture.url}
                      onLoad={() => {
                        setSlidePictureIsLoading(false);
                      }}
                    ></Image>
                  )}
                </Box>
                {pictures[currentPicture.tag]?.length > 1 && (
                  <Button
                    w="40px"
                    h="40px"
                    borderRadius="100%"
                    onClick={() => {
                      let newIndex = currentPicture.index + 1;
                      if (newIndex >= pictures[currentPicture.tag]?.length) {
                        newIndex = 0;
                      }

                      setCurrentPicture(pictures[currentPicture.tag][newIndex]);
                      setSlidePictureIsLoading(true);
                    }}
                  >
                    <ChevronRightIcon fontSize="15px"></ChevronRightIcon>
                  </Button>
                )}
              </HStack>
            </ModalBody>

            <ModalFooter>
              <HStack w="full" justifyContent="space-between">
                <HStack>
                  <Text fontSize="14px" fontWeight="500">
                    {getHRFormat(new Date(visit.created_at))}
                  </Text>

                  <Menu>
                    <MenuButton
                      w="32px"
                      h="32px"
                      minW="32px"
                      as={IconButton}
                      aria-label="Options"
                      icon={<HiOutlineDotsHorizontal size="20" color="black" />}
                      variant="link"
                      border="0px"
                    />
                    <MenuList>
                      <MenuItem
                        fontSize="14px"
                        icon={<EditIcon fontSize="16px" />}
                        onClick={() => {
                          setKeyToDelete(currentPicture.key);
                        }}
                      >
                        Delete Image
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </HStack>

                <Box
                  p="5px"
                  borderRadius="8px"
                  fontSize="14px"
                  fontWeight="500"
                  bg="#F0F6FF"
                  color="gray"
                >
                  {currentPicture.index + 1} /{" "}
                  {pictures[currentPicture.tag]?.length}
                </Box>
                {getTagElement(currentPicture.tag)}
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}

      {addPictureModalIsOpen && (
        <AddPictureModal
          isOpen={addPictureModalIsOpen}
          tags={tags}
          basePath={uploadPath}
          defaultState={{}}
          closeAction={() => {
            setAddPictureModalIsOpen(false);
          }}
          addAction={async () => {
            getPictures();
            setAddPictureModalIsOpen(false);
          }}
        ></AddPictureModal>
      )}

      {keyToDelete && (
        <Confirmation
          isOpen={!!keyToDelete && keyToDelete?.length > 0}
          isLoading={deletePictureIsLoading}
          closeAction={() => {
            setKeyToDelete(null);
          }}
          acceptAction={async () => {
            setDeletePictureIsLoading(true);
            try {
              let result = await axiosInstance().post("/storage/delete", {
                key: keyToDelete,
              }, {
                headers: {
                  Authorization: 'Bearer ' + localStorage.getItem("access_token"),
                },
              });

              if (result.data.status === 0) {
                toast.success("Image removed successfully.");

                setCurrentPicture(null);

                getPictures();

                setKeyToDelete(null);
              } else {
                toast.error("There is a problem on deleting this image.");
              }
            } catch (err) {
              toast.error("There is a problem on deleting this image.");
            }
            setDeletePictureIsLoading(false);
          }}
        >
          Are you sure to Delete this image?
        </Confirmation>
      )}
    </>
  );
};

export default PatientPictures;
