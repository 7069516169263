import { TableFieldInterface } from "../../../../../interfaces/Interfaces";

export const fields: Array<TableFieldInterface> = [
    {
      title: "Date",
      descriptor: "start_at",
      expression: "DATE_TIME_HR",      
    },
    {
      title: "Patient Name",
      descriptor: ["given_name", "family_name"],
    },
    { 
      title: "Status",
      descriptor: "status"
    },
    {
      title: "Action",
      descriptor: "",
      expression: "ACTION_FIELD",
    },
  ];