import {
  Box,
  Button,
  Card,
  CardBody,
  Heading,
  HStack,
  SimpleGrid,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Link as ReactRouterLink, useParams } from "react-router-dom";
import TextBox from "../../../components/FromsElements/TextBox";
import { useEffect, useState } from "react";
import { FormItem } from "../../../interfaces/Interfaces";
import TextAreaBox from "../../../components/FromsElements/TextAreaBox";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GET_PATIENT_BY_ID,
  ADD_PATIENT,
  UPDATE_PATIENT,
  GET_PATIENTS_LAST_CUSTOMER_CODE,
} from "./queries";
import SelectBox from "../../../components/FromsElements/SelectBox";
import SimpleReactValidator from "simple-react-validator";
import useCustomToast from "../../../hooks/useCustomToast";
import { MonthMap } from "../../../helpers/DateUtil";
import { EditForm } from "./components/EditForm";
import { FormFieldsType } from "./types";
const PatientForm = () => {
  const params = useParams();
  const [validator, setValidator] = useState<any>(
    new SimpleReactValidator({
      element: (message: string) => {
        return (
          <Text fontSize="sm" color="red.500">
            {message}
          </Text>
        );
      },
    })
  );

  const [recordIsLoading, setRecordIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const toast = useCustomToast();
  const defaultState : FormFieldsType = {
    id: null,
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    occupation: "",
    address: "",
    contact_method: "",
    emergency_contact_full_name: "",
    emergency_contact_email: "",
    emergency_contact_phone: "",
    birthday_day: "",
    birthday_month: "",
    birthday_year: "",
    center_id: Number(process.env.REACT_APP_CENTER_ID),
    tries: 0,
  };

  const [stateData, setStateData] = useState<FormFieldsType>(defaultState);

  const [getPatientById, getPatientByIdResult] = useLazyQuery(
    GET_PATIENT_BY_ID,
    {
      fetchPolicy: "no-cache",
      variables: {
        id: params.id,
      },
    }
  );

  const [getPatientCustomerCode, getPatientCustomerCodeResult] = useLazyQuery(
    GET_PATIENTS_LAST_CUSTOMER_CODE,
    {
      fetchPolicy: "no-cache",
    }
  );

  const [addPatient] = useMutation(ADD_PATIENT);
  const [updatePatient] = useMutation(UPDATE_PATIENT);

  const saveRecord = async () => {
    setStateData({ ...stateData, tries: stateData.tries + 1 });
    if (validator.allValid()) {
      let record: any = {
        first_name: stateData.first_name,
        last_name: stateData.last_name,
        email: stateData.email,
        mobile: stateData.mobile,
        occupation: stateData.occupation,
        address: {
          full: stateData.address,
        },
        contact_method: stateData.contact_method,
        emergency_contact: {
          emergency_contact_full_name: stateData.emergency_contact_full_name,
          emergency_contact_email: stateData.emergency_contact_email,
          emergency_contact_phone: stateData.emergency_contact_phone,
        },
        birthday:
          stateData.birthday_year +
          "-" +
          stateData.birthday_month +
          "-" +
          stateData.birthday_day,
        center_id: Number(process.env.REACT_APP_CENTER_ID),
      };

      if (stateData.id) {
        record.id = stateData.id;

        let result = await updatePatient({
          variables: {
            id: record.id,
            object: record,
          },
        });

        if (result?.data?.update_patients_by_pk?.id) {
          toast.success("Record Saved Successfully.");
        } else {
          toast.error("There is an error on saving record data.");
        }
      } else {
        let date = new Date();
        record.email_verified_at =
          date.getFullYear() +
          "-" +
          date.getMonth().toString().padStart(2, "0") +
          "-" +
          date.getDate().toString().padStart(2, "0");
        record.mobile_verified_at = record.email_verified_at;
        record.customer_code = "10001";
        let customerCodeResult = await getPatientCustomerCode();
        if (
          customerCodeResult?.data?.patients_aggregate?.aggregate?.max
            ?.customer_code
        ) {
          record.customer_code = (
            parseInt(
              customerCodeResult?.data?.patients_aggregate?.aggregate?.max
                ?.customer_code
            ) + 1
          ).toString();
        }
        let result = await addPatient({
          variables: {
            object: record,
          },
        });

        if (result?.data?.insert_patients_one?.id) {
          setStateData({
            ...stateData,
            id: result.data.insert_patients_one.id,
          });
          toast.success("Record Saved Successfully.");
        } else {
          toast.error("There is an error on saving record data.");
        }
      }
    } else {
      validator.showMessages();
    }
  };

  useEffect(() => {
    if (params.id) {
      getPatientById({
        variables: {
          id: parseInt(params.id),
        },
      });
    } else {
      setRecordIsLoading(false);
    }
  }, [params]);

  useEffect(() => {
    if (getPatientByIdResult?.data?.patients_by_pk) {
      let record = getPatientByIdResult.data.patients_by_pk;

      let birthday = record.birthday?.split("-");

      let temp = {
        id: record.id,
        first_name: record.first_name,
        last_name: record.last_name,
        email: record.email,
        mobile: record.mobile,
        occupation: record.occupation,
        address: record.address?.full,
        contact_method: record.contact_method,
        emergency_contact_full_name: record.emergency_contact?.emergency_contact_full_name,
        emergency_contact_email: record.emergency_contact?.emergency_contact_email,
        emergency_contact_phone: record.emergency_contact?.emergency_contact_phone,
        birthday_day: birthday ? birthday[2] : null,
        birthday_month: birthday ? birthday[1] : null,
        birthday_year: birthday ? birthday[0] : null,
        center_id: Number(process.env.REACT_APP_CENTER_ID),
        tries: 0,
      };

      setStateData(temp);
      setRecordIsLoading(false);
    }
  }, [getPatientByIdResult]);

  return (
    <VStack w="full" p="25px">
      <HStack w="full" flexWrap="wrap">
        <Heading flex="1" fontSize="30px">
          {stateData.id && <>Edit Patient</>}

          {!stateData.id && <>Add New Patient</>}
        </Heading>
        <HStack>
          <Button as={ReactRouterLink} to="/admin/patients" variant="btnNormal">
            Back To List
          </Button>
          <Button
            variant="btnMain"
            isDisabled={isSaving}
            minW={120}
            onClick={async () => {
              setIsSaving(true);
              await saveRecord();
              setIsSaving(false);
            }}
          >
            {isSaving ? <Spinner px="5px"></Spinner> : "Save" }
          </Button>
        </HStack>
      </HStack>

      <EditForm 
        setStateData={setStateData}
        stateData={stateData}
        recordIsLoading={recordIsLoading}
        validator={validator}
      />

    </VStack>
  );
};

export default PatientForm;
