import {
  Button,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Heading,
  Image,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { DeleteFormDataMutation, DeleteFormDataMutationVariables, GetVisitDataQuery } from "../../../../../../gql/graphql";
import noNote from "../../../../../../assets/images/appointment_empty.svg";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { useDisclosureWithData } from "../../../../../../hooks/useDisclosureWithData";
import {
  EditModalDataType,
  EditRecordingModal,
} from "../../modals/EditRecordingModal";
import { AddRecordingModal } from "../../modals/AddRecordingModal";
import Confirmation from "../../../../../../components/Confirmation";
import { useMutation } from "@apollo/client";
import { DELETE_FORM_DATA } from "../../../mutations";
import useCustomToast from "../../../../../../hooks/useCustomToast";
import { getDateTimeHRFormat } from "../../../../../../helpers/DateUtil";

type TreatmentFormsType = {
  visit: GetVisitDataQuery["visits_by_pk"];
  refetch: () => void;
};

export const TreatmentForms = (props: TreatmentFormsType) => {
  const { visit, refetch } = props;
  const toast = useCustomToast();

  const {
    isOpen: isModalOpen,
    onClose: onModalClose,
    onOpen: onModalOpen,
    data: modalData,
  } = useDisclosureWithData<EditModalDataType>();

  const {
    isOpen: isDeleteConfirmationOpen,
    onClose: onDeleteConfirmationClose,
    onOpen: onDeleteConfirmationOpen,
    data: deleteModalData,
  } = useDisclosureWithData<EditModalDataType>();

  const {
    isOpen: isAddModalOpen,
    onClose: onAddModalClose,
    onOpen: onAddModalOpen,
  } = useDisclosure();

  const [deleteFormData] = useMutation<DeleteFormDataMutation, DeleteFormDataMutationVariables>(DELETE_FORM_DATA);

  return (
    <>
      <Card
        mb="30px"
        pb="15px"
        border="1px solid"
        borderColor="gray.300"
        boxShadow="none"
        borderRadius="16px"
        position="relative"
      >
        <CardHeader display="flex" justifyContent="space-between">
          <Heading fontSize="24px">Recordings</Heading>
          <Button
            variant={"ghost"}
            fontSize="16px"
            fontWeight="500"
            color="#0065FF"
            cursor="pointer"
            onClick={() => {
              onAddModalOpen();
            }}
          >
            Add Recording +
          </Button>
        </CardHeader>
        <CardBody
          p="30px"
          pt="0"
          minH="200px"
          maxH={{ base: "auto", md: "415px" }}
          overflowY="auto"
        >
          <VStack
            w="full"
            gap="4"
            h="full"
            justifyContent={visit?.form_data?.length ? "top" : "center"}
          >
            {!visit?.form_data?.length ? (
              <>
                <Image w="66px" src={noNote}></Image>
                <Text fontSize="14px" fontWeight="400" color="#5C5C5C">
                  No recordings
                </Text>
              </>
            ) : (
              <Table>
                <Thead>
                  <Tr>
                    <Th>#</Th>
                    <Th>Applied Treatment</Th>
                    <Th>Last Update</Th>
                    <Th>Actions</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {visit?.form_data?.map((form, idx) => (
                    <Tr key={idx}>
                      <Td>{idx + 1}</Td>
                      <Td>{form.treatment?.title}</Td>
                      <Td>{getDateTimeHRFormat(new Date(form.updated_at))}</Td>
                      <Td>
                        <HStack spacing={2}>
                          <Button
                            variant="btnNormal"
                            onClick={() => {
                              onModalOpen({
                                id: form.id,
                              });
                            }}
                          >
                            <AiOutlineEdit />
                          </Button>
                          <Button variant="btnNormal" onClick={() => {
                            onDeleteConfirmationOpen({
                              id: form.id
                            })
                          }}>
                            <AiOutlineDelete />
                          </Button>
                        </HStack>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            )}
          </VStack>
        </CardBody>
      </Card>

      <EditRecordingModal
        isOpen={isModalOpen}
        onClose={onModalClose}
        data={modalData}
        visit={visit}
      />

      <AddRecordingModal
        isOpen={isAddModalOpen}
        onClose={onAddModalClose}
        onSuccessStore={(id: number) => {
          onModalOpen({id});    
          refetch();    
        }}
        visit={visit}
      />

        <Confirmation
          isOpen={isDeleteConfirmationOpen}
          closeAction={onDeleteConfirmationClose}
          acceptAction={async () => {
            let result = await deleteFormData({
              variables: {
                id: Number(deleteModalData?.id),
              },
            });

            if (result.data?.delete_form_data_by_pk?.id) {
              toast.success("Deleted successfully.");
            }

            onDeleteConfirmationClose();
            refetch();
          }}
        >
          <Text>Confirm delete the recording?</Text>
        </Confirmation>
    </>
  );
};
