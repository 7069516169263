import {Box, Button, Checkbox, FormControl, FormLabel, HStack, Input, Radio, Text, VStack} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import Item from "./Item";
import FieldModal from "../../modals/FieldModal";

const ActionOptionBoxField = (props:any) => {
    const {
        item,
        preDelete,
        preEdit,
        currentSectionIndex,
        indexH,
        updateData,
        sections,
        swapItems
    } = props;

    const [values, setValues] = useState<any>(null);
    const [fieldModalIsOpen, setFieldModalIsOpen] = useState(false)
    const [parentIndex, setParentIndex] = useState(indexH)

    const preAddField = (pIndex:string|null) => {
        if (pIndex) {
            setParentIndex(indexH + pIndex + '|')
        } else {
            let temp:any = indexH.split('|');
            temp.splice(-2);
            temp = temp.join('|');
            setParentIndex(temp + '|')
        }
        setFieldModalIsOpen(true);
    }
    const closeFieldModal = () => {
        setFieldModalIsOpen(false);
    }
    useEffect(() => {
        setValues(item.values)
    }, [item]);

    return (
        <FormControl w='full'>
            <FormLabel>
                {item.required && (
                <Text as="span" color="red.500" fontSize="14px" me="5px">
                    *
                </Text>
                )}
                <Box fontSize="16px" dangerouslySetInnerHTML={{__html: item.title}}></Box>
            </FormLabel>

            <VStack w='full'>
                {values && Object.keys(values).map((option:any) => {
                    return (
                        <VStack w='full'  borderBottom='1px solid #ccc' p="15px">
                            <Radio

                            >
                                {values[option].text && values[option].text.length > 0 &&
                                    <Box fontSize="16px" dangerouslySetInnerHTML={{__html: values[option].text}}></Box>
                                }

                                {values[option].name && values[option].name.length > 0 &&
                                    <Box fontSize="16px">{values[option].name}</Box>
                                }

                                {values[option].title && values[option].title.length > 0 &&
                                    <Box fontSize="16px">{values[option].title}</Box>
                                }
                            </Radio>

                            { values[option]?.items &&
                                <VStack w='full' pt='15px' mt='15px' borderTop='1px solid #ccc' alignItems="start">
                                    <Text>If {values[option].name} :</Text>

                                    {values[option].items.map((tItem:any, key: number) => {
                                        return (
                                            <Item swapItems={swapItems} hideAddButton={true} updateData={updateData} indexH={indexH + 'values|' + option + '|items|' + key + '|'} form={option} isExtendable={true} sections={sections} currentSectionIndex={currentSectionIndex} preDelete={preDelete} preEdit={preEdit} item={tItem}></Item>
                                        );
                                    }) }

                                    <Button
                                        onClick={() => {
                                            preAddField('values|' + option + '|items');
                                        }}
                                    >
                                        Add New Field
                                    </Button>
                                </VStack>
                            }

                        </VStack>
                    );

                })}
            </VStack>
            { fieldModalIsOpen &&
                <FieldModal
                    indexH={parentIndex}
                    isOpen={fieldModalIsOpen}
                    onClose={closeFieldModal}
                    sections={sections}
                    currentSection={sections}
                    data={null}
                    onSubmit={(type:string, action:string, fieldData:any, pIndex:any) => {
                        updateData(type, action, fieldData, pIndex);
                        closeFieldModal();
                    }}
                ></FieldModal>
            }
        </FormControl>
    );
}

export default ActionOptionBoxField;