import { Alert, Button, HStack, Text, VStack } from "@chakra-ui/react";
import { Form_Data_Notes } from "../../../gql/graphql";
import { getHRFormat } from "../../../helpers/DateUtil";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import nl2br from 'react-nl2br';

type PropTypes = {
  item: Pick<Form_Data_Notes, "id" | "body" | "created_at">;
  onEdit: () => void;
  onDelete: () => void;
};

export const Note = (props: PropTypes) => {
  const { item, onEdit, onDelete } = props;
  return (
    <VStack spacing={2} justifyContent={"flex-start"} my={5}>
      <HStack w={"full"} spacing={3}>
        <Text fontWeight={"600"}>Note</Text>
        <Text fontWeight={"normal"}>
          {getHRFormat(new Date(item.created_at))}
        </Text>
        <Button variant={"ghost"} onClick={onEdit}>
          <AiOutlineEdit fontSize={20} />
        </Button>
        <Button variant={"ghost"} onClick={onDelete}>
          <AiOutlineDelete fontSize={20} />
        </Button>
      </HStack>
      <Alert borderRadius={5} colorScheme={"yellow"}>
        <Text fontWeight={"normal"}>{nl2br(item.body)}</Text>
      </Alert>
    </VStack>
  );
};
