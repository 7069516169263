import { Box, Button, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { backendApiInstance } from "../../../helpers/backendApiInstance";
import { useMutation } from "@apollo/client";
import { DELETE_SQUAREUP_ACCESS } from "./mutations";
import { DeleteSquareupAccessMutation, DeleteSquareupAccessMutationVariables } from "../../../gql/graphql";

export const Redirect = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const [params] : any = useSearchParams();
  const code = params.get('code');

  const apiInstance = backendApiInstance();

  const [deleteAccess] = useMutation<DeleteSquareupAccessMutation, DeleteSquareupAccessMutationVariables>(DELETE_SQUAREUP_ACCESS);

  useEffect(() => {
    if (code) {
      apiInstance.default
        .squareupAuthorize({
          code,
        })
        .then(() => {
          setIsAuthenticated(true);
          setIsLoading(false);
        })
        .catch((err) => {
          setHasError(true);
          setIsLoading(false);
        });
    }
  }, [code]);

  return (
    <Box padding={5}>
      {isLoading ? (
        <Text>Authenticating. Please wait...</Text>
      ) : (
        <>
          {hasError ? (
            <Text>Problem occurred during authentication.</Text>
          ) : (
            <Text>{isAuthenticated ? "Authenticated." : "Not Authenticated."}</Text>
          )}
        </>
      )}

      {isAuthenticated && <Box>
            <Button onClick={async () => {
                await deleteAccess();
                setIsAuthenticated(false);
            }}>Revoke Access</Button>
        </Box>}
    </Box>
  );
};
