import Item from "./Item";
import {Box, FormControl, FormLabel, HStack, Text} from "@chakra-ui/react";

const RadioCardGroupField = (props:any) => {
    const {
        item,
        preEdit,
        preDelete,
        currentSectionIndex,
        indexH,
        updateData,
        sections,
        swapItems
    } = props;

    return (
        <FormControl w='full'>
            {item.title &&
                <FormLabel>
                    {item.required && (
                        <Text as="span" color="red.500" fontSize="14px" me="5px">
                            *
                        </Text>
                    )}
                    <Box fontSize="16px" dangerouslySetInnerHTML={{__html: item.title}}></Box>
                </FormLabel>
            }
            {item.text && item.text.length > 0 &&
                <Box fontSize="16px" dangerouslySetInnerHTML={{__html: item.text}}></Box>
            }
            <HStack gap='15px'>
            {item.sub_items.map((subItem:any, key:number) => {
                return (
                    <Item swapItems={swapItems} updateData={updateData} indexH={indexH + 'sub_items|' + key + '|'} sections={sections} currentSectionIndex={currentSectionIndex} preDelete={preDelete} preEdit={preEdit} item={subItem}></Item>
                );
            }) }
            </HStack>
        </FormControl>
    );
}

export default RadioCardGroupField;