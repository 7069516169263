import { gql } from "@apollo/client";

export const UPDATE_VISIT_BY_PK = gql`
    mutation updateVisitByPk($id: uuid! $object: visits_set_input!) {
        update_visits_by_pk(pk_columns: {id: $id}, _set: $object){
            id
        }
    }
`;

export const ADD_VISIT = gql`
    mutation addVisit($objects: [visits_insert_input!]!) {
        insert_visits(objects: $objects) {
            affected_rows,
            returning {
                id
            }
        }
    }
`;

export const ADD_FORM_DATA = gql`
    mutation addFormVisitData($data: jsonb!, $patient_id: Int!, $form_id: Int!, $user_id: Int!, $center_id: Int!, $treatment_id: Int, $visit_id: uuid) {
        insert_form_data_one(object: {center_id: $center_id, data: $data, form_id: $form_id, patient_id: $patient_id, version: 1, user_id: $user_id, treatment_id: $treatment_id, visit_id: $visit_id}) {
            id
        }
    }
`;

export const DELETE_FORM_DATA = gql`
    mutation deleteFormData($id: Int!) {
        delete_form_data_by_pk(id: $id) {
            id
        }
    }
`;

export const UPDATE_FORM_DATA_BY_PK = gql`
    mutation updateFormVisitDataByPk($id: Int!, $object: form_data_set_input!) {
        update_form_data_by_pk(pk_columns: {id: $id}, _set: $object){
            id
        }

    }
`;

export const UPDATE_PATIENT_DATA = gql`
    mutation UpdatePatient($id: Int!, $object: patients_set_input!) {
        update_patients_by_pk(pk_columns: {id: $id}, _set: $object) {
            id
        }
    }
`;

export const ADD_FORM_DATA_NOTE = gql`
    mutation addFormDataNote($object: form_data_notes_insert_input!) {
        insert_form_data_notes_one(object: $object) {
            id
            body
            user_id
            created_at
        }
    }
`;

export const DELETE_FORM_DATA_NOTE = gql`
    mutation deleteFormDataNote($id: Int!) {
        delete_form_data_notes_by_pk(id: $id) {
            id
        }
    }
`;

export const UPDATE_FORM_DATA_NOTE = gql`
    mutation updateFormDataNote($id: Int!, $body: String!) {
        update_form_data_notes_by_pk(pk_columns: {id: $id}, _set: {body: $body}) {
            id
        }
    }
`;