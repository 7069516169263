import {
  Button,
  Card,
  CardBody,
  Heading,
  HStack,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Link as ReactRouterLink, useParams } from "react-router-dom";
import TextBox from "../../../components/FromsElements/TextBox";
import { useEffect, useState } from "react";
import { FormItem } from "../../../interfaces/Interfaces";
import TextAreaBox from "../../../components/FromsElements/TextAreaBox";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_TREATMENT_CATEGORY_BY_ID } from "./queries";
import SimpleReactValidator from "simple-react-validator";
import useCustomToast from "../../../hooks/useCustomToast";
import { slugify } from "../../../helpers/StringUtil";
import { UPDATE_TREATMENT_CATEGORY, ADD_TREATMENT_CATEGORY } from "./mutations";
import {
  AddTreatmentCategoryMutation,
  AddTreatmentCategoryMutationVariables,
  GetTreatmentCategoryByIdQuery,
  GetTreatmentCategoryByIdQueryVariables,
  UpdateTreatmentCategoryMutation,
  UpdateTreatmentCategoryMutationVariables,
} from "../../../gql/graphql";

type FormFieldsType = {
  id: number | null;
  title: string;
  descriptor: string;
  description: string;
  center_id: number;
  tries: number;
};

const TreatmentCategoriesForm = () => {
  const params = useParams();
  const [validator, setValidator] = useState<any>(
    new SimpleReactValidator({
      element: (message: string) => {
        return (
          <Text fontSize="sm" color="red.500">
            {message}
          </Text>
        );
      },
    })
  );

  const [recordIsLoading, setRecordIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const toast = useCustomToast();
  const defaultState: FormFieldsType = {
    id: null,
    title: "",
    descriptor: "",
    description: "",
    center_id: Number(process.env.REACT_APP_CENTER_ID),
    tries: 0,
  };

  const [stateData, setStateData] = useState<FormFieldsType>(defaultState);

  const [getTreatmentCategoryById, getTreatmentCategoryByIdResult] =
    useLazyQuery<
      GetTreatmentCategoryByIdQuery,
      GetTreatmentCategoryByIdQueryVariables
    >(GET_TREATMENT_CATEGORY_BY_ID, {
      fetchPolicy: "no-cache",
      variables: {
        id: Number(params.id),
      },
    });

  const [addTreatment] = useMutation<
    AddTreatmentCategoryMutation,
    AddTreatmentCategoryMutationVariables
  >(ADD_TREATMENT_CATEGORY);
  const [updateTreatment] = useMutation<
    UpdateTreatmentCategoryMutation,
    UpdateTreatmentCategoryMutationVariables
  >(UPDATE_TREATMENT_CATEGORY);

  const saveRecord = async () => {
    setStateData({ ...stateData, tries: stateData.tries + 1 });
    if (validator.allValid()) {
      let record: any = {
        title: stateData.title,
        descriptor: stateData.descriptor,
        description: stateData.description,
        center_id: Number(process.env.REACT_APP_CENTER_ID),
      };

      if (stateData.id) {
        record.id = stateData.id;
        if (!record.descriptor) {
          record.descriptor = slugify(record.title, "_");
        }

        let result = await updateTreatment({
          variables: {
            id: record.id,
            object: record,
          },
        });

        if (result?.data?.update_treatment_categories_by_pk?.id) {
          toast.success("Changes Saved.");
        } else {
          toast.error("There is an error on saving record data.");
        }
      } else {
        record.descriptor = slugify(record.title, "_");
        let result = await addTreatment({
          variables: {
            object: record,
          },
        });

        if (result?.data?.insert_treatment_categories_one?.id) {
          setStateData({
            ...stateData,
            id: result.data.insert_treatment_categories_one.id,
          });
          toast.success("Changes Saved.");
        } else {
          toast.error("There is an error on saving record data.");
        }
      }
    } else {
      validator.showMessages();
    }
  };

  useEffect(() => {
    if (params.id) {
      getTreatmentCategoryById({
        variables: {
          id: parseInt(params.id),
        },
      });
    } else {
      setRecordIsLoading(false);
    }
  }, [params]);

  useEffect(() => {
    if (getTreatmentCategoryByIdResult?.data?.treatment_categories_by_pk) {
      let record =
        getTreatmentCategoryByIdResult.data.treatment_categories_by_pk;
      let temp: FormFieldsType = {
        id: record.id,
        title: record.title || "",
        descriptor: record.descriptor || "",
        description: record.description || "",
        center_id: Number(process.env.REACT_APP_CENTER_ID),
        tries: 0,
      };

      setStateData(temp);
      setRecordIsLoading(false);
    }
  }, [getTreatmentCategoryByIdResult]);

  return (
    <VStack w="full" p="25px">
      <HStack w="full" flexWrap="wrap">
        <Heading flex="1" fontSize="30px">
          {stateData.id && <>Edit Treatment Category</>}

          {!stateData.id && <>Add New Treatment Category</>}
        </Heading>
        <HStack>
          <Button
            as={ReactRouterLink}
            to="/admin/treatment_categories"
            variant="btnNormal"
          >
            Back To List
          </Button>
          <Button
            variant="btnMain"
            minW={120}
            isDisabled={isSaving}
            onClick={async () => {
              setIsSaving(true);
              await saveRecord();
              setIsSaving(false);
            }}
          >
            {isSaving ? <Spinner px="5px"></Spinner> : "Save" }
          </Button>
        </HStack>
      </HStack>

      <VStack w="full">
        {recordIsLoading && (
          <HStack w="full" h="full" justifyContent="center" py="35px">
            <Spinner></Spinner>
          </HStack>
        )}

        {!recordIsLoading && (
          <Card
            boxShadow="null"
            w="full"
            borderRadius="16px"
            border="1px solid"
            borderColor="gray.100"
            position="relative"
            mt="20px"
            flex="1"
          >
            <CardBody p="15px">
              <VStack w="full" spacing={5}>
                <TextBox
                  w="full"
                  state={stateData}
                  item={{
                    title: "Title",
                    descriptor: "title",
                    values: [],
                    validation_rule: "required",
                  }}
                  validator={validator}
                  inputItemHandler={(value: string, item: FormItem) => {
                    setStateData({ ...stateData, title: value });
                  }}
                ></TextBox>

                <TextAreaBox
                  state={stateData}
                  item={{
                    title: "Description",
                    descriptor: "description",
                    values: [],
                  }}
                  inputItemHandler={(value: string, item: FormItem) => {
                    setStateData({ ...stateData, description: value });
                  }}
                ></TextAreaBox>
              </VStack>
            </CardBody>
          </Card>
        )}
      </VStack>
    </VStack>
  );
};

export default TreatmentCategoriesForm;
