import { gql } from "@apollo/client";

export const GET_PARAMS_FORM_STRUCTURE = gql`
    query getParamsFormStructure($id: Int!, $center_id: Int!){
        form_schemas(where: {
                id: {_eq: $id},
                center_id: {_eq: $center_id}
        }) {
            id,
            version,
            template
        }
    }
`;


