import { gql } from "@apollo/client";

export const GET_PATIENTS = gql`
  query searchPatientByNameByCode($keyword: String!, $center_id: Int!) {
    patients(
      limit: 10
      offset: 0
      order_by: { created_at: desc }
      where: {
        _and: [
          { center_id: { _eq: $center_id } }
          {
            _or: [
              { first_name: { _ilike: $keyword } }
              { last_name: { _ilike: $keyword } }
              { customer_code: { _ilike: $keyword } }
              { mobile: { _ilike: $keyword } }
              { email: { _ilike: $keyword } }
            ]
          }
        ]
      }
    ) {
      address
      birthday
      contact_method
      customer_code
      email
      email_verified_at
      emergency_contact
      first_name
      id
      last_name
      mobile
      mobile_verified_at
      occupation
      skin_type
    }
  }
`;
