import {useEffect, useState} from "react";
import {Box, Button, FormControl, FormLabel, Heading, Input, Textarea, VStack} from "@chakra-ui/react";
import RenderItems from "./RenderItems";
import FormModal from "../modals/FormModal";

const RenderForm = (props:any) => {
    const {
        formObject,
        currentFormIndex,
        updateForm,
        sections,
        currentSection,
        currentSectionIndex,
        showEdit,
        indexH,
        updateData,
        preDelete,
        swapItems
    } = props;

    const [formData, setFormData] = useState<any>(null);
    const [formModalIsOpen, setFormModalIsOpen] = useState(false)

    const closeFormModal = () => {
        setFormModalIsOpen(false);
    }

    const openFormModal = () => {
        setFormModalIsOpen(true);
    }

    useEffect(() => {
        if (formObject) {
            setFormData(formObject);
        }
    }, [formObject]);

    return (
        <>
            { formData &&
                <>
                    <VStack w='full' gap='15px' p='15px' bg='white' borderRadius="5px" mb="15px">
                        <Heading fontSize='20px'>Form Information</Heading>
                        {showEdit &&
                            <Button
                                onClick={
                                    () => {
                                        openFormModal()
                                }
                            }
                            >
                                Edit Form
                            </Button>
                        }
                        <FormControl>
                            <FormLabel>Title</FormLabel>
                            <Input
                                isDisabled={true}
                                type='text'
                                value={formData.title}
                                onChange={(e) => {
                                    setFormData({...formData, title: e.target.value})
                                }} />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Descriptor</FormLabel>
                            <Input
                                isDisabled={true}
                                type='text'
                                value={formData.descriptor}
                                onChange={(e) => {
                                    setFormData({...formData, descriptor: e.target.value})
                                }} />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Description</FormLabel>
                            <Textarea
                                isDisabled={true}
                                value={formData.description}
                                onChange={(e) => {
                                    setFormData({...formData, description: e.target.value})
                                }} />
                        </FormControl>
                    </VStack>

                    <VStack w='full' gap='15px' p='15px' bg='white' borderRadius="5px">
                        <Heading fontSize='20px'>Form Structure</Heading>
                        <RenderItems
                            indexH={indexH}
                            form={formData}
                            items={formData.items}
                            sections={sections}
                            currentFormIndex={currentFormIndex}
                            currentSectionIndex={currentSectionIndex}
                            updateData={updateData}
                            preDelete={preDelete}
                            swapItems={swapItems}
                        ></RenderItems>
                    </VStack>
                </>
            }

            { showEdit && formModalIsOpen &&
                <FormModal
                    isOpen={formModalIsOpen}
                    onClose={closeFormModal}
                    sections={sections}
                    currentSection={currentSection}
                    currentSectionIndex={currentSectionIndex}
                    currentFormIndex={currentFormIndex}
                    data={ formObject }
                    indexH={indexH}
                    onSubmit={(oldData:any, newData:any, indexH:string) => {
                        if (updateForm) {
                            updateForm(oldData, newData, indexH);
                            closeFormModal();
                        }
                    }}
                ></FormModal>
            }
        </>
    );
}

export default RenderForm;