import {
    Button,
    Card,
    CardBody, Divider,
    Heading,
    HStack,
    Spinner,
    Text,
    VStack
} from "@chakra-ui/react";
import {Link as ReactRouterLink, useParams} from "react-router-dom";
import TextBox from "../../../../components/FromsElements/TextBox";
import {useEffect, useState} from "react";
import {FormItem} from "../../../../interfaces/Interfaces";
import TextAreaBox from "../../../../components/FromsElements/TextAreaBox";
import {useLazyQuery, useMutation} from "@apollo/client";
import {GET_TREATMENT_BY_ID, ADD_TREATMENT_PRESCRIPTION, UPDATE_TREATMENT_PRESCRIPTION} from "../queries";
import SimpleReactValidator from "simple-react-validator";
import useCustomToast from "../../../../hooks/useCustomToast";
import {slugify} from "../../../../helpers/StringUtil";
import { GetTreatmentByIdQuery, GetTreatmentByIdQueryVariables } from "../../../../gql/graphql";
const PrescriptionForm = () => {

    const params = useParams();
    const  [validator, setValidator] = useState<any>(new SimpleReactValidator({
        element: (message:string) => {return(<Text fontSize="sm" color="red.500">{message}</Text>)}
    }));

    const [recordIsLoading, setRecordIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);

    const toast = useCustomToast();
    const defaultState = {
        id: null,
        title: '',
        description: '',
        body: '',
        treatment_id: params.tid,
        tries: 0
    };

    const [stateData, setStateData] = useState(defaultState);
    const [treatment, setTreatment] = useState<any>({});

    const [getTreatmentById, getTreatmentByIdResult] = useLazyQuery<GetTreatmentByIdQuery, GetTreatmentByIdQueryVariables>(GET_TREATMENT_BY_ID, {
        fetchPolicy: "no-cache",
        variables: {
            id: Number(params.id),
        }
    });

    const [addTreatmentPrescription] = useMutation(ADD_TREATMENT_PRESCRIPTION);
    const [updateTreatmentPrescription] = useMutation(UPDATE_TREATMENT_PRESCRIPTION);

    const saveRecord = async () => {
        setStateData({...stateData, tries: stateData.tries + 1});
        if (validator.allValid()) {
            let record:any = {
                title: stateData.title,
                body: stateData.body,
                description: stateData.description,
                treatment_id: stateData.treatment_id
            };

            if (stateData.id) {
                record.id = stateData.id;
                if (!record.description) {
                    record.description = slugify(record.title, '_');
                }

                let result = await updateTreatmentPrescription({
                    variables: {
                        id: record.id,
                        object: record
                    }
                });

                if (result?.data?.update_treatment_prescriptions_by_pk?.id) {
                    toast.success('Record Saved Successfully.')

                } else {
                    toast.error('There is an error on saving record data.')
                }
            } else {
                record.description = slugify(record.title, '_');
                let result = await addTreatmentPrescription({
                    variables: {
                        object: record
                    }
                });

                if (result?.data?.insert_treatment_prescriptions_one?.id) {
                    setStateData({...stateData, id: result.data.insert_treatment_prescriptions_one.id});
                    toast.success('Record Saved Successfully.')

                } else {
                    toast.error('There is an error on saving record data.')
                }
            }



        } else {
            validator.showMessages()
        }
    }

    useEffect(() => {
        if (params.tid) {
            getTreatmentById({
                variables: {
                    id: Number(params.tid),
                }
            });
        } else {
            setRecordIsLoading(false);
        }
    }, [params])

    useEffect(() => {
        if (getTreatmentByIdResult?.data?.treatments_by_pk) {
            let record = getTreatmentByIdResult.data.treatments_by_pk;
            setTreatment(record);
            record.prescriptions.map((prescription:any) => {
                if (prescription.id == params.id) {
                    let temp = {
                        id: prescription.id,
                        title: prescription.title,
                        description: prescription.description,
                        body: prescription.body,
                        treatment_id: prescription.treatment_id,
                        tries: 0
                    };
                    setStateData(temp);
                }
            });

            setRecordIsLoading(false);
        }
    }, [getTreatmentByIdResult]);

    return (
        <VStack w="full" p="25px">
            <HStack w="full" flexWrap="wrap">
                <Heading flex="1" fontSize="30px">
                    {stateData.id &&
                        <>{treatment?.title} : Edit Prescription</>
                    }

                    {!stateData.id &&
                        <>Add New Prescription</>
                    }
                </Heading>
                <Button as={ReactRouterLink} to={`/admin/treatments/${params.tid}/prescriptions`} variant="btnNormal">
                    Back To List
                </Button>
            </HStack>

            <VStack w="full">
                {recordIsLoading &&
                    <HStack w="full" h="full" justifyContent="center" py="35px">
                        <Spinner></Spinner>
                    </HStack>
                }

                {!recordIsLoading &&
                    <Card boxShadow="null" w="full" borderRadius="16px" border="1px solid" borderColor="gray.100" position="relative"
                        mt="20px" flex="1"
                    >
                        <CardBody p="15px">
                            <VStack w="full" justifyContent="start">
                                <TextBox state={stateData} w="full" item={
                                    {
                                        title: "Title",
                                        descriptor: "title",
                                        values: [],
                                        validation_rule: "required",

                                    }}
                                    validator={validator}
                                    inputItemHandler={(value:string, item:FormItem) => {
                                        setStateData({...stateData, title: value});
                                    }}
                                ></TextBox>

                                <TextAreaBox state={stateData} item={
                                    {
                                        title: "Body",
                                        descriptor: "body",
                                        values: [],
                                        validation_rule: "required",
                                    }}
                                    validator={validator}
                                    inputItemHandler={(value:string, item:FormItem) => {
                                        setStateData({...stateData, body: value});
                                    }}
                                ></TextAreaBox>
                            </VStack>
                            <Divider my="15px"></Divider>
                            <HStack w="full">
                                <Button variant="btnNormal" as={ReactRouterLink} to={`/admin/treatments/${params.tid}/prescriptions`}>
                                    Cancel
                                </Button>
                                <Button variant="btnMain" isDisabled={isSaving}
                                    onClick={async () => {
                                        setIsSaving(true);
                                        await saveRecord();
                                        setIsSaving(false);
                                    }}
                                >
                                    {isSaving &&
                                        <Spinner px="5px"></Spinner>
                                    }
                                    Save
                                </Button>
                            </HStack>
                        </CardBody>
                    </Card>
                }
            </VStack>
        </VStack>
    );
}

export default PrescriptionForm;