import {Box, Button, ButtonGroup, HStack, Icon, IconButton, VStack} from "@chakra-ui/react";
import TextField from "./TextField";
import TextAreaField from "./TextAreaField";
import GroupField from "./GroupField";
import SelectField from "./SelectField";
import {DeleteIcon, EditIcon, ChevronUpIcon, ChevronDownIcon, PlusSquareIcon, SmallAddIcon} from "@chakra-ui/icons";
import CheckBoxField from "./CheckBoxField";
import AlertField from "./AlertField";
import ComponentField from "./ComponentField";
import PlainTextField from "./PlainTextField";
import RadioCardGroupField from "./RadioCardGroupField";
import RadioCardField from "./RadioCardField";
import ActionCheckBoxField from "./ActionCheckBoxField";
import FieldModal from "../../modals/FieldModal";
import {useEffect, useState} from "react";
import YesNoButtonField from "./YesNoButtonField";
import ActionOptionBoxField from "./ActionOptionBoxField";
import OptionBoxField from "./OptionBoxField";

const  Item = (props:any) => {

    const {
        item,
        preEdit,
        preDelete,
        isExtendable,
        sections,
        form,
        doUpdate,
        doDelete,
        key,
        currentSectionIndex,
        indexH,
        updateData,
        hideAddButton,
        swapItems
    } = props;

    const [fieldModalIsOpen, setFieldModalIsOpen] = useState(false)
    const [parentIndex, setParentIndex] = useState(indexH)
    const [indexObject, setIndexObject] = useState([])
    const [lastIndex, setLastIndex] = useState<any>(null)
    const [availableFieldTypes, setAvailableFieldTypes] = useState<Array<any>>([])
    const closeFieldModal = () => {
        setFieldModalIsOpen(false);
    }
    const preAddField = (pIndex:string|null) => {
        if (pIndex) {
            setParentIndex(indexH + pIndex + '|')
        } else {
            let temp:any = indexH.split('|');
            temp.splice(-2);
            temp = temp.join('|');
            setParentIndex(temp + '|')
        }
        setFieldModalIsOpen(true);
    }

    const preEditField = (pIndex:string|null, item:any) => {
        if (pIndex) {
            setParentIndex(indexH + pIndex + '|');
            preEdit(indexH + pIndex + '|', item);
        } else {
            preEdit(indexH, item);
        }

    }

    const canMoveNext = () => {
        return indexObject.length > 1 && indexObject.length > parseInt(lastIndex) + 1;
    }

    const canMovePrev = () => {
        return  indexObject.length > 1 && parseInt(lastIndex) > 0;
    }

    useEffect(() => {
        if (indexH) {
            let indices:any = indexH.split('|');
            indices.pop();

            let lastIndexIn = indices.pop();
            let target = indices.reduce((obj:any, index:any) => obj && obj[index], sections);

            if (target && lastIndexIn) {
                setIndexObject(target);
                setLastIndex(parseInt(lastIndexIn))
            }
        }
    }, [indexH]);

    return (
        <VStack w='full'>
            <HStack w='full' gap="15px" alignItems="start" border='1px solid #e1e1e1' bg='#fcfcfc' borderRadius='5px' p='10px'>
                { item.type === 'input' && <TextField swapItems={swapItems} sections={sections} updateData={updateData} indexH={indexH} item={item} preDelete={preDelete}></TextField> }
                { item.type === 'textarea' && <TextAreaField swapItems={swapItems} sections={sections} updateData={updateData} indexH={indexH} item={item} preDelete={preDelete}></TextAreaField> }
                { item.type === 'select' && <SelectField swapItems={swapItems} sections={sections} updateData={updateData} indexH={indexH} item={item} preDelete={preDelete}></SelectField> }
                { item.type === 'group' && <GroupField swapItems={swapItems} sections={sections} updateData={updateData} indexH={indexH} preEdit={preEdit} preDelete={preDelete} item={item}  currentSectionIndex={currentSectionIndex}></GroupField> }
                { item.type === 'checkbox' && <CheckBoxField swapItems={swapItems} sections={sections} updateData={updateData} indexH={indexH} key={key} preEdit={preEdit} preDelete={preDelete} item={item}  currentSectionIndex={currentSectionIndex}></CheckBoxField> }
                { item.type === 'action_checkbox' && <ActionCheckBoxField swapItems={swapItems} sections={sections} updateData={updateData} indexH={indexH} key={key} preEdit={preEdit} preDelete={preDelete} item={item}  currentSectionIndex={currentSectionIndex}></ActionCheckBoxField> }
                { item.type === 'action_option_box' && <ActionOptionBoxField swapItems={swapItems} sections={sections} updateData={updateData} indexH={indexH} key={key} preEdit={preEdit} preDelete={preDelete} item={item}  currentSectionIndex={currentSectionIndex}></ActionOptionBoxField> }
                { item.type === 'option_box' && <OptionBoxField swapItems={swapItems} sections={sections} updateData={updateData} indexH={indexH} key={key} preEdit={preEdit} preDelete={preDelete} item={item}  currentSectionIndex={currentSectionIndex}></OptionBoxField> }
                { item.type === 'yes_no_button' && <YesNoButtonField swapItems={swapItems} updateData={updateData} indexH={indexH} sections={sections} key={key} preEdit={preEdit} preDelete={preDelete} item={item} currentSectionIndex={currentSectionIndex}></YesNoButtonField> }
                { item.type === 'alert' && <AlertField swapItems={swapItems} sections={sections} updateData={updateData} indexH={indexH} preEdit={preEdit} preDelete={preDelete} item={item}  currentSectionIndex={currentSectionIndex}></AlertField> }
                { item.type === 'component' && <ComponentField swapItems={swapItems} sections={sections} updateData={updateData} indexH={indexH} preEdit={preEdit} preDelete={preDelete} item={item}  currentSectionIndex={currentSectionIndex}></ComponentField> }
                { item.type === 'text' && <PlainTextField swapItems={swapItems} sections={sections} updateData={updateData} indexH={indexH} preEdit={preEdit} preDelete={preDelete} item={item}  currentSectionIndex={currentSectionIndex}></PlainTextField> }
                { item.type === 'radioCardGroup' && <RadioCardGroupField swapItems={swapItems} sections={sections} updateData={updateData} indexH={indexH} preEdit={preEdit} preDelete={preDelete} item={item}  currentSectionIndex={currentSectionIndex}></RadioCardGroupField> }
                { item.type === 'RadioCard' && <RadioCardField swapItems={swapItems} sections={sections} updateData={updateData} indexH={indexH} preEdit={preEdit} preDelete={preDelete} item={item}  currentSectionIndex={currentSectionIndex}></RadioCardField> }
                <ButtonGroup size='sm' isAttached>
                    {canMovePrev() &&
                        <IconButton
                            colorScheme='blue'
                            aria-label='Move Prev This Field'
                            icon={<ChevronUpIcon />}
                            onClick={() => {
                                swapItems(lastIndex, lastIndex - 1, indexH);
                            }}
                        />
                    }
                    {canMoveNext() &&
                        <IconButton
                            colorScheme='blue'
                            aria-label='Move Next This Field'
                            icon={<ChevronDownIcon />}
                            onClick={() => {
                                swapItems(lastIndex, lastIndex + 1, indexH);
                            }}
                        />
                    }

                    { (
                        item.type === 'group' ||
                        (item.type === 'component' && item.component === 'AddressBox') ||
                        item.type === 'radioCardGroup'
                        ) &&
                        <IconButton
                            colorScheme='green'
                            aria-label='Add New Field'
                            icon={<SmallAddIcon />}
                            onClick={() => {
                                if (item.type === 'radioCardGroup') {
                                    setAvailableFieldTypes([{
                                        title: 'Radio Card',
                                        value: 'RadioCard'
                                    }]);
                                } else {
                                    setAvailableFieldTypes([]);
                                }
                                preAddField('sub_items');
                            }}
                        />
                    }
                    <IconButton
                        colorScheme='yellow'
                        aria-label='Edit This Field'
                        icon={<EditIcon />}
                        onClick={() => {
                            preEditField('', item);
                        }}
                    />
                    <IconButton
                        colorScheme='orange'
                        aria-label='Delete This Field'
                        icon={<DeleteIcon />}
                        onClick={() => {
                            preDelete(indexH);
                        }}
                    />
                </ButtonGroup>
            </HStack>

            {!hideAddButton && isExtendable &&
                <>
                    <Box>
                        <Button
                            isDisabled={!form}
                            onClick={() => {
                                preAddField(null);
                            }}
                        >
                            Add New Field
                        </Button>
                    </Box>
                </>
            }
            { fieldModalIsOpen &&
                <FieldModal
                    indexH={parentIndex}
                    isOpen={fieldModalIsOpen}
                    onClose={closeFieldModal}
                    sections={sections}
                    availableFieldTypes={availableFieldTypes}
                    currentSection={sections}
                    currentForm={form}
                    data={null}
                    onSubmit={(type:string, action:string, fieldData:any, pIndex:any) => {
                        updateData(type, action, fieldData, pIndex);
                        closeFieldModal();
                    }}
                ></FieldModal>
            }
        </VStack>
    );
}

export default Item;