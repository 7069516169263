import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_FORM_TEMPLATE, GET_PATIENT_FORM_DATA } from "../queries";
import { ADD_FORM_DATA, UPDATE_FORM_DATA_BY_PK } from "../mutations";
import { useEffect, useState } from "react";
import {
  Link as ReactRouterLink,
  useNavigate,
  useParams,
} from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";
import useCustomToast from "../../../../hooks/useCustomToast";
import {
  Box,
  Button,
  Card,
  CardBody,
  Container,
  Divider,
  Heading,
  HStack,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import FormElementsContainer from "../../../../components/FromsElements/FormElementsContainer";
import { FormItem } from "../../../../interfaces/Interfaces";
import {
  AddFormVisitDataMutation,
  AddFormVisitDataMutationVariables,
  GetFormTemplateQuery,
  GetFormTemplateQueryVariables,
  UpdateFormVisitDataByPkMutation,
  UpdateFormVisitDataByPkMutationVariables,
} from "../../../../gql/graphql";

const SecondConsultationForm = () => {
  const params = useParams();
  const user = useAuth();
  const [fdid, setFdid] = useState<any>(null);
  const toast = useCustomToast();
  const [InsertFormData] = useMutation<
    AddFormVisitDataMutation,
    AddFormVisitDataMutationVariables
  >(ADD_FORM_DATA);
  const [updateFormDataByPk] = useMutation<
    UpdateFormVisitDataByPkMutation,
    UpdateFormVisitDataByPkMutationVariables
  >(UPDATE_FORM_DATA_BY_PK);
  const [formState, setFormState] = useState<any>({});
  const navigate = useNavigate();
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const [getFormTemplate, formTemplateData] = useLazyQuery<
    GetFormTemplateQuery,
    GetFormTemplateQueryVariables
  >(GET_FORM_TEMPLATE, {
    fetchPolicy: "no-cache",
    variables: {
      descriptor: "SECOND_CONSULTATION_FORM",
      centerId: Number(process.env.REACT_APP_CENTER_ID),
    },
  });

  const [getPatientFormData, formData] = useLazyQuery(GET_PATIENT_FORM_DATA, {
    fetchPolicy: "no-cache",
    variables: {
      id: fdid,
    },
  });

  const initData = async () => {
    await getFormTemplate();

    if (params.fdid) {
      setFdid(params.fdid);
    }
  };

  useEffect(() => {
    if (fdid) {
      getPatientFormData();
    }
  }, [fdid]);

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    if (
      formTemplateData?.data?.form_schemas[0]?.template?.sections[0].forms[0]
        .items.length > 0
    ) {
      let data: any = {};

      for (let item of formTemplateData?.data?.form_schemas[0]?.template
        ?.sections[0].forms[0].items) {
        data[item.descriptor] = null;
      }

      setFormState(data);
    }
  }, [formTemplateData]);

  useEffect(() => {
    if (formData?.data?.form_data_by_pk?.data) {
      setFormState(formData.data.form_data_by_pk.data);
    }
  }, [formData]);

  const insertData = async () => {
    if (formTemplateData?.data?.form_schemas[0]?.id) {
      let result = await InsertFormData({
        variables: {
          patient_id: Number(params.pid),
          center_id: Number(process.env.REACT_APP_CENTER_ID),
          data: formState,
          form_id: formTemplateData?.data?.form_schemas[0]?.id,
          user_id: user.user_id,
        },
      });

      if (result.data?.insert_form_data_one?.id) {
        setFdid(result.data?.insert_form_data_one?.id);
        await getPatientFormData();
        toast.success("Data saved successfully");
        navigate(`${result.data?.insert_form_data_one?.id}`);
      } else {
        toast.error("There is a problem on saving data!");
      }
    }

    setSaveBtnLoading(false);
  };

  const updateData = async () => {
    let result = await updateFormDataByPk({
      variables: {
        id: fdid,
        object: {
          data: formState,
        },
      },
    });

    if (result.data?.update_form_data_by_pk?.id) {
      toast.success("Data saved successfully");
    } else {
      toast.error("There is a problem on saving data!");
    }
    setSaveBtnLoading(false);
  };

  const updateResult = (value: any, item: FormItem) => {
    let temp = JSON.parse(JSON.stringify(formState));
    temp[value.id] = value.value;
  };

  return (
    <Container w="full" maxW="container.xl" mt="35px">
      <HStack flexWrap="wrap">
        <Button
          as={ReactRouterLink}
          to={`/dashboard/patient/${params.pid}`}
          display="flex"
          bg="transparent"
          borderRadius="100px"
          h="48px"
          w="120px"
          border="1px solid"
          borderColor="gray.300"
        >
          <ChevronLeftIcon fontSize="xl"></ChevronLeftIcon>
          <Text fontSize="md" mx="10px">
            Back
          </Text>
        </Button>

        <Heading fontSize="30px" mx="15px">
          {
            formTemplateData?.data?.form_schemas[0]?.template?.sections[0]
              ?.title
          }
        </Heading>
      </HStack>

      {formTemplateData.called && formTemplateData.loading && (
        <VStack w="full" h="200px" justifyContent="center" alignItems="center">
          <Spinner></Spinner>
        </VStack>
      )}

      {formTemplateData?.data?.form_schemas[0]?.template && (
        <Box w="full" my="30px">
          <Card boxShadow="none" border="0" borderRadius="12px" my="30px">
            <CardBody p="45px">
              <Heading fontSize="24px" mb="30px">
                {
                  formTemplateData?.data?.form_schemas[0]?.template?.sections[0]
                    .forms[0].title
                }
              </Heading>

              {formTemplateData?.data?.form_schemas[0]?.template?.sections[0]
                .forms[0].items.length > 0 &&
                Object.keys(formState).length > 0 && (
                  <VStack spacing={4} align="stretch" w="full">
                    <FormElementsContainer
                      items={
                        formTemplateData?.data?.form_schemas[0]?.template
                          ?.sections[0].forms[0].items
                      }
                      inputItemHandler={(value: any, item: any) => {
                        let temp = JSON.parse(JSON.stringify(formState));

                        if (typeof value === "object") {
                          temp[value.id] = value.value;
                        } else {
                          temp[item.descriptor] = value;
                        }

                        setFormState(temp);
                      }}
                      state={formState}
                      divider={<Divider mt="25px" mb="15px" />}
                    ></FormElementsContainer>
                  </VStack>
                )}
            </CardBody>
          </Card>

          <Card boxShadow="none" border="0" borderRadius="12px" my="30px">
            <CardBody px="45px" py="25px">
              <HStack gap="15px">
                <Button
                  minW={120}
                  variant="btnMain"
                  isDisabled={saveBtnLoading}
                  onClick={() => {
                    setSaveBtnLoading(true);
                    if (fdid) {
                      updateData();
                    } else {
                      insertData();
                    }
                  }}
                >
                  {saveBtnLoading && <Spinner mx="5px"></Spinner>}
                  Save
                </Button>
              </HStack>
            </CardBody>
          </Card>
        </Box>
      )}
    </Container>
  );
};

export default SecondConsultationForm;
