import {
  Box,
  Card,
  CardBody,
  Divider,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { FiSearch } from "react-icons/fi";
import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_PATIENTS } from "./queries";
import { Link as ReactRouterLink } from "react-router-dom";
import { Link as ChakraLink } from "@chakra-ui/react";
import {
  SearchPatientByNameByCodeQuery,
  SearchPatientByNameByCodeQueryVariables,
} from "../../../gql/graphql";
const PatientSearchBox = () => {
  const [keyword, setKeyword] = useState<string>("");
  const [searchPatientByNameByCode, { error, loading, data, called }] =
    useLazyQuery<
      SearchPatientByNameByCodeQuery,
      SearchPatientByNameByCodeQueryVariables
    >(GET_PATIENTS, {
      variables: {
        keyword: "%" + keyword + "%",
        center_id: Number(process.env.REACT_APP_CENTER_ID),
      },
    });

  useEffect(() => {
    const getData = setTimeout(() => {
      if (keyword.length >= 3) {
        searchPatientByNameByCode();
      }
    }, 1000);

    return () => clearTimeout(getData);
  }, [keyword]);

  return (
    <>
      {keyword.length > 0 && (
        <Box
          position="fixed"
          backdropFilter="auto"
          backdropBlur="3px"
          top="0"
          left="0"
          right="0"
          bottom="0"
          zIndex="500"
        ></Box>
      )}
      <Heading fontSize="20px" mb="22px" zIndex="501" position="relative">
        Search your patients by name or code!
      </Heading>

      <Box zIndex="501" position="relative">
        <InputGroup h="64px">
          <InputLeftElement h="full" w="50px" justifyContent="end">
            <Icon as={FiSearch} fontSize="24px" color="gray.500"></Icon>
          </InputLeftElement>
          <Input
            key="patient_list_keyword"
            paddingStart="60px"
            paddingEnd="60px"
            placeholder=""
            borderRadius="12px"
            bg="white"
            h="full"
            value={keyword}
            onChange={(e) => {
              setKeyword(e.target.value);
            }}
          />
          <InputRightElement
            h="full"
            w="60px"
            justifyContent="center"
            cursor="pointer"
            onClick={() => {
              setKeyword("");
            }}
          >
            {keyword.length > 0 && <CloseIcon></CloseIcon>}
          </InputRightElement>
        </InputGroup>
        {keyword.length >= 3 && data && data?.patients && (
          <Card position="absolute" left="0" right="0" top="70px">
            <CardBody py="0">
              {data.patients.map((patient, index) => {
                return (
                  <Box key={index}>
                    <ChakraLink
                      py="5px"
                      minW="33%"
                      as={ReactRouterLink}
                      to={"/dashboard/patient/" + patient.id}
                      fontWeight="normal"
                      fontSize="sm"
                    >
                      <Text
                        fontFamily="Open Sans"
                        fontWeight="500"
                        fontSize="16px"
                        py="15px"
                      >
                        <Text as="span" px="5px">
                          {patient.first_name +
                            " " +
                            patient.last_name}
                        </Text>
                        -
                        <Text as="span" px="5px">
                          {patient.customer_code}
                        </Text>
                      </Text>
                    </ChakraLink>
                    <Divider></Divider>
                  </Box>
                );
              })}

              {data.patients.length === 0 && (
                <Text
                  color="gray.500"
                  p="30px"
                  fontFamily="Open Sans"
                  fontWeight="500"
                  fontSize="16px"
                  textAlign="center"
                >
                  No data found
                </Text>
              )}
            </CardBody>
          </Card>
        )}
      </Box>
    </>
  );
};

export default PatientSearchBox;
