import ModalBox from "../../../../../components/ModalBox";
import { FiFilePlus } from "react-icons/fi";
import {
  Box,
  Button,
  Heading,
  HStack,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import useCustomToast from "../../../../../hooks/useCustomToast";
import TextAreaBox from "../../../../../components/FromsElements/TextAreaBox";
import { FormItem } from "../../../../../interfaces/Interfaces";
import { ADD_VISIT_NOTES, UPDATE_VISIT_NOTE } from "../mutations";
import { AddVisitNotesMutation, AddVisitNotesMutationVariables, UpdateVisitNoteByPkMutation, UpdateVisitNoteByPkMutationVariables } from "../../../../../gql/graphql";
import { SelectedNoteType } from "..";
interface AddNoteModalProps {
  isOpen: boolean;
  closeAction: any;
  addAction: any;
  defaultState: SelectedNoteType
}

export const AddNoteModal = (props: AddNoteModalProps) => {

  let params = useParams<{ visitId: string; id: string }>();
  const [addVisitNotes] = useMutation<AddVisitNotesMutation, AddVisitNotesMutationVariables>(
    ADD_VISIT_NOTES
  );
  const [updateVisitNoteByPk] = useMutation<UpdateVisitNoteByPkMutation, UpdateVisitNoteByPkMutationVariables>(
    UPDATE_VISIT_NOTE
  );

  const toast = useCustomToast();
  const [stateData, setStateData] = useState<any>(props.defaultState);
  const [loading, setLoading] = useState(false);

  const addNoteModalCancelButton = () => {
    return (
      <Button
        variant="btnNoraml"
        border="1px solid"
        borderColor="gray.400"
        w="full"
        borderRadius="100px"
        h="48px"
        mb="30px"
        ms="45px"
        onClick={() => {
          setStateData({ text: "", id: null });

          props.closeAction();
        }}
      >
        Cancel
      </Button>
    );
  };

  const addNoteModalActionButton = () => {
    return (
      <Button
        variant="btnMain"
        border="1px solid"
        borderColor="gray.400"
        w="full"
        borderRadius="100px"
        h="48px"
        mb="30px"
        me="45px"
        isDisabled={loading || !stateData?.text?.length}
        onClick={async () => {
          setLoading(true);
          let hasError = false;
          if (stateData.id) {
            let result = await updateVisitNoteByPk({
              variables: {
                id: stateData.id,
                object: {
                  note: stateData.text,
                },
              },
            });
            hasError =
              !result?.data?.update_visit_notes_by_pk?.id;
          } else {
            let result = await addVisitNotes({
              variables: {
                objects: [
                  {
                    note: stateData.text,
                    visit_id: params.visitId
                  },
                ],
              },
            });

            hasError =
              !result?.data?.insert_visit_notes?.affected_rows;
          }

          setLoading(false);
          if (!hasError) {
            toast.success("Note has been successfully saved.");
            setStateData({ text: "", id: null });
            props.addAction();
          }
        }}
      >
        Save
      </Button>
    );
  };

  return (
    <ModalBox
      isOpen={props.isOpen}
      onClose={() => {
        setStateData({ text: "", id: null });
        props.closeAction();
      }}
      size="xl"
      icon={FiFilePlus}
      iconsize="26px"
    >
      <ModalBody pb={6}>
        <Box>
          <Heading size="md" textAlign="center">
            Add new Note
          </Heading>

          <TextAreaBox
            state={stateData}
            item={{
              descriptor: "text",
              title: "Note",
              values: {},
              options: {
                props: {
                  h: "250px",
                },
              },
            }}
            inputItemHandler={(value: string, item: FormItem) => {
              setStateData({ ...stateData, text: value });
            }}
          ></TextAreaBox>
        </Box>
      </ModalBody>
      <ModalFooter>
        <HStack
          w="full"
          align="center"
          spacing={10}
          marginStart={10}
          marginEnd={10}
          justifyContent={"space-between"}
        >
          {addNoteModalCancelButton()}
          {addNoteModalActionButton()}
        </HStack>
      </ModalFooter>
    </ModalBox>
  );
};
