import {Box, Button, Checkbox, FormControl, FormLabel, HStack, Input, Radio, Text, VStack} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import Item from "./Item";
import FieldModal from "../../modals/FieldModal";

const OptionBoxField = (props:any) => {
    const {
        item,
    } = props;

    const [values, setValues] = useState<any>(null);

    useEffect(() => {
        setValues(item.values)
    }, [item]);

    return (
        <FormControl w='full'>
            <FormLabel>
                {item.required && (
                    <Text as="span" color="red.500" fontSize="14px" me="5px">
                        *
                    </Text>
                )}
                <Box fontSize="16px" dangerouslySetInnerHTML={{__html: item.title}}></Box>
            </FormLabel>

            <VStack w='full'>
                {values && Object.keys(values).map((option:any) => {
                    return (
                        <VStack w='full'  borderBottom='1px solid #ccc' p="15px">
                            <Radio

                            >
                                {values[option].text && values[option].text.length > 0 &&
                                    <Box fontSize="16px" dangerouslySetInnerHTML={{__html: values[option].text}}></Box>
                                }

                                {values[option].name && values[option].name.length > 0 &&
                                    <Box fontSize="16px">{values[option].name}</Box>
                                }

                                {values[option].title && values[option].title.length > 0 &&
                                    <Box fontSize="16px">{values[option].title}</Box>
                                }
                            </Radio>
                        </VStack>
                    );

                })}
            </VStack>
        </FormControl>
    );
}

export default OptionBoxField;