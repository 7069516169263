import {
  Box,
  Button,
  HStack,
  Heading,
  ModalBody,
  ModalFooter,
  Spinner,
} from "@chakra-ui/react";
import ModalBox from "../../../../components/ModalBox";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import TextAreaBox from "../../../../components/FormsElementsWithHook/TextAreaBox";

type PropTypes = {
  isOpen: boolean;
  onClose: () => void;
  defaultValues?: FormType;
  onSave: (dt: FormType) => Promise<void>;
};

type FormType = {
  id?: number | null;
  note: string;
};

export const NoteModal = (props: PropTypes) => {
  const { defaultValues, isOpen, onSave, onClose } = props;

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const getDefaultValues = (): FormType => ({
    note: "",
  });

  const form = useForm<FormType>({
    defaultValues: defaultValues || getDefaultValues(),
  });

  const { reset, handleSubmit } = form;

  useEffect(() => {
    if (!isOpen) {
      reset(getDefaultValues());
      setIsSaving(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (defaultValues?.id) {
      reset(defaultValues);
      setIsSaving(false);
    }
  }, [defaultValues]);

  const onSubmit: SubmitHandler<FormType> = async (data) => {
    setIsSaving(true);
    await onSave(data);
    setIsSaving(false);
  };

  return (
    <FormProvider {...form}>
      <ModalBox
        isOpen={props.isOpen}
        onClose={props.onClose}
        size="lg"
        iconsize="24px"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody pb={6}>
            <Box w="full">
              <Heading size="md" textAlign="center">
                Add Note
              </Heading>

              <TextAreaBox
                item={{
                  descriptor: "note",
                  title: "Note",
                  placeholder: "Write your note",
                  values: {},
                  options: {
                    max_character: 1500,
                    props: {
                      minH: "150px",
                    },
                  },
                }}
                validator={{ required: true }}
              />
            </Box>
          </ModalBody>
          <ModalFooter>
            <HStack
              w="full"
              align="center"
              spacing={10}
              marginStart={10}
              marginEnd={10}
              justifyContent={"space-between"}
            >
              <Button
                variant="btnNoraml"
                border="1px solid"
                borderColor="gray.400"
                w="full"
                borderRadius="100px"
                h="48px"
                mb="30px"
                onClick={onClose}
              >
                Cancel
              </Button>

              <Button
                variant="btnMain"
                border="1px solid"
                borderColor="gray.400"
                w="full"
                isDisabled={isSaving}
                borderRadius="100px"
                h="48px"
                mb="30px"
                type="submit"
              >
                Save Note
                {isSaving && (
                  <>
                    <Spinner />
                  </>
                )}
              </Button>
            </HStack>
          </ModalFooter>
        </form>
      </ModalBox>
    </FormProvider>
  );
};
