import {Box, Checkbox, FormControl, FormLabel, HStack, Input, Text, VStack} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import Item from "./Item";

const ActionCheckBoxField = (props:any) => {
    const {
        item,
        preDelete,
        preEdit,
        currentSectionIndex,
        indexH,
        updateData,
        sections,
        swapItems
    } = props;

    const [expansion, setExpansion] = useState<any>(null);

    useEffect(() => {

        let expansions:any = [];

        if ((!!item.values.checked) && (item.values.checked.constructor === Object)) {
            expansions.push({...item.values.checked, text: 'checked'});
        }

        if ((!!item.values.unchecked) && (item.values.unchecked.constructor === Object)) {
            expansions.push({...item.values.unchecked, text: 'unchecked'});
        }

        setExpansion(expansions);
    }, [item]);

    return (
        <FormControl w='full'>
            <FormLabel>
                {item.required && (
                <Text as="span" color="red.500" fontSize="14px" me="5px">
                    *
                </Text>
                )}
                <Box fontSize="16px" dangerouslySetInnerHTML={{__html: item.title}}></Box>
            </FormLabel>
            <Checkbox
                isChecked={item.default_value.toString() === 'true'}
            >
                {item.text && item.text.length > 0 &&
                    <Box fontSize="16px" dangerouslySetInnerHTML={{__html: item.text}}></Box>
                }
            </Checkbox>

            <VStack w='full'>
                {expansion && expansion.map((option:any) => {
                    if (option.forms) {
                        return (
                            <></>
                        )
                    }

                    if (option.items) {
                        return (
                            <VStack w='full' pt='15px' mt='15px' borderTop='1px solid #ccc' alignItems="start">
                                <Text>If {option.text} :</Text>

                                {option.items.map((tItem:any, key:number) => {
                                    return (
                                        <Item swapItems={swapItems} updateData={updateData} indexH={indexH + 'values|' + option.text + '|items|' + key + '|'} form={option} isExtendable={true} sections={sections} currentSectionIndex={currentSectionIndex} preDelete={preDelete} preEdit={preEdit} item={tItem}></Item>
                                    );
                                }) }
                            </VStack>


                        )
                    }
                })}
            </VStack>
        </FormControl>
    );
}

export default ActionCheckBoxField;