import { gql } from "@apollo/client";

export const GET_VISIT_DATA = gql`
  query getVisitData($id: uuid!) {
    visits_by_pk(id: $id) {
      id
      patient_id
      date
      start_time
      end_time
      created_at
      updated_at
      duration
      user_id
      visit_number
      patient {
        id
        first_name
        last_name
        skin_type
        customer_code
      }
      visit_notes(order_by: { created_at: asc }) {
        created_at
        id
        note
      }
      visit_prescriptions(order_by: { created_at: asc }) {
        created_at
        id
        text
        treatment_prescription_id
      }
      form_data {
        id
        data
        created_at
        updated_at
        treatment_id
        user_id
        version
        form_id
        form {
          version
          descriptor
          center_id
          template
        }
        treatment {
          id
          title
          description
          prescriptions {
            id
            title
            body
          }
        }
        center_id
      }
    }
  }
`;

export const GET_TREATMENT = gql`
  query getTreatment($id: Int!) {
    treatments_by_pk(id: $id) {
      id
      center_id
      description
      data
      title
      created_at
      updated_at
      image
      form_id
      descriptor
      category_id
    }
  }
`;

export const GET_VISIT_LAST_NUMBER_BY_PATIENT_ID = gql`
  query GetVisitLastNumberByPatientId($patient_id: Int!) {
    visits_aggregate(
      where: {
        patient_id: { _eq: $patient_id }
        visit_number: { _is_null: false }
      }
    ) {
      aggregate {
        max {
          visit_number
        }
      }
    }
  }
`;
