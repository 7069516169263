import { useEffect, useState } from "react";
import { FormItemProps } from "../../../interfaces/Interfaces";
import {
  Text,
  FormControl,
  FormLabel,
  Textarea,
  HStack,
  Box,
} from "@chakra-ui/react";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { useFormContext } from "react-hook-form";
import { get } from "lodash";

const TextAreaBox = (props: FormItemProps) => {
  let item = props.item;
  let validator = props.validator;

  const {
    formState: { errors },
    register,
    watch,
    setValue,
  } = useFormContext();

  const error = get(errors, item.descriptor);

  let recommendationData = props.recommendationData;
  const [recommendedValue, setrecommendedValue] = useState<any>(null);

  useEffect(() => {
    if (item && item?.getRecommendedValue) {
      let func = new Function("recommendations,data", item.getRecommendedValue);
      setrecommendedValue(func(item.recommendations, recommendationData));
    }
  }, [item]);

  const characterCount = watch(item.descriptor)?.length;

  return (
    <FormControl isInvalid={!!error} position="relative">
      {item.title.length > 0 && (
        <FormLabel flexDirection="column">
          {item.required && (
            <Text as="span" color="red.500" fontSize="14px" me="5px">
              *
            </Text>
          )}
          <Text as="span">{item.title}</Text>
        </FormLabel>
      )}
      <Box w="full" position="relative">
        <Textarea
          placeholder={
            item.placeholder && item.placeholder.length > 0
              ? item.placeholder
              : ""
          }
          {...item?.options?.props}
          fontSize="14px"
          fontWeight="400"
          maxLength={
            item?.options?.max_character ? item?.options?.max_character : -1
          }
          key={item.descriptor}
          {...register(item.descriptor, validator)}
        ></Textarea>
        {item?.options?.max_character && (
          <Text
            color="gray.500"
            fontSize="12px"
            position="absolute"
            right="15px"
            bottom="1px"
          >
            {characterCount} / {item?.options?.max_character}
          </Text>
        )}
      </Box>
      {item.recommendations && recommendedValue !== null && (
        <HStack my="10px">
          <InfoOutlineIcon color="#757575"></InfoOutlineIcon>
          <Text flex="1" fontSize="14px" fontWeight="400" color="#757575">
            Recommended:{" "}
            <Text color="#2E2E2E" as="span">
              {recommendedValue}
            </Text>
          </Text>
          <Text
            fontSize="14px"
            fontWeight="400"
            color="#0065FF"
            cursor="pointer"
            onClick={() => {
              setValue(item.descriptor, item);
            }}
          >
            Apply Recommended
          </Text>
        </HStack>
      )}
    </FormControl>
  );
};

export default TextAreaBox;
