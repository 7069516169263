import ModalBox from "../../../components/ModalBox";
import SelectBox from "../../../components/FromsElements/SelectBox";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import useCustomToast from "../../../hooks/useCustomToast";
import { FormItem } from "../../../interfaces/Interfaces";
import Uploader from "../../../components/Uploader";
import axiosInstance from "../../../helpers/axiosInstance";
import { cleanFolderName } from "../../../helpers/StringUtil";
import { ModalBody } from "@chakra-ui/react";
interface UploadModalProps {
  isOpen: boolean;
  closeAction: any;
  addAction: any;
  basePath: string;
  type: string;
  fileTypes?: string;
}
const UploadModal = (props: UploadModalProps) => {
  const [errors, setErrors] = useState<Array<string>>([]);
  const [isLoading, setIsLoading] = useState(false);

  const toast = useCustomToast();

  const [loading, setLoading] = useState(false);

  return (
    <ModalBox
      isOpen={props.isOpen}
      onClose={() => {
        props.closeAction();
      }}
      size="xl"
    >
      <ModalBody pb={6}>
        <Uploader
          accept={props.fileTypes ?? ""}
          isLoading={isLoading}
          errorMessages={errors}
          cancelAction={() => {
            props.closeAction();
          }}
          uploadAction={async (files: any) => {
            setErrors([]);
            if (files.length) {
              setIsLoading(true);
              let formData = new FormData();
              for (let i = 0; i < files.length; i++) {
                formData.append("files[]", files[i]);
              }

              formData.append("basePath", props.basePath);
              formData.append("type", props.type);

              try {
                let result = await axiosInstance().post(
                  "/storage/upload",
                  formData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                      "Authorization": 'Bearer ' + localStorage.getItem('access_token')
                    },
                  }
                );

                if (result.data.status === 0) {
                  toast.success("Your file(s) has been uploaded.");

                  props.closeAction();
                  props.addAction(result.data);
                } else {
                  toast.error(result.data.message);
                }
              } catch (err: any) {
                let response = err.response;
                if (response?.status === 422) {
                  let errs: Array<string> = [];
                  Object.keys(response.data?.data?.errors).map((index) => {
                    let cerr = response.data.data.errors[index];
                    Object.keys(cerr).map((cindex) => {
                      errs.push(cerr[cindex]);
                    });
                  });

                  setErrors(errs);
                } else {
                  toast.error(
                    "There is an error on uploading files.Please contact support."
                  );
                }
              }
              setIsLoading(false);
            } else {
              toast.error("Please select at least one file.");
            }
          }}
        ></Uploader>
      </ModalBody>
    </ModalBox>
  );
};

export default UploadModal;
