import * as React from "react"
import {
    Box, Heading, Flex, Image, Text, Grid, GridItem
} from "@chakra-ui/react"
import {Link as ReactRouterLink, useParams} from 'react-router-dom'
import { Link as ChakraLink, LinkProps, Spinner } from '@chakra-ui/react'
import tempIcon from "../../assets/images/doctor.png";
import {PageHeader} from "../PageHeader";
import {PageFooter} from "../PageFooter";

interface MainTemplateProps{

};

export const MainTemplate = (props: any) => {

    const is_loading = props.is_loading ?? false;

    return (
        <Grid minH="100vh" maxW={'100vw'}
              templateAreas={`"header"
                  "main"
                  "footer"`}
              gridTemplateRows={'72px 1fr 15px'}
              gridTemplateColumns={'1fr'}
              h='full'
              gap='1'
              fontWeight='bold'
              bg="#fafafa"
        >
            <GridItem area={'header'} maxW={'100vw'}>
                <PageHeader></PageHeader>
            </GridItem>
            <GridItem area={'main'} position="relative" maxW={'100vw'}>
                { is_loading &&
                    <Flex position="absolute" top="0" left="0" bottom="0" right="0" w="full" h="full"
                        justifyContent="center" alignItems="center">
                        <Spinner />
                    </Flex>
                }
                {props.children}
            </GridItem>
            <GridItem area={'footer'} maxW={'100vw'}>
                <PageFooter></PageFooter>
            </GridItem>
        </Grid>
    );

}