import {Box, Button, FormControl, FormLabel, HStack, Input, Text} from "@chakra-ui/react";

const TextField = (props:any) => {

    const {
        item
    } = props;
    return (
        <FormControl w='full'>
            <FormLabel>
                {item.required && (
                    <Text as="span" color="red.500" fontSize="14px" me="5px">
                        *
                    </Text>
                )}
                <Box fontSize="16px" dangerouslySetInnerHTML={{__html: item.title}}></Box>
            </FormLabel>
            <Input
                bg='white'
                type='text'
                placeholder={item.placeholder}
            />
        </FormControl>
    );
}

export default TextField;