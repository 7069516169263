import React, {useEffect} from 'react';
import {FormItemProps} from "../../../interfaces/Interfaces";
import {Text, FormControl, FormLabel, RadioGroup, Stack, Radio, VStack, Box} from "@chakra-ui/react";
import {useDispatch} from "react-redux";
import {nextStep} from "../../../store/slice";
const OptionBox = (props: FormItemProps) => {
    const dispatch = useDispatch()
    let item = props.item;
    let inputItemHandler = props.inputItemHandler;
    let state = props.state;
    let validator = props.validator;
    let IsInvalidMessage = (validator && item.validation_rule)? validator.message(item.title, state[item.descriptor], item.validation_rule) : null;

    const handleClick = (activeItem:any, key: string) => {
        if (inputItemHandler) {
            inputItemHandler({
                "key" : key,
                "id" : item.descriptor,
                "value" : activeItem.value,
                "item" : item
            }, item);
        }

        if (activeItem.action === 'nextStep') {
            dispatch(nextStep())
        }
    }

    return (
        <FormControl>
            {item.title.length > 0 &&
                <FormLabel flexDirection="column">
                    {item.required &&
                        <Text as="span" color="red.500" fontSize="14px" me="5px">*</Text>
                    }
                    <Box as="span" fontWeight="700" dangerouslySetInnerHTML={{__html: item.title}}></Box>
                </FormLabel>
            }

            <RadioGroup value={state[item.descriptor] ? state[item.descriptor] : ''}>
                <Stack>
                    {item.values &&
                        Object.keys(item.values).map((key:string) => {
                            return (
                                <Radio w="full" key={key} value={key}
                                    onChange={() => {
                                        if (item.values) {
                                            handleClick(item.values[key], key)
                                        }
                                    }}
                                    isInvalid={IsInvalidMessage ? IsInvalidMessage : false}>
                                    <Text fontWeight="500">{item.values ? item.values[key].title : ''}</Text>
                                </Radio>
                            )
                        })
                    }
                </Stack>
            </RadioGroup>
            {!item?.hide_validation_error && IsInvalidMessage}
        </FormControl>
    );
}

export default OptionBox;
