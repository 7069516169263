import { configureStore } from '@reduxjs/toolkit'
import consultationReducer from './slice'
import mobileAuthenticatorReducer from '../components/MobileAuthorizer/slice'
import sidebarMenuReducer from '../components/AdminTemplate/Sidebar/store/slice'
export const store = configureStore({
    reducer: {
        consultation: consultationReducer,
        mobileAuthenticator: mobileAuthenticatorReducer,
        sidebarMenu: sidebarMenuReducer
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch