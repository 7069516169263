import { useState } from "react";
export function useDisclosureWithData<TData = any>() : {
    isOpen: boolean,
    onOpen: (d: TData) => void;
    onClose: () => void;
    data?: TData
} {
  const [isOpen, setIsOpen] = useState<{ o: boolean; d?: TData }>({ o: false });

  const onOpen = (d: TData) => {
    setIsOpen({ o: true, d });
  };

  const onClose = () => {
    setIsOpen({ o: false });
  };

  return { isOpen: isOpen.o, onOpen, onClose, data: isOpen.d };
}
