import { gql } from "@apollo/client";

export const GET_PATIENTS_WITH_CONSULTATION_FORM = gql`
  query GetPatientsWithConsultationFormList(
    $where: form_data_bool_exp!
    $limit: Int!
    $offset: Int!
  ) {
    form_data(
      limit: $limit
      offset: $offset
      order_by: { created_at: desc }
      where: $where
    ) {
      id
      created_at
      patient {
        address
        birthday
        contact_method
        customer_code
        email
        email_verified_at
        emergency_contact
        first_name
        id
        last_name
        mobile
        mobile_verified_at
        occupation
        skin_type
      }
    }

    form_data_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;
