import {Box, FormControl, FormLabel, Select, Text, Textarea} from "@chakra-ui/react";
import {useEffect} from "react";

const SelectField = (props:any) => {
    const {
        item
    } = props;

    return (
        <FormControl w='full'>
            <FormLabel>
                {item.required && (
                    <Text as="span" color="red.500" fontSize="14px" me="5px">
                        *
                    </Text>
                )}
                <Box fontSize="16px" dangerouslySetInnerHTML={{__html: item.title}}></Box>
            </FormLabel>
            <Select
                bg='white'
                placeholder={item.placeholder}
            >
                {(!!item.values) && (item.values.constructor === Array) && item.values.map((value:any, key:any) => <option>{value}</option>)}
                {(!!item.values) && (item.values.constructor === Object) && Object.keys(item.values).map((key:any) => <option>{item.values[key]?.value}</option>)}
            </Select>
        </FormControl>
    );
}

export default SelectField;