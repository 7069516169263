import {Heading, HStack, Icon, Image, Text, VStack} from "@chakra-ui/react";
import * as React from "react";
import logo from "../../../assets/images/logo.svg";
import SidebarMenuItem, {MenuItemInterface} from "./SidebarMenuItem";
import { MdDashboard } from "react-icons/md";
import { AiOutlineFolderOpen, AiOutlineApartment } from "react-icons/ai";
import treatmentIcon from "./icons/treatments-icon.svg"
import patientIcon from "./icons/patients-icon.svg"
import doctorIcon from "./icons/doctors-icon.svg"
import { FaWpforms } from "react-icons/fa";
import {useEffect, useState} from "react";
import {AddIcon} from "@chakra-ui/icons";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store/Store";
import {setSelectedMenu, toggleSidebar} from "./store/slice";
import {useLocation} from "react-router-dom";

interface SidebarProps {
    is_open: boolean,
}
const Sidebar = () => {

    const menuDefault: Array<MenuItemInterface> = [
        {
            id: '1',
            descriptor: 'DASHBOARD',
            title: 'Dashboard',
            route: '/admin/dashboard',
            icon: <Icon fontSize="24px" as={MdDashboard}></Icon>
        },
        {
            id: '2',
            descriptor: 'TREATMENTS',
            title: 'Treatments',
            route: '/admin/treatments',
            icon: <Image h="24px" src={treatmentIcon} />
        },
        {
            id: '3',
            descriptor: 'TREATMENT_CATEGORIES',
            title: 'Treatment Categories',
            route: '/admin/treatment_categories',
            icon: <Icon fontSize="24px" as={AiOutlineFolderOpen} />
        },
        {
            id: '4',
            descriptor: 'PATIENTS',
            title: 'Patients',
            route: '/admin/patients',
            icon: <Image h="24px" src={patientIcon} />
        },
        {
            id: '5',
            descriptor: 'DOCTORS',
            title: 'Doctors',
            route: '/admin/users',
            icon: <Image h="24px" src={doctorIcon} />
        },
        {
            id: '6',
            descriptor: 'FORM_SCHEMAS',
            title: 'Form Schemas',
            route: '/admin/form_schemas',
            icon: <Icon fontSize={"24px"} as={AiOutlineApartment} />
        },
        // {
        //     id: '7',
        //     descriptor: 'FORM_GENERATOR',
        //     title: 'Form Generator',
        //     route: '/admin/form_generator',
        //     icon: <Icon fontSize={"24px"} as={FaWpforms} />
        // }

    ];
    const sidebarMenuState = useSelector((state: RootState) => state.sidebarMenu)
    const dispatch = useDispatch();
    const [menu, setMenu] = useState(menuDefault);

    const location = useLocation();

    useEffect(() => {
        if (location?.pathname && menu) {
            menu.map((item) => {
                if (item.route && location.pathname.includes(item.route)) {
                    dispatch(setSelectedMenu(item.descriptor));
                }
            });
        }
    }, [location, menu]);

    return (
        <VStack className="sidebar" zIndex="1" overflowY="auto" w="312px"
            borderEnd="1px solid"
            borderColor="#E6E6E6"
            bg="white"
            position={{
                base: "fixed",
                lg: "static",
            }}
            left={sidebarMenuState.sidebarIsOpen ? '0' : '-312px'}
            top={{
                base: "64px",
                lg: "0",
            }}
            bottom="0"
            h={{
                base: "auto",
                lg: "full",
            }}
        >
            <VStack w="full" bg="white" gap="10px" py="30px" px="15px">
                <Image width="72px" src={logo}></Image>
                <Heading fontSize="20px">Trinity Skin Clinic</Heading>
                <Text fontSize="13px" color="gray.600">Admin Panel</Text>
            </VStack>
            <VStack p="15px" px="30px" w="full">
                {menu.map((item: MenuItemInterface) =>
                    <SidebarMenuItem
                        onChange={(item:MenuItemInterface) => {
                            dispatch(setSelectedMenu(item.descriptor));
                            dispatch(toggleSidebar());
                        }}
                        isOpen={sidebarMenuState.currentMenu === item.descriptor}
                        key={item.id} isRoot={true} item={item}
                    ></SidebarMenuItem>
                )}
            </VStack>
        </VStack>
    );
}

export default Sidebar;