import { FormItemProps } from "../../../interfaces/Interfaces";
import {
  Text,
  FormControl,
  FormLabel,
  Input,
  InputProps,
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";
import { Controller, useFormContext } from "react-hook-form";
import { get } from "lodash";

interface DatePickerBoxInterFace extends FormItemProps, InputProps {}

const DatePickerBox = (props: DatePickerBoxInterFace) => {
  let item = props.item;
  let validator = props.validator;

  const {
    watch,
    formState: { errors },
    control,
    register
  } = useFormContext();

  const error = get(errors, item.descriptor);

  return (
    <FormControl w={props.w ?? ""} isInvalid={!!error}>
      {item.title.length > 0 && (
        <FormLabel flexDirection="column">
          {item.required && (
            <Text as="span" color="red.500" fontSize="14px" me="5px">
              *
            </Text>
          )}
          <Text as="span">{item.title}</Text>
        </FormLabel>
      )}

      <Controller
        control={control}
        name={item.descriptor}
        render={({ field }) => (
          <DatePicker
            selected={watch(item.descriptor) || ""}
            {...item?.options?.props}
            key={item.descriptor}
            className={"full"}
            placeholderText={item.placeholder?.length ? item.placeholder : ""}
            customInput={<Input w="full" style={{ width: "100%" }} />}
            {...register(item.descriptor, validator)}
            onChange={(date) => {
              field.onChange(date);
            }}
          />
        )}
      />
    </FormControl>
  );
};

export default DatePickerBox;
