import { decodeToken } from 'react-jwt';
const useAuth = () => {

    let accessToken = localStorage.getItem('access_token');

    const tokenPayload = accessToken && decodeToken(accessToken as string);
    const payload = (tokenPayload as any);
    const claims = payload?.custom_claims;
    let userId:any = null;
    let roles:Array<any> = [];

    if (payload?.exp && payload?.exp * 1000 > Date.now()) {
        userId = claims?.['x-hasura-user-id'];
        roles = claims?.['x-hasura-allowed-roles'] || [];
    } else {
        localStorage.removeItem('access_token');
    }

    return {user_id: userId, roles: roles};
}

export default useAuth;