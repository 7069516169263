import {Box, Button, FormControl, FormLabel, HStack, Input, Text, Textarea} from "@chakra-ui/react";

const TextAreaField = (props:any) => {

    const {
        item
    } = props;
    return (
        <FormControl w='full'>
            <FormLabel>
                {item.required && (
                    <Text as="span" color="red.500" fontSize="14px" me="5px">
                        *
                    </Text>
                )}

                {item.text && item.text.length > 0 &&
                    <Box fontSize="16px" dangerouslySetInnerHTML={{__html: item.text}}></Box>
                }

                {item.name && item.name.length > 0 &&
                    <Box fontSize="16px" dangerouslySetInnerHTML={{__html: item.name}}></Box>
                }

                {item.title && item.title.length > 0 &&
                    <Box fontSize="16px" dangerouslySetInnerHTML={{__html: item.title}}></Box>
                }
            </FormLabel>
            <Textarea
                bg='white'
                placeholder={item.placeholder}
            />
        </FormControl>
    );
}

export default TextAreaField;