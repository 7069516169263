import { TableFieldInterface } from "../../../interfaces/Interfaces";

export const fields: Array<TableFieldInterface> = [
    {
      title: "#",
      descriptor: "",
      expression: "INDEX_NUMBER_FIELD",
    },
    {
      title: "Name",
      items: [
        {
          descriptor: "first_name",
        },
        {
          descriptor: "last_name",
        },
      ],
    },
    {
      title: "Customer Code",
      descriptor: "customer_code",
    },
    {
      title: "Email",
      descriptor: "email",
    },

    {
      title: "Mobile",
      descriptor: "mobile",
    },
    {
      title: "Action",
      descriptor: "",
      expression: "ACTION_FIELD",
    },
  ];