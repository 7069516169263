import {
  Box,
  Button,
  Card,
  CardBody,
  Heading,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
  VStack,
} from "@chakra-ui/react";
import {
  ArrowBackIcon,
  ArrowForwardIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CloseIcon,
  EditIcon,
  SmallAddIcon,
} from "@chakra-ui/icons";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { GET_TREATMENT_CATEGORIES } from "./queries";
import {
  Link as ReactRouterLink,
  useNavigate,
  useParams,
} from "react-router-dom";
import { TableFieldInterface } from "../../../interfaces/Interfaces";
import ListTable from "../../../components/FromsElements/ListTable";
import {
  GetTreatmentCategoriesListQuery,
  GetTreatmentCategoriesListQueryVariables,
  UpdateTreatmentCategoryMutation,
  UpdateTreatmentCategoryMutationVariables,
} from "../../../gql/graphql";
import useAuth from "../../../hooks/useAuth";
import { useDebounce } from "use-debounce";
import { FiSearch } from "react-icons/fi";
import { AiFillFileText, AiOutlineDelete } from "react-icons/ai";
import { useDisclosureWithData } from "../../../hooks/useDisclosureWithData";
import Confirmation from "../../../components/Confirmation";
import { UPDATE_TREATMENT_CATEGORY } from "./mutations";
import dayjs from "dayjs";

const TreatmentCategories = () => {
  const fields: Array<TableFieldInterface> = [
    {
      title: "#",
      descriptor: "",
      expression: "INDEX_NUMBER_FIELD",
    },

    {
      title: "Title",
      descriptor: "title",
    },

    {
      title: "Treatment Count",
      descriptor: "treatments",
      expression: "COUNT",
    },
    {
      title: "Action",
      descriptor: "",
      expression: "ACTION_FIELD",
    },
  ];

  const { user_id } = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const [page, setPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [lastPage, setLastPage] = useState(1);
  const [pages, setPages] = useState<Array<number>>([]);
  const [records, setRecords] = useState<Array<any>>([]);
  const perPage = 10;
  const [listIsLoading, setListIsLoading] = useState(true);

  const [keyword, setKeyword] = useState("");
  const [debouncedSearchInputValue] = useDebounce(keyword, 500);
  const sanitizedSearch = debouncedSearchInputValue?.trim()?.replace(/^#/, "");

  const { data, loading, refetch } = useQuery<
    GetTreatmentCategoriesListQuery,
    GetTreatmentCategoriesListQueryVariables
  >(GET_TREATMENT_CATEGORIES, {
    fetchPolicy: "no-cache",
    variables: {
      where: {
        _and: [
          { center_id: { _eq: Number(process.env.REACT_APP_CENTER_ID) } },
          { deleted_at: { _is_null: true } },
          {
            _or: [
              { title: { _ilike: "%" + sanitizedSearch + "%" } },
              { descriptor: { _ilike: "%" + sanitizedSearch + "%" } },
              { description: { _ilike: "%" + sanitizedSearch + "%" } },
            ],
          },
        ],
      },
      limit: perPage,
      offset: (page - 1) * perPage,
    },
    skip: !user_id,
  });

  const makePaginationUrl = (page: number) => {
    if (page < 1) {
      page = 1;
    } else if (page > lastPage) {
      page = lastPage;
    }
    let path = "/admin/treatment_categories/" + page;

    if (keyword) {
      path += "/" + keyword;
    }

    return path;
  };
  const getPagination = () => {
    let step = 2;
    let startFrom = 1;
    let endTo = lastPage;
    let hasLeft = page - step > 1;
    let hasRight = page + step < lastPage;

    if (hasRight) {
      endTo = page + step;
    }

    if (hasLeft) {
      startFrom = page - step;
    }

    let temp = [];
    for (let i = startFrom; i <= endTo; i++) {
      temp.push(i);
    }
    setPages(temp);
  };

  useEffect(() => {
    if (data?.treatment_categories_aggregate?.aggregate?.count) {
      setTotalRecord(data.treatment_categories_aggregate.aggregate.count);
    } else {
      setTotalRecord(0);
    }
  }, [data]);

  useEffect(() => {
    if (totalRecord) {
      let temp = totalRecord / perPage;
      let floored = Math.floor(totalRecord / perPage);

      if (temp == floored) {
        setLastPage(temp);
      } else {
        setLastPage(floored + 1);
      }
    } else {
      setLastPage(1);
    }
  }, [totalRecord]);

  useEffect(() => {
    getPagination();
  }, [lastPage, page]);

  useEffect(() => {
    if (params?.k && params.k.length >= 3) {
      setKeyword(params.k);
    }

    if (params?.p) {
      let p = parseInt(params.p);

      if (p >= 1 && p <= 100) {
        setPage(p);
      }
    }
  }, [params]);

  useEffect(() => {
    if (data?.treatment_categories?.length) {
      setRecords(data.treatment_categories);
    } else {
      setRecords([]);
    }

    setListIsLoading(false);
  }, [data]);

  const {
    isOpen: isDeleteConfirmationOpen,
    onOpen: onDeleteConfirmationOpen,
    onClose: onDeleteConfirmationClose,
    data: deleteConfirmationData,
  } = useDisclosureWithData<{ id: number }>();

  const [updateTreatment, { loading: isDeleting }] = useMutation<
    UpdateTreatmentCategoryMutation,
    UpdateTreatmentCategoryMutationVariables
  >(UPDATE_TREATMENT_CATEGORY);

  return (
    <>
      <VStack w="full" p="25px">
        {listIsLoading && (
          <HStack
            w="full"
            h="full"
            minH="200px"
            alignItems="center"
            justifyContent="center"
          >
            <Spinner></Spinner>
          </HStack>
        )}
        {!listIsLoading && (
          <>
            <HStack w="full" flexWrap="wrap">
              <Heading flex="1" fontSize="30px">
                Treatment Categories List
              </Heading>
              <Button
                as={ReactRouterLink}
                to="/admin/treatment_categories/add"
                variant="btnMain"
              >
                New Category
                <SmallAddIcon mx="10px" fontSize="20px"></SmallAddIcon>
              </Button>
            </HStack>

            <VStack w="full">
              <Card
                boxShadow="null"
                w="full"
                borderRadius="16px"
                border="1px solid"
                borderColor="gray.100"
                position="relative"
                mt="20px"
                flex="1"
              >
                <CardBody p="15px">
                  <Box mb="30px">
                    <InputGroup h="48px">
                      <InputLeftElement h="full" w="38px" justifyContent="end">
                        <Icon
                          as={FiSearch}
                          fontSize="24px"
                          color="gray.500"
                        ></Icon>
                      </InputLeftElement>
                      <Input
                        key="treatment_category_list_keyword"
                        paddingStart="50px"
                        paddingEnd="50px"
                        placeholder="Search ..."
                        borderRadius="12px"
                        bg="white"
                        h="full"
                        value={keyword}
                        onChange={(e) => {
                          setKeyword(e.target.value);
                        }}
                      />
                      <InputRightElement
                        h="full"
                        w="60px"
                        justifyContent="center"
                        cursor="pointer"
                        onClick={() => {
                          setKeyword("");
                        }}
                      >
                        {keyword.length > 0 && <CloseIcon></CloseIcon>}
                      </InputRightElement>
                    </InputGroup>
                  </Box>
                  <ListTable
                    data={records}
                    fields={fields}
                    page={page}
                    total={totalRecord}
                    loading={loading}
                    hasPagination={true}
                    perPage={perPage}
                    actionField={(id: any) => {
                      return (
                        <HStack>
                          <Button
                            title="Edit"
                            onClick={() => {
                              navigate(
                                "/admin/treatment_categories/edit/" + id
                              );
                            }}
                          >
                            <EditIcon></EditIcon>
                          </Button>
                          <Button
                            title="Delete"
                            onClick={() => {
                              onDeleteConfirmationOpen({ id });
                            }}
                          >
                            <Icon as={AiOutlineDelete}></Icon>
                          </Button>
                        </HStack>
                      );
                    }}
                  ></ListTable>
                  {records && lastPage > 1 && (
                    <HStack
                      mt="30px"
                      w="full"
                      justifyContent="center"
                      spacing="10px"
                    >
                      <Button
                        isDisabled={page === 1}
                        as={ReactRouterLink}
                        to={makePaginationUrl(1)}
                      >
                        <ArrowBackIcon fontSize="24px"></ArrowBackIcon>
                      </Button>
                      <Button
                        isDisabled={page === 1}
                        as={ReactRouterLink}
                        to={makePaginationUrl(page - 1)}
                      >
                        <ChevronLeftIcon fontSize="24px"></ChevronLeftIcon>
                      </Button>

                      {pages.map((i) => (
                        <Button
                          variant={page == i ? "btnMain" : ""}
                          as={ReactRouterLink}
                          to={makePaginationUrl(i)}
                        >
                          {i}
                        </Button>
                      ))}

                      <Button
                        isDisabled={page === lastPage}
                        as={ReactRouterLink}
                        to={makePaginationUrl(page + 1)}
                      >
                        <ChevronRightIcon fontSize="24px"></ChevronRightIcon>
                      </Button>
                      <Button
                        isDisabled={page === lastPage}
                        as={ReactRouterLink}
                        to={makePaginationUrl(lastPage)}
                      >
                        <ArrowForwardIcon fontSize="24px"></ArrowForwardIcon>
                      </Button>
                    </HStack>
                  )}
                </CardBody>
              </Card>
            </VStack>
          </>
        )}
      </VStack>
      <Confirmation
        isOpen={isDeleteConfirmationOpen}
        isLoading={isDeleting}
        closeAction={onDeleteConfirmationClose}
        acceptAction={async () => {
          if (deleteConfirmationData?.id) {
            await updateTreatment({
              variables: {
                id: deleteConfirmationData.id,
                object: {
                  deleted_at: new Date(),
                },
              },
            });

            refetch();
            onDeleteConfirmationClose();
          }
        }}
      >
        Confirm delete category?
      </Confirmation>
    </>
  );
};

export default TreatmentCategories;
