export function isNumeric(str: string): boolean {
    if(!str) return false;
    return /^-?\d+(\.\d+)?$/.test(str.replace(',', '.'));
}

export type OptionType = Record<string, { value: string } & any >;

export function sortValues(obj: OptionType): OptionType {
    const entries = Object.entries(obj);

    for (const [key, valueObj] of entries) {
        if (!isNumeric(valueObj.value)) {
            return obj;
        }
    }

    const sortedEntries = entries.sort(([keyA, objA], [keyB, objB]) => {
        const numA = parseFloat(objA.value.replace(',', '.'));
        const numB = parseFloat(objB.value.replace(',', '.'));
        return numA - numB;
    });

    return Object.fromEntries(sortedEntries);
}