import icon3gp from "./icons/3gp.png";
import icon7z from "./icons/7z.png";
import iconae from "./icons/ae.png";
import iconai from "./icons/ai.png";
import iconapk from "./icons/apk.png";
import iconasf from "./icons/asf.png";
import iconavi from "./icons/avi.png";
import iconbak from "./icons/bak.png";
import iconbmp from "./icons/bmp.png";
import iconcdr from "./icons/cdr.png";
import iconcss from "./icons/css.png";
import iconcsv from "./icons/csv.png";
import icondivx from "./icons/divx.png";
import icondll from "./icons/dll.png";
import icondoc from "./icons/doc.png";
import icondocx from "./icons/docx.png";
import icondw from "./icons/dw.png";
import icondwg from "./icons/dwg.png";
import iconeps from "./icons/eps.png";
import iconexe from "./icons/exe.png";
import iconflv from "./icons/flv.png";
import iconfw from "./icons/fw.png";
import icongif from "./icons/gif.png";
import icongz from "./icons/gz.png";
import iconhtml from "./icons/html.png";
import iconico from "./icons/ico.png";
import iconiso from "./icons/iso.png";
import iconjar from "./icons/jar.png";
import iconjpg from "./icons/jpg.png";
import iconjs from "./icons/js.png";
import iconmov from "./icons/mov.png";
import iconmp3 from "./icons/mp3.png";
import iconmp4 from "./icons/mp4.png";
import iconmpeg from "./icons/mpeg.png";
import iconpdf from "./icons/pdf.png";
import iconphp from "./icons/php.png";
import iconpng from "./icons/png.png";
import iconppt from "./icons/ppt.png";
import iconpptx from "./icons/pptx.png";
import iconps from "./icons/ps.png";
import iconpsd from "./icons/psd.png";
import iconrar from "./icons/rar.png";
import iconsvg from "./icons/svg.png";
import iconswf from "./icons/swf.png";
import iconsys from "./icons/sys.png";
import icontargz from "./icons/tar.gz.png";
import icontar from "./icons/tar.png";
import icontiff from "./icons/tiff.png";
import icontxt from "./icons/txt.png";
import iconwav from "./icons/wav.png";
import iconzip from "./icons/zip.png";
import iconxls from "./icons/xls.png";
import iconxlsx from "./icons/xlsx.png";
import none from "./icons/none.png";
import folder from "./icons/folder.png";
import {Image, ImageProps} from "@chakra-ui/react";

interface FileIconProps {
    ext: string,
    props: ImageProps
}

const FileIcon = (props: any) => {
    const map:any = {
        icon3gp : icon3gp,
        icon7z : icon7z,
        iconae : iconae,
        iconai : iconai,
        iconapk : iconapk,
        iconasf : iconasf,
        iconavi : iconavi,
        iconbak : iconbak,
        iconbmp : iconbmp,
        iconcdr : iconcdr,
        iconcss : iconcss,
        iconcsv : iconcsv,
        icondivx : icondivx,
        icondll : icondll,
        icondoc : icondoc,
        icondocx : icondocx,
        icondw : icondw,
        icondwg : icondwg,
        iconeps : iconeps,
        iconexe : iconexe,
        iconflv : iconflv,
        iconfw : iconfw,
        icongif : icongif,
        icongz : icongz,
        iconhtml : iconhtml,
        iconico : iconico,
        iconiso : iconiso,
        iconjar : iconjar,
        iconjpg : iconjpg,
        iconjs : iconjs,
        iconmov : iconmov,
        iconmp3 : iconmp3,
        iconmp4 : iconmp4,
        iconmpeg : iconmpeg,
        iconpdf : iconpdf,
        iconphp : iconphp,
        iconpng : iconpng,
        iconppt : iconppt,
        iconpptx : iconpptx,
        iconps : iconps,
        iconpsd : iconpsd,
        iconrar : iconrar,
        iconsvg : iconsvg,
        iconswf : iconswf,
        iconsys : iconsys,
        icontargz : icontargz,
        icontar : icontar,
        icontiff : icontiff,
        icontxt : icontxt,
        iconwav : iconwav,
        iconzip : iconzip,
        iconxls : iconxls,
        iconxlsx : iconxlsx,
        folder : folder,
        none : none,
    };

    let ext:any = props?.ext;

    if (ext === 'folder') {
        ext = folder;
    } else if (map['icon'+ext]) {
        ext = map['icon'+ext];
    } else {
        ext = none;
    }

    return (
        <Image {...props.props} src={ext}></Image>
    );
}

export default FileIcon;