import { TableFieldInterface } from "../../../interfaces/Interfaces";

export const fields: Array<TableFieldInterface> = [
  {
    title: "#",
    descriptor: "",
    expression: "INDEX_NUMBER_FIELD",
  },
  {
    title: "Name",
    descriptor: "title",
  },
  {
    title: "Descriptor",
    descriptor: "descriptor",
  },
  {
    title: "Version",
    descriptor: "version",
  },
  {
    title: "Updated At",
    descriptor: "updated_at",
    expression: "DATE_TIME_HR",
  },
  {
    title: "Action",
    descriptor: "",
    expression: "ACTION_FIELD",
  },
];
