import {
  Box,
  Button,
  Card,
  CardBody,
  Heading,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Link as ReactRouterLink, useParams } from "react-router-dom";
import { DeleteIcon, EditIcon, SmallAddIcon } from "@chakra-ui/icons";
import TextBox from "../../../components/FromsElements/TextBox";
import { useEffect, useState } from "react";
import { FormItem } from "../../../interfaces/Interfaces";
import TextAreaBox from "../../../components/FromsElements/TextAreaBox";
import { FiHardDrive } from "react-icons/fi";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  GET_FORMS,
  GET_TREATMENT_BY_ID,
  ADD_TREATMENT,
  UPDATE_TREATMENT,
  GET_TREATMENT_CATEGORIES_RELATION,
} from "./queries";
import SelectBox from "../../../components/FromsElements/SelectBox";
import FileManager from "../../../components/FileManager";
import SimpleReactValidator from "simple-react-validator";
import useCustomToast from "../../../hooks/useCustomToast";
import { slugify } from "../../../helpers/StringUtil";
import {
  AddTreatmentMutation,
  AddTreatmentMutationVariables,
  GetTreatmentByIdQuery,
  GetTreatmentByIdQueryVariables,
  GetTreatmentCategoriesRelationQuery,
  GetTreatmentCategoriesRelationQueryVariables,
  Treatments_Set_Input,
  UpdateTreatmentMutation,
  UpdateTreatmentMutationVariables,
} from "../../../gql/graphql";
import useAuth from "../../../hooks/useAuth";
import { RichTextBox } from "../../../components/FromsElements/RichText";

type FormFieldsType = {
  id: number | null;
  title: string;
  descriptor: string;
  description: string;
  form_id: string;
  session_required: string;
  session_duration: string;
  consent_form_url: string;
  tries: number;
  category_id: string;
};

const TreatmentForm = () => {
  const params = useParams();
  const { user_id } = useAuth();

  const [validator, setValidator] = useState<any>(
    new SimpleReactValidator({
      element: (message: string) => {
        return (
          <Text fontSize="sm" color="red.500">
            {message}
          </Text>
        );
      },
    })
  );

  const [recordIsLoading, setRecordIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const toast = useCustomToast();
  const defaultState: FormFieldsType = {
    id: null,
    title: "",
    descriptor: "",
    description: "",
    form_id: "",
    session_required: "",
    session_duration: "",
    consent_form_url: "",
    tries: 0,
    category_id: "",
  };
  let durationValues: any = {
    0: {
      title: "Select Duration",
      value: 0,
    },
  };

  let step = 15;
  for (let i = 1; i <= 20; i++) {
    durationValues[i * step] = {
      title:
        Math.floor((i * step) / 60)
          .toString()
          .padStart(2, "0") +
        ":" +
        (((i * step) / 60 - Math.floor((i * step) / 60)) * 60)
          .toString()
          .padStart(2, "0"),
      value: i * step,
    };
  }
  const [formFileManagerIsOpen, setFormFileManagerIsOpen] = useState(false);
  const [stateData, setStateData] = useState<FormFieldsType>(defaultState);
  const [forms, setForms] = useState<Array<any>>([]);
  const [categories, setCategories] = useState<Array<any>>([]);

  const [getFormsList, getFormsListResult] = useLazyQuery(GET_FORMS, {
    fetchPolicy: "no-cache",
    variables: {
      center_id: Number(process.env.REACT_APP_CENTER_ID),
    },
  });

  const [getTreatmentById, getTreatmentByIdResult] = useLazyQuery<
    GetTreatmentByIdQuery,
    GetTreatmentByIdQueryVariables
  >(GET_TREATMENT_BY_ID, {
    fetchPolicy: "no-cache",
    variables: {
      id: Number(params.id),
    },
  });

  const [addTreatment] = useMutation<
    AddTreatmentMutation,
    AddTreatmentMutationVariables
  >(ADD_TREATMENT);
  const [updateTreatment] = useMutation<
    UpdateTreatmentMutation,
    UpdateTreatmentMutationVariables
  >(UPDATE_TREATMENT);

  const saveRecord = async () => {
    setStateData({ ...stateData, tries: stateData.tries + 1 });
    if (validator.allValid()) {
      const record: Treatments_Set_Input = {
        title: stateData.title,
        descriptor: stateData.descriptor,
        description: stateData.description,
        center_id: Number(process.env.REACT_APP_CENTER_ID),
        image: "",
        form_id: Number(stateData.form_id),
        category_id: Number(stateData.category_id),
        data: {
          session_duration: stateData.session_duration,
          session_required: stateData.session_required,
          consent_form_url: stateData.consent_form_url,
        },
      };

      if (stateData.id) {
        record.id = stateData.id;
        if (!record.descriptor) {
          record.descriptor = slugify(record.title || "", "_");
        }

        let result = await updateTreatment({
          variables: {
            id: record.id,
            object: record,
          },
        });

        if (result?.data?.update_treatments_by_pk?.id) {
          toast.success("Record Saved Successfully.");
        } else {
          toast.error("There is an error on saving record data.");
        }
      } else {
        record.descriptor = slugify(record.title || "", "_");
        let result = await addTreatment({
          variables: {
            object: record,
          },
        });

        if (result?.data?.insert_treatments_one?.id) {
          setStateData({
            ...stateData,
            id: result.data.insert_treatments_one.id,
          });
          toast.success("Record Saved Successfully.");
        } else {
          toast.error("There is an error on saving record data.");
        }
      }
    } else {
      validator.showMessages();
    }
  };

  useEffect(() => {
    getFormsList();
  }, []);

  useEffect(() => {
    if (params.id) {
      getTreatmentById({
        variables: {
          id: Number(params.id),
        },
      });
    } else {
      setRecordIsLoading(false);
    }
  }, [params]);

  useEffect(() => {
    if (getTreatmentByIdResult?.data?.treatments_by_pk) {
      let record = getTreatmentByIdResult.data.treatments_by_pk;
      let temp: FormFieldsType = {
        id: record.id,
        title: record.title || "",
        descriptor: record.descriptor || "",
        description: record.description || "",
        form_id: (record.form_id || "").toString(),
        session_required: record.data.session_required,
        session_duration: record.data.session_duration,
        consent_form_url: record.data.consent_form_url,
        tries: 0,
        category_id: (record.category_id || "").toString(),
      };

      setStateData(temp);
      setRecordIsLoading(false);
    }
  }, [getTreatmentByIdResult]);

  useEffect(() => {
    if (getFormsListResult?.data?.form_schemas?.length) {
      let temp: any = {
        0: {
          title: "Select a form",
          value: "",
        },
      };

      for (let formIndex in getFormsListResult.data.form_schemas) {
        temp[getFormsListResult.data.form_schemas[formIndex].id] = {
          title: getFormsListResult.data.form_schemas[formIndex].title,
          value: getFormsListResult.data.form_schemas[formIndex].id,
        };
      }

      setForms(temp);
    }
  }, [getFormsListResult]);

  const { data: categoriesData } = useQuery<
    GetTreatmentCategoriesRelationQuery,
    GetTreatmentCategoriesRelationQueryVariables
  >(GET_TREATMENT_CATEGORIES_RELATION, {
    variables: {
      center_id: Number(process.env.REACT_APP_CENTER_ID),
    },
    skip: !user_id,
  });
  useEffect(() => {
    if (categoriesData?.treatment_categories) {
      let temp: any = {
        0: {
          title: "Select a category",
          value: "",
        },
      };

      for (let formIndex in categoriesData?.treatment_categories) {
        temp[categoriesData.treatment_categories[formIndex].id] = {
          title: categoriesData.treatment_categories[formIndex].title,
          value: categoriesData.treatment_categories[formIndex].id,
        };
      }

      setCategories(temp);
    }
  }, [categoriesData]);

  return (
    <VStack w="full" p="25px">
      <HStack w="full" flexWrap="wrap">
        <Heading flex="1" fontSize="30px">
          {stateData.id && <>Edit Treatment</>}

          {!stateData.id && <>Add New Treatment</>}
        </Heading>
        <HStack>
          <Button
            as={ReactRouterLink}
            to="/admin/treatments"
            variant="btnNormal"
          >
            Back To List
          </Button>
          {stateData.id && (
            <Button
              as={ReactRouterLink}
              to={`/admin/treatments/${params.id}/prescriptions`}
              variant="btnNormal"
            >
              See Prescriptions
            </Button>
          )}

          <Button
            variant="btnMain"
            isDisabled={isSaving}
            minW={120}
            onClick={async () => {
              setIsSaving(true);
              await saveRecord();
              setIsSaving(false);
            }}
          >
            {isSaving ? <Spinner px="5px"></Spinner> : "Save"}
          </Button>
        </HStack>
      </HStack>

      <VStack w="full">
        {recordIsLoading && (
          <HStack w="full" h="full" justifyContent="center" py="35px">
            <Spinner></Spinner>
          </HStack>
        )}

        {!recordIsLoading && (
          <Card
            boxShadow="null"
            w="full"
            borderRadius="16px"
            border="1px solid"
            borderColor="gray.100"
            position="relative"
            mt="20px"
            flex="1"
          >
            <CardBody p="15px">
                <SimpleGrid
                  w="full"
                  gap="15px"
                  alignItems="baseline"
                  columns={{
                    lg: 2,
                    md: 2,
                    base: 1,
                  }}
                >
                  <TextBox
                    state={stateData}
                    item={{
                      title: "Title",
                      descriptor: "title",
                      values: [],
                      validation_rule: "required",
                    }}
                    validator={validator}
                    inputItemHandler={(value: string, item: FormItem) => {
                      setStateData({ ...stateData, title: value });
                    }}
                  ></TextBox>

                  <TextBox
                    state={stateData}
                    item={{
                      title: "Required Sessions",
                      descriptor: "session_required",
                      values: [],
                      options: {
                        props: {
                          type: "number",
                        },
                      },
                      validation_rule: "required",
                    }}
                    validator={validator}
                    inputItemHandler={(value: string, item: FormItem) => {
                      setStateData({ ...stateData, session_required: value });
                    }}
                  ></TextBox>
                  <SelectBox
                    item={{
                      descriptor: "session_duration",
                      title: "Session Duration",
                      values: durationValues,
                      validation_rule: "required",
                    }}
                    validator={validator}
                    state={stateData}
                    inputItemHandler={(value: string, item: any) => {
                      setStateData({ ...stateData, session_duration: value });
                    }}
                  ></SelectBox>
                  <HStack gap="0" spacing="0" alignItems="end">
                    <TextBox
                      state={stateData}
                      w="full"
                      item={{
                        title: "Consent Form Url",
                        descriptor: "consent_form_url",
                        values: [],
                        options: {
                          props: {
                            borderTopEndRadius: 0,
                            borderBottomEndRadius: 0,
                          },
                        },
                      }}
                      inputItemHandler={(value: string, item: FormItem) => {
                        setStateData({
                          ...stateData,
                          consent_form_url: value,
                        });
                      }}
                    ></TextBox>
                    <Button
                      borderTopStartRadius="0"
                      borderBottomStartRadius="0"
                      onClick={() => {
                        setFormFileManagerIsOpen(true);
                      }}
                    >
                      <Icon as={FiHardDrive}></Icon>
                    </Button>
                    <Modal
                      onClose={() => {
                        setFormFileManagerIsOpen(false);
                      }}
                      size="full"
                      isOpen={formFileManagerIsOpen}
                    >
                      <ModalOverlay />
                      <ModalContent>
                        <ModalHeader>File Manager</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody h="full" display="flex" alignItems="stretch">
                          <FileManager
                            type="public"
                            path=""
                            onSelect={(file: any) => {
                              setStateData({
                                ...stateData,
                                consent_form_url: file.url,
                              });
                              setFormFileManagerIsOpen(false);
                            }}
                          ></FileManager>
                        </ModalBody>
                      </ModalContent>
                    </Modal>
                  </HStack>

                  <SelectBox
                    state={stateData}
                    item={{
                      title: "Category",
                      descriptor: "category_id",
                      values: categories,
                    }}
                    inputItemHandler={(value: string, item: FormItem) => {
                      setStateData({ ...stateData, category_id: value });
                    }}
                  ></SelectBox>

                  <SelectBox
                    state={stateData}
                    item={{
                      title: "Treatment Session Form",
                      descriptor: "form_id",
                      values: forms,
                    }}
                    inputItemHandler={(value: string, item: FormItem) => {
                      setStateData({ ...stateData, form_id: value });
                    }}
                  ></SelectBox>

                  <Box gridColumn={{ base: "span 1", md: "span 2" }}>
                    <RichTextBox
                      state={stateData}
                      item={{
                        title: "Description",
                        descriptor: "description",
                        values: [],
                      }}
                      inputItemHandler={(value: string, item: FormItem) => {
                        setStateData({ ...stateData, description: value });
                      }}
                    />
                  </Box>
                </SimpleGrid>
            </CardBody>
          </Card>
        )}
      </VStack>
    </VStack>
  );
};

export default TreatmentForm;
