const generateString = (length: number) => {
    let result = ' ';
    const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

const cleanFolderName = (title: string) => {
    let tempTitle = title?.replace('_', ' ').replace('-', ' ').replace(/\w+/g,function(w){return w[0].toUpperCase() + w.slice(1).toLowerCase();})

    tempTitle = tempTitle.slice(0, -1);

    return tempTitle;
}

const slugify = (str:string, delimiter:string) => {
    str = str.replace(/^\s+|\s+$/g, ''); // trim leading/trailing white space
    str = str.toLowerCase(); // convert string to lowercase
    str = str.replace(/[^a-z0-9 -]/g, '') // remove any non-alphanumeric characters
        .replace(/\s+/g, delimiter) // replace spaces with hyphens
        .replace(/-+/g, delimiter); // remove consecutive hyphens
    return str;
}

const nl2br = (str:string, replaceMode?:string, isXhtml?:boolean) => {

    var breakTag = (isXhtml) ? '<br />' : '<br>';
    var replaceStr = (replaceMode) ? '$1'+ breakTag : '$1'+ breakTag +'$2';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr);
}

export {cleanFolderName, generateString, slugify, nl2br};