import { gql } from "@apollo/client";

export const GET_CONSULTATION_FORM_STRUCTURE = gql`
  query GetConsultationFormStructure($center_id: Int!) {
    form_schemas(
      order_by: { version: desc }
      where: {
        descriptor: { _eq: "CONSULTATION_FORM" }
        center_id: { _eq: $center_id }
      }
    ) {
      id
      version
      template
    }
  }
`;
//
