import { useQuery } from "@apollo/client";
import {
  GetPatientsWithConsultationFormListQuery,
  GetPatientsWithConsultationFormListQueryVariables,
} from "../../../../../gql/graphql";
import { GET_PATIENTS_WITH_CONSULTATION_FORM } from "../../../ConsultationForms/queries";
import useAuth from "../../../../../hooks/useAuth";
import { Button, HStack, Image, Spinner, Text, VStack } from "@chakra-ui/react";
import emptyImage from "../../../../../assets/images/appointment_empty.svg";
import ListTable from "../../../../../components/FromsElements/ListTable";
import { useNavigate } from "react-router-dom";
import { ViewIcon } from "@chakra-ui/icons";
import { fields } from "./fields";
import { useEffect, useState } from "react";

export const ConsultationFormWidget = () => {
  const { user_id } = useAuth();
  const perPage = 5;
  const page = 1;
  const navigate = useNavigate();
  const [records, setRecords] = useState<Array<any>>([]);

  const { data, loading } = useQuery<
    GetPatientsWithConsultationFormListQuery,
    GetPatientsWithConsultationFormListQueryVariables
  >(GET_PATIENTS_WITH_CONSULTATION_FORM, {
    skip: !user_id,
    variables: {
      where: {
        _and: [
          { center_id: { _eq: Number(process.env.REACT_APP_CENTER_ID) } },
          { form: { descriptor: { _eq: "CONSULTATION_FORM" } } },
        ],
      },
      limit: perPage,
      offset: 0,
    },
  });

  useEffect(() => {
    let temp: Array<any> = [];
    if (data?.form_data?.length) {
      for (let formData in data.form_data) {
        let td = JSON.parse(JSON.stringify(data.form_data[formData].patient));
        td["created_at"] = data.form_data[formData].created_at;
        temp.push(td);
      }
    }
    setRecords(temp);
  }, [data]);

  return (
    <>
      {Number(data?.form_data_aggregate.aggregate?.count) > 0 && (
        <VStack spacing={3} alignItems={"flex-start"} w={"100%"}>
          <ListTable
            data={records}
            fields={fields}
            page={page}
            total={data?.form_data_aggregate.aggregate?.count}
            loading={loading}
            hasPagination={false}
            perPage={perPage}
            actionField={(id: any) => {
              return (
                <Button
                  onClick={() => {
                    navigate("/dashboard/patient/" + id, {
                      state: { back_url: "/dashboard" },
                    });
                  }}
                >
                  <ViewIcon></ViewIcon>
                </Button>
              );
            }}
          ></ListTable>

          {Number(data?.form_data_aggregate.aggregate?.count) > perPage && (
            <Button
              variant={"link"}
              onClick={() => {
                navigate("/dashboard/consultation-forms/", {
                  state: { back_url: "/dashboard" },
                });
              }}
            >
              See All Forms
            </Button>
          )}
        </VStack>
      )}

      {loading && !data?.form_data && (
        <HStack w="full" h="100px" justifyContent="center" alignItems="center">
          <Spinner fontSize="24px"></Spinner>
        </HStack>
      )}

      {!loading && data?.form_data_aggregate.aggregate?.count === 0 && (
        <VStack w="full" my="30px">
          <Image src={emptyImage}></Image>
          <Text fontSize="14px" fontWeight="400" color="gray">
            There are currently no data
          </Text>
        </VStack>
      )}
    </>
  );
};
