import * as React from "react"
import {
    Box,
    Heading,
    Flex,
    Image,
    Text,
    Stack,
    Divider,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Container,
    Card,
    CardBody,
    CardHeader,
    CardFooter, VStack, SimpleGrid, Circle, Square, Button, Icon
} from "@chakra-ui/react"
import {Link as ReactRouterLink} from 'react-router-dom'
import {Link as ChakraLink, LinkProps} from '@chakra-ui/react'
import tempIcon from "../../assets/images/doctor.png";
import {PageHeader} from "../../components/Template/PageHeader";
import {MainTemplate} from "../../components/Template/MainTemplate";
// import testData from "../../TestData/patientResources.json"
import {useEffect, useState} from "react";
import {ChevronRightIcon} from "@chakra-ui/icons"
import {SectionObject} from "../../interfaces/Interfaces";
import {init} from "../../store/slice";
import { gql, useQuery } from "@apollo/client";
import {GET_PATIENT_RESOURCE_CATEGORIES} from "./queries";
import imageDivider from "../../assets/images/patient_resources/title_divider.svg"
import banner from "../../assets/images/patient_resources/patient-resources-banner.jpg"
import prtImage from "../../assets/images/patient-resources-title.svg"
import { FiArrowUpRight } from "react-icons/fi";
import { GetPatientResourcesCategoriesQuery, GetPatientResourcesCategoriesQueryVariables } from "../../gql/graphql";

export const PatientResources = () => {

    const [resourceData, setResourceData] = useState<Array<any>>([]);

    const {error, loading, data} = useQuery<GetPatientResourcesCategoriesQuery, GetPatientResourcesCategoriesQueryVariables>(GET_PATIENT_RESOURCE_CATEGORIES, {
        context: {
            assumeRole: 'public',
        },
        variables: {
            center_id: Number(process.env.REACT_APP_CENTER_ID)
        }
    });

    useEffect(() => {
        if (data && data.patient_resources_categories) {
            setResourceData(data.patient_resources_categories);
        }
    }, [data, loading]);

    return (
        <MainTemplate is_loading={loading}>
            <Container w="full" maxW="full" p="0" position="relative" pt="70px">
                <Heading fontSize={{base: '18px', md: '30px', lg: '40px', xl: '60px'}} fontWeight="800"
                    position="absolute" right="22%" top="5%">
                    <Circle display="inline-block" size={{base: "10px", md: "16px"}} bg='#D5ABFF'></Circle>
                    Your Guide to <Text as="span" color="blue">Beauty</Text><br />
                    and <Text as="span" color="blue" position="relative">Wellness<Image width={{base: '20px', sm: "30", md: '35px', lg: '42px', xl: '50px'}} ps={{base: "5px", md: "15px"}} display="inline-block" src={prtImage}></Image></Text>
                </Heading>
                <Image src={banner} w="full"></Image>

                <Box position="absolute" top="0" bottom="0" left="0" right="0">

                    <Square size={{base: "20px", lg: "70px"}} border="2px solid" borderColor="#FFB0D0" borderRadius="8px"
                        position="absolute" left="55px" top="25%"
                    ></Square>
                    <Circle size="35px" border="2px solid" borderColor="#B6D9C1"
                        position="absolute" left={{base: "85px", md: "195px"}} top="5%"
                    ></Circle>
                    <Square size={{base: "20px", lg: "70px"}} border="2px solid" borderColor="#99C1FF" borderRadius="8px"
                        position="absolute" right="45px" top="40%" transform="rotate(30deg)"
                    ></Square>

                </Box>
            </Container>
            <Flex textAlign="center" mx="15px" my="100px">
                <Heading fontSize="48px" fontWeight="800" position="relative" display="flex" mx="auto">

                    <Circle size="58px" position="absolute" border="9px solid" borderColor="gray.100" left="-20px" top="-30px" zIndex="0"></Circle>
                    <Square size="58px" position="absolute" border="9px solid" borderColor="gray.100" left="56px" top="-30px" zIndex="0" borderRadius="20px"></Square>
                    <Circle size="58px" position="absolute" border="9px solid" borderColor="gray.100" left="56px" top="45px" zIndex="0"></Circle>
                    <Square size="58px" position="absolute" border="9px solid" borderColor="gray.100" left="-20px" top="45px" zIndex="0" borderRadius="20px"></Square>

                    <Text as="span" zIndex="1000" fontSize={{base: "30px", sm: "48px"}}>Patient Resource Categories</Text>
                </Heading>
            </Flex>
            <Container maxW="container.xl">
                {!loading && resourceData.length > 0 && resourceData.map((resource: any) => {
                    return (
                        <>
                            { resource.patient_resources.length > 0 &&
                                <Box my="25px">
                                    <Heading fontSize="36px" fontWeight="700">{resource.title}</Heading>
                                    <Image src={imageDivider} mb="25px" mt="5px"></Image>
                                </Box>
                            }

                            <SimpleGrid mt="15px" mb="55px" w="full" maxW="full" columns={{base: 1, md: 2, lg: 3}}>
                                { resource.patient_resources && resource.patient_resources.map((item:any) => {
                                    return (
                                        <ChakraLink as={ReactRouterLink} to={'/patients/resources/' + item.descriptor}
                                            p="15px">
                                            <VStack filter={{base: "none", md: "grayscale(100%)"}}
                                                role="group"
                                                _hover={{
                                                    filter: "none"
                                                }}
                                            >
                                                <Image
                                                    src={item.thumbnail}
                                                    alt={item.title}
                                                    borderRadius='2xl'
                                                />
                                                <Stack mt='5px' spacing='1'>
                                                    <Heading fontSize="20px" fontWeight="700">{item.title}</Heading>
                                                    <Text lineHeight="20px" fontSize="15px" color="gray" fontWeight="400">
                                                        {item.briefly}
                                                    </Text>
                                                    <Box textAlign="start">
                                                        <Button variant="btnLink" opacity="0" _groupHover={{
                                                            opacity: 1
                                                        }}>
                                                            <Text as="span" fontSize="14px">View More</Text>
                                                            <Icon as={FiArrowUpRight} fontSize="16px"></Icon>
                                                        </Button>
                                                    </Box>
                                                </Stack>
                                            </VStack>
                                        </ChakraLink>
                                    )
                                })}
                            </SimpleGrid>
                        </>
                    )
                })

                }


            </Container>
        </MainTemplate>
    );
}