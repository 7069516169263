import { useMutation, useQuery } from "@apollo/client";
import { GET_PARAMS_FORM_STRUCTURE } from "./queries";
import { SimpleGrid, Button, Text, HStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import FormElementsContainer from "../../../../../../components/FromsElements/FormElementsContainer";
import { FormItem } from "../../../../../../interfaces/Interfaces";
import { UPDATE_FORM_DATA_BY_PK } from "../../../mutations";
import useCustomToast from "../../../../../../hooks/useCustomToast";
import useAuth from "../../../../../../hooks/useAuth";
import {
  GetParamsFormStructureQuery,
  GetParamsFormStructureQueryVariables,
  UpdateFormVisitDataByPkMutation,
  UpdateFormVisitDataByPkMutationVariables,
} from "../../../../../../gql/graphql";
import {useEffectOnce} from "react-use";

type ParamsFormPropTypes = {
  visitId: string;
  patientId: number;
  formId: number;
  recommendationData: any;
  formData?: any;
  onClose: () => void;
};

const ParamsForm = (props: ParamsFormPropTypes) => {
  const [updateFormDataByPk] = useMutation<
    UpdateFormVisitDataByPkMutation,
    UpdateFormVisitDataByPkMutationVariables
  >(UPDATE_FORM_DATA_BY_PK);
  const [currentForm, setCurrentForm] = useState<any>(null);
  const [stateData, setStateData] = useState<any>({});
  const toast = useCustomToast();
  const user = useAuth();
  const form_data = props.formData;
  const [formId, setFormId] = useState<any>(null);
  const { error, loading, data, client } = useQuery<
    GetParamsFormStructureQuery,
    GetParamsFormStructureQueryVariables
  >(GET_PARAMS_FORM_STRUCTURE, {
    fetchPolicy: "no-cache",
    variables: {
      id: props.formId,
      center_id: Number(process.env.REACT_APP_CENTER_ID),
    },
  });
  const saveData = async () => {
    let hasError = false;

    let result = await updateFormDataByPk({
      variables: {
        id: formId,
        object: {
          data: stateData,
        },
      },
    });

    if (!result.data?.update_form_data_by_pk?.id) {
      hasError = true;
    }

    if (!hasError) {
      toast.success("Data saved Successfully.");
    } else {
      toast.error("There is a problem on saving data!");
    }
  };

  useEffect(() => {
    if (form_data?.data) {
      setStateData(form_data.data);
      setFormId(form_data.id);
    }
  }, [form_data]);

  useEffect(() => {
    if (data?.form_schemas) {
      setCurrentForm(data.form_schemas[0].template.sections[0].forms[0]);
    }
  }, [data]);

  useEffect(() => {
    if (Object.keys(stateData).length === 0 && currentForm?.items) {
      let temp: any = {};
      for (let index in currentForm.items) {
        temp[currentForm.items[index].descriptor] = null;
      }
      setStateData(temp);
    }
  }, [currentForm]);

  return loading ? (
    <Text minHeight={"50vh"}>Please Wait...</Text>
  ) : (
    <>
      {currentForm?.items?.length > 0 && (
        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          spacingX="30px"
          spacingY="20px"
        >
          <FormElementsContainer
            items={currentForm.items}
            recommendationData={props.recommendationData}
            inputItemHandler={(value: any, item: FormItem) => {
              let temp = JSON.parse(JSON.stringify(stateData));

              if (typeof value === typeof String) {
                temp[item.descriptor] = value;

              } else {
                temp[item.descriptor] = value.value;
              }

              setStateData(temp);
            }}
            state={stateData}
          ></FormElementsContainer>
        </SimpleGrid>
      )}

      <HStack spacing={3}>
      <Button
        variant="btnNormal"
        border="1px solid"
        borderColor="gray.100"      
        mt="30px"
        minW="150px"
        onClick={props.onClose}
      >
        Close
      </Button>

      <Button
        variant="btnMain"
        mt="30px"
        minW="150px"
        onClick={() => {
          saveData();
        }}
      >
        Save
      </Button>
      </HStack>
    </>
  );
};

export default ParamsForm;
