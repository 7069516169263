import ModalBox from "../../../../../components/ModalBox";
import { FiFilePlus } from "react-icons/fi";
import {
  Box,
  Button,
  Heading,
  HStack,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import SelectBox from "../../../../../components/FromsElements/SelectBox";
import * as React from "react";
import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import {
  UPDATE_VISIT_PRESCRIPTION,
  ADD_VISIT_PRESCRIPTIONS,
} from "../mutations";

import { useParams } from "react-router-dom";
import useCustomToast from "../../../../../hooks/useCustomToast";
import TextAreaBox from "../../../../../components/FromsElements/TextAreaBox";
import { FormItem } from "../../../../../interfaces/Interfaces";
import {
  AddVisitPrescriptionsMutation,
  AddVisitPrescriptionsMutationVariables,
  UpdateVisitPrescriptionByPkMutation,
  UpdateVisitPrescriptionByPkMutationVariables,
} from "../../../../../gql/graphql";

interface AddPrescriptionModalProps {
  isOpen: boolean;
  closeAction: any;
  addAction: any;
  prescriptions: Array<any>;
  defaultState: {
    id: number | null;
    text: String | null;
    prescription_id: number | null;
  };
}
export const AddPrescriptionModal = (props: AddPrescriptionModalProps) => {
  let params = useParams<{ visitId: string; id: string }>();

  const [addVisitPrescriptions] = useMutation<
    AddVisitPrescriptionsMutation,
    AddVisitPrescriptionsMutationVariables
  >(ADD_VISIT_PRESCRIPTIONS);
  const [updateVisitPrescriptionByPk] = useMutation<
    UpdateVisitPrescriptionByPkMutation,
    UpdateVisitPrescriptionByPkMutationVariables
  >(UPDATE_VISIT_PRESCRIPTION);
  const toast = useCustomToast();
  const [stateData, setStateData] = useState<any>(props.defaultState);
  const [prescriptionList, setPrescriptionList] = useState<any>({});

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let temp: any = {};
    if (props.prescriptions.length) {
      for (let prescription in props.prescriptions) {
        temp[props.prescriptions[prescription].id] = {
          title: props.prescriptions[prescription].title,
          value: props.prescriptions[prescription].id,
          text: props.prescriptions[prescription].body,
        };
      }

      temp[-1] = {
        title: "Custom",
        value: -1,
        text: "",
      };
    }

    setPrescriptionList(temp);
  }, [props.prescriptions]);

  const addPrescriptionModalCancelButton = () => {
    return (
      <Button
        variant="btnNoraml"
        border="1px solid"
        borderColor="gray.400"
        w="full"
        borderRadius="100px"
        h="48px"
        mb="30px"
        ms="45px"
        onClick={() => {
          setStateData({ text: "", prescription_id: -1 });

          props.closeAction();
        }}
      >
        Cancel
      </Button>
    );
  };

  const addPrescriptionModalActionButton = () => {
    return (
      <Button
        variant="btnMain"
        border="1px solid"
        borderColor="gray.400"
        w="full"
        borderRadius="100px"
        h="48px"
        mb="30px"
        me="45px"
        isDisabled={loading || stateData?.text?.length === 0}
        onClick={async () => {
          setLoading(true);
          let hasError = false;

          if (stateData.id) {
            let result = await updateVisitPrescriptionByPk({
              variables: {
                id: stateData.id,
                object: {
                  treatment_prescription_id:
                    stateData.prescription_id > 0
                      ? stateData.prescription_id
                      : null,
                  text: stateData.text,
                },
              },
            });
            hasError = !result?.data?.update_visit_prescriptions_by_pk?.id;
          } else {
            let result = await addVisitPrescriptions({
              variables: {
                objects: [
                  {
                    created_at: new Date(),
                    visit_id: params.visitId,
                    treatment_prescription_id:
                      stateData.prescription_id > 0 ?
                      stateData.prescription_id : null,
                    text: stateData.text,
                  },
                ],
              },
            });
            hasError = !result?.data?.insert_visit_prescriptions?.affected_rows;
          }
          setLoading(false);
          if (!hasError) {
            toast.success("Prescription has been successfully saved.");
            setStateData({ text: "", id: null, prescription_id: null });
            props.addAction();
          }
        }}
      >
        Save
      </Button>
    );
  };

  return (
    <ModalBox
      isOpen={props.isOpen}
      onClose={() => {
        setStateData({ text: "", id: null, prescription_id: null });
        props.closeAction();
      }}
      size="xl"
      icon={FiFilePlus}
      iconsize="26px"
    >
      <ModalBody pb={6}>
        <Box>
          <Heading size="md" textAlign="center">
            Add new Prescription
          </Heading>

          <SelectBox
            state={stateData}
            item={{
              title: "From Template",
              descriptor: "prescription_id",
              values: prescriptionList,
            }}
            inputItemHandler={(value: string, item: FormItem) => {
              setStateData({
                ...stateData,
                prescription_id: value,
                text: prescriptionList[value].text,
              });
            }}
          ></SelectBox>
          <Box my="25px"></Box>
          <TextAreaBox
            state={stateData}
            item={{
              descriptor: "text",
              title: "Prescription",
              values: {},
              options: {
                props: {
                  h: "200px",
                },
              },
            }}
            inputItemHandler={(value: string, item: FormItem) => {
              setStateData({ ...stateData, text: value, prescription_id: -1 });
            }}
          ></TextAreaBox>
        </Box>
      </ModalBody>

      <ModalFooter>
        <HStack
          w="full"
          align="center"
          spacing={10}
          marginStart={10}
          marginEnd={10}
          justifyContent={"space-between"}
        >
          {addPrescriptionModalCancelButton()}
          {addPrescriptionModalActionButton()}
        </HStack>
      </ModalFooter>
    </ModalBox>
  );
};
