import {
    Button,
    Card,
    CardBody, Heading,
    HStack, SimpleGrid,
    Spinner,
    Text,
    VStack
} from "@chakra-ui/react";
import { Link as ReactRouterLink, useParams } from "react-router-dom";
import TextBox from "../../../components/FromsElements/TextBox";
import { useEffect, useState } from "react";
import { FormItem } from "../../../interfaces/Interfaces";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_ROLES, GET_USER_BY_ID, ADD_USER, UPDATE_USER } from "./queries";
import SelectBox from "../../../components/FromsElements/SelectBox";
import SimpleReactValidator from "simple-react-validator";
import useCustomToast from "../../../hooks/useCustomToast";
const UserForm = () => {
  const params = useParams();
  const [validator, setValidator] = useState<any>(
    new SimpleReactValidator({
      element: (message: string) => {
        return (
          <Text fontSize="sm" color="red.500">
            {message}
          </Text>
        );
      },
    })
  );

  const [recordIsLoading, setRecordIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const toast = useCustomToast();
  const defaultState = {
    id: null,
    name: "",
    email: "",
    mobile: "",
    title: "",
    role_id: "",
    enable: true,
    center_id: Number(process.env.REACT_APP_CENTER_ID),
    tries: 0,
  };

  const [stateData, setStateData] = useState(defaultState);
  const [roles, setRoles] = useState<Array<any>>([]);
  const [getRolesList, getRolesListResult] = useLazyQuery(GET_ROLES, {
    fetchPolicy: "no-cache",
    variables: {},
  });

  const [getUserById, getUserByIdResult] = useLazyQuery(GET_USER_BY_ID, {
    fetchPolicy: "no-cache",
    variables: {
      id: params.id,
    },
  });

  const [addUser] = useMutation(ADD_USER);
  const [updateUser] = useMutation(UPDATE_USER);

  const saveRecord = async () => {
    setStateData({ ...stateData, tries: stateData.tries + 1 });
    if (validator.allValid()) {
      let record: any = {
        name: stateData.name,
        title: stateData.title,
        email: stateData.email,
        mobile: stateData.mobile,
        center_id: Number(process.env.REACT_APP_CENTER_ID),
        enable: stateData.enable,
        role_id: stateData.role_id,
      };

      if (stateData.id) {
        record.id = stateData.id;

        let result = await updateUser({
          variables: {
            id: record.id,
            object: record,
          },
        });

        if (result?.data?.update_users_by_pk?.id) {
          toast.success("Record Saved Successfully.");
        } else {
          toast.error("There is an error on saving record data.");
        }
      } else {
        let result = await addUser({
          variables: {
            object: record,
          },
        });

        if (result?.data?.insert_users_one?.id) {
          setStateData({ ...stateData, id: result.data.insert_users_one.id });
          toast.success("Record Saved Successfully.");
        } else {
          toast.error("There is an error on saving record data.");
        }
      }
    } else {
      validator.showMessages();
    }
  };

  useEffect(() => {
    getRolesList();
  }, []);

  useEffect(() => {
    if (params.id) {
      getUserById({
        variables: {
          id: parseInt(params.id),
        },
      });
    } else {
      setRecordIsLoading(false);
    }
  }, [params]);

  useEffect(() => {
    if (getUserByIdResult?.data?.users_by_pk) {
      let record = getUserByIdResult.data.users_by_pk;
      let temp = {
        id: record.id,
        name: record.name,
        title: record.title,
        email: record.email,
        mobile: record.mobile,
        center_id: Number(process.env.REACT_APP_CENTER_ID),
        enable: record.enable,
        role_id: record.role_id,
        tries: 0,
      };

      setStateData(temp);
      setRecordIsLoading(false);
    }
  }, [getUserByIdResult]);

  useEffect(() => {
    if (getRolesListResult?.data?.roles?.length) {
      let temp: any = {
        0: {
          title: "Select a Role",
          value: null,
        },
      };

      for (let roleIndex in getRolesListResult.data.roles) {
        temp[getRolesListResult.data.roles[roleIndex].id] = {
          title: getRolesListResult.data.roles[roleIndex].name,
          value: getRolesListResult.data.roles[roleIndex].id,
        };
      }

      setRoles(temp);
    }
  }, [getRolesListResult]);

  return (
    <VStack w="full" p="25px">
      <HStack w="full" flexWrap="wrap">
        <Heading flex="1" fontSize="30px">
          {stateData.id && <>Edit User</>}

          {!stateData.id && <>Add New User</>}
        </Heading>
        <HStack>
          <Button as={ReactRouterLink} to="/admin/users" variant="btnNormal">
            Back To List
          </Button>
          <Button
            variant="btnMain"
            isDisabled={isSaving}
            onClick={async () => {
              setIsSaving(true);
              await saveRecord();
              setIsSaving(false);
            }}
            minW={120}
          >
            {isSaving ? <Spinner px="5px"></Spinner> : "Save"}
          </Button>
        </HStack>
      </HStack>

      <VStack w="full">
        {recordIsLoading && (
          <HStack w="full" h="full" justifyContent="center" py="35px">
            <Spinner></Spinner>
          </HStack>
        )}

        {!recordIsLoading && (
          <Card
            boxShadow="null"
            w="full"
            borderRadius="16px"
            border="1px solid"
            borderColor="gray.100"
            position="relative"
            mt="20px"
            flex="1"
          >
            <CardBody p="15px">
              <SimpleGrid
                w="full"
                gap="15px"
                alignItems="baseline"
                columns={{
                  lg: 3,
                  md: 2,
                  base: 1,
                }}
              >
                <TextBox
                  item={{
                    title: "Name",
                    descriptor: "name",
                    values: [],
                    validation_rule: "required",
                  }}
                  validator={validator}
                  inputItemHandler={(value: string, item: FormItem) => {
                    setStateData({ ...stateData, name: value });
                  }}
                  state={stateData}
                ></TextBox>
                <TextBox
                  item={{
                    title: "Title",
                    descriptor: "title",
                    values: [],
                    validation_rule: "required",
                  }}
                  validator={validator}
                  inputItemHandler={(value: string, item: FormItem) => {
                    setStateData({ ...stateData, title: value });
                  }}
                  state={stateData}
                ></TextBox>
                <SelectBox
                  state={stateData}
                  validator={validator}
                  item={{
                    title: "Role",
                    descriptor: "role_id",
                    values: roles,
                    validation_rule: "required",
                  }}
                  inputItemHandler={(value: any, item: FormItem) => {
                    if (value === "Select a Role") {
                      value = null;
                    }
                    setStateData({ ...stateData, role_id: value });
                  }}
                ></SelectBox>

                <TextBox
                  item={{
                    title: "Email",
                    descriptor: "email",
                    values: [],
                    validation_rule: "required",
                  }}
                  validator={validator}
                  inputItemHandler={(value: string, item: FormItem) => {
                    setStateData({ ...stateData, email: value });
                  }}
                  state={stateData}
                ></TextBox>

                <TextBox
                  item={{
                    title: "Mobile",
                    descriptor: "mobile",
                    values: [],
                    validation_rule: "required",
                  }}
                  validator={validator}
                  inputItemHandler={(value: string, item: FormItem) => {
                    setStateData({ ...stateData, mobile: value });
                  }}
                  state={stateData}
                ></TextBox>
              </SimpleGrid>
            </CardBody>
          </Card>
        )}
      </VStack>
    </VStack>
  );
};

export default UserForm;
