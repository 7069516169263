import {
    Button,
    FormControl,
    FormHelperText,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    VStack,
    Select, Alert
} from "@chakra-ui/react";
import {useEffect, useState} from "react";

const AddNewOptionModal = (props:any) => {

    const {
        onClose,
        onSubmit,
        keys
    } = props;

    const [key, setKey] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');


    return (
        <Modal isOpen={true} onClose={onClose} size='sm'>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    Add new option
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <VStack w='full' gap='15px'>
                        <FormControl>
                            <FormLabel>Key</FormLabel>
                            <Input type='text' value={key} onChange={
                                (e) => {
                                    setErrorMessage('');
                                    setKey(e.target.value)
                                }
                            } />
                        </FormControl>
                        {errorMessage &&
                            <Alert bg='red.100' color='red.500'>{errorMessage}</Alert>
                        }
                    </VStack>
                </ModalBody>

                <ModalFooter>
                    <Button
                        variant='ghost'
                        mr={3}
                        onClick={onClose}
                    >
                        Close
                    </Button>
                    <Button
                        colorScheme='blue'
                        onClick={() => {
                            if (keys.indexOf(key) === -1) {
                                if (onSubmit) {
                                    onSubmit(key)
                                }
                            } else {
                                setErrorMessage('This Key already exists');
                            }
                        }
                        }
                    >
                        Save
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default AddNewOptionModal;