import { gql } from "@apollo/client";

export const GET_TREATMENT_CATEGORIES_WITH_TREATMENTS = gql`
  query getTreatmentCategoriesWithTreatments($centerId: Int!) {
    treatment_categories(where: { center_id: { _eq: $centerId }, deleted_at: { _is_null: true } }) {
      id
      title
      descriptor
      description
      treatments(where: { deleted_at: { _is_null: true } }, order_by: { created_at: asc }) {
        id
        title
        description
      }
    }
  }
`;