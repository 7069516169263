import {
    Button,
    FormControl,
    FormHelperText,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    VStack,
    Select, Textarea, Checkbox
} from "@chakra-ui/react";
import {useEffect, useState} from "react";

const FormModal = (props:any) => {
    const {
        onClose,
        onSubmit,
        data,
        currentSectionIndex,
        sections,
        indexH
    } = props;

    const defaultFormData = {
        title: '',
        descriptor: '',
        description: '',
        overview_description: '',
        hideSubmitButton: false,
        form: '',
        parent: '',
        place: 'end',
        section: sections ? sections[currentSectionIndex] : [],
        sectionIndex: currentSectionIndex,
        add_form_description: '',
        add_form_link_title: '',
        added_form_title: '',
        can_add_form: 'no',
    };

    const [formData, setFormData] = useState<any>(null);

    useEffect(() => {
        if (data) {
            setFormData({
                title: data.title,
                descriptor: data.descriptor,
                description: data.description,
                overview_description: data?.overview_description ?? '',
                hideSubmitButton: data.hideSubmitButton ?? false,
                form: '',
                parent: data.parent,
                items: data.items,
                place: '',
                sectionIndex: currentSectionIndex,
                add_form_description: data.add_form_description,
                add_form_link_title: data.add_form_link_title,
                added_form_title: data.added_form_title,
                can_add_form: data.can_add_form ? 'yes' : 'no',
            })
        } else {
            setFormData(defaultFormData)
        }
    }, []);


    const filterFormDataBeforeSubmit = (newFormData:any) => {

        if (newFormData.can_add_form === 'yes') {
            newFormData.forms = [];
            newFormData.can_add_form = true;
        } else {
            delete newFormData.forms;
            delete newFormData.add_form_description;
            delete newFormData.add_form_link_title;
            delete newFormData.added_form_title;
            delete newFormData.can_add_form;
        }

        if (typeof newFormData?.parent === typeof undefined || newFormData?.parent?.length === 0) {
            delete newFormData.parent;
        }

        if (typeof newFormData?.overview_description === typeof undefined || newFormData?.overview_description?.length === 0) {
            delete newFormData.overview_description;
        }

        if (onSubmit) {
            onSubmit( data, newFormData, indexH);
        }
    }

    const canChooseFormPlace = () => {
        if (indexH) {
            let temp = indexH.split('|');
            temp.pop();
            return temp.length <= 3;
        }

        return true;
    }

    return (
        <>
            { formData &&
                <Modal isOpen={true} onClose={onClose} size='xl'>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>
                            {data?.title ? 'Edit Form : ' + data.title : 'Create New Form'}
                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <VStack w='full' gap='15px'>
                                <FormControl>
                                    <FormLabel>Title</FormLabel>
                                    <Input type='text' value={formData.title} onChange={(e) => {setFormData({...formData, title: e.target.value})}} />
                                </FormControl>

                                <FormControl>
                                    <FormLabel>Descriptor</FormLabel>
                                    <Input type='text' value={formData.descriptor} onChange={(e) => {setFormData({...formData, descriptor: e.target.value})}} />
                                </FormControl>

                                <FormControl>
                                    <FormLabel>Parent Form Descriptor</FormLabel>
                                    <Input type='text' value={formData.parent} onChange={(e) => {setFormData({...formData, parent: e.target.value})}} />
                                </FormControl>

                                <FormControl>
                                    <FormLabel>Can Add More:</FormLabel>
                                    <Select
                                        placeholder='Select ...'
                                        value={formData.can_add_form}
                                        onChange={(e) => {setFormData({...formData, can_add_form: e.target.value})}}
                                    >
                                        <option value='no'>No</option>
                                        <option value='yes'>Yes</option>
                                    </Select>
                                </FormControl>

                                {formData?.can_add_form === 'yes' &&
                                    <>
                                        <FormControl>
                                            <FormLabel>Add Form Description</FormLabel>
                                            <Textarea value={formData.add_form_description} onChange={(e) => {setFormData({...formData, add_form_description: e.target.value})}} />
                                        </FormControl>

                                        <FormControl>
                                            <FormLabel>Add Form Link Title</FormLabel>
                                            <Input type='text' value={formData.add_form_link_title} onChange={(e) => {setFormData({...formData, add_form_link_title: e.target.value})}} />
                                        </FormControl>

                                        <FormControl>
                                            <FormLabel>Added Form Title</FormLabel>
                                            <Input type='text' value={formData.added_form_title} onChange={(e) => {setFormData({...formData, added_form_title: e.target.value})}} />
                                        </FormControl>
                                    </>
                                }

                                <FormControl>
                                    <FormLabel>Description</FormLabel>
                                    <Textarea  value={formData.description} onChange={(e) => {setFormData({...formData, description: e.target.value})}} ></Textarea>
                                </FormControl>

                                <FormControl>
                                    <FormLabel>Overview Description</FormLabel>
                                    <Textarea  value={formData.overview_description} onChange={(e) => {setFormData({...formData, overview_description: e.target.value})}} ></Textarea>
                                </FormControl>

                                {canChooseFormPlace() && sections?.length > 0 &&
                                    <FormControl>
                                        <FormLabel>Form Place:</FormLabel>
                                        <Select
                                            placeholder='Select Section Place'
                                            value={formData.place}
                                            onChange={(e) => {setFormData({...formData, place: e.target.value})}}
                                        >
                                            <option value='start'>Start Of Current Section</option>
                                            <option value='end'>End Of Current Section</option>
                                            <option value='after'>After</option>
                                        </Select>
                                    </FormControl>
                                }
                                {canChooseFormPlace() && formData.place === 'after' && sections[currentSectionIndex]?.forms?.length > 0 &&
                                    <FormControl ps='25px'>
                                        <FormLabel>Form:</FormLabel>
                                        <Select
                                            placeholder='Select Form'
                                            value={formData.form}
                                            onChange={(e) => {setFormData({...formData, form: e.target.value})}}
                                        >
                                            {sections[currentSectionIndex].forms.map((formItem:any, formIndex:number) => {
                                                return (
                                                    <option key={formItem.descriptor} value={formIndex}>{formItem.title}</option>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                }

                                <FormControl>
                                    <Checkbox
                                        isChecked={formData.hideSubmitButton}
                                        onChange={(e) => {setFormData({...formData, hideSubmitButton: e.target.checked})}}
                                    >
                                        Hide Submit Button
                                    </Checkbox>
                                </FormControl>
                            </VStack>
                        </ModalBody>

                        <ModalFooter>
                            <Button
                                variant='ghost'
                                mr={3}
                                onClick={onClose}
                            >
                                Close
                            </Button>
                            <Button
                                colorScheme='blue'
                                onClick={() => {filterFormDataBeforeSubmit(formData)}}
                            >
                                Save
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            }
        </>
    );
}

export default FormModal;