import {Box, Flex, HStack, Text, VStack} from "@chakra-ui/react";
import * as React from "react";
import {ChevronDownIcon, ChevronUpIcon} from "@chakra-ui/icons";
import {useEffect, useState} from "react";
import {Link as ReactRouterLink} from "react-router-dom";

import "./style.css"
interface MenuItemInterface {
    id: string,
    descriptor: string,
    title: string,
    is_active?: boolean,
    is_root?: boolean,
    route?: string,
    href?: string,
    icon?: any,
}
interface SidebarMenuItemProps {
    item: MenuItemInterface,
    isRoot?: boolean,
    isOpen?: boolean|null,
    onChange?: any
}

export type {MenuItemInterface}

const SidebarMenuItem = (props:SidebarMenuItemProps) => {

    const item:MenuItemInterface = props.item;

    return (
        <VStack w="full">
            <HStack w="full" py="10px" px="15px" cursor="pointer" borderRadius="4px"
                as={ReactRouterLink} to={item.route ? item.route : (item.href ? item.href : '#')}
                className={props.isOpen ? 'menu-selected' : 'menu-normal'}
                onClick={(e) => {
                    if (props.onChange) {
                        props.onChange(item);
                    }
                }}
                _hover={{
                    bg: "gray.100"
                }}
            >
                { item.icon &&
                    <Flex justifyContent="center" w="30px" alignItems="start">
                        {item.icon}
                    </Flex>
                }
                { item.title?.length > 0 &&
                    <Text fontSize="16px" fontWeight="500" flex="1">{item.title}</Text>
                }
            </HStack>
        </VStack>
    );
}

export default SidebarMenuItem;