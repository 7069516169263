import SimpleReactValidator from "simple-react-validator";
import TextBox from "../../../../../components/FromsElements/TextBox";
import { MonthMap } from "../../../../../helpers/DateUtil";
import {
  Box,
  Card,
  CardBody,
  HStack,
  SimpleGrid,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FormItem } from "../../../../../interfaces/Interfaces";
import SelectBox from "../../../../../components/FromsElements/SelectBox";
import TextAreaBox from "../../../../../components/FromsElements/TextAreaBox";
import { FormFieldsType } from "../../types";

type PropTypes = {
  stateData: FormFieldsType;
  setStateData: (state: FormFieldsType) => void;
  recordIsLoading: boolean;
  validator: SimpleReactValidator;
};

export const EditForm = (props: PropTypes) => {
  const { stateData, setStateData, recordIsLoading, validator } = props;

  let days: any = {};
  let month: any = {};
  let years: any = {};

  for (let i = 1; i <= 31; i++) {
    let temp = i.toString().padStart(2, "0");
    days[i] = {
      title: temp,
      value: temp,
    };
  }

  for (let i = 1; i <= 12; i++) {
    let temp = i.toString().padStart(2, "0");
    month[i] = {
      title: MonthMap[temp],
      value: temp,
    };
  }

  for (let i = 1900; i <= new Date().getFullYear(); i++) {
    let temp = i.toString();
    years[i] = {
      title: temp,
      value: temp,
    };
  }

  return (
    <VStack w="full">
      {recordIsLoading && (
        <HStack w="full" h="full" justifyContent="center" py="35px">
          <Spinner></Spinner>
        </HStack>
      )}

      {!recordIsLoading && (
        <Card
          boxShadow="null"
          w="full"
          borderRadius="16px"
          border="1px solid"
          borderColor="gray.100"
          position="relative"
          mt="20px"
          flex="1"
        >
          <CardBody p="15px">
            <SimpleGrid
              w="full"
              gap="15px"
              alignItems="baseline"
              columns={{
                xl: 4,
                lg: 3,
                md: 2,
                base: 1,
              }}
            >
              <TextBox
                item={{
                  title: "First Name",
                  descriptor: "first_name",
                  values: [],
                  validation_rule: "required",
                }}
                validator={validator}
                inputItemHandler={(value: string, item: FormItem) => {
                  setStateData({ ...stateData, first_name: value });
                }}
                state={stateData}
              ></TextBox>
              <TextBox
                item={{
                  title: "Last Name",
                  descriptor: "last_name",
                  values: [],
                  validation_rule: "required",
                }}
                validator={validator}
                inputItemHandler={(value: string, item: FormItem) => {
                  setStateData({ ...stateData, last_name: value });
                }}
                state={stateData}
              ></TextBox>

              <TextBox
                item={{
                  title: "Email",
                  descriptor: "email",
                  values: [],
                  validation_rule: "required",
                }}
                validator={validator}
                inputItemHandler={(value: string, item: FormItem) => {
                  setStateData({ ...stateData, email: value });
                }}
                state={stateData}
              ></TextBox>

              <TextBox
                item={{
                  title: "Mobile",
                  descriptor: "mobile",
                  values: [],
                  validation_rule: "required",
                }}
                validator={validator}
                inputItemHandler={(value: string, item: FormItem) => {
                  setStateData({ ...stateData, mobile: value });
                }}
                state={stateData}
              ></TextBox>

<VStack w="full" justifyContent="start">
                <Text w="full" fontWeight={'500'}>Birthday</Text>
                <HStack w="full">
                  <SelectBox
                    flex="1"
                    state={stateData}
                    item={{
                      descriptor: "birthday_day",
                      title: "",
                      values: days,
                      options: { props: { flex: 1 } },
                    }}
                    inputItemHandler={(value: string, item: FormItem) => {
                      setStateData({ ...stateData, birthday_day: value });
                    }}
                  ></SelectBox>

                  <SelectBox
                    flex="1"
                    state={stateData}
                    item={{
                      descriptor: "birthday_month",
                      title: "",
                      values: month,
                      options: { props: { flex: 1 } },
                    }}
                    inputItemHandler={(value: string, item: FormItem) => {
                      setStateData({ ...stateData, birthday_month: value });
                    }}
                  ></SelectBox>

                  <SelectBox
                    flex="1"
                    state={stateData}
                    item={{
                      descriptor: "birthday_year",
                      title: "",
                      values: years,
                      options: { props: { flex: 1 } },
                    }}
                    inputItemHandler={(value: string, item: FormItem) => {
                      setStateData({ ...stateData, birthday_year: value });
                    }}
                  ></SelectBox>
                </HStack>
              </VStack>

              <SelectBox
                state={stateData}
                validator={validator}
                item={{
                  descriptor: "contact_method",
                  title: "Contact Method",
                  validation_rule: "required",
                  values: {
                    "-1": {
                      title: "Select one",
                      value: "Select one",
                    },
                    Mobile: {
                      title: "Mobile",
                      value: "Mobile",
                    },
                    Email: {
                      title: "Email",
                      value: "Email",
                    },
                    Mail: {
                      title: "Mail",
                      value: "Mail",
                    },
                  },
                }}
                inputItemHandler={(value: string, item: FormItem) => {
                  setStateData({ ...stateData, contact_method: value });
                }}
              ></SelectBox>  

              <Box
                gridColumn={{
                  base: "span 1",
                  md: "span 2",
                  lg: "span 2",
                  xl: "span 2",
                }}
              >
                <TextBox
                  state={stateData}
                  item={{
                    title: "Address",
                    descriptor: "address",
                    values: [],
                    validation_rule: "required",
                  }}
                  validator={validator}
                  inputItemHandler={(value: string, item: FormItem) => {
                    setStateData({ ...stateData, address: value });
                  }}
                ></TextBox>
              </Box>                          

              <TextBox
                item={{
                  title: "Emergency Contact Full Name",
                  descriptor: "emergency_contact_full_name",
                  values: [],
                }}
                validator={validator}
                inputItemHandler={(value: string, item: FormItem) => {
                  setStateData({
                    ...stateData,
                    emergency_contact_full_name: value,
                  });
                }}
                state={stateData}
              ></TextBox>

              <TextBox
                item={{
                  title: "Emergency Contact Email",
                  descriptor: "emergency_contact_email",
                  values: [],
                }}
                validator={validator}
                inputItemHandler={(value: string, item: FormItem) => {
                  setStateData({
                    ...stateData,
                    emergency_contact_email: value,
                  });
                }}
                state={stateData}
              ></TextBox>

              <TextBox
                item={{
                  title: "Emergency Contact Phone",
                  descriptor: "emergency_contact_phone",
                  values: [],
                }}
                validator={validator}
                inputItemHandler={(value: string, item: FormItem) => {
                  setStateData({
                    ...stateData,
                    emergency_contact_phone: value,
                  });
                }}
                state={stateData}
              ></TextBox>

              <TextBox
                item={{
                  title: "Occupation",
                  descriptor: "occupation",
                  values: [],
                }}
                validator={validator}
                inputItemHandler={(value: string, item: FormItem) => {
                  setStateData({ ...stateData, occupation: value });
                }}
                state={stateData}
              ></TextBox>

            </SimpleGrid>
          </CardBody>
        </Card>
      )}
    </VStack>
  );
};
