import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Heading,
} from "@chakra-ui/react";
import {
  Form as FormInterface,
  FormItem,
} from "../../../interfaces/Interfaces";
import { Form } from "./Form";
import { RiStickyNoteAddLine } from "react-icons/ri";
import { Form_Data_Notes } from "../../../gql/graphql";
import { Note } from "./Note";
import { AiOutlineEdit } from "react-icons/ai";

type PropTypes = {
  card: FormInterface;
  isOpen: boolean;
  data?: any;
  canAddNotes?: boolean;
  noteModalOnOpen?: (dt: { id?: number; note: string }) => void;
  notes: Array<Pick<Form_Data_Notes, "id" | "body" | "created_at">>;
  onDeleteNote?: (id: number) => void;
  canEdit?: boolean;
  editModalOnOpen?: () => void;
};

export const SectionFormGroup = (props: PropTypes) => {
  const {
    card,
    isOpen,
    data,
    canAddNotes = true,
    canEdit = true,
    noteModalOnOpen,
    notes,
    onDeleteNote,
    editModalOnOpen
  } = props;

  return (
    <Card w="full" display={isOpen ? "flex" : "none"}>
      <CardHeader pb="0">
        <HStack borderBottom="3px dashed" borderColor="gray.200" pb="15px">
          <Heading size="md" flex={1}>
            {card.title}
          </Heading>
          {canEdit && (
            <Button variant={"ghost"} onClick={editModalOnOpen}>
              <AiOutlineEdit fontSize={24} />
            </Button>
          )}          
          {canAddNotes && (
            <Button
              variant={"ghost"}
              onClick={() => noteModalOnOpen && noteModalOnOpen({ note: "" })}
            >
              <RiStickyNoteAddLine fontSize={24} />
            </Button>
          )}
        </HStack>
      </CardHeader>
      <CardBody py="0">
        {notes?.length > 0 &&
          notes.map((note, index) => (
            <Note
              key={index}
              item={note}
              onEdit={() => {
                noteModalOnOpen &&
                  noteModalOnOpen({ id: note.id, note: note.body || "" });
              }}
              onDelete={() => onDeleteNote && onDeleteNote(note.id)}
            />
          ))}

        {card.items.map((itemGroup: any) => {
          return itemGroup.items.map((item: FormItem, itemKey: number) => {
            return (
              <Box key={itemKey + 1000}>
                {item.type !== "text" && (
                  <Form state={data} key={item.descriptor} item={item} />
                )}
              </Box>
            );
          });
        })}
      </CardBody>
    </Card>
  );
};
