import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import {Form, PatientDataInterface, SectionObject} from "../interfaces/Interfaces";

export type ConsultationState = {
    sections: Array<SectionObject>,
    currentForm: Form|null,
    currentStep: number,
    currentSection: number,
    forms: Array<Form>,
    formsData: Record<string, any>,
    patient_data: PatientDataInterface,
    form_finished: boolean,
    validator?: any
}

type InitState = {
    sections: Array<SectionObject>,
    currentForm: Form|null,
    currentStep: number,
    currentSection: number,
    forms: Array<Form>,
    formsData?: Record<string, any>,
    patient_data: PatientDataInterface,
    form_finished: boolean,
    validator?: any}

export interface initAction {
    forms?: Array<Form>
}

const initialState: ConsultationState = {
    sections: [],
    currentForm: null,
    currentStep: 1,
    currentSection: 1,
    forms: [],
    formsData: {},
    patient_data: {},
    form_finished: false,
    validator: null
}

export const initFormDataStructure = (data:any):any => {
    let result:any = {};
    if ((!!data) && (data.constructor === Array)) {
        data.map((item) => {
            Object.assign(result, initFormDataStructure(item));
        });
    } else if (data?.forms?.length > 0) {
        Object.assign(result, initFormDataStructure(data.forms));
    } else if (data?.items?.length > 0) {
        Object.assign(result, initFormDataStructure(data.items));
    } else {
        if (data?.descriptor?.length > 0) {
            if (!data?.is_not_input) {
                if (typeof data?.default_value !== typeof undefined) {
                    result[data?.descriptor] = data.default_value;
                } else {
                    result[data?.descriptor] = null;
                }
            }
        }
        if (data?.sub_items?.length > 0) {
            Object.assign(result, initFormDataStructure(data.sub_items));
        } else if ((!!data?.values) && (data?.values.constructor === Object)) {
            Object.keys(data.values).map((key) => {
                Object.assign(result, initFormDataStructure(data.values[key]));
            });
        }
    }

    return result;
}

export const consultationSlice = createSlice({
    name: 'consultation',
    initialState,
    reducers: {
        init : (state, action: PayloadAction<InitState>) => {
            state.sections = action.payload.sections
            state.currentForm = action.payload.currentForm
            state.currentStep = action.payload.currentStep
            state.currentSection = action.payload.currentSection
            state.formsData = action.payload.formsData ? { ...action.payload.formsData, submitTries: 0 } : initFormDataStructure(action.payload.sections);
            state.formsData.submitTries = 0;
            state.patient_data = {}
            state.form_finished = action.payload.form_finished
            state.validator = action.payload.validator ? action.payload.validator : null;
        },
        nextStep: (state) => {
            if (state.sections[state.currentSection - 1].forms.length < state.currentStep + 1) {
                if (state.sections.length >= state.currentSection + 1) {
                    state.currentSection++;
                    state.currentStep = 1;
                    state.currentForm = state.sections[state.currentSection - 1].forms[state.currentStep - 1];
                } else {

                }
            } else {
                state.currentStep++;
                state.currentForm = state.sections[state.currentSection - 1].forms[state.currentStep - 1];
            }
        },
        nextSection: (state) => {

        },
        prevStep: (state) => {
            if (state.currentStep - 1 === 0) {
                if (state.currentSection - 1 > 0) {
                    state.currentSection--;
                    state.currentStep = state.sections[state.currentSection - 1].forms.length;
                    state.currentForm = state.sections[state.currentSection - 1].forms[state.currentStep - 1];
                } else {

                }
            } else {
                state.currentStep--;
                state.currentForm = state.sections[state.currentSection - 1].forms[state.currentStep - 1];
            }
        },
        prevSection: (state) => {

        },
        setFormData: (state, action) => {
            state.formsData[action.payload.id] = action.payload.value;

            let item = action.payload.item;
            let activeItem:any = null;
            let key = action.payload.key;

            if (key && item.values && item.values[key]) {
                activeItem = item.values[key];
            }

            // Remove Other forms added by old selection
            // TODO: Other scenarios must be added
            if (item && item.values) {
                if ((!!item.values) && (item.values.constructor === Array)) {

                } else if ((!!item.values) && (item.values.constructor === Object)) {
                    Object.keys(item.values).map((ikey) => {
                        if (key && key !== ikey) {
                            if (item.values[ikey].forms && item.values[ikey].has_added) {
                                if (item.values[ikey].show_type === 'end_of_current_section' || item.values[ikey].show_type === 'next_form') {
                                    state.sections[state.currentSection - 1].forms.map((frm, ind) => {
                                        item.values[ikey].forms.map((itemForm:any, indexForm:number) => {
                                            if (frm.descriptor === itemForm.descriptor) {
                                                state.sections[state.currentSection - 1].forms.splice(ind, 1);
                                                state.sections[state.currentSection - 1].forms[state.currentStep - 1].items.map((fitem, findex) => {
                                                    if (item.descriptor === fitem.descriptor && state.sections[state.currentSection - 1].forms[state.currentStep - 1].items[findex].values) {
                                                        state.sections[state.currentSection - 1].forms[state.currentStep - 1].items[findex].values[ikey].has_added = false;
                                                        if (state.currentForm) {
                                                            state.currentForm.items[findex].values[ikey].has_added = false;
                                                        }
                                                    }
                                                });
                                            }
                                        });

                                    });
                                }
                            }
                        }
                    });
                }
            }


            if (activeItem && item && activeItem.forms && !activeItem.has_added && activeItem.forms.length > 0) {
                if (activeItem.show_type === 'end_of_current_section') {
                    state.sections[state.currentSection - 1].forms.push(...activeItem.forms);
                    state.sections[state.currentSection - 1].forms[state.currentStep - 1].items.map((fitem, findex) => {
                        if (item.descriptor === fitem.descriptor && state.sections[state.currentSection - 1].forms[state.currentStep - 1].items[findex].values) {
                            state.sections[state.currentSection - 1].forms[state.currentStep - 1].items[findex].values[key].has_added = true;
                            if (state.currentForm) {
                                state.currentForm.items[findex].values[key].has_added = true;
                            }
                        }
                    });
                } else if (activeItem.show_type === 'next_form') {
                    state.sections[state.currentSection - 1].forms.splice(state.currentStep, 0, ...activeItem.forms);
                    state.sections[state.currentSection - 1].forms[state.currentStep - 1].items.map((fitem, findex) => {
                        if (item.descriptor === fitem.descriptor && state.sections[state.currentSection - 1].forms[state.currentStep - 1].items[findex].values) {
                            state.sections[state.currentSection - 1].forms[state.currentStep - 1].items[findex].values[key].has_added = true;
                            if (state.currentForm) {
                                state.currentForm.items[findex].values[key].has_added = true;
                            }
                        }
                    });
                } else if (activeItem.show_type === 'attached') {

                }
            }

        },
        add_form_item: (state, action) => {

        },
        extendForms: (state, action) => {
            if (action.payload.type === 'add') {
                if (state.currentForm && state.currentForm.items) {

                    if (!state.sections[state.currentSection - 1]?.forms[state.currentStep - 1]?.extendedItems) {
                        state.sections[state.currentSection - 1].forms[state.currentStep - 1].extendedItems = [];
                    }
                    let descriptorPostFix = Math.random();
                    let newItems = JSON.parse(JSON.stringify(state.currentForm.items));
                    newItems.map((it:any, ind:number) => {
                        newItems[ind].descriptor = newItems[ind].descriptor + '_' + descriptorPostFix;
                        state.formsData[newItems[ind].descriptor] = null;
                    });

                    state.sections[state.currentSection - 1].forms[state.currentStep - 1].extendedItems.push(newItems);
                    state.currentForm = state.sections[state.currentSection - 1].forms[state.currentStep - 1];
                }
            } else {
                if (state.sections[state.currentSection - 1].forms[state.currentStep - 1].extendedItems && state.sections[state.currentSection - 1].forms[state.currentStep - 1].extendedItems.length > 0) {
                    state.sections[state.currentSection - 1].forms[state.currentStep - 1].extendedItems.map((item:any, ind:number) => {
                        if (ind === action.payload.index) {
                            item.map((iitem:any ) => {
                                delete state.formsData[iitem.descriptor];
                            });
                        }

                        // delete state.formsData[item.descriptor];
                    });
                    state.sections[state.currentSection - 1].forms[state.currentStep - 1].extendedItems.splice(action.payload.index, 1);
                    state.currentForm = state.sections[state.currentSection - 1].forms[state.currentStep - 1];
                }
            }


        },
        setPatientData: (state, action) => {
            state.patient_data = action.payload;
        },
        setFormFinished: (state) => {
            state.form_finished = true;
            state.currentForm = null;
        },
        inCreaseSubmitTries: (state) => {
            state.formsData.submitTries+=1;
        },
        setValidator: (state, action) => {
            if (state.currentForm) {
                state.currentForm.validator = action.payload;
            }
        }
    },
})

// Action creators are generated for each case reducer function
export const { nextSection, nextStep,prevSection, prevStep, init , setFormData, add_form_item, extendForms, setPatientData, setFormFinished, inCreaseSubmitTries, setValidator} = consultationSlice.actions

export default consultationSlice.reducer