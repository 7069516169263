import Item from "./Fields/Item";
import {Box, Button, VStack} from "@chakra-ui/react";
import FieldModal from "../modals/FieldModal";
import {useState} from "react";

const RenderItems = (props:any) => {

    const {
        items,
        sections,
        form,
        currentSectionIndex,
        currentFormIndex,
        updateData,
        indexH,
        preDelete,
        swapItems
    } = props;

    const [action, setAction] = useState<any>(null)
    const [fieldModalIsOpen, setFieldModalIsOpen] = useState(false)
    const [currentField, setCurrentField] = useState<any>(null)
    const [currentFieldIndex, setCurrentFieldIndex] = useState<any>(null)

    const [parentIndex, setParentIndex] = useState(indexH)


    const closeFieldModal = () => {
        setFieldModalIsOpen(false);
        setAction(null)
    }

    const preAddField = (pIndex:string|null) => {
        if (pIndex) {
            setParentIndex(indexH + pIndex + '|')
        }
        setAction('add_field')
        setFieldModalIsOpen(true);
    }

    const preEditField = (pIndex:string, field:any) => {
        setParentIndex(pIndex);
        setCurrentField(field);
        setAction('edit_field')
        setFieldModalIsOpen(true);
    }

    const storeField = (type:string, action:any, fieldData:any, pIndex:string) => {
        updateData(type, action, fieldData, pIndex);
    }

    const preDeleteField = (field:any) => {
        setCurrentField(field);
        setAction('delete_field')
    }

    const deleteField = (field:any) => {

    }

    return (
        <>
            <VStack w='full'>
                {items && items.map((item:any, key:number) =>
                    <Item
                        indexH={indexH + 'items|' + key + '|'}
                        key={key}
                        preEdit={preEditField}
                        preDelete={preDelete}
                        updateData={storeField}
                        doDelete={deleteField}
                        currentSectionIndex={currentSectionIndex}
                        sections={sections}
                        item={item}
                        swapItems={swapItems}
                    ></Item>
                )}

                <Box>
                    <Button
                        isDisabled={!form}
                        onClick={() => {
                            preAddField('items');
                        }}
                    >
                        Add New Field
                    </Button>
                </Box>
            </VStack>

            { form && fieldModalIsOpen &&
                <FieldModal
                    indexH={parentIndex}
                    isOpen={fieldModalIsOpen}
                    onClose={closeFieldModal}
                    sections={sections}
                    currentForm={form}
                    currentSection={sections}
                    data={action === 'edit_field' ? currentField : null}
                    onSubmit={(type:string, action:string, fieldData:any, pIndex:any) => {
                        storeField(type, action, fieldData, pIndex);
                        setFieldModalIsOpen(false);
                    }}
                ></FieldModal>
            }
        </>
    );
}

export default RenderItems;