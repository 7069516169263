/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { General } from '../models/General';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class DefaultService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get bookings from squareup
     * @param startAtMin
     * @param startAtMax
     * @returns any The general response
     * @throws ApiError
     */
    public getBookings(
        startAtMin?: string,
        startAtMax?: string,
    ): CancelablePromise<{
        bookings: Array<{
            id: string;
            given_name?: string;
            family_name?: string;
            email?: string;
            phone_number?: string;
            start_at?: string;
            status?: string;
            customer_note?: string;
        }>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/apps/squareup/bookings',
            query: {
                'start_at_min': startAtMin,
                'start_at_max': startAtMax,
            },
            errors: {
                400: `The request body is invalid`,
                401: `The request body is invalid`,
            },
        });
    }
    /**
     * Retrieve and store access token from squareup app
     * @param requestBody
     * @returns General The general response
     * @throws ApiError
     */
    public squareupAuthorize(
        requestBody: {
            code: string;
        },
    ): CancelablePromise<General> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/apps/squareup/authorize',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `The request body is invalid`,
                401: `The request body is invalid`,
            },
        });
    }
    /**
     * Retrieve and store access token from squareup app
     * @returns General The general response
     * @throws ApiError
     */
    public squareupRefreshToken(): CancelablePromise<General> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/apps/squareup/refresh',
            errors: {
                400: `The request body is invalid`,
                401: `The request body is invalid`,
            },
        });
    }
    /**
     * Retrieve patient information based on its unique_id
     * @param uniqueId
     * @returns any Success
     * @throws ApiError
     */
    public getPatientInfo(
        uniqueId: string,
    ): CancelablePromise<{
        data: Record<string, any>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/consultation/request',
            query: {
                'unique_id': uniqueId,
            },
            errors: {
                400: `The request body is invalid`,
                401: `The request body is invalid`,
                403: `The request body is invalid`,
                404: `The record could not be found`,
            },
        });
    }
    /**
     * Create patient record in consultation page
     * @param requestBody
     * @returns any The general insert response
     * @throws ApiError
     */
    public createPatient(
        requestBody: {
            unique_id?: string;
            center_id: number;
            first_name: string;
            last_name: string;
            birthday: string;
            occupation?: string;
            email?: string;
            mobile?: string;
            address?: Record<string, any>;
            emergency_contact?: {
                emergency_contact_full_name?: string;
                emergency_contact_phone?: string;
                emergency_contact_email?: string;
            };
            contact_method?: string;
        },
    ): CancelablePromise<{
        data: {
            id: string;
            unique_id: string;
        };
        message?: string;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/consultation/request',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `The request body is invalid`,
                401: `The request body is invalid`,
                403: `The request body is invalid`,
            },
        });
    }
    /**
     * Upserts consultation form data for a patient
     * @param requestBody
     * @returns any The general insert response
     * @throws ApiError
     */
    public createForm(
        requestBody: {
            unique_id: string;
            data: Record<string, any>;
        },
    ): CancelablePromise<{
        data: {
            id: string;
        };
        message?: string;
    }> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v1/consultation/request',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `The request body is invalid`,
                401: `The request body is invalid`,
                403: `The request body is invalid`,
            },
        });
    }
}
