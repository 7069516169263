import {FormControl, FormLabel, Text, VStack} from "@chakra-ui/react";
import MobileAuthorizer from "../../../../../components/MobileAuthorizer";
import AddressBox from "../../../../../components/FromsElements/AddressBox";
import Item from "./Item";

const ComponentField = (props:any) => {
    const {
        item,
        preDelete,
        preEdit,
        currentSectionIndex,
        indexH,
        updateData,
        sections,
        swapItems
    } = props;

    return (
        <FormControl w='full'>
            {/*<FormLabel>*/}
            {/*    {item.required && (*/}
            {/*        <Text as="span" color="red.500" fontSize="14px" me="5px">*/}
            {/*            **/}
            {/*        </Text>*/}
            {/*    )}{item.title}*/}
            {/*</FormLabel>*/}

            {item.component === 'MobileAuthorizer' &&
                <MobileAuthorizer
                    state={{mobile: ''}}
                    item={item}
                ></MobileAuthorizer>
            }
            {item.component === 'AddressBox' &&
                <VStack w='full'>
                    <AddressBox
                        state={{address: ''}}
                        item={item}
                    ></AddressBox>

                    {item.sub_items && item.sub_items.map((subItem:any, key:number) => {
                        return (
                            <Item swapItems={swapItems} updateData={updateData} indexH={indexH + 'sub_items|' + key + '|'} sections={sections} currentSectionIndex={currentSectionIndex} preDelete={preDelete} preEdit={preEdit} item={subItem}></Item>
                        );
                    }) }
                </VStack>
            }

        </FormControl>
    );
}

export default ComponentField;