import ModalBox from "../../../components/ModalBox";
import SelectBox from "../../../components/FromsElements/SelectBox";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import useCustomToast from "../../../hooks/useCustomToast";
import { FormItem } from "../../../interfaces/Interfaces";
import Uploader from "../../../components/Uploader";
import axiosInstance from "../../../helpers/axiosInstance";
import { cleanFolderName } from "../../../helpers/StringUtil";
import TextBox from "../../FromsElements/TextBox";
import {
  Button,
  HStack,
  ModalBody,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
interface CreateFolderProps {
  isOpen: boolean;
  closeAction: any;
  addAction: any;
  basePath: string;
  type: string;
  folders?: Array<any>;
}
const CreateFolderModal = (props: CreateFolderProps) => {
  const [errors, setErrors] = useState<Array<string>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataState, setDataState] = useState({
    name: "",
  });
  const toast = useCustomToast();
  const [isOk, setIsOk] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setErrors([]);
    if (!dataState.name.length) {
      setIsOk(false);
    } else if (props.folders) {
      let founded = false;

      for (let item of props.folders) {
        if (item.name.toLowerCase() === dataState.name.toLowerCase()) {
          founded = true;
          setErrors(["This folder already exists!"]);
        }
      }

      props.folders.map((folder: any) => {});

      if (founded) {
        setIsOk(false);
      } else {
        setIsOk(true);
      }
    } else {
      setIsOk(true);
    }
  }, [dataState]);

  return (
    <ModalBox
      isOpen={props.isOpen}
      onClose={() => {
        props.closeAction();
      }}
      size="xl"
    >
      <ModalBody pb={6}>
        <VStack w="full">
          {errors.length && (
            <VStack w="full">
              {errors.map((error) => (
                <Text color="red">{error}</Text>
              ))}
            </VStack>
          )}
          <TextBox
            w="full"
            state={dataState}
            item={{
              descriptor: "name",
              title: "Folder Name",
              values: [],
            }}
            inputItemHandler={(value: string, item: any) => {
              setDataState({
                name: value,
              });
            }}
          ></TextBox>

          <HStack>
            <Button
              onClick={() => {
                props.closeAction();
              }}
            >
              Cancel
            </Button>
            <Button
              isDisabled={!isOk || isLoading}
              onClick={async () => {
                setIsLoading(true);
                await props.addAction(dataState.name);
                setIsLoading(false);
              }}
            >
              {isLoading && <Spinner px="5px"></Spinner>}
              Create
            </Button>
          </HStack>
        </VStack>
      </ModalBody>
    </ModalBox>
  );
};

export default CreateFolderModal;
