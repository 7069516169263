import {
  Box,
  Button,
  Card,
  CardBody,
  Container,
  Heading,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
  Text,
} from "@chakra-ui/react";
import {
  Link as ReactRouterLink,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  ArrowBackIcon,
  ArrowForwardIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CloseIcon, ViewIcon
} from "@chakra-ui/icons";
import {
  GET_PATIENTS_WITH_CONSULTATION_FORM,
} from "./queries";
import ListTable from "../../../components/FromsElements/ListTable";
import { TableFieldInterface } from "../../../interfaces/Interfaces";
import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import {
  GetPatientsWithConsultationFormListQuery,
  GetPatientsWithConsultationFormListQueryVariables,
} from "../../../gql/graphql";
import { useDebounce } from "use-debounce";
import useAuth from "../../../hooks/useAuth";

const ConsultationForms = () => {
  const fields: Array<TableFieldInterface> = [
    {
      title: "#",
      descriptor: "",
      expression: "INDEX_NUMBER_FIELD",
    },
    {
      title: "Patient Name",
      descriptor: ["first_name", "last_name"],
    },
    {
      title: "Patient Code",
      descriptor: "customer_code",
    },
    {
      title: "Email",
      descriptor: "email",
    },
    {
      title: "Phone",
      descriptor: "mobile",
    },
    {
      title: "Request Date",
      descriptor: "created_at",
      expression: "DATE_TIME_HR",
    },
    {
      title: "Action",
      descriptor: "",
      expression: "ACTION_FIELD",
    },
  ];
  const navigate = useNavigate();
  const { user_id } = useAuth();
  const params = useParams();
  const [keyword, setKeyword] = useState("");
  const [debouncedSearchInputValue] = useDebounce(keyword, 500);
  const [page, setPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [lastPage, setLastPage] = useState(1);
  const [pages, setPages] = useState<Array<number>>([]);
  const [records, setRecords] = useState<Array<any>>([]);
  const perPage = 10;

  const sanitizedSearch = debouncedSearchInputValue?.trim().replace(/^#/, "");
  const {data, loading, refetch} = useQuery<
    GetPatientsWithConsultationFormListQuery,
    GetPatientsWithConsultationFormListQueryVariables
  >(GET_PATIENTS_WITH_CONSULTATION_FORM, {
    skip: !user_id,
    variables: {
      where: {
        _and: [
          { center_id: { _eq: Number(process.env.REACT_APP_CENTER_ID) } },
          { form: { descriptor: { _eq: "CONSULTATION_FORM" } } },
          { patient: {
              _or: [
                { first_name: { _ilike: "%" + sanitizedSearch + "%" } },
                { last_name: { _ilike: "%" + sanitizedSearch + "%" } },
                { customer_code: { _ilike: "%" + sanitizedSearch + "%" } },
                { mobile: { _ilike: "%" + sanitizedSearch + "%" } },
                { email: { _ilike: "%" + sanitizedSearch + "%" } }
              ]
          } }
        ]        
      },
      limit: perPage,
      offset: (page - 1) * perPage,
    },
  });

  const makePaginationUrl = (page: number) => {
    if (page < 1) {
      page = 1;
    } else if (page > lastPage) {
      page = lastPage;
    }
    let path = "/dashboard/consultation-forms/" + page;

    if (keyword) {
      path += "/" + keyword;
    }

    return path;
  };

  const getPagination = () => {
    let step = 2;
    let total = 2 * step + 1;
    let startFrom = 1;
    let endTo = lastPage;
    let hasLeft = page - step > 1;
    let hasRight = page + step < lastPage;

    if (hasRight) {
      endTo = page + step;
    }

    if (hasLeft) {
      startFrom = page - step;
    }

    let temp = [];
    for (let i = startFrom; i <= endTo; i++) {
      temp.push(i);
    }
    setPages(temp);
  };
  useEffect(() => {
    if (data?.form_data_aggregate?.aggregate?.count) {
      setTotalRecord(
        data?.form_data_aggregate?.aggregate?.count
      );
    } else {
      setTotalRecord(0);
    }
  }, [data]);
  useEffect(() => {
    if (totalRecord) {
      let temp = totalRecord / perPage;
      let floored = Math.floor(totalRecord / perPage);

      if (temp == floored) {
        setLastPage(temp);
      } else {
        setLastPage(floored + 1);
      }
    } else {
      setLastPage(1);
    }
  }, [totalRecord]);

  useEffect(() => {
    getPagination();
  }, [lastPage, page]);

  useEffect(() => {
    if (params?.k && params.k.length >= 3) {
      setKeyword(params.k);
    }

    if (params?.p) {
      let p = parseInt(params.p);

      if (p >= 1 && p <= 100) {
        setPage(p);
      }
    }
  }, [params]);

  useEffect(() => {
    let temp: Array<any> = [];
    if (data?.form_data?.length) {
      for (let formData in data.form_data) {
        let td = JSON.parse(
          JSON.stringify(data.form_data[formData].patient)
        );
        td["created_at"] =
          data.form_data[formData].created_at;
        temp.push(td);
      }
    }
    setRecords(temp);
  }, [data]);

  return (
    <Container w="full" maxW="container.xl" mt="35px">
      <HStack flexWrap="wrap">
        <Button
          as={ReactRouterLink}
          to={`/dashboard/`}
          display="flex"
          bg="transparent"
          borderRadius="100px"
          h="48px"
          w="120px"
          border="1px solid"
          borderColor="gray.300"
        >
          <ChevronLeftIcon fontSize="xl"></ChevronLeftIcon>
          <Text fontSize="md" mx="10px">
            Back
          </Text>
        </Button>
        <Heading fontSize="30px" mx="15px">
          Consultation Forms
        </Heading>
      </HStack>

      <Card
        my="30px"
        w="full"
        border="1px solid"
        borderColor="gray.300"
        boxShadow="none"
        borderRadius="16px"
      >
        <CardBody position="relative">
          <Box mb="30px">
            <InputGroup h="48px">
              <InputLeftElement h="full" w="38px" justifyContent="end">
                <Icon as={FiSearch} fontSize="24px" color="gray.500"></Icon>
              </InputLeftElement>
              <Input
                key="patient_list_keyword"
                paddingStart="50px"
                paddingEnd="50px"
                placeholder="Search ..."
                borderRadius="12px"
                bg="white"
                h="full"
                value={keyword}
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
              />
              <InputRightElement
                h="full"
                w="60px"
                justifyContent="center"
                cursor="pointer"
                onClick={() => {
                  setKeyword("");
                }}
              >
                {keyword.length > 0 && <CloseIcon></CloseIcon>}
              </InputRightElement>
            </InputGroup>
          </Box>
          {data?.form_data && (
            <ListTable
              data={records}
              fields={fields}
              page={page}
              total={totalRecord}
              loading={loading}
              hasPagination={true}
              perPage={perPage}
              actionField={(id: any) => {
                return (
                  <Button
                    onClick={() => {
                      navigate("/dashboard/patient/" + id, {
                        state: { back_url: makePaginationUrl(page) },
                      });
                    }}
                  >
                    <ViewIcon></ViewIcon>
                  </Button>
                );
              }}
            ></ListTable>
          )}

          {!data?.form_data && (
            <HStack
              w="full"
              h="100px"
              justifyContent="center"
              alignItems="center"
            >
              <Spinner fontSize="24px"></Spinner>
            </HStack>
          )}

          {records && lastPage > 1 && (
            <HStack mt="30px" w="full" justifyContent="center" spacing="10px">
              <Button
                isDisabled={page === 1}
                as={ReactRouterLink}
                to={makePaginationUrl(1)}
              >
                <ArrowBackIcon fontSize="24px"></ArrowBackIcon>
              </Button>
              <Button
                isDisabled={page === 1}
                as={ReactRouterLink}
                to={makePaginationUrl(page - 1)}
              >
                <ChevronLeftIcon fontSize="24px"></ChevronLeftIcon>
              </Button>

              {pages.map((i) => (
                <Button
                  variant={page == i ? "btnMain" : ""}
                  as={ReactRouterLink}
                  to={makePaginationUrl(i)}
                >
                  {i}
                </Button>
              ))}

              <Button
                isDisabled={page === lastPage}
                as={ReactRouterLink}
                to={makePaginationUrl(page + 1)}
              >
                <ChevronRightIcon fontSize="24px"></ChevronRightIcon>
              </Button>
              <Button
                isDisabled={page === lastPage}
                as={ReactRouterLink}
                to={makePaginationUrl(lastPage)}
              >
                <ArrowForwardIcon fontSize="24px"></ArrowForwardIcon>
              </Button>
            </HStack>
          )}
        </CardBody>
      </Card>
    </Container>
  );
};

export default ConsultationForms;
