import { gql } from "@apollo/client";

export const ADD_FORM_SCHEMA = gql`
  mutation AdminAddFormSchema($object: form_schemas_insert_input!) {
    insert_form_schemas_one(object: $object) {
      id
    }
  }
`;

export const UPDATE_FORM_SCHEMA = gql`
  mutation AdminUpdateFormSchema($id: Int!, $object: form_schemas_set_input!) {
    update_form_schemas_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
    }
  }
`;