import { TableFieldInterface } from "../../../../../interfaces/Interfaces";

export const fields: Array<TableFieldInterface> = [
    {
      title: "Patient Name",
      descriptor: ["first_name", "last_name"],
    },
    {
      title: "Request Date",
      descriptor: "created_at",
      expression: "DATE_TIME_HR",
    },
    {
      title: "Action",
      descriptor: "",
      expression: "ACTION_FIELD",
    },
  ];