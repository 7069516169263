import {Box, Checkbox, FormControl, FormLabel, Input, Text} from "@chakra-ui/react";

const CheckBoxField = (props:any) => {
    const {
        item
    } = props;
    return (
        <FormControl w='full'>
            <FormLabel>
                {item.required && (
                <Text as="span" color="red.500" fontSize="14px" me="5px">
                    *
                </Text>
                )}
                <Box fontSize="16px" dangerouslySetInnerHTML={{__html: item.title}}></Box>
            </FormLabel>
            <Checkbox
                isChecked={item.default_value.toString() === 'true'}
            >
                {item.text && item.text.length > 0 &&
                    <Box fontSize="16px" dangerouslySetInnerHTML={{__html: item.text}}></Box>
                }
            </Checkbox>
        </FormControl>
    );
}

export default CheckBoxField;