import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Card,
  CardBody,
  Container,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spinner,
  Square,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Link } from "@chakra-ui/react";
import { Link as ReactRouterLink, useParams } from "react-router-dom";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DeleteIcon,
  EditIcon,
  LinkIcon,
} from "@chakra-ui/icons";
import * as React from "react";
import { getPatientImageBasePath } from "../../../../helpers/S3Options";
import { useLazyQuery } from "@apollo/client";
import { GET_META_DATA, GET_PATIENT_DATA } from "../queries";
import { useEffect, useState } from "react";
import axiosInstance from "../../../../helpers/axiosInstance";
import AddPictureModal from "./modals/AddPictureModal";
import { IoIosResize } from "react-icons/io";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { getHRFormat } from "../../../../helpers/DateUtil";
import Confirmation from "../../../../components/Confirmation";
import useCustomToast from "../../../../hooks/useCustomToast";
import FileIcon from "../../../../components/FileIcon";
import { cleanFolderName } from "../../../../helpers/StringUtil";
import {
  GetMetaDataQuery,
  GetMetaDataQueryVariables,
} from "../../../../gql/graphql";

const Pictures = () => {
  const params = useParams();
  const toast = useCustomToast();
  const [slidePictureIsLoading, setSlidePictureIsLoading] = useState(false);
  const [basePath, setBasePath] = useState("");
  const [pictures, setPictures] = useState<Array<any> | null>(null);
  const [pictureIsLoading, setPictureIsLoading] = useState(true);
  const [addPictureModalIsOpen, setAddPictureModalIsOpen] = useState(false);
  const [deletePictureIsLoading, setDeletePictureIsLoading] = useState(false);
  const [keyToDelete, setKeyToDelete] = useState<string | null>(null);
  const [currentPicture, setCurrentPicture] = useState<any>({
    name: "",
    tag: "",
    type: "",
    url: "",
    index: 0,
  });
  const [picturesIsEmpty, setPicturesIsEmpty] = useState(true);
  const [tags, setTags] = useState<Array<string>>([]);

  const [getMetaData, pictureFoldersMetaData] = useLazyQuery<
    GetMetaDataQuery,
    GetMetaDataQueryVariables
  >(GET_META_DATA, {
    variables: {
      descriptor: "PATIENT_PICTURE_FOLDERS",
      centerId: Number(process.env.REACT_APP_CENTER_ID),
    },
  });

  const [getPatientData, { error, loading, data, called }] = useLazyQuery(
    GET_PATIENT_DATA,
    {
      fetchPolicy: "no-cache",
      variables: {
        id: params.pid,
      },
    }
  );

  const addSubFolder = (parent: any, child: any) => {};

  useEffect(() => {
    if (pictures && pictureFoldersMetaData?.data?.meta_data[0]?.data) {
      let toBeAddedFolder: any = [];
      let currentFolders = Object.keys(pictures);
      let tempTags = currentFolders;

      for (let folder of pictureFoldersMetaData?.data?.meta_data[0]?.data) {
        if (currentFolders.indexOf(folder.path) === -1) {
          toBeAddedFolder.push(basePath + folder.path);
          tempTags.push(folder.path);
        }
      }

      if (toBeAddedFolder.length > 0) {
        axiosInstance().post("/storage/create_folder", {
          keys: toBeAddedFolder,
        }, {
            headers: {
                "Authorization": 'Bearer ' + localStorage.getItem('access_token')
            }
        });
      }

      setTags(tempTags);
    }
  }, [pictureFoldersMetaData, pictures]);

  const getPictures = async () => {
    setPictureIsLoading(true);

    let list = await axiosInstance().post("/storage/list", {
      key: basePath,
    }, {
        headers: {
            "Authorization": 'Bearer ' + localStorage.getItem('access_token')
        }
    });

    if (list?.data?.data?.files) {
      let tempPictures = [];
      let tempFolders: any = {
        "/": [],
      };

      for (let file of list?.data?.data?.files) {
        let tempKey = file.key.replace(basePath, "");

        if (file.type === "folder") {
          if (!tempFolders[tempKey]) {
            tempFolders[tempKey] = [];
          }
        } else if (file.type === "image") {
          let tempFolderIndex = tempKey.replace(file.name, "");
          if (tempFolderIndex.length > 0) {
            file.tag = tempFolderIndex;
            file.index = tempFolders[tempFolderIndex].length;
            tempFolders[tempFolderIndex].push(file);
          } else {
            file.tag = "/";
            file.index = tempFolders["/"].length;
            tempFolders["/"].push(file);
          }
          setPicturesIsEmpty(false);
        }
        tempPictures.push(file);
      }
      setPictures(tempFolders);
    }

    setPictureIsLoading(false);
  };

  useEffect(() => {
    getMetaData();
    if (params.pid) {
      getPatientData();
    }
  }, [params]);

  useEffect(() => {
    if (data?.patients_by_pk) {
      setBasePath(getPatientImageBasePath(data?.patients_by_pk) + "pictures/");
    }
  }, [data]);

  useEffect(() => {
    if (basePath) {
      getPictures();
    }
  }, [basePath]);

  return (
    <Container w="full" maxW="container.xl" mt="35px">
      <HStack flexWrap="wrap">
        <Button
          as={ReactRouterLink}
          to={`/dashboard/patient/${params.pid}`}
          display="flex"
          bg="transparent"
          borderRadius="100px"
          h="48px"
          w="120px"
          border="1px solid"
          borderColor="gray.300"
        >
          <ChevronLeftIcon fontSize="xl"></ChevronLeftIcon>
          <Text fontSize="md" mx="10px">
            Back
          </Text>
        </Button>

        <Heading fontSize="30px" mx="15px">
          General Photos
        </Heading>

        {basePath && (
          <HStack flex="1" justifyContent="end">
            <Button
              variant="btnNormal"
              mx="10px"
              onClick={() => {
                setAddPictureModalIsOpen(true);
              }}
            >
              upload
            </Button>
          </HStack>
        )}
      </HStack>

      {pictureIsLoading && (
        <Flex justifyContent="center" alignItems="center" h="100px" w="full">
          <Spinner></Spinner>
        </Flex>
      )}

      {!pictureIsLoading && (
        <Card
          my="30px"
          w="full"
          boxShadow="null"
          borderRadius="16px"
          border="1px solid"
          borderColor="gray.300"
          position="relative"
        >
          <CardBody p="30px">
            {picturesIsEmpty && (
              <Alert status="info">
                <AlertIcon />
                There is no pictures to show. you can
                <Button
                  variant="btnNormal"
                  mx="10px"
                  onClick={() => {
                    setAddPictureModalIsOpen(true);
                  }}
                >
                  upload your pictures
                </Button>
              </Alert>
            )}

            {!picturesIsEmpty && (
              <VStack w="full" spacing="15px" alignItems="start">
                {pictures &&
                  Object.keys(pictures).map((folder: any, folderIndex) => {
                    if (pictures[folder].length === 0) {
                      return <Text as="span" key={folderIndex}></Text>;
                    } else {
                      return (
                        <VStack
                          key={folderIndex}
                          w="full"
                          spacing="30px"
                          alignItems="start"
                        >
                          <Heading fontSize="18px">
                            {folder === "/"
                              ? "General"
                              : cleanFolderName(folder)}
                          </Heading>
                          <SimpleGrid
                            columns={{ base: 1, md: 2, lg: 3, xl: 4 }}
                            gap="15px"
                            mt="25px"
                            w="full"
                          >
                            {pictures[folder].map(
                              (file: any, index: number) => (
                                <Card
                                  boxShadow="none"
                                  key={index}
                                  border="1px solid"
                                  borderColor="#cccccc"
                                  w="full"
                                  p="0"
                                  overflow="hidden"
                                  borderRadius="16px"
                                >
                                  <CardBody
                                    justifyContent="center"
                                    alignItems="center"
                                    p="0"
                                    position="relative"
                                  >
                                    <Box
                                      position="absolute"
                                      top="15px"
                                      right="15px"
                                    >
                                      <Square
                                        size="25px"
                                        bg="#f8f8f8"
                                        borderRadius="8px"
                                        onClick={() => {
                                          setCurrentPicture(file);
                                          setSlidePictureIsLoading(true);
                                        }}
                                      >
                                        <Icon
                                          color="#5C5C5C"
                                          fontSize="15px"
                                          as={IoIosResize}
                                        ></Icon>
                                      </Square>
                                    </Box>
                                    <VStack
                                      h="full"
                                      justifyContent="space-between"
                                    >
                                      <Image
                                        src={file.url}
                                        w="full"
                                        maxH="207px"
                                        objectFit="cover"
                                      ></Image>
                                      <HStack
                                        px="15px"
                                        w="full"
                                        mb="10px"
                                        justifyContent="space-between"
                                      >
                                        <Menu>
                                          <MenuButton
                                            w="32px"
                                            h="32px"
                                            minW="32px"
                                            as={IconButton}
                                            aria-label="Options"
                                            icon={
                                              <HiOutlineDotsHorizontal
                                                size="20"
                                                color="black"
                                              />
                                            }
                                            variant="link"
                                            border="0px"
                                          />
                                          <MenuList>
                                            <MenuItem
                                              fontSize="14px"
                                              icon={
                                                <EditIcon fontSize="16px" />
                                              }
                                              onClick={() => {
                                                setKeyToDelete(file.key);
                                              }}
                                            >
                                              Delete Image
                                            </MenuItem>
                                          </MenuList>
                                        </Menu>
                                      </HStack>
                                    </VStack>
                                  </CardBody>
                                </Card>
                              )
                            )}
                          </SimpleGrid>
                          <Divider></Divider>
                        </VStack>
                      );
                    }
                  })}
              </VStack>
            )}
          </CardBody>
        </Card>
      )}
      {addPictureModalIsOpen && (
        <AddPictureModal
          isOpen={addPictureModalIsOpen}
          basePath={basePath}
          tags={tags}
          closeAction={() => {
            setAddPictureModalIsOpen(false);
          }}
          addAction={async () => {
            getPictures();
            setAddPictureModalIsOpen(false);
          }}
        ></AddPictureModal>
      )}

      {keyToDelete && (
        <Confirmation
          isOpen={!!keyToDelete && keyToDelete?.length > 0}
          isLoading={deletePictureIsLoading}
          closeAction={() => {
            setKeyToDelete(null);
          }}
          acceptAction={async () => {
            setDeletePictureIsLoading(true);
            try {
              let result = await axiosInstance().post(
                "/storage/delete",
                {
                  key: keyToDelete,
                },
                {
                  headers: {
                    Authorization: 'Bearer ' + localStorage.getItem("access_token"),
                  },
                }
              );

              if (result.data.status === 0) {
                toast.success("File removed successfully.");

                setCurrentPicture(null);

                getPictures();

                setKeyToDelete(null);
              } else {
                toast.error("There is a problem on deleting this File.");
              }
            } catch (err) {
              toast.error("There is a problem on deleting this File.");
            }
            setDeletePictureIsLoading(false);
          }}
        >
          Are you sure to Delete this picture?
        </Confirmation>
      )}

      {pictures &&
        Object.keys(pictures)?.length > 0 &&
        currentPicture?.url?.length > 0 && (
          <Modal
            size="xl"
            isOpen={currentPicture?.url?.length > 0}
            onClose={() => {
              setCurrentPicture(null);
            }}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader fontSize="17px">Picture</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <HStack w="full">
                  {pictures[currentPicture.tag]?.length > 1 && (
                    <Button
                      w="40px"
                      h="40px"
                      borderRadius="100%"
                      onClick={() => {
                        let newIndex = currentPicture.index - 1;
                        if (newIndex < 0) {
                          newIndex = pictures[currentPicture.tag]?.length - 1;
                        }

                        setCurrentPicture(
                          pictures[currentPicture.tag][newIndex]
                        );
                        setSlidePictureIsLoading(true);
                      }}
                    >
                      <ChevronLeftIcon fontSize="15px"></ChevronLeftIcon>
                    </Button>
                  )}

                  <Box flex="1" position="relative">
                    <HStack
                      position="absolute"
                      top="0"
                      right="0"
                      left="0"
                      bottom="0"
                      bg="#ffffff"
                      alignItems="center"
                      justifyContent="center"
                      zIndex={slidePictureIsLoading ? 100000 : -1}
                    >
                      <Spinner size="lg"></Spinner>
                    </HStack>
                    {currentPicture?.url && (
                      <Image
                        w="full"
                        src={currentPicture.url}
                        onLoad={() => {
                          setSlidePictureIsLoading(false);
                        }}
                      ></Image>
                    )}
                  </Box>
                  {pictures[currentPicture.tag]?.length > 1 && (
                    <Button
                      w="40px"
                      h="40px"
                      borderRadius="100%"
                      onClick={() => {
                        let newIndex = currentPicture.index + 1;
                        if (newIndex >= pictures[currentPicture.tag]?.length) {
                          newIndex = 0;
                        }

                        setCurrentPicture(
                          pictures[currentPicture.tag][newIndex]
                        );
                        setSlidePictureIsLoading(true);
                      }}
                    >
                      <ChevronRightIcon fontSize="15px"></ChevronRightIcon>
                    </Button>
                  )}
                </HStack>
              </ModalBody>

              <ModalFooter>
                <HStack w="full" justifyContent="space-between">
                  <HStack>
                    <Menu>
                      <MenuButton
                        w="32px"
                        h="32px"
                        minW="32px"
                        as={IconButton}
                        aria-label="Options"
                        icon={
                          <HiOutlineDotsHorizontal size="20" color="black" />
                        }
                        variant="link"
                        border="0px"
                      />
                      <MenuList>
                        <MenuItem
                          fontSize="14px"
                          icon={<EditIcon fontSize="16px" />}
                          onClick={() => {
                            setKeyToDelete(currentPicture.key);
                          }}
                        >
                          Delete Image
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </HStack>

                  <Box
                    p="5px"
                    borderRadius="8px"
                    fontSize="14px"
                    fontWeight="500"
                    bg="#F0F6FF"
                    color="gray"
                  >
                    {currentPicture.index + 1} /{" "}
                    {pictures[currentPicture.tag]?.length}
                  </Box>
                </HStack>
              </ModalFooter>
            </ModalContent>
          </Modal>
        )}
    </Container>
  );
};

export default Pictures;
