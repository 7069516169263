import React, {useEffect} from 'react';
import {FormItemProps} from "../../../interfaces/Interfaces";
import {Text, FormControl, FormLabel, RadioGroup, Stack, Radio, VStack, Box} from "@chakra-ui/react";
import {useDispatch} from "react-redux";
import {extendForms, nextStep, setFormData} from "../../../store/slice";
import {FormContainer} from "../FormContainer";
const ActionOptionBox = (props: FormItemProps) => {
    const dispatch = useDispatch()
    let item = props.item;
    let inputItemHandler = props.inputItemHandler;
    let state = props.state;
    let validator = props.validator;
    let IsInvalidMessage = (validator && item.validation_rule)? validator.message(item.title, state[item.descriptor], item.validation_rule) : null;

    const handleClick = (activeItem:any, key: string) => {
        if (validator) {
            validator.purgeFields();
        }

        if (inputItemHandler) {
            inputItemHandler({
                "key" : key,
                "id" : item.descriptor,
                "value" : activeItem.value,
                "item" : item
            }, item);
        }

        if (activeItem.action === 'nextStep') {
            dispatch(nextStep())
        }
    }

    return (
        <>
            <FormControl>
                {item.title.length > 0 &&
                    <FormLabel flexDirection="column">
                        {item.required &&
                            <Text as="span" color="red.500" fontSize="14px" me="5px">*</Text>
                        }
                        <Text as="span">{item.title}</Text>
                    </FormLabel>
                }

                <RadioGroup value={state[item.descriptor] ? state[item.descriptor] : ''} key={item.descriptor}>
                    <Stack>
                        {item.values &&
                            Object.keys(item.values).map((key:string) => {
                                return (<Radio w="full" key={item.descriptor + '_' + key} value={key} onChange={() => {
                                    if (item.values) {
                                        handleClick(item.values[key], key)
                                    }
                                }
                                } isInvalid={IsInvalidMessage ? IsInvalidMessage : false}>{item.values ? item.values[key].name : ''}</Radio>)
                            })
                        }
                    </Stack>
                </RadioGroup>
                {!item?.hide_validation_error && IsInvalidMessage}
            </FormControl>
            { typeof state[item.descriptor] !== typeof undefined &&
                <>
                    {item.values &&
                        Object.keys(item.values).map((key:string) => {
                            if (item.values && state[item.descriptor] === item.values[key].value && item.values[key].show_type === 'attached') {
                                return (
                                    <Box mt="20px" key={item.descriptor + key}>
                                        <FormContainer form={item.values[key]}  validator={validator} inputItemHandler={inputItemHandler} extendFormHandler={extendForms} state={state}></FormContainer>
                                    </Box>
                                );
                            }
                        })
                    }
                </>
            }
        </>
    );
}

export default ActionOptionBox;
