import {
    Button,
    FormControl,
    FormHelperText,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    VStack,
    Select
} from "@chakra-ui/react";
import {useEffect, useState} from "react";

const SectionModal = (props:any) => {

    const {
        onClose,
        onSubmit,
        data,
        sections,
        currentSectionIndex
    } = props;

    const defaultSectionData = {
        descriptor: '',
        title: '',
        schema: '',
        place: 'end',
        section: ''
    };

    const [sectionData, setSectionData] = useState<any>(null);

    useEffect(() => {
        if (data) {
            setSectionData({
                descriptor: data.descriptor,
                title: data.title,
                schema: data.schema,
                place: '',
                section: ''
            })
        } else {
            setSectionData(defaultSectionData)
        }
    }, []);

    return (
        <>
            { sectionData &&
                <Modal isOpen={true} onClose={onClose} size='xl'>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>
                            {data?.title ? 'Edit Section : ' + data.title : 'Create New Section'}
                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <VStack w='full' gap='15px'>
                                <FormControl>
                                    <FormLabel>Title</FormLabel>
                                    <Input type='text' value={sectionData.title} onChange={(e) => {setSectionData({...sectionData, title: e.target.value})}} />
                                </FormControl>

                                <FormControl>
                                    <FormLabel>Descriptor</FormLabel>
                                    <Input type='text' value={sectionData.descriptor} onChange={(e) => {setSectionData({...sectionData, descriptor: e.target.value})}} />
                                </FormControl>

                                {/*<FormControl>*/}
                                {/*    <FormLabel>Schema</FormLabel>*/}
                                {/*    <Input type='text' value={sectionData.schema} onChange={(e) => {setSectionData({...sectionData, schema: e.target.value})}} />*/}
                                {/*</FormControl>*/}

                                {sections.length > 0 &&
                                    <FormControl>
                                        <FormLabel>Section Place:</FormLabel>
                                        <Select
                                            placeholder='Select Section Place'
                                            value={sectionData.place}
                                            onChange={(e) => {setSectionData({...sectionData, place: e.target.value})}}
                                        >
                                            <option value='start'>Start Of Sections</option>
                                            <option value='end'>End Of Sections</option>
                                            <option value='after'>After</option>
                                         </Select>
                                    </FormControl>
                                }
                                {sectionData.place === 'after' && sections.length > 0 &&
                                    <FormControl ps='25px'>
                                        <FormLabel>Section:</FormLabel>
                                        <Select
                                            placeholder='Select Section'
                                            value={sectionData.section}
                                            onChange={(e) => {setSectionData({...sectionData, section: e.target.value})}}
                                        >
                                            {sections.map((section:any, sectionKey:number) => {
                                                return (
                                                    <option key={section.descriptor} value={sectionKey}>{section.title}</option>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                }
                            </VStack>
                        </ModalBody>

                        <ModalFooter>
                            <Button
                                variant='ghost'
                                mr={3}
                                onClick={onClose}
                            >
                                Close
                            </Button>
                            <Button
                                colorScheme='blue'
                                onClick={() => {if (onSubmit) {onSubmit(currentSectionIndex, data, sectionData)}}}
                            >
                                Save
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            }
        </>
    );
}

export default SectionModal;