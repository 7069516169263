import {useEffect, useState} from "react";
import {Button, Box, HStack, VStack, Select, FormControl, Heading, Spinner} from "@chakra-ui/react";
import SectionModal from "./modals/SectionModal";
import FormModal from "./modals/FormModal";
import RenderForm from "./components/RenderForm";
import DeleteModal from "./modals/DeleteModal";
import DeleteMainItemModal from "./modals/DeleteMainItemModal";
import {Link as ReactRouterLink, useParams} from "react-router-dom";
import useCustomToast from "../../../hooks/useCustomToast";
import {useMutation, useQuery} from "@apollo/client";
import {
    AdminAddFormSchemaMutation,
    AdminAddFormSchemaMutationVariables,
    AdminGetFormSchemaByIdQuery,
    AdminGetFormSchemaByIdQueryVariables,
    AdminUpdateFormSchemaMutation,
    AdminUpdateFormSchemaMutationVariables,
    Form_Schemas_Set_Input
} from "../../../gql/graphql";
import {GET_FORM_SCHEMA_BY_ID} from "../FormSchema/queries";
import {ADD_FORM_SCHEMA, UPDATE_FORM_SCHEMA} from "../FormSchema/mutations";

type FormFieldsType = {
    id: number | null;
    version: string;
    template: string;
    title: string;
    descriptor: string;
    center_id: number;
    tries: number;
};
const FormGenerator = (props:any) => {
    const params = useParams();
    const [isSaving, setIsSaving] = useState(false);
    const toast = useCustomToast();
    const defaultState: FormFieldsType = {
        id: null,
        version: "",
        title: "",
        descriptor: "",
        template: "",
        center_id: Number(process.env.REACT_APP_CENTER_ID),
        tries: 0,
    };
    const [stateData, setStateData] = useState<FormFieldsType>(defaultState);

    const { data, loading, refetch } = useQuery<
        AdminGetFormSchemaByIdQuery,
        AdminGetFormSchemaByIdQueryVariables
    >(GET_FORM_SCHEMA_BY_ID, {
        fetchPolicy: "no-cache",
        variables: {
            id: Number(params.id),
        },
        skip: !params?.id,
    });

    const [addFormSchema] = useMutation<
        AdminAddFormSchemaMutation,
        AdminAddFormSchemaMutationVariables
    >(ADD_FORM_SCHEMA);
    const [updateFormSchema] = useMutation<
        AdminUpdateFormSchemaMutation,
        AdminUpdateFormSchemaMutationVariables
    >(UPDATE_FORM_SCHEMA);

    const saveRecord = async () => {
        setStateData({ ...stateData, tries: stateData.tries + 1 });
        let record: Form_Schemas_Set_Input = {
            center_id: stateData.center_id,
            version: stateData.version,
            descriptor: stateData.descriptor,
            title: stateData.title,
            template: {sections: sections},//JSON.parse(stateData.template),
            updated_at: new Date(),
        };

        if (stateData.id) {
            record.id = stateData.id;

            let result = await updateFormSchema({
                variables: {
                    id: record.id,
                    object: record,
                },
            });

            if (result?.data?.update_form_schemas_by_pk?.id) {
                toast.success("Record Saved Successfully.");
            } else {
                toast.error("There is an error on saving record data.");
            }
        } else {
            let date = new Date();

            let result = await addFormSchema({
                variables: {
                    object: record,
                },
            });

            if (result?.data?.insert_form_schemas_one?.id) {
                setStateData({
                    ...stateData,
                    id: result.data.insert_form_schemas_one.id,
                });
                toast.success("Record Saved Successfully.");
            } else {
                toast.error("There is an error on saving record data.");
            }
        }
    };

    useEffect(() => {
        if (data?.form_schemas_by_pk) {
            let record = data.form_schemas_by_pk;

            let temp: FormFieldsType = {
                id: record.id,
                center_id: record.center_id,
                descriptor: record.descriptor,
                template: JSON.stringify(record.template, null, 4),
                title: record.title || "",
                version: record.version,
                tries: 0,
            };

            setStateData(temp);
            storeSectionsData(record.template.sections);
        }
    }, [data]);



    const [action, setAction] = useState<any>(null)
    const [sections, setSections] = useState<any>([]);
    const [currentSectionIndex, setCurrentSectionIndex] = useState<any>(null)
    const [sectionModalIsOpen, setSectionModalIsOpen] = useState(false)
    const [currentFormIndex, setCurrentFormIndex] = useState<any>(null)
    const [formModalIsOpen, setFormModalIsOpen] = useState(false)
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false)
    const [deleteSectionModalIsOpen, setDeleteSectionModalIsOpen] = useState(false)
    const [deleteFormModalIsOpen, setDeleteFormModalIsOpen] = useState(false)
    const [deleteIndexH, setDeleteIndexH] = useState<any>(null)

    const preDelete = (dih:string) => {
        setDeleteIndexH(dih);
        setDeleteModalIsOpen(true);
    }

    const deleteItem = (indexH:string) => {
        let indices:any = indexH.split('|');
        indices.pop();

        let sectionsTemp = JSON.parse(JSON.stringify(sections));
        let lastIndex = indices.pop();
        let target = indices.reduce((obj:any, index:any) => obj && obj[index], sectionsTemp);

        if (target && lastIndex !== undefined) {
            if (Array.isArray(target)) {
                target.splice(lastIndex, 1);
            } else {
                delete target[lastIndex];
            }
        }

        storeSectionsData(sectionsTemp);
        setDeleteModalIsOpen(false);
    }

    const storeSectionsData = (sections:any) => {
        setSections(sections);
    }

    const preAddSection = () => {
        setAction('add_section')
        setSectionModalIsOpen(true);
    }

    const preEditSection = () => {
        setAction('edit_section')
        setSectionModalIsOpen(true);
    }

    const preDeleteSection = () => {
        setAction('delete_section')
        setDeleteSectionModalIsOpen(true);
    }

    const deleteSection = () => {
        let tempSections = JSON.parse(JSON.stringify(sections));

        tempSections.splice(currentSectionIndex, 1);

        storeSectionsData(tempSections);

        if (canGoToNextSection()) {
            goToNextSection();
        } else if (canGoToPreviousSection()) {
            goToPreviousSection();
        } else {
            setCurrentSectionIndex(null);
        }

        closeDeleteSectionModal();
    }
    const closeDeleteSectionModal = () => {
        setDeleteSectionModalIsOpen(false);
        setAction(null)
    }

    const preDeleteForm = () => {
        setAction('delete_form')
        setDeleteFormModalIsOpen(true);
    }

    const deleteForm = () => {
        let tempSections = JSON.parse(JSON.stringify(sections));
        tempSections[currentSectionIndex].forms.splice(currentFormIndex, 1);

        storeSectionsData(tempSections);

        if (canGoToNextForm()) {
            goToNextForm();
        } else if (canGoToPreviousForm()) {
            goToPreviousForm();
        } else {
            setCurrentFormIndex(null);
        }

        closeDeleteFormModal();
    }
    const closeDeleteFormModal = () => {
        setDeleteFormModalIsOpen(false);
        setAction(null)
    }

    const closeSectionModal = () => {
        setSectionModalIsOpen(false);
        setAction(null)
    }

    const preAddForm = () => {
        setAction('add_form')
        setFormModalIsOpen(true);
    }

    const preEditForm = () => {
        setAction('edit_form')
        setFormModalIsOpen(true);
    }

    const closeFormModal = () => {
        setFormModalIsOpen(false);
        setAction(null)
    }

    const canGoToPreviousSection = () => {

        if (currentSectionIndex === null || currentSectionIndex <= 0) {
            return false;
        }

        return true;
    }

    const goToPreviousSection = () => {
        let nextSectionIndex = currentSectionIndex - 1;

        if (nextSectionIndex >= 0) {
            goToSectionByIndex(nextSectionIndex);
        }
    }

    const canGoToNextSection = () => {

        if (currentSectionIndex === null || currentSectionIndex >= sections.length - 1) {
            return false;
        }

        return true;
    }

    const goToNextSection = () => {

        let nextSectionIndex = currentSectionIndex + 1;

        if (sections.length - 1 >= nextSectionIndex) {
            goToSectionByIndex(nextSectionIndex);
        }
    }

    const goToSectionByIndex = (index:any) => {
        setCurrentSectionIndex(index);

        if (sections[index]?.forms?.length > 0) {
            if (currentFormIndex === null) {
                setCurrentFormIndex(0);
            }
        } else {
            setCurrentFormIndex(null);
        }
    }

    const canGoToPreviousForm = () => {

        if (currentFormIndex === null || currentFormIndex <= 0) {
            return false;
        }

        return true;
    }

    const goToPreviousForm = () => {
        let nextFormIndex = currentFormIndex - 1;

        if (nextFormIndex >= 0) {
            goToFormByIndex(nextFormIndex);
        }
    }

    const canGoToNextForm = () => {
        if (currentFormIndex === null || currentFormIndex >= sections[currentSectionIndex].forms.length - 1) {
            return false;
        }
        return true;
    }

    const goToNextForm = () => {
        let nextFormIndex = currentFormIndex + 1;
        if (sections[currentSectionIndex].forms.length - 1 >= nextFormIndex) {
            goToFormByIndex(nextFormIndex);
        }
    }

    const goToFormByIndex = (index:any) => {
        setCurrentFormIndex(index);
    }

    const storeSection = (currentSectionIndex:number, oldData:any, newData:any) => {

        let sectionData:any = {
            descriptor: newData.descriptor,
            forms: oldData?.forms ? oldData.forms : [],
            schema: newData.schema,
            title: newData.title
        };

        let place = newData.place;
        let sectionIndex = newData.section ? parseInt(newData.section) : currentSectionIndex;
        let tempSections = JSON.parse(JSON.stringify(sections));

        if (place) {
            if (oldData) {
                tempSections.splice(currentSectionIndex, 1);
            }

            switch (place) {
                case 'start':
                    tempSections.unshift(sectionData);
                    sectionIndex = 0;
                    break;
                case 'end':
                    tempSections.push(sectionData);
                    sectionIndex = tempSections.length - 1;
                    break;
                case 'after':
                    if (sectionIndex !== null) {
                        let newIndex = sectionIndex + 1;
                        if (sectionIndex > currentSectionIndex) {
                            newIndex = sectionIndex;
                        }
                        tempSections.splice(newIndex, 0, sectionData);
                        sectionIndex = newIndex;
                    }
                    break;
            }
        } else {
            if (oldData) {
                tempSections[currentSectionIndex] = sectionData;
            } else {
                tempSections.push(sectionData);
                sectionIndex = tempSections.length - 1;
            }
        }

        storeSectionsData(tempSections);
        goToSectionByIndex(sectionIndex);
        closeSectionModal();
    }

    const storeMainForm = (oldData:any, newData:any, indexH:string) => {
        let formData:any = JSON.parse(JSON.stringify(newData));

        delete formData.form;
        delete formData.place;
        delete formData.section;
        delete formData.sectionIndex;

        formData.items = oldData?.items ? oldData.items : [];

        let place = newData.place;
        let formIndex = newData.form ? parseInt(newData.form) : currentFormIndex;
        let tempForm = JSON.parse(JSON.stringify(sections[currentSectionIndex].forms));
        let tempSections = JSON.parse(JSON.stringify(sections));

        if (place) {
            if (oldData) {
                tempForm.splice(currentFormIndex, 1);
            }

            switch (place) {
                case 'start':
                    tempForm.unshift(formData);
                    formIndex = 0;
                    break;
                case 'end':
                    tempForm.push(formData);
                    formIndex = tempForm.length - 1;
                    break;
                case 'after':
                    if (formIndex !== null) {
                        let newIndex = formIndex + 1;
                        if (formIndex > currentFormIndex) {
                            newIndex = formIndex;
                        }
                        tempForm.splice(newIndex, 0, formData);
                    }
                    break;
            }
        } else {
            if (oldData) {
                tempForm[currentFormIndex] = formData;
            } else {
                tempForm.push(formData);
                formIndex = tempForm.length - 1;
            }
        }

        tempSections[currentSectionIndex].forms = tempForm;
        storeSectionsData(tempSections);
        goToFormByIndex(formIndex);
        closeFormModal();
    }

    // useEffect(() => {
    //     if (testData) {
    //         storeSectionsData(testData.sections)
    //     }
    // }, [testData])

    useEffect(() => {

        if (currentSectionIndex !== null) {
            if (sections[currentSectionIndex]?.forms?.length) {
                goToFormByIndex(0);
            }
        }
    }, [currentSectionIndex]);

    useEffect(() => {
        if (sections.length > 0) {
            if (currentSectionIndex === null) {
                goToSectionByIndex(0);
            }
        }
    }, [sections]);

    const setValueInObject = (action:string, data:any, indices:any, newValue:any) => {
        let lastIndex = indices.pop();
        let target = indices.reduce((obj:any, index:any) => obj && obj[index], data);

        if (target && lastIndex) {
            if (action === 'update') {
                target[lastIndex] = newValue;
            } else {
                target[lastIndex].push(newValue);
            }
        }
    }

    const updateData = (type:string, action:string, data:any, pIndex:string) => {
        let indices:any = pIndex.split('|');
        indices.pop();

        let sectionsTemp = JSON.parse(JSON.stringify(sections));

        if (type === 'form') {
            data.items = data?.items ? data.items : [];
        }
        setValueInObject(action, sectionsTemp, [...indices], data);
        storeSectionsData(sectionsTemp);
    }

    const swapItems = (fIndex:number, sIndex:number, index:string) => {
        let indices:any = index.split('|');
        indices.pop();

        let sectionsTemp = JSON.parse(JSON.stringify(sections));
        let lastIndex = indices.pop();
        let target = indices.reduce((obj:any, index:any) => obj && obj[index], sectionsTemp);
        let temp = JSON.parse(JSON.stringify(target[sIndex]));
        target[sIndex] = JSON.parse(JSON.stringify(target[fIndex]));
        target[fIndex] = JSON.parse(JSON.stringify(temp));

        storeSectionsData(sectionsTemp);
    }

    return (
        <Box w='full' p='30px' m='0'>
            <HStack w="full" flexWrap="wrap" mb='30px'>
                <Heading flex="1" fontSize="30px">
                    Edit Form: {data?.form_schemas_by_pk?.title}
                </Heading>
                <Button
                    as={ReactRouterLink}
                    to={"/admin/form_schemas"}
                    variant="btnNormal"
                >
                    Back To List
                </Button>
                <Button
                    as={ReactRouterLink}
                    to={"/admin/form_schemas/edit/" + params.id}
                    variant="btnNormal"
                >
                    Back To Edit Form
                </Button>
                <Button
                    variant="btnMain"
                    minW={120}
                    isDisabled={isSaving}
                    onClick={async () => {
                        setIsSaving(true);
                        // setStateData({ ...stateData, template: value });
                        await saveRecord();
                        setIsSaving(false);
                    }}
                >
                    {isSaving ? <Spinner px="5px"></Spinner> : "Save"}
                </Button>
            </HStack>
            <VStack w='full' gap='15px'>
                <HStack w='full' gap='15px'>
                    <Button
                        onClick={() => {
                            preAddSection();
                        }}
                    >
                        Add New Section
                    </Button>
                    <Button
                        isDisabled={currentSectionIndex === null}
                        onClick={() => {
                            preAddForm();
                        }}
                    >
                        Add New Form
                    </Button>
                </HStack>

                {sections.length > 0 &&
                    <FormControl bg='white' p='15px' borderRadius="5px">
                        <VStack w='full'>
                            <HStack w='full' gap='15px'>
                                <Button
                                    isDisabled={!canGoToPreviousSection()}
                                    onClick={() => {goToPreviousSection()}}
                                >
                                    Previous Section
                                </Button>

                                <Select
                                    value={currentSectionIndex}
                                    flex='1'
                                    onChange={(e) => {
                                        goToSectionByIndex(e.target.value);
                                    }}
                                >
                                {sections.map((sectionItem:any, sectionItemKey:number) => {
                                    return (
                                        <option key={sectionItemKey} value={sectionItemKey}>
                                            {sectionItem?.title}
                                        </option>
                                    )
                                })}
                                </Select>
                                <Button
                                    isDisabled={currentSectionIndex === null}
                                    onClick={() => {preEditSection()}}
                                >
                                    Edit Current Section
                                </Button>
                                <Button
                                    colorScheme='red'
                                    isDisabled={currentSectionIndex === null}
                                    onClick={() => {preDeleteSection()}}
                                >
                                    Delete Current Section
                                </Button>
                                <Button
                                    px='10px'
                                    isDisabled={!canGoToNextSection()}
                                    onClick={() => {goToNextSection()}}
                                >
                                    Next Section
                                </Button>

                            </HStack>
                        </VStack>
                    </FormControl>
                }

                {currentSectionIndex !== null && sections[currentSectionIndex]?.forms.length > 0 &&
                    <Box w='full' p='15px' bg='white' borderRadius="5px">
                        <FormControl>
                            <VStack w='full'>
                                <HStack w='full' gap='15px'>
                                    <Button
                                        isDisabled={!canGoToPreviousForm()}
                                        onClick={() => {goToPreviousForm()}}
                                    >
                                        Previous Form
                                    </Button>

                                    <Select
                                        value={currentFormIndex}
                                        flex='1'
                                        onChange={(e) => {
                                            goToFormByIndex(e.target.value);
                                        }}
                                    >
                                        {sections[currentSectionIndex].forms.map((formItem:any, formItemKey:number) => {
                                            return (
                                                <option key={formItemKey} value={formItemKey}>
                                                    {formItem.title}
                                                </option>
                                            )
                                        })}
                                    </Select>
                                    <Button
                                        isDisabled={currentFormIndex === null}
                                        onClick={() => {preEditForm()}}
                                    >
                                        Edit Current Form
                                    </Button>
                                    <Button
                                        colorScheme='red'
                                        isDisabled={currentFormIndex === null}
                                        onClick={() => {preDeleteForm()}}
                                    >
                                        Delete Current Form
                                    </Button>
                                    <Button
                                        px='10px'
                                        isDisabled={!canGoToNextForm()}
                                        onClick={() => {goToNextForm()}}
                                    >
                                        Next Form
                                    </Button>

                                </HStack>
                            </VStack>
                        </FormControl>
                    </Box>
                }

                {sections[currentSectionIndex]?.forms?.length > 0 && currentFormIndex !== null &&
                    <Box w='full'>
                        <RenderForm
                            indexH={currentSectionIndex + '|forms|' + currentFormIndex + '|'}
                            formObject={sections[currentSectionIndex].forms[currentFormIndex]}
                            currentFormIndex={currentFormIndex}
                            currentSectionIndex={currentSectionIndex}
                            sections={sections}
                            updateForm={(fAction:string, currentSectionIndex:number, oldData:any, newData:any) => {
                                let sectionTemp = JSON.parse(JSON.stringify(sections));
                                sectionTemp[currentSectionIndex].forms[currentFormIndex] = newData;

                                setSections(sectionTemp);

                            }}
                            updateData={updateData}
                            preDelete={preDelete}
                            swapItems={swapItems}
                        ></RenderForm>
                    </Box>
                }

            </VStack>

            { sectionModalIsOpen &&
                <SectionModal
                    onClose={closeSectionModal}
                    sections={sections}
                    currentSectionIndex={currentSectionIndex}
                    data={action === 'edit_section' ? sections[currentSectionIndex] : null}
                    onSubmit={storeSection}
                ></SectionModal>
            }

            { currentSectionIndex !== null && formModalIsOpen &&
                <FormModal
                    onClose={closeFormModal}
                    sections={sections}
                    currentSectionIndex={currentSectionIndex}
                    currentFormIndex={currentFormIndex}
                    indexH={currentSectionIndex + '|forms|' + currentFormIndex + '|'}
                    data={action === 'edit_form' ? sections[currentSectionIndex].forms[currentFormIndex] : null}
                    onSubmit={(oldData:any, newData:any, indexH:string) => {
                        storeMainForm(oldData, newData, indexH);
                    }}
                ></FormModal>
            }

            { deleteModalIsOpen &&
                <DeleteModal
                    onClose={() => {
                        setDeleteModalIsOpen(false)
                    }}
                    indexH={deleteIndexH}
                    onSubmit={(indexH:string) => {
                        deleteItem(indexH);
                    }}
                ></DeleteModal>
            }

            { deleteSectionModalIsOpen &&
                <DeleteMainItemModal
                    onClose={closeDeleteSectionModal}
                    onSubmit={deleteSection}
                    type='Section'
                ></DeleteMainItemModal>
            }

            { deleteFormModalIsOpen &&
                <DeleteMainItemModal
                    onClose={closeDeleteFormModal}
                    onSubmit={deleteForm}
                    type='Form'
                ></DeleteMainItemModal>
            }
        </Box>
    );
}

export default FormGenerator;