import {gql} from "@apollo/client";

export const GET_USER_DATA = gql`
    query getUserData($id: Int!) {
        users_by_pk(id: $id) {
            title
            email
            id
            mobile
            name
        }
    }
`;


export const GET_SQUAREUP_ACCESS = gql`
  query getSquareupAccess {
    temp_squareup_access {
      id
      access_token
    }
  }
`;
