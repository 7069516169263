import { Box, Text } from "@chakra-ui/react";
import { getSquareupBaseUrl } from "../../../clients/squareup/utils";

export const Connect = () => {

    const url = `${getSquareupBaseUrl(process.env.REACT_APP_SQUAREUP_ENV || 'sandbox')}/oauth2/authorize?client_id=${process.env.REACT_APP_SQUAREUP_CLIENT_ID}&scope=CUSTOMERS_READ+APPOINTMENTS_ALL_READ+APPOINTMENTS_READ+APPOINTMENTS_WRITE&response_type=code&redirect_uri=${process.env.REACT_APP_SQUAREUP_REDIRECT}`;
    window.location.href = url;

    return (
        <Box padding={5}>
            <Text>Connecting. Please wait...</Text>
        </Box>
    );
}