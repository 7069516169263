import dayjs from "dayjs";

const DateUtil = () => {

}

const getDbFormat = (date: Date) => {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');

    return year + '-' + month + '-' + day;
}

const getHRFormat = (date: Date) => {
    let year = date.getFullYear();
    let month = date.toLocaleString('default', { month: 'short' })
    let day = date.getDate().toString().padStart(2, '0');
    return day + ' ' + month + ' ' + year;
}

const getDateTimeHRFormat = (date: Date) => {
    return dayjs(date).format('DD MMM YYYY (HH:mm)')
}

const getHRFormatWithOutYear = (date: Date) => {
    let year = date.getFullYear();
    let month = date.toLocaleString('default', { month: 'short' })
    let day = date.getDate().toString().padStart(2, '0');
    return day + ' ' + month;
}

const getDayName = (day:number, type?: string) => {

    const daysOfWeek = [ 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday' ];
    if (type && type === 'full') {
        return daysOfWeek[day];
    }

    return daysOfWeek[day].substring(0, 3);
}

const addMinutesToATime = (time: String, minutes: number) => {

    let temp = time.split(':');

    let startTimeArray: Array<number> = [parseInt(temp[0]), parseInt(temp[1])];

    let durationArray = [0,0];

    durationArray[0] = Math.floor(minutes/60);
    durationArray[1] = minutes - (durationArray[0] * 60);

    startTimeArray[0] += durationArray[0];
    startTimeArray[1] += durationArray[1];

    if (startTimeArray[1] > 59) {
        startTimeArray[1] -= 60;
        startTimeArray[0] += 1;
    }

    if (startTimeArray[0] > 23) {
        startTimeArray[0] -= 24;
    }

    return startTimeArray[0].toString().padStart(2, '0') + ':' + startTimeArray[1].toString().padStart(2, '0');
}

const MonthMap : Record<string, string> = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "May",
    "05": "Apr",
    "06": "Jun",
    "07": "Jul",
    "08": "Aug",
    "09": "Sep",
    "10": "Oct",
    "11": "Nov",
    "12": "Dec"
}

export  {getDbFormat, getHRFormat, getDayName, getHRFormatWithOutYear, addMinutesToATime, getDateTimeHRFormat, MonthMap};
// export default getHRFormat;